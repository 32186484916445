import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgxImageZoomComponent } from 'ngx-image-zoom';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { AppConfig } from '../../app/app.config';
import { ConnectionService } from '../../services/connection-service';
import { EditInstantCheckup } from '../../app/chat/view/instant-checkup/edit-instant-checkup';

@Component({
  selector: 'instant-checkup-magnifier',
  templateUrl: 'instant-checkup-magnifier.html',
  styleUrls: ['./instant-checkup-magnifier.scss'],
})
export class InstantCheckUpMagnifierComponent {
  user: any;
  @Input('user')
  set setUser(user: any) {
    if (!user) return;
    this.user = user;
    this.loadInstantCheckUps();
  }
  @ViewChild('imageLense', { static: false }) ngxImageZoomComponent: NgxImageZoomComponent;
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;
  @Output('needHelpButtonClicked') needHelpButtonClicked: EventEmitter<boolean> = new EventEmitter();
  selectedInstantCheckUpImageUrl: string;
  selectedInstantCheckUpImageIndex: number;
  instantCheckUpImageList: Array<any> = [];
  selectedInstantCheckUpImageCreatedAt: string;
  consultationSessionImagesNames: Array<string> = [];
  constructor(
    public appConfig: AppConfig,
    private dialog: MatDialog,
    private connectionService: ConnectionService) {}

  ngOnInit(): void {
  }

  async loadInstantCheckUps(): Promise<void> {
    const consultationSessionImages = await this.connectionService.getAllConsultationChatImage(this.user);
    this.consultationSessionImagesNames = consultationSessionImages.map((imageLink: string) => imageLink.split('/').pop());
    const instantCheckUpImages = await this.connectionService.fetchUserInstantCheckup({
      userId: this.user.get('username'),
      skip: this.instantCheckUpImageList?.length,
      limit: 15,
      project: ['imagePath', 'type', 'compressedImagePath'],
    });
    instantCheckUpImages.forEach((each_: any) => {
      const each = each_;
      each.differenceInDays = moment().diff(each.createdAt, 'days');
      each.consultationSessionImage = this.consultationSessionImagesNames.includes(each.imagePath.split('?')[0].split('/').pop());
    });
    this.instantCheckUpImageList = [...this.instantCheckUpImageList, ...instantCheckUpImages];
    if (!this.selectedInstantCheckUpImageUrl) {
      this.selectedInstantCheckUpImageUrl = this.instantCheckUpImageList[0].imagePath;
      this.selectedInstantCheckUpImageCreatedAt = `${moment(this.instantCheckUpImageList[0].createdAt)
        .format('DD MMM, YY')} (${this.instantCheckUpImageList[0].differenceInDays} days)`;
      this.selectedInstantCheckUpImageIndex = 0;
    }
  }

  async editPhotoType(index: number, id: string): Promise<void> {
    const imageUrl = this.instantCheckUpImageList[index].imagePath;
    const dialogRef = this.dialog.open(EditInstantCheckup, { data: { id, imageUrl } });
    dialogRef.afterClosed().subscribe((data: any): any => {
      this.instantCheckUpImageList[index].type = data.get('type');
    });
  }
  async openImage(index: number): Promise<void> {
    this.selectedInstantCheckUpImageIndex = index;
    this.selectedInstantCheckUpImageUrl = this.instantCheckUpImageList[this.selectedInstantCheckUpImageIndex].imagePath;
    this.selectedInstantCheckUpImageCreatedAt = `${moment(this.instantCheckUpImageList[this.selectedInstantCheckUpImageIndex].createdAt)
      .format('DD MMM YY')}, (${this.instantCheckUpImageList[this.selectedInstantCheckUpImageIndex].differenceInDays} days)`;
  }

  hidePhoto(): void {
    this.instantCheckUpImageList.splice(this.selectedInstantCheckUpImageIndex, 1);
    this.moveToOtherPhoto(-1);
  }

  moveToOtherPhoto(moveOrder: number): void {
    this.selectedInstantCheckUpImageIndex += moveOrder;
    this.selectedInstantCheckUpImageUrl = this.instantCheckUpImageList[this.selectedInstantCheckUpImageIndex].imagePath;
    this.selectedInstantCheckUpImageCreatedAt = `${moment(this.instantCheckUpImageList[0].createdAt)
      .format('DD MMM YY')}, (${this.instantCheckUpImageList[this.selectedInstantCheckUpImageIndex].differenceInDays} days)`;
  }

  private isScrolledToBottom(): boolean {
    const element = this.scrollContainer.nativeElement;
    const distanceBtwBottom = Math.abs((element.scrollHeight - element.scrollTop) - element.clientHeight);
    return distanceBtwBottom < 50;
  }

  async onScroll(): Promise<void> {
    const isAtBottom = this.isScrolledToBottom();
    if (isAtBottom) {
      await this.loadInstantCheckUps();
    }
  }

  openWaitingForImagePopup(): void {
    this.needHelpButtonClicked.emit();
  }
}
