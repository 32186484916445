
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a class="pl-4" routerLink="/products/medialink">Media Link</a></li>
          <li>View</li>
        </ul>
        <div class="pull-right"><span>
            <button class="btn-xs btn-primary-outline ml-2" routerLink="../../medialink/new">New</button>
            <button class="btn-xs btn-primary-outline ml-2" routerLink="../../medialink/{{mediaLink?.id}}/edit">Edit</button></span></div>
      </div>
    </div>
    <div class="flex-content" *ngIf="loading">
      <div class="col-xs-12">
        <div class="col-xs-12 flex mt-0">
          <label class="col-xs-2">Media Link  Id</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{mediaLink?.id}}</div>
        </div>
        <div class="col-xs-12 flex mt-0">
          <label class="col-xs-2">Unique Id</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{mediaLink?.get('uniqueId')}}</div>
        </div>
        <div class="col-xs-12 flex mt-0" *ngIf="mediaLink?.get('title')">
          <label class="col-xs-2">Title</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{mediaLink?.get('title')}}</div>
        </div>
        <div class="col-xs-12 flex mt-0">
          <label class="col-xs-2">Media Type</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{mediaLink?.get('mediaType')}}</div>
        </div>
        <div class="col-xs-12 flex mt-0">
          <label class="col-xs-2">Media Sub Type</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{mediaLink?.get('mediaSubType')}}</div>
        </div>
        <div class="col-xs-12 flex" *ngIf="products.length">
          <label class="col-xs-2">Products</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8"><a class="inline product-label night" *ngFor="let product of products;" routerLink="/products/catalog/{{product.id}}">{{product?.get('title')}} &nbsp; [{{product?.get('quantity')}}{{product?.get('quantityUnit')}}] {{product?.get('type') === apiClientConstant.Catalog.Type.SAMPLE ? '(SAMPLE)' : '' }}</a></div>
        </div>
        <div class="col-xs-12 flex" *ngIf="mediaLink?.get(&quot;link&quot;)">
          <label class="col-xs-2">Regimen Cover Image</label>
          <div class="col pull-left mt-3">:</div>
          <div class="mt-3"><img style="padding-left:15px" height="200px" widht="200px" src="{{mediaLink?.get('link')}}"/></div>
        </div>
      </div>
    </div>
  </div>
</div>