
<div class="col-xs-12 p-0" *ngIf="user">
  <div class="pull-right extra-collapse-icons">
    <div class="mobile call-icon"><em class="fas fa-phone-alt" (click)="callUserThroughExotel(user.get(&quot;MobileNumber&quot;))" title="Mobile"></em><em class="fas fa-phone-alt alt-2 ml-3" *ngIf="user.get(&quot;alternateNumber&quot;)" (click)="callUserThroughExotel(user.get(&quot;alternateNumber&quot;))" title="Alternate Mobile"></em></div>
  </div>
  <collapse title="Call Logs" iconClass="fas fa-prescription-bottle-alt" extraClass="highlight-head">
    <div class="col-xs-12 of-auto" style="max-height:185px;">
      <div class="col-xs-12 bold">
        <div class="col-xs-2">Direction</div>
        <div class="col-xs-2">operator</div>
        <div class="col-xs-2">Status</div>
        <div class="col-xs-3">Called At</div>
        <div class="col-xs-2">Duration</div>
      </div>
      <div class="col-xs-12 p-0 mt-3" *ngFor="let call of callLogs;let i = index;">
        <div class="col-xs-2">{{call.get('direction')}}</div>
        <div class="col-xs-2">{{operatorList[['outgoing', 'outbound-api'].includes(call.get('direction')) ? call.get('from') : call.get('to')]}}<span *ngIf="!operatorList[([&quot;outgoing&quot;, &quot;outbound-api&quot;].includes(call.get(&quot;direction&quot;))) ? call.get(&quot;from&quot;) : call.get(&quot;to&quot;)]">{{call.get('direction')==='outgoing' ? call.get('from') : call.get('to')}}</span></div>
        <div class="col-xs-2">{{call.get('status')}}</div>
        <div class="col-xs-3">{{call.get('calledAt')}}</div>
        <div class="col-xs-2">{{call.get('conversationDuration')}}</div>
      </div>
    </div>
  </collapse>
</div>