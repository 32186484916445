import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ConnectionService } from 'src/services/connection-service';
import { ServerAPIModels } from 'src/app/server-api.constants';
import { Broadcaster } from 'src/components/broadcaster';
import moment from 'moment';
import { Table } from 'api-client';

@Component({
  selector: 'dashboard-activity',
  templateUrl: './activity.html',
  styleUrls: ['../dashboard.scss'],
  providers: [DatePipe],
})
export class DashboardActivityComponent {
  user: Table.User;
  startDate: Date;
  endDate: Date;

  @Input('userDetails')
  set updateStartDate(userDetails: { startDate: Date, endDate: Date, user: Table.User }) {
    this.startDate = userDetails.startDate;
    this.endDate = userDetails.endDate;
    this.user = userDetails.user;
    this.updateActivityDetails();
  }

  constructor(private conn: ConnectionService,
    private broadcaster: Broadcaster,
    private datePipe: DatePipe,
  ) {}

  webActivityTime: Array<{
    activeTime: number,
    productiveTime: number,
    breaks: number,
    loginTime?: string,
    avgApprovalTime: number,
    avgCheckupTime: number,
    avgTicketTime: number }> = [];
  labels: Array<string> = [];
  maxWorkingHour: number = 10;
  avgApprovalTime: number;
  avgCheckupTime: number;
  avgTicketTime: number;
  numberOfBreaks: number;
  firstLoginTime: string;

  async updateActivityDetails(): Promise<void> {
    if (this.startDate && this.endDate && this.user) {
      this.webActivityTime = [];
      this.labels = [];
      await this.getActivityData();
      this.addLabels();
    }
  }

  getYAxisLabels(): number[] {
    const labels = [];
    for (let i = this.maxWorkingHour; i >= 0; i -= 2) {
      labels.push(i);
    }
    return labels;
  }

  addLabels(): void {
    const current: Date = this.startDate;
    while (current < this.endDate) {
      const formattedDate = moment(current).format('YYYY-MM-DD');
      this.labels.push(formattedDate);
      current.setDate(current.getDate() + 1);
    }
  }

  async getActivityData(): Promise<void> {
    try {
      const response = await this.conn.getUserActivityData(this.user.id, this.startDate.toDateString(), this.endDate.toDateString());
      const activityData = response.data;
      let activeDays = 0;
      let sumOfAvgApprovalTime = 0;
      let sumOfAvgFollowUpTime = 0;
      let sumOfAvgTicketTime = 0;
      activityData.result.forEach((item: ServerAPIModels['MonitoringDataInnerResponse']) => {
        if (Object.keys(item).length === 0) {
          this.webActivityTime.push({
            activeTime: 0,
            productiveTime: 0,
            breaks: 0,
            avgApprovalTime: 0,
            avgCheckupTime: 0,
            avgTicketTime: 0,
          });
        } else {
          this.webActivityTime.push({
            activeTime: item.activeTimeInHour,
            productiveTime: item.workingTimeInHour,
            breaks: item.numberOfBreaks,
            loginTime: moment(item.startOfTheDay).utcOffset('+05:30').format('HH:mm'),
            avgApprovalTime: item.averageOrderTime,
            avgCheckupTime: item.averageFollowupTime,
            avgTicketTime: item.averageTicketTime,
          });
          activeDays += 1;
          sumOfAvgApprovalTime += item.averageOrderTime;
          sumOfAvgFollowUpTime += item.averageFollowupTime;
          sumOfAvgTicketTime += item.averageTicketTime;
        }
      });
      this.webActivityTime.pop();
      this.numberOfBreaks = this.webActivityTime[this.webActivityTime.length - 1].breaks;
      this.avgApprovalTime = (sumOfAvgApprovalTime / activeDays);
      this.avgCheckupTime = (sumOfAvgFollowUpTime / activeDays);
      this.avgTicketTime = (sumOfAvgTicketTime / activeDays);
      this.firstLoginTime = this.webActivityTime[this.webActivityTime.length - 1].loginTime;
    } catch (error) {
      if (error.length) {
        this.broadcaster.broadcast('NOTIFY', { message: error });
      }
    }
  }

  getFormattedDate(date: string): string {
    return this.datePipe.transform(date, 'dd MMM');
  }

  convertToMinutesAndSecond(hours: number): string {
    const totalMinutes = hours * 60;
    const minutes = Math.floor(totalMinutes);
    const seconds = Math.round((totalMinutes - minutes) * 60);
    const result = `${minutes} min ${seconds} sec`;
    return result;
  }
}
