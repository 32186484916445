
<div class="flex justifyC" id="loginModal" tabindex="-1" role="dialog" aria-hidden="true">
  <form class="form col-md-12 pos-fix vh-center" id="loginForm" (submit)="login()"><img class="center-block" id="logo" src="https://cdn.cureskin.com/app/img/logo_white.svg"/>
    <div *ngIf="!disableUserPasswordLogin">
      <div class="form-group">
        <input class="form-control input-lg" id="username" type="text" name="username" [(ngModel)]="user.username" placeholder="Username" required="required"/>
      </div>
      <div class="form-group">
        <input class="form-control input-lg" id="password" type="password" name="password" [(ngModel)]="user.password" placeholder="Password" required="required"/>
      </div>
      <div class="form-group flex justifyC">
        <button type="submit">Sign In</button>
      </div>
    </div>
    <div id="google-btn"></div>
    <div class="text-danger">{{ui.error}}</div>
  </form>
</div>