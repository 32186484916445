
<div class="title px-3" [ngClass]="{&quot;list-header&quot;: showImagesInColumn}"><em class="fas fa-portrait pr-3"></em><span>Instant Checkups</span><em class="fa fa-plus-square-o pl-3" (click)="openImageUploadDialog()"></em>
  <div class="pull-right">
    <label class="pull-right m-0 text-info pointer mt-2" *ngIf="comparePhoto.length &gt;= 1" (click)="clearComparePhoto()">Clear</label>
    <label class="pull-right m-0 text-info pointer mt-2 mr-3" *ngIf="comparePhoto.length === 2" (click)="openComparison()">COMPARE PHOTOS</label>
  </div>
</div>
<div class="col-xs-12 p-0">
  <div class="of-auto" id="instant_checkups" style="max-height:200px;" [ngClass]="{&quot;flex&quot;: showImagesInColumn}" [beforeBottomView]="beforeBottomCallback">
    <div class="fontS mb-3 col-xs-12 flex p-0" *ngFor="let instantCheckup of instantCheckups; let i = index;" title="{{instantCheckup.createdAt | amDateFormat: &quot;MMM DD, YYYY hh:mm a&quot;}}">
      <div class="col-xs-12 px-3 py-2" [ngStyle]="{&quot;background-color&quot; : instantCheckup.tags?.includes(&quot;GOOD&quot;) || instantCheckup.tags?.includes(&quot;AI_GOOD&quot;)? &quot;#e4fbef&quot;: &quot;&quot;}">
        <input class="checkBox mr-3 min-height" *ngIf="!showImagesInColumn" type="checkbox" [checked]="comparePhoto.includes(instantCheckup.objectId)" name="{{instantCheckup.objectId}}" (click)="onSelectInstantCheckup($event,instantCheckup.objectId)"/><a class="mr-2" *ngIf="instantCheckup.thumbnailImagePath || instantCheckup.imagePath" href="{{baseUrlForWebApp}}/user/instantCheckup/{{instantCheckup.objectId}}?username={{chatUser.get('username')}}" target="_blank"><img [src]="instantCheckup.thumbnailImagePath || instantCheckup.imagePath" style="height: 100px; width: 100px"/></a><span class="mr-2" *ngIf="!instantCheckup.thumbnailImagePath"><a class="text-info" href="{{baseUrlForWebApp}}/user/instantCheckup/{{instantCheckup.objectId}}?username={{chatUser.get('username')}}" target="_blank">({{instantCheckup.type}})</a></span><span class="mr-2" *ngIf="i &lt; instantCheckups.length">{{instantCheckup.createdAt | amDateFormat: 'MMM DD YYYY'}} ({{instantCheckup.type}}<i class="fa fa-pencil-square-o px-1" (click)="openInstantCheckupEditPopup(instantCheckup.objectId,instantCheckup.imagePath)"></i><span>)({{instantCheckup.source}}) ({{ instantCheckup.timeDifference}} days)</span></span>
      </div>
    </div>
  </div>
</div>