
<div class="col-xs-12 p-0" *ngIf="attendToChatCache">
  <div class="col-xs-12 p-0 flex mb-3" *ngIf="!hideChatStatus">
    <div class="col-xs-3">
      <div class="bold">Chat Status ({{(attendToChatCache.onlineStatusUpdateTime > (dateNow - 10 * 60000)) ? 'Online': 'Offline'}})</div>
    </div>
    <div class="pull-left pr-3">:</div>
    <div class="col-xs-8">
      <button class="btn-xs btn-danger" *ngIf="!attendToChatCache.attended" (click)="openModal()">UnAttended ({{messages.length}})</button>
      <button class="btn-xs btn-secondary" *ngIf="attendToChatCache.attended" (click)="markChatUnAttended()">Attended</button>
      <waitlist-chat class="ml-2" [user]="userObj"></waitlist-chat>
    </div>
  </div>
  <div class="col-xs-12 flex mb-3">
    <div class="col-xs-3">
      <div class="bold">Label</div>
    </div>
    <div class="pull-left pr-3">:</div>
    <div class="col-xs-8 text-nowrap flex flex-wrap">
      <select (change)="autoCompleteOnSelect($event, 'userLabel')">
        <option selected="selected" hidden="hidden">Choose a label</option>
        <option *ngFor="let option of chatLabelOptions" [value]="option">{{option}}</option>
      </select>
      <div class="label label-primary ml-2 mt-2 px-3 py-2" *ngFor="let label of assignedUserLabelList; let i = index;" [style]="{ backgroundColor: label.color }">{{label.name}}<i class="fa fa-trash-o ml-2" (click)="removeItem(i, &quot;userLabel&quot;)"></i></div>
    </div>
  </div>
  <div class="col-xs-12 p-0 flex mb-3" *ngIf="!hideChatStatus">
    <div class="col-xs-3 bold"><span class="pr-2">Assign Team</span><i class="fa fa-history" (click)="openAssignedChatHistory()"></i></div>
  </div>
  <div class="col-xs-12 p-0 font-bold my-2" *ngIf="pendingAssignedChats?.length">
    <div class="col-xs-2 p-0">Assigned Team</div>
    <div class="col-xs-2 p-0">Assigned By</div>
    <div class="col-xs-8 p-0">Message</div>
  </div>
  <div class="col-xs-12 p-0 mt-2" *ngFor="let item of pendingAssignedChats;let i = index;">
    <div class="col-xs-2 p-0">{{item.team}}<i class="fa fa-trash-o pointer ml-2" (click)="unAssignChat(item, i)"></i></div>
    <div class="col-xs-2 p-0">{{item.assignedBy}}</div>
    <div class="col-xs-8 p-0">{{item.message}}</div>
  </div>
</div>
<ng-template #blockedMessageDialog>
  <mat-dialog-content style="max-height:100%;height:100% !important;">
    <div class="flex-box">
      <div class="flex-heading">
        <div class="col-xs-12 m-b-xs">
          <button class="pull-right btn-success btn-xs" type="button" [mat-dialog-close]="" aria-label="Close"><span aria-hidden="true">×</span></button>
          <div class="h3">Blocked Messages</div>
        </div>
        <div class="line bg-gd-dk m-b-xs no-margin-top"></div>
      </div>
      <div class="flex-content">
        <div class="col-xs-12 m-t-xs p-0" *ngFor="let message of messages;">
          <div class="col-xs-2">
            <input type="checkbox" [(ngModel)]="message.checked"/>&nbsp;&nbsp;
            {{message.Mode}}
          </div>
          <div class="col-xs-10">{{message.Message}}</div>
        </div>
      </div>
      <div class="flex-footer">
        <div class="btn btn-sm btn-danger" (click)="markStatusTo(appConfig.Shared.BlockAssistant.BlockStage.DELETED)">Delete</div>
        <div class="btn btn-sm btn-danger" style="margin:10px;" (click)="markAll()">Mark all</div>
        <div class="btn btn-sm btn-success pull-right" (click)="markStatusTo(appConfig.Shared.BlockAssistant.BlockStage.MOVE_TO_ASSISTANT)">Send Message To User</div>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>
