
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header"><a routerLink="/marketing/article">Article / {{id || 'New'}}</a></div>
    </div>
    <div class="flex-content">
      <div class="col-xs-12 p-0 pb-4">
        <form class="p-4" #articleForm="ngForm" (submit)="saveArticle()">
          <div class="col-xs-12">
            <div class="col-xs-6">
              <label class="col-xs-12 mt-0">Internal title for article</label>
              <div class="col-xs-12">
                <language-string-selection [parseObj]="articleObj" name="titleLanguageString" [required]="true" [tags]="[&quot;article&quot;,&quot;internal_title&quot;]"></language-string-selection>
              </div>
            </div>
            <div class="col-xs-6">
              <label class="col-xs-12 mt-0">Toolbar title</label>
              <div class="col-xs-12">
                <language-string-selection [parseObj]="articleObj" name="toolbarTitleLanguageString" [required]="true" [tags]="[&quot;article&quot;,&quot;toolbar_title&quot;]"></language-string-selection>
              </div>
            </div>
            <div class="col-xs-6">
              <label class="col-xs-12">Click To Action</label>
              <div class="col-xs-10">
                <input-select [parseObj]="articleObj" name="clickToAction" [options]="clickToActionOptions" [required]="true"></input-select>
              </div>
            </div>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-4">Products</label>
            <div class="col-xs-12" *ngIf="currentAttachedCatalog">
              <div class="badge mb-3">{{currentAttachedCatalog.get('title')}}&nbsp;[{{currentAttachedCatalog.get('quantity')}}{{currentAttachedCatalog.get('quantityUnit')}}] {{currentAttachedCatalog.get('type') === apiClientConstant.Catalog.Type.SAMPLE ? '(SAMPLE)' : '' }}<span class="pl-2 far fa-trash-alt" (click)="removeCatalog()"></span></div>
            </div>
            <div class="col-xs-12">
              <input class="form-control" type="text" placeholder="Type Product Name..." matInput="matInput" name="add_product" autocomplete="off" [formControl]="autoCompleteProductController" [matAutocomplete]="productAuto"/>
              <mat-autocomplete class="search-bar-xs" #productAuto="matAutocomplete">
                <mat-option *ngFor="let option of productOptions | async" [value]="option.name" (onSelectionChange)="onProductSelection(option)">{{option.name}}</mat-option>
              </mat-autocomplete>
            </div>
          </div>
          <div class="col-xs-6" *ngIf="articleObj.get(&quot;clickToAction&quot;) === appConfig.Shared.ArticleData.ClickToAction.BUY_REGIMEN">
            <label class="col-xs-12">Regimen Class</label>
            <div class="col-xs-10">
              <input-select [parseObj]="articleObj" name="class" [options]="classOptions" [required]="true"></input-select>
            </div>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-12">Conditions</label>
            <div class="col-xs-12">
              <question-condition [conditions]="this.articleObj.get(&quot;conditions&quot;)"></question-condition>
            </div>
          </div>
          <div class="col-xs-12" *ngIf="articleObj.get(&quot;clickToAction&quot;) === appConfig.Shared.ArticleData.ClickToAction.BUY_PRODUCT">
            <label class="col-xs-12">Products</label>
            <div class="col-xs-10 no-padder-right flex" style="flex-wrap:wrap"><i class="fa fa-plus-square-o pointer m-t-xs m-l-xs" (click)="openAddProductModal()"></i></div>
            <div class="col-xs-10 no-padder-right flex" *ngIf="articleObj.get(&quot;product&quot;)">
              <div class="col-xs-12">{{articleObj.get("product").get('title')}}</div>
            </div>
          </div>
          <div class="col-xs-12">
            <div class="col-xs-6">
              <label class="col-xs-12">Select template</label>
              <div class="col-xs-10">
                <input-select [parseObj]="articleObj" name="template" [options]="templateOptions" [required]="true"></input-select>
              </div>
            </div>
            <div class="col-xs-6">
              <label class="col-xs-6">Script Link</label>
              <div class="col-xs-10">
                <input-text [parseObj]="articleObj" name="scriptLink" placeholder="Script link"></input-text>
              </div>
            </div>
          </div>
          <div class="col-xs-12" *ngIf="articleObj.get(&quot;clickToAction&quot;) === appConfig.Shared.ArticleData.ClickToAction.SHARE">
            <label class="col-xs-12">Share image link</label>
            <div class="col-xs-12">
              <language-string-selection [parseObj]="articleObj" required="required" name="shareImageUrlLanguageString" [required]="true" [tags]="[&quot;article&quot;,&quot;share_image&quot;]"></language-string-selection>
            </div>
          </div>
          <div class="col-xs-12" *ngIf="articleObj.get(&quot;template&quot;) === appConfig.Shared.ArticleData.Template.pug && articleObj.get(&quot;clickToAction&quot;) !== appConfig.Shared.ArticleData.ClickToAction.SHARE">
            <label class="col-xs-12">Article HTML</label>
            <div class="col-xs-10">
              <div class="col-xs-12">
                <language-string-multiple-selection-with-tag [parseObj]="articleObj.get(&quot;params&quot;)" name="srcLanguageString" [required]="true" [tags]="[&quot;article&quot;,&quot;article_html_content&quot;]"></language-string-multiple-selection-with-tag>
              </div>
            </div>
          </div>
          <div class="col-xs-12 mt-3" *ngIf="articleObj.get(&quot;html&quot;)">
            <input-textarea [parseObj]="articleObj" name="html" [rows]="9" [cols]="3" placeholder="HTML" required="true" [disabled]="true"></input-textarea>
          </div>
          <div class="col-xs-6" *ngIf="articleObj.get(&quot;template&quot;) === &quot;imageArticle&quot;">
            <label class="col-xs-12"></label>
            <div class="col-xs-12">
              <language-string-multiple-selection-with-tag title="Upload Article Image" [parseObj]="articleObj.get(&quot;params&quot;)" name="articleImagesLanguageString" [required]="true" [tags]="[&quot;article_image&quot;]" [showUploadButton]="true"></language-string-multiple-selection-with-tag>
            </div>
          </div>
          <div class="col-xs-6" *ngIf="articleObj.get(&quot;template&quot;) === &quot;videoArticle&quot;">
            <label class="col-xs-12">URL</label>
            <div class="col-xs-12">
              <language-string-selection [parseObj]="articleObj.get(&quot;params&quot;)" name="urlLanguageString" [required]="true" [tags]="[&quot;article&quot;,&quot;url&quot;]"></language-string-selection>
            </div>
          </div>
          <div class="col-xs-12" *ngIf="![&quot;html&quot;, &quot;imageArticle&quot;, &quot;videoArticle&quot;].includes(articleObj.get(&quot;template&quot;))">
            <div class="col-xs-6">
              <label class="col-xs-12">Heading</label>
              <div class="col-xs-12">
                <language-string-selection [parseObj]="articleObj.get(&quot;params&quot;)" name="headingLanguageString" [required]="true" [tags]="[&quot;article&quot;,&quot;html_heading&quot;]"></language-string-selection>
              </div>
            </div>
            <div class="col-xs-6">
              <label class="col-xs-3" *ngIf="articleObj.get('template') !== 'dietArticle'">Image/Video URL</label>
              <div class="col-xs-12" *ngIf="articleObj.get('template') !== 'dietArticle'">
                <language-string-selection [parseObj]="articleObj.get(&quot;params&quot;)" name="urlLanguageString" [required]="true" [tags]="[&quot;article&quot;,&quot;url&quot;]"></language-string-selection>
              </div>
            </div>
            <div class="col-xs-6" *ngIf="articleObj.get('template') === 'dietArticle'">
              <label class="col-xs-3">Nutrition facts</label>
              <div class="col-xs-12">
                <language-string-selection [parseObj]="articleObj.get(&quot;params&quot;)" name="nutritionFactLanguageString" [required]="true" [tags]="[&quot;article&quot;,&quot;nutrition_fact&quot;]"></language-string-selection>
              </div>
            </div>
            <div class="col-xs-6">
              <label class="col-xs-12">Description</label>
              <div class="col-xs-12">
                <language-string-selection [parseObj]="articleObj.get(&quot;params&quot;)" name="descriptionLanguageString" [required]="true" [tags]="[&quot;article&quot;,&quot;html_description&quot;]"></language-string-selection>
              </div>
            </div>
            <div class="col-xs-6" *ngIf="articleObj.get(&quot;template&quot;) === &quot;productArticle&quot;">
              <label class="col-xs-12">Section below image</label>
              <div class="col-xs-12">
                <language-string-selection [parseObj]="articleObj.get(&quot;params&quot;)" name="sectionLanguageString" [required]="true" [tags]="[&quot;article&quot;,&quot;html_section&quot;]"></language-string-selection>
              </div>
              <label class="col-xs-12">Image group
                <language-string-multiple-selection-with-tag [parseObj]="articleObj.get(&quot;params&quot;)" name="srcLanguageString"></language-string-multiple-selection-with-tag>
              </label>
            </div>
          </div>
          <div class="col-xs-12" *ngIf="articleObj.get(&quot;template&quot;) === &quot;generalArticle&quot;">
            <div class="col-xs-12">
              <div class="col-xs-6"></div>
              <label class="col-xs-12">Section below image</label>
              <div class="col-xs-12">
                <language-string-selection [parseObj]="articleObj.get(&quot;params&quot;)" name="sectionLanguageString" [required]="true" [tags]="[&quot;article&quot;,&quot;html_section&quot;]"></language-string-selection>
              </div>
              <div class="col-xs-6">
                <label class="col-xs-12">Numbered list</label>
                <div class="col-xs-12" *ngFor="let item of articleObj.get('params').list;">
                  <input class="col-xs-10" [(ngModel)]="item.heading" name="listHeading" type="text" placeholder="Numbered list headings"/>
                  <input class="col-xs-10 mt-3" [(ngModel)]="item.content" name="listContent" type="text" placeholder="Numbered list content"/>
                </div>
              </div>
            </div>
            <div class="col-xs-12 mt-3">
              <button type="button" name="list" (click)="articleObj.get(&quot;params&quot;).list.push({})">Add</button>
            </div>
            <div class="col-xs-12">
              <label class="col-xs-12">Sections below list</label>
              <div class="col-xs-12" *ngFor="let item of articleObj.get('params').sections;">
                <input class="col-xs-10" [(ngModel)]="item.text" name="sectionsText" type="text" placeholder="Paragraphs Below list"/>
              </div>
              <div class="col-xs-12 mt-3">
                <button type="button" name="section" (click)="articleObj.get(&quot;params&quot;).sections.push({})">Add</button>
              </div>
            </div>
          </div>
          <div class="col-xs-12" *ngIf="articleObj.get(&quot;template&quot;) === &quot;dietArticle&quot;">
            <div class="col-xs-12 no-padder">
              <label class="col-xs-3">Diet card<i class="fa fa-plus-square-o m-l-xs pointer" (click)="openAddDietCardModal()"></i></label>
              <div class="col-xs-12" *ngFor="let item of articleObj.get('params').diet;">
                <div class="b-a col-xs-12">
                  <div class="col-xs-12 no-padder">
                    <div class="col-xs-2">URL</div>
                    <div class="col-xs-1 no-padder" style="width:5px;">:</div>
                    <div class="col-xs-8">{{item.url}}</div>
                  </div>
                  <div class="col-xs-12 no-padder">
                    <div class="col-xs-2">Heading</div>
                    <div class="col-xs-1 no-padder" style="width:5px;">:</div>
                    <div class="col-xs-8">{{item.heading}}</div>
                  </div>
                  <div class="col-xs-12 no-padder">
                    <div class="col-xs-2">Sub Heading</div>
                    <div class="col-xs-1 no-padder" style="width:5px;">:</div>
                    <div class="col-xs-8">{{item.subheading}}</div>
                  </div>
                  <div class="col-xs-12 no-padder">
                    <div class="col-xs-2">Text</div>
                    <div class="col-xs-1 no-padder" style="width:5px;">:</div>
                    <div class="col-xs-8">{{item.text}}</div>
                  </div>
                  <div class="col-xs-12 no-padder">
                    <div class="col-xs-2">Sub Text</div>
                    <div class="col-xs-1 no-padder" style="width:5px;">:</div>
                    <div class="col-xs-8">{{item.subtext}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 no-padder">
              <label class="col-xs-3">Lifestyle card<i class="fa fa-plus-square-o m-l-xs pointer" (click)="openAddLifestyleModal()"></i></label>
              <div class="col-xs-12" *ngFor="let item of articleObj.get('params').lifestyle;">
                <div class="b-a col-xs-12">
                  <div class="col-xs-12 no-padder">
                    <div class="col-xs-2">URL</div>
                    <div class="col-xs-1 no-padder" style="width:5px;">:</div>
                    <div class="col-xs-8">{{item.url}}</div>
                  </div>
                  <div class="col-xs-12 no-padder">
                    <div class="col-xs-2">Heading</div>
                    <div class="col-xs-1 no-padder" style="width:5px;">:</div>
                    <div class="col-xs-8">{{item.heading}}</div>
                  </div>
                  <div class="col-xs-12 no-padder">
                    <div class="col-xs-2">Text</div>
                    <div class="col-xs-1 no-padder" style="width:5px;">:</div>
                    <div class="col-xs-8">{{item.text}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12">
            <button class="my-3" type="submit" [disabled]="!articleForm.valid">{{ articleObj.id ? 'Update' : 'Create' }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
