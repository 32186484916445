import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ConnectionService } from '../../../services/connection-service';
import { userFieldsToSkip, consultationFieldsToSkip } from './fields-to-skip-in-chat-summary';
import { DataSelectModal } from '../../modal/dataSelectModal/index';

@Component({
  selector: 'user-chat-view-summary',
  templateUrl: './user-chat-view-summary.html',
})
export class UserChatViewSummaryComponent implements OnDestroy {
  subscriptions: Array<Subscription> = [];
  attendToChatCacheObj: any;
  @Input('user')
  set setUser(user: any) {
    if (!user) {
      return;
    }
    this.user = user;
    this.loadSummary();
  }
  @Input('fromNewOrderApprovalPage') fromNewOrderApprovalPage: boolean;
  @Input('isProductOrder') isProductOrder: boolean;
  @Input('regimenClass')
  set setRegimenClass(regimenClass: 'FACE' | 'HAIR' | 'BODY') {
    switch (regimenClass) {
      case 'FACE': this.showFaceConsultation = true; break;
      case 'HAIR': this.showHairConsultation = true; break;
      case 'BODY': this.showBodyConsultation = true; break;
      default:
    }
  }
  user: any;
  currentRegimenClass: string = this.setRegimenClass;
  questionIds: Array<string> = [];
  questionTitleMap: { [key: string]: string } = {};
  faceConsultation: Array<{ key: string; value: string; }> = [];
  hairConsultation: Array<{ key: string; value: string; }> = [];
  bodyConsultation: Array<{ key: string; value: string; }> = [];
  userDetails: Array<{ key: string; value: string; }> = [];
  importantDetails: Array<string> = ['FemalePregOrNewMom', 'FemaleMarried', 'FemaleHormonal', 'FemaleBreastfeeding',
    'FemaleHormonalUnderAge', 'FollowUpFemaleHormonal', 'FemaleHormonal', 'PastSkinTreatment'];
  followupAnswers: Array<{ key: string; value: string; createdAt: string }> = [];
  showUserDetails: boolean = true;
  showFaceConsultation: boolean = false;
  showHairConsultation: boolean = false;
  showBodyConsultation: boolean = false;
  showFollowUpAnswers: boolean = true;
  labelOptions: Array<string> = [];
  thyroidOrSeriousIllness: Array<string> = ['Low iron', 'thyroid', 'Deficiency of any vitamin',
    'Significant weight loss in the last 6 months', 'Feeling excessively tired or stressed', 'None of the above', 'I don\'t know.'];
  labels: Array<string> = [];
  constructor(private conn: ConnectionService,
    private dialog: MatDialog) {
  }

  async ngOnInit(): Promise<void> {
    if (this.fromNewOrderApprovalPage) {
      this.importantDetails = [];
    }
    const labelList = JSON.parse(JSON.stringify(await this.conn.findAllTags()));
    labelList.forEach((label: any) => this.labelOptions.push(label.name));
  }

  private async loadSummary(): Promise<void> {
    await this.fetchQuestionAndUpdateThyroidOptions();
    await Promise.all([
      this.generateUserSummary(),
      this.generateConsultationSummary(),
      this.generateTemporaryAnswerSummary(),
      this.getUserLabels(),
    ]);
    await this.updateQuestionTitles();
  }

  async fetchQuestionAndUpdateThyroidOptions(): Promise<void> {
    const question = await this.conn.findQuestions({ where: { uniqueIdentifier: 'ThyroidOrSeriousIllness' } });
    const title = question[0]?.get('title');
    const regex = /^\d+\.\s(.+)$/gm;
    const options = [];
    let match;
    // eslint-disable-next-line no-cond-assign
    while ((match = regex.exec(title)) !== null) {
      options.push(match[1]);
    }
    this.thyroidOrSeriousIllness = options;
  }

  private async generateConsultationSummary(): Promise<void> {
    const consultationSessions = (await this.conn.findConsultationSession({
      where: { user: this.user, archive: false },
    })).map((each: any) => each.toJSON());
    consultationSessions.forEach((each_: { [key: string]: unknown }) => {
      const each = each_;
      const className = each.PrivateMainConcernClass as string;
      const currentConsultation = `${className.toLowerCase()}Consultation`;
      consultationFieldsToSkip.forEach((key: string) => delete each[key]);
      Object.keys(each).forEach((key: string) => {
        if (each[key] === 'auto-response' || each[key] === 'Get started!') {
          delete each[key];
          return;
        }
        this[currentConsultation].push({ key, value: each[key] });
        this.questionIds.push(key);
      });
    });
    this.hairConsultation.forEach((element: { key: string, value: string }, index: any): any => {
      if (element.key === 'familyHistoryForHairfall' && element.value === 'None') {
        this.hairConsultation.splice(index, 1);
      }
      if (element.key === 'dandruffOrWhiteFlakes' && element.value === 'No') {
        this.hairConsultation.splice(index, 1);
      }
      if (element.key === 'anyBloodTestInFewMonths' && element.value === 'No') {
        this.hairConsultation.splice(index, 1);
      }
    });
    this.updateConsultation(this.hairConsultation);
    this.updateConsultation(this.bodyConsultation);
    this.updateConsultation(this.faceConsultation);
    this.updateConsultation(this.userDetails);
  }

  private updateConsultation(ConsultationArray: Array<any>): void {
    const privateMainIndex = ConsultationArray.findIndex((obj: { key: string }) => obj.key === 'PrivateMainConcern');
    if (privateMainIndex !== -1) {
      ConsultationArray.splice(privateMainIndex, 1, { key: 'MainConcern', value: ConsultationArray[privateMainIndex].value });
    }
    const thyroidOrSeriousIllnessIndex = ConsultationArray.findIndex((obj: { key: string }) => obj.key === 'ThyroidOrSeriousIllness');
    if (thyroidOrSeriousIllnessIndex !== -1) {
      let thyroidOrSeriousIllnessValue: any = ConsultationArray[thyroidOrSeriousIllnessIndex].value;
      thyroidOrSeriousIllnessValue = this.convertToNumberIfValid(thyroidOrSeriousIllnessValue);
      if (!isNaN(thyroidOrSeriousIllnessValue)) {
        ConsultationArray.splice(thyroidOrSeriousIllnessIndex,
          1,
          { key: 'ThyroidOrSeriousIllness', value: this.thyroidOrSeriousIllness[thyroidOrSeriousIllnessValue - 1] });
      }
    }
    const genericeIndex = ConsultationArray.findIndex((obj: { key: string }) => obj.key === 'anythingElseGeneric');
    if (genericeIndex !== -1) {
      if (this.fromNewOrderApprovalPage && ConsultationArray[genericeIndex].value === 'None') {
        ConsultationArray.splice(genericeIndex, 1);
      } else {
        ConsultationArray.splice(genericeIndex, 1, { key: 'QueryForDoctor', value: ConsultationArray[genericeIndex].value });
      }
    }
  }

  public convertToNumberIfValid(input: string): any {
    if (/^[0-9]+(\.[0-9]*)?$/.test(input)) {
      return parseFloat(input);
    }
    return NaN;
  }

  private async updateQuestionTitles(): Promise<void> {
    const questions = await this.conn.findQuestions({
      where: { uniqueIdentifier: this.questionIds },
      project: ['title', 'uniqueIdentifier'],
      limit: this.questionIds.length,
    });
    questions.forEach((question: any) => this.questionTitleMap[question.get('uniqueIdentifier')] = question.get('title'));
  }

  private async generateUserSummary(): Promise<void> {
    const userJson = this.user.toJSON();
    userFieldsToSkip.forEach((each: string) => delete userJson[each]);
    Object.keys(userJson).forEach((key_: string) => {
      const key = key_;
      if (key === 'marketingTags') {
        return;
      }
      if (this.fromNewOrderApprovalPage && (key === 'deliveryAddress' || key === 'isCleverTapEnabled')) {
        return;
      }
      let value = userJson[key];
      if (value === 'auto-response') {
        delete userJson[key];
        return;
      }
      if (value instanceof Array) {
        value = value.join(' , ').toString();
      }
      this.userDetails.push({ key, value });
      this.questionIds.push(key);
    });
  }

  private async generateTemporaryAnswerSummary(): Promise<void> {
    const temporaryAnswers = (await this.conn.findTemporaryAnswer({
      where: { user: this.user },
      descending: 'expireOn',
    })).map((each: any) => each.toJSON());
    const fieldsToSkip = ['ACL', 'updatedAt', 'expireOn', 'user', 'objectId', 'dbArchive'];
    const tempQuestionIdMap: { [key: string]: boolean } = {};
    temporaryAnswers.forEach((temporaryAnswer_: { [key: string]: unknown } & { createdAt: string }) => {
      const temporaryAnswer = temporaryAnswer_;
      fieldsToSkip.forEach((each: string) => delete temporaryAnswer[each]);
      const { createdAt }: { createdAt: string } = temporaryAnswer;
      delete temporaryAnswer.createdAt;
      Object.keys(temporaryAnswer).forEach((key: string) => {
        if (tempQuestionIdMap[key]) {
          return;
        }
        tempQuestionIdMap[key] = true;
        this.followupAnswers.push({
          key,
          value: temporaryAnswer[key] as string,
          createdAt,
        });
        this.questionIds.push(key);
      });
    });
  }

  async getUserLabels(): Promise<void> {
    this.attendToChatCacheObj = await this.conn.findAttendToChatCache(this.user);
    this.labels = this.attendToChatCacheObj.get('userLabel');
  }

  addLabels(): void {
    const dialogRef = this.dialog.open(DataSelectModal,
      { data: { options: [...this.labelOptions], preSelectedData: [...this.labels], multipleSelect: true } });
    this.subscriptions.push(dialogRef.afterClosed().subscribe(async (response: Array<string>) => {
      if (!response) return;
      this.labels = response;
      this.attendToChatCacheObj.set('userLabel', this.labels);
      await this.attendToChatCacheObj.save();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
