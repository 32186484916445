
<div class="hbox">
  <div class="doctor-notes">
    <div class="title">Notes for Doctor
      <div class="pull-right add-new-button" (click)="addNotes()">+</div>
    </div>
    <div class="notes-list">
      <div class="flex justify-content-center" *ngIf="!notes.length">--No Notes Available--</div>
      <div class="doctor-notes-list bdr-btm" *ngFor="let note of notes">
        <div class="date">{{note.get('createdAt') | amDateFormat: 'DD MMM, YY'}}</div>
        <div class="doctor-name" *ngIf="note.get('AddedBy')?.get('type') == apiClientConstant.User.Type.DOCTOR;else elseStatement">Dr. {{note.get('Name')?.split(' ')[1]}} {{note.get('Name')?.split(' ')[2]?.charAt(0) || ''}}<i class="fa-solid fa-stethoscope doctor-icon ml-1"></i></div>
        <ng-template #elseStatement>
          <div class="doctor-name">{{note.get('Name')?.split('_')[0]}}</div>
        </ng-template>
        <div class="message">{{note.get('message')}}</div>
      </div>
    </div>
  </div>
</div>