
<div class="hbox">
  <div class="flex-box">
    <div class="flex-content">
      <div class="col-xs-12 h-full p-0" *ngIf="chatUser">
        <chat-sidebar [patient]="chatUser"></chat-sidebar>
        <div class="sidebar-user-details col-xs-12 col-sm-7 h-full p-3 pl-sm-0 of-auto" id="parentContainer">
          <section class="row" id="operatorAndDoctorAllocation" *ngIf="internalUserRoles?.includes(&quot;operatorAndDoctorAllocator&quot;)">
            <div class="col-xs-12 title"><em class="fas fa-exchange-alt pr-3"></em><span>Change operator/doctor Allocation</span></div>
            <div class="col-xs-4">
              <select class="form-control" [(ngModel)]="changeAllocationData.input" name="assignToIndex">
                <option *ngFor="let option of internalUsers, let i = index" value="{{option.id}}">{{ option.get('username').split('operator')[0] }}</option>
              </select>
            </div>
            <div class="col-xs-4 ml-3">
              <button (click)="changeAllocation()">{{changeAllocationData.status}}</button>
            </div>
          </section>
          <section class="row" id="userTagSection">
            <user-tags [user]="chatUser"></user-tags>
          </section>
          <section class="row" id="sendMessageSection">
            <send-message-section [patient]="chatUser" (reloadChat)="reloadChat()"></send-message-section>
          </section>
          <section class="row" id="SBARSection" *ngIf="supportTicketId">
            <SBAR [supportTicketId]="supportTicketId"></SBAR>
          </section>
          <section class="row" id="followupSection">
            <followup-section [patient]="chatUser"></followup-section>
          </section>
          <section class="row" id="regimenSection">
            <regimen-list [user]="chatUser" [supportTicketId]="supportTicketId"></regimen-list>
          </section>
          <section class="row" id="callLogSection">
            <call-logs [user]="chatUser"></call-logs>
          </section>
          <section class="row" id="ticketListSection">
            <ticket-list *ngIf="chatUser" [user]="chatUser"></ticket-list>
          </section>
          <section class="row" id="ticketNotesSection" *ngIf="supportTicketId">
            <user-notes-ticket [patient]="chatUser" [supportTicketId]="supportTicketId"></user-notes-ticket>
          </section>
          <div class="flex justifySB align-item-start" id="notesSection">
            <div class="flex-grow">
              <section class="row">
                <user-notes-doctor [user]="chatUser"></user-notes-doctor>
              </section>
            </div><span class="p-2"></span>
            <div class="flex-grow">
              <section class="row">
                <user-notes-operator [user]="chatUser"></user-notes-operator>
              </section>
            </div>
          </div>
          <section class="row" id="chatActionSection">
            <div class="col-xs-12 title"><em class="fas fa-info-circle pr-3"></em><span>Chat Actions</span></div>
            <div class="col-xs-12">
              <blocked-bot-assistant-modal [user]="chatUser" [(updateStatus)]="updateStatus"></blocked-bot-assistant-modal>
            </div>
          </section>
          <section class="row" id="orderSection"><span>
              <select class="mb-3" [(ngModel)]="selectedOrderType">
                <option *ngFor="let type of orderType" [value]="type">{{type}}</option>
              </select></span>
            <order-list [user]="chatUser" (updatedOrderId)="updatedOrderId($event)" [selectedOrderType]="selectedOrderType"></order-list>
          </section>
          <section class="row" id="consultationList">
            <consultation-list [user]="chatUser" (updatedConsultationId)="updatedConsultationId($event)"></consultation-list>
          </section>
          <section class="p-0 py-3 row" id="instantCheckupSection">
            <div class="col-xs-12 p-0">
              <instant-checkup-modal [user]="chatUser" [doctor]="user"></instant-checkup-modal>
            </div>
          </section>
          <section class="row" id="followupReportSection">
            <div class="col-xs-12 p-0">
              <follow-up-report-modal [user]="chatUser"></follow-up-report-modal>
            </div>
          </section>
          <section class="row" id="mainConcernSection" *ngIf="chatUser">
            <div class="col-xs-12 title"><em class="fas fa-id-card pr-3"></em><span>Main Concerns</span></div>
            <div class="col-xs-12 flex">
              <div class="col-xs-3 bold"><span>Selected Concern</span><a class="fa fa-pencil-square-o ml-3" *ngIf="!editConcernFlag" (click)="editConcernFlag = true"></a></div>
              <div class="pull-left pr-3">:</div>
              <div class="col-xs-7" *ngIf="!editConcernFlag">
                <div class="inline-flex" *ngFor="let mainConcern of mainConcernConsultationSession;let i = index;"><span class="fontS3 label label-primary p-2 mr-2 mb-2">{{mainConcern.get('PrivateMainConcern')}} ({{mainConcern.get('PrivateMainConcernClass')}})</span>
                  <ng-container *ngFor="let concern of mainConcern.get(&quot;concernList&quot;)"><span class="fontS3 label label-default p-2 mr-2" *ngIf="concern !== mainConcern.get(&quot;PrivateMainConcern&quot;)">{{concern}}</span></ng-container>
                </div>
              </div>
              <div class="col-xs-4" *ngIf="editConcernFlag">
                <select class="form-control" [(ngModel)]="selectedConcernIndex" name="regimenObj" required="required">
                  <option *ngFor="let concern of mainConcernOptions;let i = index;" value="{{i}}">{{concern.get('value')}}</option>
                </select>
              </div>
              <div class="col-xs-2" *ngIf="editConcernFlag">
                <button class="btn-xs ml-3" (click)="saveSelectedMainConcern();">Save</button>
              </div>
            </div>
            <div class="col-xs-12 flex mt-2" *ngIf="showRefreshDate">
              <div class="col-xs-2 bold">Refresh Date</div>
              <div class="pull-left pr-3">:</div>
              <div class="col-xs-7">{{ chatUser.get('refreshDate') ? (chatUser.get('refreshDate') | amDateFormat: "MMM DD, YYYY") : ' ' }}</div>
            </div>
          </section>
          <section class="row" id="treeSection">
            <div class="col-xs-12 p-0">
              <tree-trigger [user]="chatUser"></tree-trigger>
            </div>
          </section>
          <section class="row" id="assignChatSection">
            <div class="col-xs-12 title"><em class="fas fa-comment-alt"></em><span>Assign Chat</span></div>
            <div class="col-xs-12 p-0">
              <assign-chat [user]="chatUser" #assignChatComponet></assign-chat>
            </div>
          </section>
          <section class="row" id="quickActionSection">
            <div class="col-xs-12 title"><em class="fas fa-info-circle"></em><span>Quick Actions</span></div>
            <div class="col-xs-12 mb-3">
              <button class="btn-xs btn-primary-outline mr-3" *ngIf="!chatUser.get(&quot;userOptedOutOfSMS&quot;)" (click)="userOptedOutOfSMS()">Opted-in for SMS</button>
              <button class="btn-xs btn-primary-outline mr-3" *ngIf="chatUser.get(&quot;userOptedOutOfSMS&quot;)">Opted-out of SMS</button>
              <button class="btn-xs btn-primary-outline mr-3" (click)="openImageUploadDialog()">Upload & Send file</button>
            </div>
          </section>
          <section class="row" id="prescriptionSection" *ngIf="prescriptions?.length">
            <div class="col-xs-12 title"><em class="fas fa-file-prescription"></em><span>Prescriptions</span></div>
            <div class="col-xs-12 p-0"><a class="text-info db" *ngFor="let prescription of prescriptions; let i=index;" href="{{prescription.get(&quot;signedPrescriptionUrl&quot;)}}" target="_blank">Prescription {{i+1}}</a></div>
          </section>
          <section class="row" id="userFilesSection" *ngIf="userFiles?.length">
            <div class="col-xs-12 title"><em class="fas fa-file-prescription"></em><span>User Files</span></div>
            <div class="col-xs-12 p-0"><a class="text-info db" *ngFor="let file of userFiles; let i=index;" href="{{file.get(&quot;fileUrl&quot;)}}" target="_blank">User File {{i+1}}</a></div>
          </section>
          <section class="row" id="waitingCallSection">
            <div class="col-xs-12 title"><em class="fas fa-comment-alt"></em><span>Waiting Calls</span><em class="fa fa-plus-square-o pl-3" (click)="addWaitingCall()" title="Add waiting call"></em></div>
            <div class="col-xs-12 p-0 waiting-box" *ngIf="chatUser.id">
              <waiting-call [user]="chatUser" [refresh]="waitingCallRefresh" #waitingCallComponet></waiting-call>
            </div>
          </section>
          <div class="flex justifySB align-item-start" id="deletedContentSection">
            <div class="flex-grow">
              <section class="row">
                <div class="col-xs-12 title"><em class="fas fa-trash"></em><span>Deleted Regimens</span></div>
                <div class="col-xs-12 p-0 of-auto fontS" style="max-height:150px;">
                  <div *ngIf="deletedRegimens.length">
                    <div class="mb-2" *ngFor="let regimen of deletedRegimens">{{regimen.concern}} ({{regimen.deletionDate | amDateFormat: "MMM DD, YYYY hh:mm a"}})</div>
                  </div>
                  <div class="p-3 text-center" *ngIf="!deletedRegimens.length">No deleted Regimens</div>
                </div>
              </section>
            </div><span class="p-2"></span>
            <div class="flex-grow">
              <section class="row">
                <div class="col-xs-12 title"><em class="fas fa-trash"></em><span>Deleted Checkups</span></div>
                <div class="col-xs-12 p-0 of-auto fontS" style="max-height:150px;">
                  <div class="mb-2" *ngFor="let deletedInstantCheckup of deletedInstantCheckups">{{deletedInstantCheckup.get('updatedAt')| amDateFormat: "MMM DD, YYYY hh:mm a"}}</div>
                  <div class="p-3 text-center" *ngIf="!deletedInstantCheckups?.length">No deleted Checkups</div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
