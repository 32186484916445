import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { Broadcaster } from 'src/components/broadcaster';
import { AppConfig } from 'src/app/app.config';
import { ConnectionService } from '../../../../services/connection-service';

@Component({ selector: 'order-note', templateUrl: './order-note.html' })
export class OrderNoteComponent {
  @Input('order')
  set setOrder(x: any) {
    this.updateOrder(x);
  }
  @Input('note')
  set setNote(note: string) {
    if (!note) {
      return;
    }
    this.note = note;
    this.addOrderNote();
  }
  @Output('noteUpdated') noteUpdatedEmitter: EventEmitter<string> = new EventEmitter<string>();

  order: any;
  note: string;
  noteOptions: Array<any> = [];

  constructor(private conn: ConnectionService, private broadcaster: Broadcaster, public appConfig: AppConfig) {
    this.noteOptions = [
      { display: 'order not serviceable', value: 'order_not_serviceable' },
      { display: 'Last call status: Customer number not reachable', value: 'customer_number_not_reachable' },
      { display: 'escalation - retry delivery', value: 'escalation_retry_delivery' },
      { display: 'escalation - customer frustrated due to delivery issues', value: 'escalation_customer_frustrated' },
      { display: 'escalation - fake attempt', value: 'escalation_fake_attempt' },
      { display: 'Last call status: Customer refused to talk', value: 'customer_refused_to_talk' },
      { display: 'No response', value: 'no_response' },
      { display: 'User disconnected', value: 'user_disconnected' },
      { display: 'User will upload images', value: 'user_will_upload_images' },
      { display: 'Customer wants to cancel', value: 'customer_wants_to_cancel' },
      { display: 'Customer wants the product but courier not delivered', value: 'courier_not delivered' },
      { display: 'Tracking showing delivered but actually not delivered - Need POD',
        value: 'tracking_showing_delivered_but_actually_not_delivered_need_pod' },
      { display: 'Missing Product - Requested user for unboxing video, invoice, image of products',
        value: 'missing_product_requested_user_for_unboxing_video_invoice_image_of_products' },
      { display: 'Address updated', value: 'address_updated' },
      { display: 'User will pay online and confirm this order', value: 'online_payment' },
      { display: 'Order placed by operator', value: 'order_placed_by_operator' },
      { display: 'Reverse pick-up required - Customer wants to return',
        value: 'reverse_pickup_required_customer_wants_to_return' },
      { display: 'Reverse pick-up required - Order not cancelled on time',
        value: 'reverse_pickup_required_order_not_cancelled_on_time' },
      { display: 'User wants to return - Self-ship', value: 'user_wants_to_return_self_ship' },
      { display: 'Refund requested', value: 'refund_requested' },
      { display: 'User received damaged product - Product image, invoice and video requested',
        value: 'user_received_damaged_products_product_image_invoice_video_requested' },
      { display: 'User informed order delivered - Product image, invoice/outer packaging pic requested',
        value: 'user_informed_order_delivered_product_image_invoice_outer_packaging_pic_requested' },
      { display: 'Marked RTO as per user’s cancellation request', value: 'marked_rto_as_per_user\'s_cancellation_request' },
      { display: 'Alternative products given due to stock issues - User informed',
        value: 'alternative_products_given_due_to_stock_issues_user_informed' },
      { display: 'Address Verified - On call', value: 'address_verified_on_call' },
      { display: 'Address Verified - On Google', value: 'address_verified_on_google' },
      { display: 'Address Verified - Order previously delivered on same address',
        value: 'address_verified_order_previously_delivered_on_same_address' },
    ];
  }

  addOrderNote(): void {
    if (this.note.includes('escalation_')) {
      const labels = this.order.get('labels') || [];
      if (!labels.includes('escalation')) {
        labels.push(this.note);
        this.order.set('labels', labels);
      }
    }
    if (!this.order.get('user')) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Order note without user cannot be save',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    const notes = this.order.get('notes') || [];
    notes.push({ message: this.note, byUser: this.conn.getCurrentUser().get('username'), createdAt: moment().toISOString() });
    this.order.set('notes', notes);
    this.order.save().then(() => {
      this.note = '';
      this.noteUpdatedEmitter.emit(undefined);
    }).catch((error: any) => alert(error.message));
  }

  private updateOrder(order: any): void {
    this.order = order;
  }
}
