
<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Upload Instant Checkup</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <div class="col-xs-12 no-padder">
      <label class="col-xs-12 mt-0">Choose File </label>
      <div class="col-xs-12">
        <input class="col-xs-12" type="file" (change)="file = $event.target.files[0]"/>
      </div>
    </div>
    <div class="col-xs-12 no-padder">
      <label class="col-xs-12">Type</label>
      <div class="col-xs-12"></div>
      <select class="col-xs-12" [(ngModel)]="imageType" name="imageType" required="required">
        <option *ngFor="let type of types" value="{{type}}">{{ type }}</option>
      </select>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn-xs pull-right" [disabled]="!file || popUpModel.loading || !imageType" [ngClass]="{ &quot;btn-success&quot;: !!file && !popUpModel.loading, &quot;btn-outline-primary&quot;: !file || popUpModel.loading }" (click)="uploadInstantCheckup()">{{popUpModel.loading ? 'Uploading' : 'Upload'}}<i class="fa fa-spin fa-spinner m-l-xs" *ngIf="popUpModel.loading"></i></button>
  </div>
</mat-dialog-content>
