import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';

@Component({ selector: 'add-order-label-modal', templateUrl: './add-order-label.html' })
export class AddOrderLabelModal {
  private allLabels: Array<string> = [];
  existingLabels: Array<string> = [];
  addedLabels: Array<string> = [];
  suggestedLabels: Array<string> = [];
  private order: any;

  constructor(
    private conn: ConnectionService,
    private appConfig: AppConfig,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AddOrderLabelModal>) {
  }
  ngOnInit(): void {
    this.order = this.data.order;
    this.existingLabels = this.order.get('labels') || [];
    this.addToAllLabels(this.existingLabels);
    this.fetchWarehouses();
  }

  addSuggestion(): void {
    const label = prompt('Add label to suggestion');
    if (!label) {
      return;
    }
    if (this.allLabels.includes(label)) {
      return;
    }
    this.allLabels.push(label);
    this.updateLabelsView();
  }
  hideModal(): void {
    this.dialogRef.close();
  }

  async updateOrderLabels(): Promise<void> {
    if (!this.addedLabels.length) {
      this.hideModal();
      return;
    }
    const labels = [...this.existingLabels, ...this.addedLabels];
    await this.order.save({ labels });
    this.hideModal();
  }

  private updateLabelsView(): void {
    this.suggestedLabels = this.allLabels.filter((each: string): boolean => (
      !this.existingLabels.includes(each) && !this.addedLabels.includes(each)));
  }

  private addToAllLabels(newLabels: Array<string>): void {
    newLabels.forEach((each: string): void => {
      if (this.allLabels.includes(each)) {
        return;
      }
      this.allLabels.push(each);
    });
  }

  removeLabel(label: string): void {
    this.addedLabels = this.addedLabels.filter((e: string) => e !== label);
    this.updateLabelsView();
  }

  addLabel(label: string): void {
    this.addedLabels.push(label);
    this.updateLabelsView();
  }

  private async fetchWarehouses(): Promise<void> {
    const warehouses = await this.conn.findWareHouse({ where: { active: true } });
    const warehouseNames = warehouses.map((each: any): string => each.get('name'));
    const warehouseMangers = warehouses.map((each: any): string => each.get('manager'));
    const warehouseLabels = warehouses.map((each: any): string => (each.get('labels') || [])).flat();
    this.addToAllLabels(warehouseNames.map((each: string) => `skipWarehouseName-${each}`));
    this.addToAllLabels(warehouseMangers.map((each: string) => `skipWarehouseManager-${each}`));
    this.addToAllLabels(warehouseNames);
    this.addToAllLabels(warehouseLabels);
    this.updateLabelsView();
  }
}
