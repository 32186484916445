import { NgZone, Component, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { ApiClientConstant, Table, RequestQueryPayload, ParseKeys } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { WindowRefService } from '../../../../services/window-ref-service';
import { AppConfig } from '../../../app.config';
import { LocalStorage } from '../../../../services/local-storage-service';

@Component({
  selector: 'open-support-tickets-list',
  templateUrl: './open-support-tickets-list.html',
  styleUrls: ['./open-support-tickets-list.scss'],
})
export class OpenSupportTicketsListComponent implements OnDestroy {
  // List View Variables
  gridApi: GridApi;
  dataSource: any;
  window: any;
  components: any;
  ui: any = {};
  columnDefs: any;
  searchKey: string;

  // Local Variables
  data: Array<string> = [];
  currentUser: any;
  autoRefresh: boolean = false;
  autoRefreshInterval: any;
  queueCount: number = 0;

  operatorFilter: Array<string> = [];
  languageFilter: Array<string> = [];
  teamFilter: Array<string> = [];
  doctorFilter: Array<string> = [];
  teams: Array<string> = [];
  labelFilter: Array<string> = [];

  resetOperatorFilter: boolean = false;
  resetDoctorFilter: boolean = false;
  resetLanguageFilter: boolean = false;
  resetLabelFilter: boolean = false;
  resetTeamFilter: boolean = false;
  assignedOnly: boolean = false;
  regimenLabels: Array<string> = ['regimen_1499', 'regimen_1999', 'regimen_2999'];

  constructor(
    private router: Router,
    private conn: ConnectionService,
    private zone: NgZone,
    public appConfig: AppConfig,
    windowRef: WindowRefService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private storage: LocalStorage) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.currentUser = this.conn.getCurrentUser();
    this.listViewSetup();
    const roles = this.storage.getJsonValue('userRoles') || [];
    const teams = Object.keys(ApiClientConstant.Role.Name)
      .map((each: string) => ApiClientConstant.Role.Name[each]);
    this.teams = roles.filter((each: any) => teams.includes(each));
    if (!this.teams.includes(ApiClientConstant.Role.Name.DOCTOR)) {
      this.doctorFilter = [];
    }
    if (!this.teams.includes(ApiClientConstant.Role.Name.CHAT_SUPPORT)) {
      this.operatorFilter = [];
    }
    this.route.queryParams.subscribe(() => this.reset());
  }

  listViewSetup(): any {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  findUserPriorityClasses(user: any): string {
    if (!user) {
      return '';
    }
    const orderState = user.orderState || ApiClientConstant.User.OrderState.NEW_USER;
    if (orderState === ApiClientConstant.User.OrderState.NEW_USER) {
      return '';
    }
    if (orderState === ApiClientConstant.User.OrderState.PROCESSING) {
      return 'fa fa-rupee mLR5 text-success';
    }
    if (orderState === ApiClientConstant.User.OrderState.CANCELED) {
      return 'fa fa-rupee mLR5 text-danger';
    }
    if (orderState === ApiClientConstant.User.OrderState.DELIVERED) {
      return moment(user.refreshDate.iso).isAfter(moment().subtract(4, 'month'))
        ? 'fa fa-rupee mLR5 text-success'
        : 'fa fa-rupee mLR5 text-warning';
    }
    return '';
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadData(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'Edit',
      width: 50,
      cellRenderer: (params: any): any => {
        const url = this.router.url.split('/');
        url.splice(url.length - 1, 1);
        if (!params.data) return '';
        const id = params.data.objectId;
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `<a class='fa fa-pencil-square-o', href='${url.join('/')}/open-tickets/${id}/edit'></a>`;
        return eDiv;
      },
    }, {
      headerName: 'UserId',
      field: 'user',
      pinned: 'left',
      editable: true,
      cellRenderer: (params_: any): any => {
        const params = params_;
        let eDiv;
        if (!params.data) {
          eDiv = this.window.document.createElement('div');
          eDiv.innerHTML = 'Loading ...';
          return eDiv;
        }
        eDiv = this.window.document.createElement('div');
        if ((params.data.allocatedOperator && !params.data.teams.includes('doctor'))
          || (params.data.allocatedDoctor && params.data.teams.includes('doctor'))) {
          let className = this.findUserPriorityClasses(params.data.user);
          if (params.data.assigned) {
            className += 'list-label label-assigned-chat';
          }
          const id = params.data.user?.PatientName || params?.value?.username;
          eDiv.innerHTML = `<a href='/ticket/${params?.value?.username}?ticketId=${params.data.objectId}' target='_blank'> <span class='${
            className}'></span> ${id} (${this.conn.getUserHash(id)})</a>`;
        } else {
          eDiv.addEventListener('click', async () => {
            try {
              const supportTicket = new Table.SupportTicket();
              supportTicket.id = params.data.objectId;
              if (this.currentUser.get('type') === 'operator') {
                await supportTicket.save({ allocatedOperator: this.conn.getCurrentUser() });
                params.data.allocatedOperator = supportTicket.toJSON().allocatedOperator;
              } else {
                await supportTicket.save({ allocatedDoctor: this.conn.getCurrentUser() });
                params.data.allocatedDoctor = supportTicket.toJSON().allocatedDoctor;
              }
              this.gridApi.redrawRows({ rowNodes: [params.node] });
            } catch (error) {
              alert(error.message);
            }
          });
          eDiv.innerHTML = '<button class="btn-xs">Capture</button>';
        }
        return eDiv;
      },
    },
    {
      headerName: 'Message',
      field: 'cacheSupportChat',
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.window.document.createElement('div');
        if (params.data.allocatedOperator || params.data.allocatedDoctor) {
          eDiv.innerHTML = `<span> ${params.value
            ? params.value.message
            : (params.data.title || params.data.titleLanguageString?.en)}</span>`;
        }
        return eDiv;
      },
    },
    {
      headerName: 'Subject',
      field: 'titleLanguageString.en',
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.window.document.createElement('div');
        if (params.data.allocatedOperator || params.data.allocatedDoctor) {
          eDiv.innerHTML = params.value;
        }
        return eDiv;
      },
    },
    {
      headerName: 'created at',
      field: 'createdAt',
      width: 120,
      sortable: true,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `<span> ${moment(params.value).fromNow()} </span>`;
        return eDiv;
      },
    },
    {
      headerName: 'Language Preference',
      field: 'languagePreference',
      width: 120,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `<span> ${params.value} </span>`;
        return eDiv;
      },
    },
    {
      headerName: 'updated at',
      field: 'updatedAt',
      width: 120,
      sortable: true,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.window.document.createElement('div');
        eDiv.innerHTML = `<span> ${moment(params.value).fromNow()} </span>`;
        return eDiv;
      },
    },
    {
      headerName: 'allocatedDoctor',
      field: 'allocatedDoctor.username',
      width: 120,
    }];
  }

  reset(): void {
    if (!this.gridApi
      || !this.operatorFilter
      || !this.doctorFilter
      || !this.languageFilter
      || !this.teamFilter) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  resetFilters(): void {
    delete this.searchKey;
    this.resetDoctorFilter = this.teams.includes(ApiClientConstant.Role.Name.DOCTOR);
    this.resetOperatorFilter = this.teams.includes(ApiClientConstant.Role.Name.CHAT_SUPPORT);
    this.resetTeamFilter = true;
    this.resetLanguageFilter = true;
    this.resetLabelFilter = true;
    this.operatorFilter = this.resetOperatorFilter ? [] : undefined;
    this.doctorFilter = this.resetDoctorFilter ? [] : undefined;
    this.teamFilter = undefined;
    this.languageFilter = undefined;
    this.reset();
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  async loadData(params: any): Promise<any> {
    const payload: RequestQueryPayload<Table.SupportTicket> = {
      where: { status: ApiClientConstant.SupportTicket.Status.Pending, attended: false },
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      include: ['supportQuestion',
        'titleLanguageString',
        'user',
        'allocatedOperator',
        'cacheSupportChat',
        'allocatedDoctor',
        'languagePreference'],
    };
    const { colId, sort }: { colId: ParseKeys<Table.SupportTicket>, sort: 'asc' | 'desc' } = params.sortModel[0]
      || { colId: 'updatedAt', sort: 'asc' };
    if (sort === 'desc') {
      payload.descending = colId;
    } else {
      payload.ascending = colId;
    }
    this.addTeamFilter(payload);
    this.addLanguageFilter(payload);
    this.addLabelFilter(payload);

    const data = (await this.conn.fetchSupportTickets(payload)).map((each: any) => each.toJSON());
    this.queueCount = await this.conn.fetchSupportTicketCount(payload);
    return data;
  }

  updateOperatorFilter(operatorFilter: Array<any>): void {
    this.resetOperatorFilter = false;
    this.operatorFilter = operatorFilter;
    this.reset();
  }

  updateDoctorFilter(doctorFilter: Array<any>): void {
    this.resetDoctorFilter = false;
    this.doctorFilter = doctorFilter;
    this.reset();
  }

  updateLanguageFilter(languageFilter: Array<any>): void {
    this.resetLanguageFilter = false;
    this.languageFilter = languageFilter;
    this.reset();
  }

  updateTeamFilter(teamFilter: Array<string>): void {
    this.resetTeamFilter = false;
    this.teamFilter = teamFilter;
    this.reset();
  }

  addLanguageFilter(requestPayload_: RequestQueryPayload<Table.SupportTicket>): void {
    const requestPayload = requestPayload_;
    if (this.languageFilter.length) {
      requestPayload.where.languagePreference = { $in: this.languageFilter };
    }
  }

  addLabelFilter(payload_: RequestQueryPayload<Table.SupportTicket>): void {
    const payload = payload_;
    if (this.labelFilter?.length) {
      payload.where.userLabel = this.labelFilter;
    }
  }

  addTeamFilter(requestPayload_: RequestQueryPayload<Table.SupportTicket>, teamFieldName: string = 'teams'): void {
    if (!this.teamFilter.length) {
      return;
    }
    const requestPayload = requestPayload_;
    requestPayload.where.$or = this.teamFilter.map((team: string) => {
      if (team === ApiClientConstant.Role.Name.CHAT_SUPPORT && this.operatorFilter.length) {
        return {
          $or: [
            { allocatedOperator: { $in: this.operatorFilter } },
            { allocatedOperator: { $exists: false } },
          ],
          [teamFieldName]: team,
        };
      }
      if (team === ApiClientConstant.Role.Name.DOCTOR && this.doctorFilter.length) {
        return {
          $or: [
            { allocatedDoctor: { $in: this.doctorFilter } },
            { allocatedDoctor: { $exists: false } },
          ],
          [teamFieldName]: team,
        };
      }
      return { [teamFieldName]: team };
    });
  }

  toggleAutoRefresh(): void {
    if (this.autoRefresh) {
      clearInterval(this.autoRefreshInterval);
      this.reset();
      this.autoRefreshInterval = setInterval(() => this.reset(), 5000);
    } else clearInterval(this.autoRefreshInterval);
  }

  toggleAssignedOnly(): void {
    this.reset();
  }

  updateLabelFilter(labelFilter: Array<any>): void {
    this.resetLabelFilter = false;
    this.labelFilter = labelFilter;
    this.reset();
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
    delete this.data;
    delete this.ui;
  }
}
