import {
  NgModule,
  ApplicationRef,
  Provider,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';
import { MatNativeDateModule } from '@angular/material/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MetabaseServices } from 'src/services/metabaseServices';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/angular';
import { MatDialogModule } from '@angular/material/dialog';
import VersionInfo from '../../package.json';
import { ConnectionService } from '../services/connection-service';
import { FaviconService } from '../services/favicon-service';
import { LocalStorage } from '../services/local-storage-service';
import { ExotelService } from '../services/exotel-service';
import { HelperService } from '../services/helper-service';
import { TabNamingService } from '../services/tab-naming-service';
import { WindowRefService } from '../services/window-ref-service';
import { AppComponent } from './app.component';
import { LoginModule } from './login';
import { LogoutModule } from './logout';
import { DirectivesModule } from '../components/directives.module';
import { ChatModule } from './chat';
import { UserModule } from './user';
import { SchedulerModule } from './scheduler';
import { LanguageStringModule } from './language-string';
import { ProductModule } from './product';
import { CouriersModule } from './couriers';
import { DoctorModule } from './doctor';
import { TreeModule } from './tree';
import { AppConfig } from './app.config';
import { EasyAccessModule } from './easyAccess';
import { OrderModule } from './order';
import { CallsLogModule } from './calls';
import { ClipboardService } from '../services/copyToClipboard-service';
import { CompareImageProgressModule } from './compare-progress';
import { environment } from '../environments/environment';
import { InputHelperService } from '../components/input/input-helper';
import { UploadFileService } from '../services/uploadFileService/uploadFile.service';
import { InstantCheckupModule } from './instant-checkup';
import { UserFollowUpReportModule } from './follow-up-report';
import { PatientModule } from './patient';
import { TimeService } from '../services/time-service';
import { EcomModule } from './ecom';
import { AnalyticsModule } from './analytics';
import { ElasticService } from '../services/elastic-search';
import { VoiceRecognitionService } from '../services/voice-recognition-service/voice-recognition.service';
import { CannedResponseModule } from './canned-response';
import { ValidationService } from '../services/validation-service';
import { LanguageVoiceModule } from './language-voice';
import { MarketingModule } from './marketing';
import { Broadcaster } from '../components/broadcaster';
import { SKUModule } from './sku';
import { ActivityTrackerDirective } from '../components/activity-tracker/activity-tracker.directive';
import { ReminderModule } from './reminder';
import { ExperimentModule } from './experiment';
import { DashboadModule } from './dashboard/dashboad.module';
import { CartModule } from './cart';
import { SBARService } from '../services/sbar-service';

export const MY_NATIVE_FORMATS = {
  fullPickerInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
};

const providers: Provider[] = [
  ConnectionService,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  HelperService,
  ExotelService,
  ValidationService,
  WindowRefService,
  LocalStorage,
  ClipboardService,
  InputHelperService,
  UploadFileService,
  TimeService,
  ElasticService,
  TabNamingService,
  FaviconService,
  VoiceRecognitionService,
  MetabaseServices,
  Broadcaster,
  SBARService,
  { provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS },
  { provide: AppConfig, useValue: AppConfig },
];

const appRoutes: Routes = [
  { path: '', redirectTo: '/chats/open-tickets', pathMatch: 'full' },
];

let sentryEnvironment = '';
const windowRefService = new WindowRefService();
if (windowRefService.nativeWindow.location.hostname.startsWith('app.cureskin.com')) {
  sentryEnvironment = 'production';
}
if (windowRefService.nativeWindow.location.hostname.startsWith('test-app.cureskin.com')) {
  sentryEnvironment = 'development';
}
if (windowRefService.nativeWindow.location.hostname.startsWith('staging-app.cureskin.com')) {
  sentryEnvironment = 'staging';
}

if (sentryEnvironment) {
  Sentry.init({
    environment: sentryEnvironment,
    release: `doctor@${VersionInfo.version}`,
    dsn: 'https://a2f4b69fe03a444b8f8019ce05f0cac2@sentry.cureskin.com/3',
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          'https://test-api.cureskin.com',
          'https://api.cureskin.com',
          'https://staging-api.cureskin.com',
        ],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 0.1,
  });
}

@NgModule({
  providers,
  imports: [
    BrowserModule,
    HttpClientModule,
    MatNativeDateModule,
    DragDropModule,
    RouterModule.forRoot(appRoutes, { enableTracing: !environment.production }),
    DirectivesModule,
    LoginModule,
    LogoutModule,
    ChatModule,
    TreeModule,
    ProductModule,
    CouriersModule,
    DoctorModule,
    UserModule,
    EasyAccessModule,
    BrowserAnimationsModule,
    OrderModule,
    SchedulerModule,
    CallsLogModule,
    CompareImageProgressModule,
    LanguageStringModule,
    LanguageVoiceModule,
    InstantCheckupModule,
    UserFollowUpReportModule,
    PatientModule,
    EcomModule,
    AnalyticsModule,
    CannedResponseModule,
    MarketingModule,
    ReminderModule,
    SKUModule,
    ExperimentModule,
    DashboadModule,
    CartModule,
    MatDialogModule,
  ],
  declarations: [AppComponent, ActivityTrackerDirective],
  bootstrap: [AppComponent],
})
export class AppModule {
  appRef: ApplicationRef;
  static parameters: any = [ApplicationRef];

  constructor(appRef: ApplicationRef) {
    this.appRef = appRef;
  }
}
