import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { HelperService } from '../../../../services/helper-service';
import { TimeService } from '../../../../services/time-service';
import { WindowRefService } from '../../../../services/window-ref-service';
import { AppConfig } from '../../../app.config';
import { Broadcaster } from '../../../../components/broadcaster';
import { SbarHistoryComponent } from '../sbar-history';
import { SBARService } from '../../../../services/sbar-service';

@Component({
  selector: 'SBAR',
  templateUrl: './SBAR.html',
})
export class SBARComponent {
  @Input('supportTicketId') supportTicketId: string;
  @Input('createSBARWithoutSupportTicket')
  set createEmptySBAR(user: any) {
    this.SBARHistory = new Table.SBARHistory();
    this.openedAsPopup = true;
    this.editMode = true;
  }
  @Output() saveSBAR: EventEmitter<any> = new EventEmitter();
  openedAsPopup: boolean;
  supportTicket: any;
  SBARHistory: any;
  editMode: boolean = false;
  enableCategoryEditing: boolean;
  category: string;
  categoryText: string;

  constructor(public appConfig: AppConfig,
    private conn: ConnectionService,
    private time: TimeService,
    private window: WindowRefService,
    private broadcasterService: Broadcaster,
    private dialog: MatDialog,
    public helper: HelperService,
    private sbarService: SBARService) {
  }

  async ngOnInit(): Promise<any> {
    if (this.openedAsPopup) {
      return;
    }
    this.supportTicket = await this.conn.fetchSupportTicket({
      where: { objectId: this.supportTicketId },
      include: ['SBAR'],
      project: ['SBAR'],
    });
    if (this.supportTicket.get('SBAR')) {
      this.SBARHistory = this.supportTicket.get('SBAR');
      this.editMode = false;
    } else {
      this.SBARHistory = new Table.SBARHistory();
      this.editMode = true;
    }
  }

  async SaveSBARHistory(): Promise<void> {
    const sbarHistory = this.SBARHistory;
    if (!sbarHistory) {
      this.broadcasterService.broadcast('NOTIFY', { message: 'SBAR missing', type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    const sbarIsFilled = await this.sbarService.checkIfSBARIsFilledCompletely(sbarHistory);
    if (!sbarIsFilled) {
      this.broadcasterService.broadcast('NOTIFY', {
        message: 'SBAR Data missing',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
      return;
    }
    if (this.category === 'Others') {
      this.SBARHistory.set('category', this.categoryText);
    }
    if (this.openedAsPopup) {
      this.saveSBAR.emit(this.SBARHistory);
      return;
    }
    try {
      this.SBARHistory.set('createdBy', this.conn.getCurrentUser());
      this.SBARHistory.set('supportTicket', this.supportTicket);
      await this.SBARHistory.save();
      this.supportTicket.set('SBAR', this.SBARHistory);
      this.supportTicket.save();
      this.broadcasterService.broadcast('NOTIFY', { message: 'saved Successfully', type: this.appConfig.Shared.Toast.Type.SUCCESS });
      this.editMode = false;
    } catch (error) {
      this.broadcasterService.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  onChangeCategory(): void {
    if (this.category === 'others') {
      return;
    }
    this.SBARHistory.set('category', this.category);
  }

  editSBAR(): void {
    this.editMode = true;
    this.SBARHistory = new Table.SBARHistory();
  }

  openSbarHistory(): void {
    this.dialog.open(SbarHistoryComponent, {
      width: '95%',
      data: { supportTicket: this.supportTicket },
    });
  }
}
