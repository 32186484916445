
<mat-dialog-content class="mat-dialog-content" *ngIf="userRoles">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Change Allocation</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body mt-0">
    <div class="col-xs-12" *ngIf="allocatedDoctorId">
      <label>Allocatted Doctor : {{currentDoctor?.get('DoctorDisplayName')}} ({{currentDoctor?.get('username')}})</label>
    </div>
    <div class="col-xs-12" *ngIf="(userRoles.includes(&quot;adminDoctor&quot;) || userRoles.includes(&quot;admin&quot;))">
      <label>Change Doctor</label>
      <div class="col-xs-12">
        <input-internal-user-select class="col-xs-4 pl-0" (onSelectInternalUser)="autoCompleteOnDoctorSelect($event)" [type]="appConfig.Shared.User.Type.DOCTOR"></input-internal-user-select>
      </div>
    </div>
    <div class="col-xs-12" *ngIf="allocatedOperatorId">
      <label>Allocatted Operator : {{currentOperator?.get('username')}}</label>
    </div>
    <div class="col-xs-12" *ngIf="(userRoles.includes(&quot;adminOperator&quot;) || userRoles.includes(&quot;admin&quot;))">
      <label>Change Operator</label>
      <div class="col-xs-12">
        <input-internal-user-select class="col-xs-4 pl-0" (onSelectInternalUser)="autoCompleteOnOperatorSelect($event)" [type]="appConfig.Shared.User.Type.OPERATOR"></input-internal-user-select>
      </div>
    </div>
    <div class="col-xs-12 flex">
      <div class="col-xs-12">
        <label>Teams</label>
        <div class="col-xs-12">
          <mat-form-field class="col-xs-5">
            <mat-select class="w100" [(ngModel)]="selectedTeams" multiple="multiple">
              <mat-option *ngFor="let option of teams" [value]="option" [selected]="option === selectedTeams.includes(option)">{{option}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-xs-6 flex" *ngIf="userRoles.includes('adminOperator') || userRoles.includes('admin') || userRoles.includes('adminDoctor')">
        <label class="mr-3">skip doctor reAllocation</label>
        <label class="i-switch i-switch-sm bg-success">
          <input type="checkbox" [(ngModel)]="skipReAllocateDoctor" name="skipReAllocateDoctor"/><i></i>
        </label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="pull-right" (click)="save()">Save</button>
  </div>
</mat-dialog-content>