import { Router, ActivatedRoute } from '@angular/router';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { Component, NgZone, OnDestroy } from '@angular/core';
import { ApiConnector, Table, RequestQueryPayload } from 'api-client';
import { Broadcaster } from 'src/components/broadcaster';
import * as moment from 'moment';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { WindowRefService } from '../../../../services/window-ref-service';
import { LocalStorage } from '../../../../services/local-storage-service';

@Component({ selector: 'offlineRegimen-list', templateUrl: './list.html' })
export class OfflineRegimenListComponent implements OnDestroy {
  // List View Variables
  gridApi: GridApi;
  dataSource: any;
  window: any;
  components: any;
  ui: any = {};
  columnDefs: any;
  count: any;
  autoRefresh: boolean = true;
  autoRefreshInterval: any;

  // Local Variables
  user: any;
  constructor(private router: Router,
    private conn: ConnectionService,
    private zone: NgZone,
    private storage: LocalStorage,
    public appConfig: AppConfig,
    public windowRef: WindowRefService,
    private broadcaster: Broadcaster,
    private route: ActivatedRoute) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.user = this.conn.getCurrentUser();
    this.listViewSetup();
    this.route.queryParams.subscribe(() => {
      this.reset();
    });
    this.autoRefreshInterval = setInterval(() => this.reset(), 30000);
  }

  listViewSetup(): any {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.setUpGrid();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadMore(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [
      {
        headerName: 'Title',
        field: 'title',
        pinned: 'left',
        width: 350,
        filter: true,
      },
      {
        headerName: 'CAPTURE',
        field: 'CAPTURE',
        cellRenderer: (params_: any): any => {
          const params = params_;
          if (!params.data) {
            const eDiv = this.window.document.createElement('div');
            eDiv.innerHTML = 'Loading ...';
            return eDiv;
          }

          const showCaptureButton: boolean = this.shouldShowCaptureButton(params);

          const renderCaptureButton = (): HTMLElement => {
            const eDiv = this.window.document.createElement('div');
            eDiv.addEventListener('click', async () => {
              const requestPayload1: RequestQueryPayload<Table.Regimen> = {
                where: { objectId: params.data.objectId },
                project: ['capturedByDoctor', 'regimenId', 'capturedAt'],
              };
              let regimen: any = {};
              regimen = await ApiConnector.findOne(Table.Regimen, requestPayload1);
              let isCapturedByAnotherDoctor: boolean = null;
              if (regimen.get('capturedByDoctor')) {
                isCapturedByAnotherDoctor = this.user.id !== regimen?.get('capturedByDoctor')?.id;
              }
              if (isCapturedByAnotherDoctor) {
                this.broadcaster.broadcast('NOTIFY', {
                  message: 'Regimen already captured by another doctor',
                  type: this.appConfig.Shared.Toast.Type.ERROR,
                });
                params.data.capturedByDoctor = regimen.get('capturedByDoctor');
                params.data.capturedAt = regimen.get('capturedAt');
                this.gridApi.redrawRows({ rowNodes: [params.node] });
                return;
              }
              try {
                const currentDoctor = this.conn.getCurrentUser();
                regimen.set('capturedByDoctor', currentDoctor);
                regimen.set('capturedAt', new Date());
                await regimen.save();
                params.data.capturedByDoctor = currentDoctor;
                params.data.capturedAt = new Date();
                const regimenId = regimen.get('regimenId');
                this.gridApi.redrawRows({ rowNodes: [params.node] });
                this.windowRef.nativeWindow.open(`/products/regimen/${regimenId}/edit?type=offline`, '_blank');
              } catch (error) {
                this.broadcaster.broadcast('NOTIFY', { message: error.message || error, type: this.appConfig.Shared.Toast.Type.ERROR });
              }
            });
            eDiv.innerHTML = '<a><button class="btn-xs">CAPTURE</button></a>';
            return eDiv;
          };

          const createDiv = (innerHtml: string): HTMLElement => {
            const eDiv = this.window.document.createElement('div');
            eDiv.innerHTML = innerHtml;
            return eDiv;
          };

          if (showCaptureButton) {
            return renderCaptureButton();
          }
          return createDiv('case Captured');
        },
        width: 110,
        filter: true,
      },
      { headerName: 'Active Status', field: 'active', width: 150, filter: true, sortable: true },
      {
        headerName: 'UserLink',
        field: 'forUser',
        cellRenderer: (params: any): any => {
          const eDiv = this.window.document.createElement('div');
          if (!params.data) return '';
          let html = '';
          const userId = params.data.forUser.objectId;
          html += `<a target="_blank" href='/chat/${userId}'>${userId}</a>`;
          eDiv.innerHTML = html;
          return eDiv;
        },
        width: 150,
        filter: true,
        sortable: true,
      },
      {
        headerName: 'Actions',
        width: 100,
        cellRenderer: (params: any): any => {
          const url = this.router.url.split('/');
          url.splice(url.length - 1, 1);
          if (!params.data) return '';
          const { data }: any = params;
          const eDiv = this.window.document.createElement('div');
          const { regimenId }: any = data;
          eDiv.innerHTML = `<a class='fa fa-eye' href='products/regimen/${regimenId}?type=offline' target='_blank'></a>`;
          return eDiv;
        },
      },
      {
        headerName: 'allocatedDoctor',
        field: 'allocatedDoctor',
        cellRenderer: (params: any): any => {
          const eDiv = this.window.document.createElement('div');
          if (!params.data) return '';
          const doctorDisplayName = params.data.allocatedDoctor.DoctorDisplayName;
          eDiv.innerHTML = `<p>${doctorDisplayName}</p>`;
          return eDiv;
        },
        width: 150,
      },
      { headerName: 'Id', field: 'regimenId', width: 250, filter: true, sortable: true },
      { headerName: 'Class', field: 'class', width: 150, filter: true, sortable: true },
      { headerName: 'F.Price', field: 'fixedPrice', width: 150, sortable: true },
      { headerName: 'approvalPercentage', field: 'approvalPercentage', width: 150, filter: true, sortable: true },
      { headerName: 'Concern', field: 'concern', width: 200, filter: true, sortable: true },
    ];
  }

  shouldShowCaptureButton(params: any):boolean {
    const isCurrentAndAllocatedDoctorSame = this.user.id === params.data.allocatedDoctor.objectId;
    const createdInThresholdTime: boolean = (
      moment().diff(params.data.createdAt, 'minutes') <= this.appConfig.Shared.allocatedDoctorTimeoutInMinutes
    );
    const isCaptureTimeExceeded = (
      moment().diff(params.data.capturedAt?.iso, 'minutes') >= this.appConfig.Shared.captureTimeoutInMinutes
    );

    if ((!isCurrentAndAllocatedDoctorSame && createdInThresholdTime) || (params.data.capturedAt && !isCaptureTimeExceeded)) {
      return false;
    }
    return true;
  }

  reset(): void {
    if (!this.gridApi) return;
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  async loadMore(params: any): Promise<any> {
    const requestPayload: RequestQueryPayload<Table.Regimen> = {
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      where: { offlineStore: { $exists: true }, doctorVerified: false },
      include: ['allocatedDoctor'],
      descending: 'createdAt',
    };
    const [data, count]: [Array<unknown>, number] = await Promise.all([
      ApiConnector.find(Table.Regimen, requestPayload)
        .then((items: any) => items.map((i: any) => JSON.parse(JSON.stringify(i)))),
      ApiConnector.count(Table.Regimen, requestPayload),
    ]);
    this.count = count;
    return data;
  }

  ngOnDestroy(): void {
    delete this.conn;
    delete this.zone;
    delete this.ui;
  }
}
