
<div class="hbox" #orderView fillComponentParentView="fillComponentParentView">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/orders">Orders</a></li>
          <li>{{ orderObj?.get('orderNumber') }}</li>
        </ul>
        <div class="pull-right">
          <button class="btn-xs btn-primary-outline" (click)="sendEmiMessage()">Send EMI Message</button>
          <button class="btn-xs btn-primary-outline ml-3" *ngIf="orderObj.get('stage') === apiClientConstant.Order.Stage.AWB_GENERATED" (click)="assignNewAWB()">Assign New AWB</button>
          <button class="btn-xs btn-primary-outline ml-3" *ngIf="[apiClientConstant.Order.Stage.SHIP_NOW, apiClientConstant.Order.Stage.OUT_OF_STOCK, apiClientConstant.Order.Stage.COURIER_NOT_AVAILABLE].includes(orderObj.get('stage'))" (click)="processWarehouse()">Process Warehouse</button>
          <button class="btn-xs btn-primary-outline ml-3" routerLink="edit">Edit</button>
          <button class="btn-xs btn-primary-outline ml-3" routerLink="../../orders/new" [queryParams]="{ username: orderObj.get(&quot;user&quot;).get(&quot;username&quot;) }">New</button>
          <button class="btn-xs btn-primary-outline ml-3" routerLink="../../orders/new" [queryParams]="{referenceOrderId: orderObj.id}">Replacement</button>
          <button class="btn-xs btn-primary-outline ml-3" *ngIf="orderObj.get('user').id" (click)="updateTrackingDetails()">Update Tracking Details</button>
          <button class="btn-xs btn-primary-outline ml-3"><a class="clr-primary" routerLink="/chat/{{orderObj.get(&quot;user&quot;).get(&quot;username&quot;)}}" target="_blank">Chat</a></button>
        </div>
      </div>
    </div>
    <div class="flex-content">
      <div class="col-xs-12 p-4">
        <div class="col-md-6 col-sm-12 pr-md-4">
          <div class="col-xs-12 flex">
            <label class="col-xs-6 mt-1">Name</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('user').get('PatientName')}} ({{orderObj.get("user").get("username")}})
              <allocated-doctor [doctor]="orderObj.get(&quot;allocatedDoctor&quot;)"></allocated-doctor>
            </div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">Contact Name</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8" id="contactName">{{orderObj.get('contactDetail')?.contactName || orderObj.get('contactName')}}
              <div class="btn btn-primary-outline btn-xs" style="margin-left: 8px;"><span (click)="copyToClipboard(orderObj.get('contactDetail')?.contactName || orderObj.get('contactName'))">
                  <div class="fa fa-copy"></div></span></div>
            </div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">Age</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('user')?.get('Age')}}</div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">Type</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('type')}}</div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">Instruction Call Needed</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('followUpNeeded') ? 'YES' : 'NO'}} &nbsp;</div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">FollowUp Required</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('followUpRequired') ? 'Yes' : 'No' }} &nbsp;</div>
          </div>
          <div class="col-xs-12 flex" *ngIf="orderObj.get(&quot;regimenId&quot;)">
            <label class="col-xs-6">Regimen Id</label>
            <div class="col pull-left">:</div><a class="col-xs-8 text-info pointer" routerLink="/products/regimen/{{orderObj.get(&quot;regimenId&quot;)}}" target="_blank">{{orderObj.get('regimenId')}}</a>
          </div>
          <div class="col-xs-12 flex" *ngIf="orderObj.get(&quot;regimen&quot;)?.get(&quot;selectedVariantId&quot;)">
            <label class="col-xs-6">variantId</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('regimen')?.get('selectedVariantId')}}</div>
          </div>
          <div class="col-xs-12 flex" *ngIf="orderObj.get(&quot;products&quot;) && orderObj.get(&quot;products&quot;).length">
            <label class="col-xs-6">Products</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8"><a class="inline label label-default m-l-xs m-t-xs pointer" *ngFor="let product of orderObj.get(&quot;products&quot;);" routerLink="/products/catalog/{{product.id}}">{{product.get('title')}} &nbsp; [{{product.get('quantity')}}{{product.get('quantityUnit')}}, unit/sheet: {{product.get('margUnit')}}] {{product.get('type') === apiClientConstant.Catalog.Type.SAMPLE ? '(SAMPLE)' : '' }}</a></div>
          </div>
          <div class="col-xs-12 flex" *ngIf="orderObj.get(&quot;services&quot;) && orderObj.get(&quot;services&quot;).length">
            <label class="col-xs-6">Services</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">
              <div class="inline label label-default m-l-xs m-t-xs pointer" *ngFor="let service of orderObj.get(&quot;services&quot;);">{{service.get('title')}} &nbsp; ({{service.get('type')}})</div>
            </div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">Order Object ID</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.id}} &nbsp;</div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">Order Number</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('orderNumber')}} &nbsp;</div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">User Object ID</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('user').id}} &nbsp;</div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">Segment</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('user').get('mod11')}} &nbsp;</div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">Order Mobile</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8" id="orderContactNumber">{{orderObj.get('contactNumber')}}
              <button class="btn-primary-outline btn-xxs ml-1"><span (click)="copyToClipboard(orderObj.get('contactNumber'))">
                  <div class="fa fa-copy"></div></span></button>
              <button class="btn-primary-outline btn-xxs ml-1" (click)="callUserThroughExotel(orderObj.get(&quot;contactNumber&quot;))">Call User</button>
              <button class="btn-xxs ml-1" (click)="activeContactNumber(orderObj.get(&quot;contactNumber&quot;))" [ngClass]="{ 'btn-success': orderObj.get('activeContactNumber') === orderObj.get('contactNumber'), 'btn-outline-primary': orderObj.get('activeContactNumber') !== orderObj.get('contactNumber') }">{{orderObj.get('activeContactNumber') !== orderObj.get('contactNumber') ? "Mark Active" : "Active"}}</button>
            </div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">User Mobile</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8" id="contactNumber">{{mobileNumber}}
              <button class="btn btn-primary-outline btn-xxs ml-1"><span (click)="copyToClipboard(orderObj.get('contactNumber'))">
                  <div class="fa fa-copy"></div></span></button>
              <button class="btn btn-primary-outline btn-xxs ml-1" (click)="callUserThroughExotel(mobileNumber)">Call User</button>
              <button class="btn btn-xxs ml-1" (click)="activeContactNumber(mobileNumber)" [ngClass]="{ 'btn-success': orderObj.get('activeContactNumber') === mobileNumber, 'btn-outline-primary': orderObj.get('activeContactNumber') !== mobileNumber }">{{orderObj.get('activeContactNumber') !== mobileNumber ? "Mark Active" : "Active"}}</button>
            </div>
          </div>
          <div class="col-xs-12 flex" *ngIf="alternateNumber">
            <label class="col-xs-6">User Alternate Mobile</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8" id="alternateNumber">{{alternateNumber}}
              <button class="btn btn-primary-outline btn-xxs ml-1"><span (click)="copyToClipboard(orderObj.get('contactNumber'))">
                  <div class="fa fa-copy"></div></span></button>
              <button class="btn btn-primary-outline btn-xxs ml-1" (click)="callUserThroughExotel(alternateNumber)">Call User</button>
              <button class="btn btn-xxs ml-1" (click)="activeContactNumber(alternateNumber)" [ngClass]="{ 'btn-success': orderObj.get('activeContactNumber') === alternateNumber, 'btn-outline-primary': orderObj.get('activeContactNumber') !== alternateNumber }">{{orderObj.get('activeContactNumber') !== alternateNumber ? "Mark Active" : "Active"}}</button>
            </div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">Address</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8" id="address">{{orderObj.get('deliveryAddress')}}
              <button class="btn btn-primary-outline btn-xxs ml-1"><span (click)="copyToClipboard(orderObj.get('deliveryAddress'))">
                  <div class="fa fa-copy"></div></span></button>
            </div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">Payment Type</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('paymentType')}}</div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">Stage</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('stage')}}</div>
          </div>
          <div class="col-xs-12 flex" *ngIf="orderObj.get(&quot;deliverOnDate&quot;)">
            <label class="col-xs-6">Deliver On Date</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('deliverOnDate')| amDateFormat: 'MMM DD, YYYY'}}</div>
          </div>
          <div class="col-xs-12 flex" *ngIf="orderObj.get(&quot;courierName&quot;)">
            <label class="col-xs-6">Courier Name</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8"><span>{{orderObj.get('courierName')}}</span></div>
          </div>
          <div class="col-xs-12 flex" *ngIf="orderObj.get(&quot;note&quot;)">
            <label class="col-xs-6">Note</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('note')}}</div>
          </div>
          <div class="col-xs-12 flex" *ngIf="orderObj.get(&quot;trackingId&quot;)">
            <label class="col-xs-6">Tracking Id</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8"><a class="underline text-info pointer" [href]="orderObj.get('trackingURL')" target="_blank">{{orderObj.get('trackingId')}}</a></div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">FollowUp Required</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('followUpRequired') ? 'Yes' : 'No' }}</div>
          </div>
          <div class="col-xs-12 flex" *ngIf="orderObj.get(&quot;deliveredOn&quot;)">
            <label class="col-xs-6">Delivered On</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('deliveredOn')| amDateFormat: 'MMM DD, YYYY'}}</div>
          </div>
          <div class="col-xs-12 flex" *ngIf="orderObj.get(&quot;stageChangeNote&quot;)">
            <label class="col-xs-6">Recent State Change message</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('stageChangeNote')}}</div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">Discount</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('discount')}}%</div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">Consultation Charges</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('consultationCharges')}} Rs.</div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">Amount</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8" id="amount">{{orderObj.get('amount')}} Rs.
              <button class="btn btn-primary-outline btn-xxs ml-1"><span (click)="copyToClipboard(orderObj.get('amount'))">
                  <div class="fa fa-copy"></div></span></button>
            </div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">Actual Amount</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8" id="actualAmount">{{orderObj.get('actualPrice')}} Rs.
              <button class="btn btn-primary-outline btn-xxs ml-1"><span (click)="copyToClipboard(orderObj.get(&quot;actualPrice&quot;))">
                  <div class="fa fa-copy"></div></span></button>
            </div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">Created By</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('createdBy') ? orderObj.get('createdBy').get('username') : orderObj.get('user').get('PatientName')}}</div>
          </div>
          <div class="col-xs-12 flex" *ngIf="orderObj.get(&quot;actualCreatedAt&quot;)">
            <label class="col-xs-6">Actual Created On</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('actualCreatedAt')| amDateFormat: 'MMM DD YYYY,hh:mm a'}}</div>
          </div>
          <div class="col-xs-12 flex" *ngIf="orderObj.get('skuEntries')">
            <label class="col-xs-6">SKUs</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{skuIds}}</div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">Created On</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('createdAt')| amDateFormat: 'MMM DD YYYY,hh:mm a'}}</div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">Updated On</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{orderObj.get('updatedAt')| amDateFormat: 'MMM DD YYYY,hh:mm a'}}</div>
          </div>
          <div class="col-xs-12 flex" *ngIf="orderObj.get(&quot;products&quot;) && orderObj.get(&quot;products&quot;).length">
            <label class="col-xs-6">Generate Invoice</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">
              <button class="btn-xxs" (click)="generateInvoiceUrl()">Generate Url for Invoice</button>
            </div>
          </div>
          <div class="col-xs-12 flex" *ngIf="orderObj.get(&quot;products&quot;) && orderObj.get(&quot;products&quot;).length">
            <label class="col-xs-6">Generate Prescription</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">
              <button class="btn-xxs" (click)="generatePrescriptionUrl()">Generate Url for Prescription</button>
            </div>
          </div>
          <div class="col-xs-12 flex" *ngIf="orderObj.get(&quot;signedInvoiceUrl&quot;)">
            <label class="col-xs-6">Download Invoice</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8 scroll"><span class="m-l-xs" *ngFor="let invoice of orderObj.get(&quot;signedInvoiceUrl&quot;)"><a class="text-info pointer" [href]="invoice.link" target="_blank">{{invoice.sellerId}} &nbsp;</a></span></div>
          </div>
          <div class="col-xs-12 flex" *ngIf="orderObj.get(&quot;signedUrlForPdfInvoice&quot;) || orderObj.get(&quot;signedUrlForPdfCreditNote&quot;)">
            <label class="col-xs-6">Download Consolidated PDF Invoice</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8 scroll"><a class="text-info pointer" *ngIf="orderObj.get(&quot;signedUrlForPdfInvoice&quot;)" [href]="orderObj.get('signedUrlForPdfInvoice')" target="_blank">Invoice PDF &nbsp;</a><a class="text-info pointer" *ngIf="orderObj.get(&quot;signedUrlForPdfCreditNote&quot;)" [href]="orderObj.get('signedUrlForPdfCreditNote')" target="_blank">Credit Note PDF &nbsp;</a></div>
          </div>
          <div class="col-xs-12 flex" *ngIf="prescriptions.length">
            <label class="col-xs-6">Download Prescription</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8 scroll"><span class="ml-1" *ngFor="let prescription of prescriptions;"><a class="label label-primary m-l-xs" target="_blank" href="{{prescription.get(&quot;signedPrescriptionUrl&quot;)}}">{{prescription.id ? "CP" + prescription.id : 'Download'}}</a><span class="fa fa-copy pointer ml-1" (click)="$event.preventDefault();copyToClipboard(prescription.get(&quot;signedPrescriptionUrl&quot;))"></span></span></div>
          </div>
          <div class="col-xs-12 flex">
            <label class="col-xs-6">Labels</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">
              <order-escalation-modal class="mt-3" [order]="orderObj" (addNote)="addLabelRemovalNote($event)"></order-escalation-modal>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="col-xs-12">
            <div class="col-xs-12 list-header pl-0 bg-color py-1">Order History</div>
            <div class="col-xs-12">
              <label class="col-xs-5 mt-1">Stage</label>
              <label class="col-xs-4 mt-1">UpdatedBy</label>
              <label class="col-xs-2 mt-1">UpdatedAt</label>
              <label class="col-xs-1 mt-1">Message</label>
            </div>
            <div class="col-xs-12 py-1 fontS2" *ngFor="let order of orderHistory">
              <div class="col-xs-5">{{order.get('stage')}}</div>
              <div class="col-xs-4">{{order.get('createdBy')?.get('username')}}</div>
              <div class="col-xs-2">{{order.get('createdAt') | amDateFormat: 'MMM DD YYYY,hh:mm a'}}</div>
              <div class="col-xs-1" *ngIf="order.get(&quot;stage&quot;) === apiClientConstant.Order.Stage.RETRY_DELIVERY">{{order.get('message')}}</div>
              <div class="col-xs-1" *ngIf="order.get(&quot;stage&quot;) !== apiClientConstant.Order.Stage.RETRY_DELIVERY">-</div>
            </div>
          </div>
          <order-refund-tracking [order]="orderObj"></order-refund-tracking>
          <div class="col-xs-12 pl-0 mt-4" *ngIf="orderObj.get(&quot;productInfo&quot;) || orderObj.get(&quot;serviceInfo&quot;)">
            <div class="col xs-12 list-header pl-0 bg-color py-1">Discount breakdown</div>
            <div class="col-xs-12">
              <label class="col-xs-4 mt-1">Product</label>
              <label class="col-xs-2 mt-1">Qty</label>
              <label class="col-xs-2 mt-1">MRP</label>
              <label class="col-xs-2 mt-1">SP</label>
              <label class="col-xs-2 mt-1">Discount</label>
            </div>
            <div class="col-xs-12 py-1 fontS2" *ngFor="let product of orderObj.get(&quot;productInfo&quot;) || []">
              <div class="col-xs-4">{{product.name}}</div>
              <div class="col-xs-2">{{product.quantity}}</div>
              <div class="col-xs-2">{{product.MRP}}</div>
              <div class="col-xs-2">{{product.SP.toFixed(0)}}</div>
              <div class="col-xs-2">{{product.MRP - product.SP.toFixed(0)}}</div>
            </div>
            <div class="col-xs-12 py-1 fontS2" *ngFor="let service of orderObj.get(&quot;serviceInfo&quot;) || []">
              <div class="col-xs-4">{{service.name}}</div>
              <div class="col-xs-2">{{service.quantity}}</div>
              <div class="col-xs-2">{{service.MRP}}</div>
              <div class="col-xs-2">{{service.SP.toFixed(0)}}</div>
              <div class="col-xs-2">{{service.MRP - service.SP.toFixed(0)}}</div>
            </div>
            <div class="col-xs-12 py-1 fontS2" *ngFor="let coupon of orderObj.get(&quot;couponInfo&quot;) || []">
              <div class="col-xs-4">{{coupon.code}}</div>
              <div class="col-xs-2">{{coupon.quantity}}</div>
              <div class="col-xs-2">0</div>
              <div class="col-xs-2">-{{coupon.discountedPrice}}</div>
              <div class="col-xs-2">{{coupon.amount}}</div>
            </div>
            <div class="col-xs-12 py-1 fontS2"><strong class="col-xs-4">Total</strong>
              <div class="col-xs-2">{{totalQuantity}}</div>
              <div class="col-xs-2">{{orderObj.get('actualPrice')}}</div>
              <div class="col-xs-2">{{orderObj.get('amount')}}</div>
              <div class="col-xs-2">{{orderObj.get('actualPrice') - orderObj.get('amount')}}</div>
            </div>
          </div>
          <div class="col-xs-12 p-0 mt-4">
            <div class="col">
              <lost-in-transit-modal [order]="orderObj"></lost-in-transit-modal>
            </div>
            <div class="col">
              <coupon-modal [order]="orderObj"></coupon-modal>
            </div>
          </div>
        </div>
        <div class="col-xs-12 flex" *ngIf="orderMedicalRecord">
          <label class="col-xs-2">Sop Questions</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">
            <div class="col-xs-12"><span class="label label-primary ml-1 pr-0 font12 mt-1"><span>Doctor &nbsp;</span><span class="label label-default ml-1">{{sopEditingDoctor}}&nbsp;</span></span></div>
            <div class="col-xs-12 flex flex-wrap"><span>productsAdded(</span><span class="label label-info ml-1 mt-1" *ngFor="let each of addedProducts">{{each}}</span><span>)&nbsp;</span></div>
            <div class="col-xs-12 flex flex-wrap"><span>productsRemoved(</span><span class="label label-info ml-1 mt-1" *ngFor="let each of removedProducts">{{each}}</span><span>)&nbsp;</span></div>
            <div class="col-xs-12 flex flex-wrap"><span class="label label-info ml-1 pr-0 font12 mt-1" *ngFor="let response of sopFieldsToShow"><span>{{response}} &nbsp;</span><span class="label label-default ml-1">{{orderMedicalRecord[response]}}&nbsp;</span></span></div>
          </div>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="col-xs-12 list-header">Payments<i class="fas fa-plus-square pointer fontS ml-3" (click)="openAddPaymentModal()"></i></div>
        <div class="col-xs-12 pl-4" *ngIf="payments.length">
          <label class="col-xs-1">PaymentSource</label>
          <label class="col-xs-1">Transaction Amount</label>
          <label class="col-xs-2">Txn Id</label>
          <label class="col-xs-2">Notes</label>
          <label class="col-xs-2">Created By</label>
          <label class="col-xs-2">Created At</label>
          <label class="col-xs-2">Transfer Payment</label>
        </div>
        <div class="col-xs-12 pl-4 my-3" *ngFor="let payment of payments">
          <div class="col-xs-1">{{payment.get('PaymentSource')}}</div>
          <div class="col-xs-1">{{payment.get('TransactionAmount')/100}}Rs.</div>
          <div class="col-xs-2"><span *ngFor="let each of payment.get('txnIds');">&nbsp;{{each}}&nbsp;</span></div>
          <div class="col-xs-2">{{payment.get('notes')}}</div>
          <div class="col-xs-2">{{payment.get('byUser')?.get('username')}}</div>
          <div class="col-xs-2">{{payment.get('createdAt')| amDateFormat: 'MMM DD YYYY,hh:mm a'}}</div>
          <div class="col-xs-2">
            <div class="col-xs-12">
              <div class="col-xs-8">
                <select [disabled]="!transferPaymentAllowed" id="transferPayment">
                  <option *ngFor="let item of unpaidOrders" [value]="item.id">{{item.get('orderNumber')}} / {{item.id}}</option>
                </select>
              </div>
              <div class="col-xs-4">
                <button class="btn-xs" (click)="movePayment()" [disabled]="!transferPaymentAllowed">Transfer</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 text-center bold" *ngIf="!payments.length">-- No Payments Made --</div>
      </div>
      <div class="col-xs-12 mb-3">
        <div class="col-xs-12 list-header">Notes</div>
        <order-note [order]="orderObj" [note]="note" (noteUpdated)="note = $event"></order-note>
      </div>
    </div>
    <div class="flex-footer">
      <div class="snackbar" #toast>{{message}}</div>
    </div>
  </div>
</div>
