
<div class="flex-box" fillComponentParentView="fillComponentParentView">
  <div class="flex-heading" #headingView>
    <div class="col-xs-12 list-header">
      <div class="pull-left"><span class="pR10">Orders ({{count || 0}})</span>
        <filter class="dib" [input]="filterConfig" [defaultFilter]="dropdownFilter" (apply)="applyFilter($event)"></filter>
      </div>
      <div class="pull-right"><i class="fa fa-plus-square-o mr-3 pointer" routerLink="new"></i></div>
    </div>
  </div>
  <div class="flex-content">
    <div class="list-view">
      <div class="list-sidebar">
        <div class="header">
          <div class="title">Filters</div>
          <button class="btn-xxs btn-secondary" (click)="resetFilters()">Reset</button>
        </div>
        <div class="content p-0">
          <div class="flex px-3">
            <label class="mr-3">Auto Refresh</label>
            <label class="i-switch i-switch-sm bg-success">
              <input type="checkbox" [(ngModel)]="autoRefresh" (ngModelChange)="toggleAutoRefresh()" name="autoRefresh"/><i></i>
            </label>
          </div>
          <div class="flex px-3">
            <label class="mr-3">Partial Order</label>
            <label class="i-switch i-switch-sm bg-success">
              <input type="checkbox" [(ngModel)]="partialOrder" (ngModelChange)="reset()" name="partialOrder"/><i></i>
            </label>
          </div>
          <div class="mb-3 px-3">
            <label class="db">Search</label>
            <input class="w100" placeholder="Name, Mobile, City" (change)="reset()" [(ngModel)]="searchKey"/>
          </div>
          <div class="mb-3 px-3">
            <label class="db">From</label>
            <input class="w100" type="date" (change)="changeDate($event, &quot;startDate&quot;)" [ngModel]="startDate | date:&quot;yyyy-MM-dd&quot;"/>
            <label class="db mt-3">To</label>
            <input class="w100" type="date" (change)="changeDate($event, &quot;endDate&quot;)" [ngModel]="endDate | date:&quot;yyyy-MM-dd&quot;"/>
          </div>
          <div class="px-3 bdr-top">
            <collapse [title]="this.appConfig.Shared.ListSideBar.Types.DOCTOR" [iconClass]="this.appConfig.Shared.ListSideBar.Icons.DOCTOR" [showList]="true">
              <doctor-filter (updatedDoctorList)="reset($event)" [reset]="resetDoctorFilter"></doctor-filter>
            </collapse>
          </div>
          <section class="list-color-holder"><span class="list-colors-circle list-label-bg-red" data-tooltip-text="Doctor call needed" data-tooltip-pos="right"></span><span class="list-colors-circle list-label-bg-yellow" data-tooltip-text="Voice note needed" data-tooltip-pos="right"></span></section>
        </div>
      </div>
      <div class="list-content">
        <ag-grid-angular class="ag-theme-balham" style="height: 100%;" [columnDefs]="columnDefs" [components]="components" [rowBuffer]="0" [rowModelType]="ui.grid.rowModelType" [paginationPageSize]="ui.grid.pageSize" [cacheBlockSize]="ui.grid.pageSize" [cacheOverflowSize]="0" [maxConcurrentDatasourceRequests]="1" [infiniteInitialRowCount]="1" [maxBlocksInCache]="10" (gridReady)="onGridReady($event)"></ag-grid-angular>
      </div>
    </div>
  </div>
</div>
