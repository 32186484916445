import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MomentModule } from 'ngx-moment';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '../../../../components/directives.module';
import { SBARComponent } from './SBAR.component';
import { InputTextModule } from '../../../../components/input/input-text';
import { SbarHistoryComponent } from '../sbar-history';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatSelectModule,
    InputTextModule,
    MomentModule,
    RouterModule,
    InputTextModule,
  ],
  declarations: [SBARComponent, SbarHistoryComponent],
  exports: [SBARComponent],
})
export class SBARModule {
}
