
<div class="hbox">
  <div class="col-xs-12 regimen-products" *ngIf="regimen">
    <div class="col-xs-12 heading flex justifySB">
      <div><span class="fw-700">Regimen details:</span><span>&nbsp; {{concerns.join(", ")}}</span><span *ngIf="regimen.active">&nbsp;(Active)</span><span *ngIf="!regimen.active">&nbsp;(Inactive)</span></div>
      <div class="flex">
        <div class="add-new-button"><a href="/orders/new?username={{user.get(&quot;username&quot;)}}" target="_blank">+</a></div>
        <div class="text-700 ml-3" (click)="openChangesPopop()">See order history</div>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="flex flex-dir-col" style="margin-top: 20px; margin-bottom: 36px;">
        <div class="new-box" *ngIf="lastDeliveredRegimen">
          <div class="flex m-4" style="width: 98.7%;">
            <div class="flex flex-dir-col alignFS" style="line-height: 20px; margin-right: 20px;"><span class="mb-1 sub-heading" style="margin-right: 44px;">1st Regimen</span><span class="mb-1" style="font-size: 13px">({{lastDeliveredRegimen.deliveredOn | date: "d MMM, yy"}})</span></div>
            <div class="flex of-scr">
              <div class="flex justifySB ml-3"><span class="mb small-box" *ngFor="let product of lastDeliveredRegimen.products"><span class="pdt-title">{{product.title}}</span></span></div>
            </div>
          </div>
        </div>
        <div class="new-box" style="border-top-width: 0; background-color: #EBF1CE;">
          <div class="flex m-4" style="width: 98.7%">
            <div class="flex alignC" style="margin-right: 20px;"><span class="mb-1 sub-heading" style="color: #4BAE4F;">Active regimen</span><img src="../../assets/images/active.png"/></div>
            <div class="flex of-scr">
              <div class="flex justifySB ml-3" *ngIf="regimenDetails?.length"><span class="mb small-box" style="background-color: white;" *ngFor="let detail of regimenDetails" [ngClass]="{'inActive-products line-through': !detail.activeProduct}">
                  <div class="flex flex-dir-col alignFS">
                    <div class="flex"><span class="pdt-title">{{detail.title}}</span></div>
                    <div class="flex">
                      <div class="flex" *ngIf="detail.startDate"><span class="pdt-content">({{detail.differenceBtwStartAndEnd}}) &nbsp;</span></div>
                      <div class="flex" *ngIf="detail.orders?.length">
                        <div class="flex" *ngFor="let order of (detail.orders.slice(0,4)); last as isLast"><span class="pdt-content" *ngIf="order.stage === appConfig.Shared.User.OrderState.DELIVERED">{{order.deliveredDate | date: "d MMM, yy" }}</span><span class="pdt-content" *ngIf="order.stage !== appConfig.Shared.User.OrderState.DELIVERED">&nbsp;({{order.stage}})</span><span *ngIf="!isLast">&nbsp;|&nbsp;</span></div>
                      </div>
                      <div class="flex" *ngIf="!detail.orders?.length"><span class="pdt-content" style="color: #D66736;">Never ordered</span></div>
                    </div>
                  </div>
                  <div class="textC fw-700" *ngIf="!regimenDetails?.length">------Empty Regimen------</div></span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex margin-addon" *ngIf="addOnProducts.length">
        <div class="flex alignFS mb-1"><span class="sub-heading mt-1">Add On Products</span>
          <div class="flex ml-2 of-scr" style="width: 80%;">
            <div class="flex justifySB ml-3" *ngIf="addOnProducts?.length"><span class="mb small-box" style="background-color: white;" *ngFor="let product of addOnProducts">
                <div class="flex flex-dir-col alignFS">
                  <div class="flex"><span class="pdt-title">{{product.title}}</span></div>
                  <div class="flex" *ngIf="product.orders?.length">
                    <div class="flex" *ngFor="let order of (product.orders).slice().reverse(); last as isLast"><span class="pdt-content" *ngIf="order.stage === apiClientConstant.User.OrderState.DELIVERED">{{order.deliveredDate | date: "d MMM, yy" }}</span><span class="pdt-content" *ngIf="order.stage !== apiClientConstant.User.OrderState.DELIVERED">&nbsp;({{order.stage}})</span><span *ngIf="!isLast">&nbsp;|&nbsp;</span></div>
                  </div>
                  <div class="flex" *ngIf="!product.orders?.length"><span class="clr-orange pdt-content">Never ordered</span></div>
                </div></span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>