<loading class="posF vh-center" *ngIf="loading" [scale]="1.5"></loading>
<div class="sales-container" *ngIf="!loading">
  <app-header [user]="chatUser" [orders]="orders" (updateOrder)="updateOrderObject($event)"></app-header>
  <div class="grid-container">
    <div class="item1">
      <div class="flex space-between main-concern">
        <div class="flex">
          <div>Main Concern:</div>
          <div class="bold">{{chatUser?.get('PrivateMainConcern')}}</div>
        </div>
        <div class="retrigger pointer" (click)="retriggerTree()">
          <span>Retrigger tree</span>
          <img src="/assets/images/retrigger.svg" alt="" class="icon">
        </div>
      </div>
      <iframe #newChatView class="item1" [src]="webAppUrl" frameborder="0"></iframe>
    </div>
    <sales-patient-info class="item2" [user]="chatUser" [order]="orderObj" [parseObject]="parseOrderObj"></sales-patient-info>
    <sales-user-photos class="item3" [user]="chatUser" [instantCheckups]="instantCheckups" [orderObj]="orderObj"></sales-user-photos>
  </div>
  <div class="flex align-start">
    <sales-doctor-notes class="child" [username]="username" [user]="chatUser"></sales-doctor-notes>
    <sales-support-ticket class="child" [username]="username" [user]="chatUser"></sales-support-ticket>
  </div>
  <div class="flex align-start">
    <sales-call-logs class="child" [user]="chatUser"></sales-call-logs>
    <sales-order-history class="child" [user]="chatUser" [orders]="orders"></sales-order-history>
  </div>
  <sales-regimen-variants *ngIf="orderObj?.regimen?.variants?.length"
    [regimen]="orderObj?.regimen" [order]="orderObj"></sales-regimen-variants>
  <div *ngIf="orderObj?.objectId" class="create-order">Create Order</div>
  <sales-order-info *ngIf="orderObj?.objectId" [orderObj]="orderObj" [user]="chatUser" [parseOrderObj]="parseOrderObj"></sales-order-info>
  <sales-payments *ngIf="orderObj?.objectId" [orderObj]="orderObj" [parseOrderObj]="parseOrderObj"></sales-payments>
  <sales-order-notes *ngIf="orderObj?.objectId" [orderObjId]="orderObj?.objectId" [note]="note"></sales-order-notes>
</div>