
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/tree/{{indicationTreeObj.get(&quot;name&quot;)}}">Tree</a></li>
          <li>{{indicationTreeObj.get('name')}}</li>
        </ul>
        <div class="pull-right">
          <div class="col-xs-12" *ngIf="[apiClientConstant.Tree.Type.INDICATION, apiClientConstant.Tree.Type.ANSWER_DEPENDENT].includes(indicationTreeObj.get(&quot;type&quot;)) ">
            <button class="btn-xxs btn-primary mr-2" type="button" (click)="copyTree()">Copy Tree</button>
            <button class="btn-xxs btn-primary mr-2" routerLink="edit">Edit</button>
            <button class="btn-xxs btn-primary mr-2" *ngIf="indicationTreeObj.get(&quot;type&quot;) === apiClientConstant.Tree.Type.ANSWER_DEPENDENT" routerLink="../../../../answer_dependent/new">New</button>
            <button class="btn-xxs btn-primary mr-2" *ngIf="indicationTreeObj.get(&quot;type&quot;) === apiClientConstant.Tree.Type.INDICATION" routerLink="../../../../indications/new">New</button>
          </div>
          <div class="col-xs-12" *ngIf="indicationTreeObj.get(&quot;type&quot;) === apiClientConstant.Tree.Type.ACTION">
            <button class="btn-xxs btn-primary mr-2" routerLink="edit">Edit</button>
            <button class="btn-xxs btn-primary mr-2" routerLink="../../../../actions/new">New</button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-content">
      <div class="col-xs-12 p-4">
        <div class="col-xs-12 flex mt-0">
          <label class="col-xs-2 mt-2">Tree</label>
          <div class="col-xs-8">{{indicationTreeObj.get('name').split('_')[1]}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">IsConsultation</label>
          <div class="col-xs-8">{{indicationTreeObj.get('isConsultation') ? 'Yes' : 'No'}}</div>
        </div>
        <div class="col-xs-12 flex" *ngIf="indicationTreeObj.get(&quot;isConsultation&quot;)">
          <label class="col-xs-2">Payment Text</label>
          <div class="col-xs-8">{{indicationTreeObj.get('lastQuestionInConsultationFlow')}}</div>
        </div>
        <div class="col-xs-12 flex" *ngIf="indicationTreeObj.get(&quot;isConsultation&quot;)">
          <label class="col-xs-2">Consultation Charges</label>
          <div class="col-xs-8">{{indicationTreeObj.get('consultationCharges')}} Rs</div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-12">Questions</label>
        </div>
        <div class="col-xs-12" *ngFor="let regimenQuestion of regimenQuestionMap;let i = index;">
          <div class="col-xs-12 flex">
            <div class="col-xs-2" *ngIf="!indicationTreeObj.get(&quot;isConsultation&quot;)">
              <div class="label label-info mt-2 pointer">{{regimenQuestion.regimen}}</div>
            </div>
            <div class="col-xs-10 flex flex-wrap"><a class="badge mr-2 mb-2 pointer" *ngFor="let question of regimenQuestion.questions;let y = index;" [href]="&quot;/trees/question/&quot; + question.questionId" title="{{question.title}}">{{question.uniqueIdentifier}} {{question.answer ? ':' : ''}} {{question.answer}}</a></div>
          </div>
          <div class="col-xs-12 bg-black my-3" style="height:1px" *ngIf="i &lt; regimenQuestionMap.length - 1"></div>
        </div>
      </div>
    </div>
  </div>
</div>