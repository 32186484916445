import { v4 as uuid } from 'uuid';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { ApiClientConstant, ApiConnector, Table } from 'api-client';
import { ValueOf } from 'api-client/src/common';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../app.config';
import { HelperService } from '../../../services/helper-service';
import { LocalStorage } from '../../../services/local-storage-service';
import { Broadcaster } from '../../../components/broadcaster';
import { WindowRefService } from '../../../services/window-ref-service';

declare interface OrderType {
  type: string;
  contactNumber: string;
  contactName: string;
  stage: ValueOf<typeof ApiClientConstant.Order.Stage>;
  products: Array<any>;
  services: Array<any>;
  paymentType: string;
  courierName?: string;
  trackingURL?: string;
  trackingId?: string;
  note?: string;
  regimenId: string;
  referenceOrder?: any;
  user: { displayName: string, userObj: any };
  amount?: number;
  actualPrice?: number;
  contactDetail: any;
  noMessageToUser?: boolean;
  consultationCharges: number;
  updateCashCoupon?: boolean;
  regimen?: any;
}

@Component({ selector: 'order-edit', templateUrl: './edit.html', styleUrls: ['./edit.scss'] })
export class OrderEditComponent implements OnDestroy {
  public autoCompleteProductController: UntypedFormControl = new UntypedFormControl();
  productOptions: Observable<Array<{ name: string; object: any }>>;
  public autoCompleteServiceController: UntypedFormControl = new UntypedFormControl();
  serviceOptions: Observable<Array<{ name: string; object: any }>>;
  public autoCompleteUserController: UntypedFormControl = new UntypedFormControl();
  userOptions: Observable<Array<{ name: string; object: any }>>;
  disableStageEdit: boolean;
  userRegimens: Array<any> = [];
  couriers: Array<string>;
  order: OrderType;
  userOrders: Array<any>;
  productsNotInRegimen: Array<any> = [];
  allRegimenProducts: Array<any> = [];
  types: Array<{ name: string, value: string }>;
  paymentTypes: Array<{ name: string, value: string }>;
  orderObj: any;
  subscriptions: Array<Subscription>;
  possibleOrderStage: any;
  requestContext: Record<string, unknown> = {};
  isDoctor: boolean = false;
  internalUserType: string;
  allowCreateOrder: boolean = true;
  allowOrderEditing: boolean = false;
  userNeedForConfirmation: string;
  doctorCallNeeded: boolean = false;
  updateAddress: boolean = false;
  ui: {
    isOrderReferenceInQuery: boolean,
    dialog: {
      Confirmation: { data: any, open: boolean },
    },
  };
  userRoles: Array<any> = [];
  selectedVariant: string;
  variants: Array<{display: string, value: string}> = [];

  dropdownSettings: any = {
    singleSelection: false,
    placeholder: 'Waiting for image reason',
    idField: 'key',
    textField: 'key',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: false,
    enableCheckAll: false,
  };
  selectedWaitingForImageReasons: Array<string> = [];
  selectedWaitingForImageReasonsTextArea: string;
  note: string = '';
  noteUpdated: boolean = false;
  paymentPopUp:boolean = false;
  changePaymentModeInProcess: boolean = false;
  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
  constructor(private router: Router,
    private conn: ConnectionService,
    private route: ActivatedRoute,
    public appConfig: AppConfig,
    private helper: HelperService,
    private storage: LocalStorage,
    private window: WindowRefService,
    private broadcaster: Broadcaster) {
  }

  ngOnInit(): void {
    this.productOptions = this.autoCompleteProductController.valueChanges
      .pipe(
        debounceTime(300),
        filter((token: string) => !!token.length),
        mergeMap((token: string) => this.getProducts(token)));
    this.serviceOptions = this.autoCompleteServiceController.valueChanges
      .pipe(
        debounceTime(300),
        filter((token: string) => !!token.length),
        mergeMap((token: string) => this.getServices(token)));
    this.userOptions = this.autoCompleteUserController.valueChanges
      .pipe(
        debounceTime(300),
        filter((token: string) => !!token.length),
        mergeMap((token: string) => this.getUsers(token)));
    this.userRoles = this.storage.getJsonValue('userRoles');
    this.internalUserType = this.conn.getCurrentUser().get('type');
    if (this.internalUserType === 'doctor') this.isDoctor = true;
    this.ui = {
      isOrderReferenceInQuery: false,
      dialog: {
        Confirmation: { data: {}, open: false },
      },
    };
    this.subscriptions = [];
    this.productsNotInRegimen = [];
    if (this.route.snapshot.queryParams.username) {
      const { username }: { username: string } = this.route.snapshot.queryParams as { username: string };
      this.conn.findUsers({
        where: { $or: ['objectId', 'username', 'MobileNumber'].map((key: string) => ({ [key]: username })) },
        limit: 1,
        project: ['PatientName', 'username', 'contactDetails', 'MobileNumber'],
      })
        .then(([user]: Array<any>) => {
          this.broadcaster.broadcast('ChatUserUpdate', { user });
          this.autoCompleteOnUserSelect({ name: this.generateDisplayName(user), object: user });
        });
    }
    this.couriers = Object.keys(ApiClientConstant.Order.Courier)
      .map((key: string) => ApiClientConstant.Order.Courier[key]);
    this.types = Object.keys(ApiClientConstant.Order.Type).map((key: string) => ({
      name: ApiClientConstant.Order.Type[key].replace(/_/g, ' '),
      value: ApiClientConstant.Order.Type[key],
    }));
    this.orderObj = new Table.Order();
    this.reset();
    this.subscribeToUrlQueryParams();
    if (!this.order.contactDetail) {
      this.order.contactDetail = { contactName: (this.order.contactName ? this.orderObj.get('contactName') : ''),
        contactNumber: (this.order.contactNumber ? this.orderObj.get('contactNumber') : '') };
    }
    if (this.isNewOrder()) {
      this.updateOrderPossibleStages();
    }
    this.doctorCallNeeded = this.orderObj.get('regimen')?.get('optedForDoctorCall');
    this.selectedVariant = this.orderObj.get('regimen')?.get('selectedVariant');
    this.variants = [
      { display: '1499 Regimen(variant1)', value: 'variant_1499' },
      { display: '1999 Regimen(variant2)', value: 'variant_1999' },
      { display: '4999 Regimen(variant3)', value: 'variant_4999' },
    ];
    if (this.order?.trackingId) {
      this.autoCompleteUserController.disable();
    }
  }

  async onChangeVariant(): Promise<void> {
    if (confirm('Are you sure to change variant')) {
      try {
        await this.conn.changeRegimenVariant(this.orderObj.get('regimen').get('regimenId'), this.selectedVariant);
        this.broadcaster.broadcast('NOTIFY', { message: 'variant updated successfully',
          type: this.appConfig.Shared.Toast.Type.SUCCESS });
        this.window.nativeWindow.location.reload();
      } catch (error) {
        this.broadcaster.broadcast('NOTIFY', { message: error.message,
          type: this.appConfig.Shared.Toast.Type.ERROR });
      }
    } else {
      delete this.selectedVariant;
      this.broadcaster.broadcast('NOTIFY', { message: 'variant not updated',
        type: this.appConfig.Shared.Toast.Type.WARNING });
    }
  }

  async getCheckoutAndOrderCreateResponse(): Promise<void> {
    let productsList: Array<string> = [];
    let servicesList: Array<string> = [];
    if (this.order.products.length) {
      productsList = this.order.products.map((product: any) => product.id);
    }
    if (this.order.services.length) {
      servicesList = this.order.services.map((service: any) => service.id);
    }
    this.paymentTypes = [
      { name: 'ONLINE', value: ApiClientConstant.Order.PaymentType.ONLINE },
      { name: 'NOT SELECTED', value: ApiClientConstant.Order.PaymentType.NOT_SELECTED },
      { name: 'NA', value: ApiClientConstant.Order.PaymentType.NA },
    ];
    if (this.orderObj.get('stage') === ApiClientConstant.Order.Stage.PAYMENT_ERROR) {
      this.paymentTypes.push({ name: 'COD', value: ApiClientConstant.Order.PaymentType.COD });
    }
    const data = this.isNewOrder()
      ? {
        addressBookId: this.orderObj.get('addressBook').id,
        paymentType: this.order.paymentType || ApiClientConstant.Order.PaymentType.NOT_SELECTED,
        regimenId: this.order.regimenId,
        type: this.order.type,
        uniqueId: uuid(),
        userObjectId: this.orderObj.get('user').id,
        products: productsList?.join(',') || '',
        services: servicesList?.join(',') || '',
      }
      : { orderId: this.orderObj?.id };
    if (data.orderId && this.orderObj.get('paymentType') === 'COD') {
      this.paymentTypes.push({
        name: 'COD',
        value: ApiClientConstant.Order.PaymentType.COD,
      });
    } else {
      const checkoutResponse = await ApiConnector.cloudRun('checkoutAndCreateOrder', data);
      this.orderObj.id = checkoutResponse.id;
      if (checkoutResponse.toJSON().paymentGateway.includes('COD')) {
        this.paymentTypes.push({
          name: 'COD',
          value: ApiClientConstant.Order.PaymentType.COD,
        });
      }
    }
  }

  async updateDoctorCallNeeded(): Promise<void> {
    this.doctorCallNeeded = !this.doctorCallNeeded;
    await this.conn.optForRegimenDoctorCall(this.orderObj?.get('regimenId'), this.doctorCallNeeded);
  }

  async getProducts(name: string): Promise<Array<{ name: string; object: any }>> {
    const products = await this.conn.findCatalogs({
      where: {
        title: { $regex: name, $options: 'i' },
        inventoryStatus: ['AVAILABLE', 'UNAVAILABLE', 'RESTRICTED'],
      },
      project: ['title', 'quantity', 'quantityUnit', 'type', 'margUnit', 'mrp', 'price'],
      ascending: 'margUnit',
      limit: 10,
    });
    return products.map((product: any): { name: string; object: any } => ({
      name: this.getProductDisplayName(product),
      object: product,
    }));
  }

  getProductDisplayName(product: any): string {
    return `${product.get('title')} [ ${
      (product.get('margUnit') || 1) > 1
        ? `${product.get('margUnit')} sheets, `
        : ''
    }${product.get('quantity')}${product.get('quantityUnit')} ] ${product.get('type') === 'sample' ? '(sample)' : ''}`;
  }

  async getServices(name: string): Promise<Array<{ name: string; object: any }>> {
    const services = await this.conn.findServices({
      where: { title: { $regex: name, $options: 'i' } },
      limit: 10,
      project: ['title', 'amount', 'type', 'amount'],
    });
    return services.map((service: any): { name: string; object: any } => ({
      name: `${service.get('title')} (₹${service.get('amount')}) (${service.get('type')})`,
      object: service,
    }));
  }

  isNewOrder(): boolean {
    return this.router.url.split('?')[0].endsWith('new');
  }

  updateOrderPossibleStages(): void {
    const shippingTeam = this.userRoles.includes('shipping');
    const stagesAllowedForOrderEditing: string[] = [
      this.appConfig.Shared.Order.Stage.SHIP_NOW,
      ...this.appConfig.Shared.Order.OrderStageBeforeShipNow,
    ];
    this.allowOrderEditing = !this.orderObj.id || stagesAllowedForOrderEditing.includes(this.order.stage);
    const stage: ValueOf<typeof ApiClientConstant.Order.Stage> = this.order.stage || ApiClientConstant.Order.Stage.INITIAL;
    this.disableStageEdit = !shippingTeam && !this.allowOrderEditing;
    this.conn.getOrderStage(stage)
      .then((data: any) => {
        this.possibleOrderStage = data.get('possibleStageChange').filter((each: any) => each.id !== data.id);
        this.possibleOrderStage = [data, ...this.possibleOrderStage]
          .map((item: any) => ({
            name: item.get('name').replace(/_/g, ' '),
            value: item.get('name'),
          }));
      });
  }

  subscribeToUrlQueryParams(): void {
    this.subscriptions.push(this.route.queryParams.subscribe((params: any) => {
      if (params.referenceOrderId) {
        this.ui.isOrderReferenceInQuery = true;
        this.order.referenceOrder = params.referenceOrderId;
        this.order.type = ApiClientConstant.Order.Type.REPLACEMENT_PRODUCT;
        this.order.paymentType = ApiClientConstant.Order.PaymentType.NA;
        this.order.amount = 0;
        this.updateOrderByReferenceOrder();
        this.updateOrderPossibleStages();
      }
    }));
    this.subscriptions.push(this.route.parent.params.subscribe(() => {
      if (this.route.parent.snapshot.data.order) {
        this.orderObj = this.route.parent.snapshot.data.order;
        this.helper.convertParseToDictionary(this.orderObj, this.order, { products: [] });
        this.order.amount = this.orderObj.get('amount');
        this.order.actualPrice = this.orderObj.get('actualPrice');
        this.order.courierName = this.orderObj.get('courierName');
        this.order.regimen = this.orderObj.get('regimen');
        this.order.trackingURL = this.orderObj.get('trackingURL');
        this.order.trackingId = this.orderObj.get('trackingId');
        this.order.noMessageToUser = this.orderObj.get('noMessageToUser') || false;
        this.order.updateCashCoupon = this.orderObj.get('updateCashCoupon') || false;

        this.order.user.userObj = this.orderObj.get('user');
        if (!Object.keys(this.orderObj.get('contactDetail')).length) {
          this.orderObj.set('contactDetail', this.order.user.userObj.get('contactDetails'));
        }
        this.order.contactDetail = {
          contactName: this.order.user.userObj.get('PatientName'),
          contactNumber: this.order.user.userObj.get('MobileNumber'),
          ...this.orderObj.get('contactDetail'),
        };
        if (this.order.user.userObj) this.order.user.displayName = this.generateDisplayName(this.order.user.userObj);
        this.updateOrderPossibleStages();
        this.calculateAmount();
        this.getUserRegimens(this.order.user);
        this.getCheckoutAndOrderCreateResponse();
      }
    }));
  }

  updateOrderByReferenceOrder(): void {
    this.conn.getOrder(this.order.referenceOrder)
      .then((order: any) => {
        this.order.user.displayName = this.generateDisplayName(order.get('user'));
        this.order.user.userObj = order.get('user');
        this.order.contactDetail = order.get('contactDetail') || this.order.user.userObj.get('contactDetails');
        this.autoCompleteUserController.setValue(this.generateDisplayName(order.get('user')));
        this.onSelectionChange();
        this.calculateAmount();
      });
  }

  async getUsers(token: string): Promise<Array<{ name: string; object: any }>> {
    const users = await this.conn.findUsers({
      where: {
        $or: ['username', 'MobileNumber', 'objectId'].map((key: string) => ({ [key]: token })),
      },
      limit: 10,
      project: ['PatientName', 'username', 'contactDetails', 'MobileNumber'],
    });
    return users.map((object: any): { name: string; object: any } => ({
      name: this.generateDisplayName(object),
      object,
    }));
  }

  generateDisplayName(user: any): string {
    return `${user.get('PatientName')} (${user.get('username')})`;
  }

  autoCompleteOnUserSelect(item: { name: string; object: any }): void {
    this.order.user.displayName = item.name;
    this.order.user.userObj = item.object;
    this.order.contactDetail = Object.assign(
      JSON.parse(JSON.stringify(this.order.user.userObj.get('contactDetails') || {})),
      {
        contactName: this.order.user.userObj.get('PatientName'),
        contactNumber: this.order.user.userObj.get('MobileNumber'),
      });
    this.autoCompleteUserController.setValue(this.order.user.displayName);
    if (!this.orderObj.id) {
      this.orderObj.set('user', item.object);
    }
    this.onSelectionChange();
    this.getUserRegimens(this.order.user.userObj);
    this.calculateAmount();
  }

  async getUserRegimens(user: any): Promise<any> {
    this.userRegimens = await this.conn.getUserRegimens(user);
  }

  async updateAddressBook(): Promise<void> {
    this.orderObj.set('addressBook', this.orderObj.get('addressBook'));
  }

  autoCompleteOnProductSelect(item: { name: string; object: any }): void {
    if (!this.order.products) this.order.products = [];
    this.order.products.push(item.object);
    this.autoCompleteProductController.setValue('');
    this.calculateAmount();
  }

  autoCompleteOnServiceSelect(item: { name: string; object: any }): void {
    if (!this.order.services) this.order.services = [];
    this.order.services.push(item.object);
    this.autoCompleteServiceController.setValue('');
    this.calculateAmount();
  }

  removeProduct(index: number): void {
    this.order.products.splice(index, 1);
    this.calculateAmount();
  }

  removeService(index: number): void {
    this.order.services.splice(index, 1);
    this.calculateAmount();
  }

  calculateAmount(): void {
    if (this.orderObj.id) {
      this.order.amount = this.orderObj.get('amount');
      this.order.actualPrice = this.orderObj.get('actualPrice');
      return;
    }
    this.order.amount = this.order.services.reduce((amount: number, item: any) => (amount + item.get('amount')), 0);
    this.order.amount = this.order.products
      .reduce((amount: number, item: any) => (amount + item.get('mrp')), this.order.amount);
    this.order.amount += this.order.consultationCharges || 0;
    this.order.amount = Number(this.order.amount.toFixed(2));
    this.order.actualPrice = this.order.services.reduce((amount: number, item: any) => (amount + item.get('amount')), 0);
    this.order.actualPrice = this.order.products.reduce((amount: number, item: any) => (amount + item.get('mrp')), this.order.actualPrice);
  }

  reset(): void {
    this.userOrders = [];
    this.order = {
      consultationCharges: 0,
      type: ApiClientConstant.Order.Type.REGIMEN,
      contactNumber: '',
      contactName: '',
      regimenId: '',
      stage: 'INITIAL',
      paymentType: '',
      products: [],
      services: [],
      user: { displayName: '', userObj: new Table.User() },
      contactDetail: {},
    };
  }

  isValid(): boolean {
    if (!this.order.user) return false;
    if (this.order.user.displayName !== this.generateDisplayName(this.order.user.userObj)) {
      delete this.order.user.displayName;
      return false;
    }
    return true;
  }

  productOrderCheck(order: any): boolean {
    if (!(order.get('type') === 'PRODUCT')) return true;
    const products = order.get('products').map((product: any) => product.id);
    this.allRegimenProducts = [];
    this.userRegimens.forEach((regimen: any) => {
      const regimenProducts = regimen.get('products');
      this.allRegimenProducts.push(...regimenProducts.map((product: any) => product.id));
    });
    products.forEach((productId: any) => {
      if (!this.allRegimenProducts.includes(productId)) {
        this.productsNotInRegimen.push(productId);
      }
    });
    if (this.productsNotInRegimen.length) {
      return confirm('Are you sure you want to create this order because the product is not in the Regimen?');
    }
    return true;
  }

  updateUserData(): any {
    const userdata = { id: this.order.user.userObj.id,
      contactDetails: this.order.contactDetail };
    return this.conn.updateUserData(userdata);
  }

  async updateOptedForDoctorCall(): Promise<void> {
    if (this.orderObj.get('stage') !== ApiClientConstant.Order.Stage.USER_CONFIRMATION_PENDING
      || this.orderObj.get('type') === ApiClientConstant.Order.Type.PRODUCT) return;
    const orderRegimen = this.orderObj.get('regimen');
    if (!orderRegimen) return;
    if (this.userNeedForConfirmation === this.appConfig.Shared.CallConfirmation.DOCTOR_CALL) {
      orderRegimen.set('optedForDoctorCall', true);
    } else if (this.userNeedForConfirmation === this.appConfig.Shared.CallConfirmation.VOICE_INSTRUCTION) {
      orderRegimen.set('optedForDoctorCall', false);
    } else if (this.userNeedForConfirmation === this.appConfig.Shared.CallConfirmation.NONE) {
      orderRegimen.unset('optedForDoctorCall');
    }
    try {
      await orderRegimen.save();
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error,
        type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  async saveOrder(confirmationData: Array<string> = []): Promise<void> {
    this.allowCreateOrder = false;
    if (!this.isValid()) {
      this.allowCreateOrder = true;
      return;
    }
    await this.updateOptedForDoctorCall();
    const isNew = !this.orderObj.id;
    this.order.contactDetail.deliveryAddress = `${this.order.contactDetail.buildingDetails} ${this.order.contactDetail.localityDetails
    } ${this.order.contactDetail.landmark} ${this.order.contactDetail.city} ${this.order.contactDetail.state
    } ${this.order.contactDetail.zipCode}`;
    if (this.order.paymentType === '') {
      this.order.paymentType = 'NOT_SELECTED';
    }
    this.helper.convertDictionaryToParse(this.order, this.orderObj);
    const user = new Table.User();
    user.id = this.order.user.userObj.id;
    this.orderObj.set('user', user);

    if (isNew) {
      this.orderObj.set('uniqueId', uuid());
    }

    if (this.orderObj.get('stage') === ApiClientConstant.Order.Stage.INITIAL
      && this.orderObj.dirtyKeys().includes('paymentType')) {
      this.orderObj.unset('previousStage');
    }

    if (!this.productOrderCheck(this.orderObj)) {
      this.allowCreateOrder = true;
      return;
    }
    if (this.orderObj.get('type') === ApiClientConstant.Order.Type.PRODUCT
      && !(this.orderObj.get('products').length
        || this.orderObj.get('services').length)) {
      alert('Please add a product or service to create order');
      this.allowCreateOrder = true;
      return;
    }
    if (this.order.stage === ApiClientConstant.Order.Stage.WAITING_FOR_IMAGE && !this.selectedWaitingForImageReasons.length) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Select any waiting for image reason',
        type: this.appConfig.Shared.Toast.Type.WARNING });
      this.allowCreateOrder = true;
      return;
    }
    const context: { images: Array<string> } = { images: [], ...this.requestContext };
    if (this.selectedWaitingForImageReasons.length) {
      this.appConfig.Shared.Order.WaitingForImageReasons.forEach((reason: { key: string, images: Array<string> }) => {
        if (this.selectedWaitingForImageReasons.includes(reason.key)) {
          reason.images.forEach((image: string) => {
            if (!context.images.includes(image)) {
              context.images.push(image);
            }
          });
        }
      });
      const notes = this.orderObj.get('notes') || [];
      let message = this.selectedWaitingForImageReasons.join(', ');
      if (this.selectedWaitingForImageReasonsTextArea) {
        message += `| ${this.selectedWaitingForImageReasonsTextArea}`;
      }
      notes.push({ message, byUser: this.conn.getCurrentUser().get('username'), createdAt: new Date().toISOString() });
      this.orderObj.set('notes', notes);
    }
    if (this.orderObj.dirtyKeys().includes('stage')
      && this.orderObj.get('stage') === ApiClientConstant.Order.Stage.CANCELED && !this.noteUpdated) {
      this.broadcaster.broadcast('NOTIFY', {
        message: 'Note is required while Canceling Order',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
      this.allowCreateOrder = true;
      return;
    }
    confirmationData.forEach((confirmation: string) => this.orderObj.set(confirmation, true));
    try {
      if (isNew) {
        await this.getCheckoutAndOrderCreateResponse();
        this.paymentPopUp = true;
      } else {
        await this.orderObj.save({}, { context });
      }
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message,
        type: this.appConfig.Shared.Toast.Type.ERROR });
      this.allowCreateOrder = true;
      return;
    }
    this.allowCreateOrder = true;
    if (!this.isNewOrder()) {
      await this.router.navigate(
        [`${isNew ? '' : '../'}../../order`, this.orderObj.id],
        { relativeTo: this.route });
    }
  }

  async changeOrderPaymentTypeForNewOrder(): Promise<void> {
    if (this.order.paymentType === this.appConfig.Shared.Order.PaymentType.NOT_SELECTED) {
      this.paymentPopUp = false;
      return;
    }
    this.changePaymentModeInProcess = true;
    await this.conn.changeOrderPaymentMode(this.order.paymentType, this.orderObj.id);
    this.paymentPopUp = false;
    await this.router.navigate(
      ['../../order', this.orderObj.id],
      { relativeTo: this.route });
  }

  onSelectionChange(): void {
    if (this.order.user.userObj.id && this.order.type === ApiClientConstant.Order.Type.REPLACEMENT_PRODUCT) {
      this.conn.getOrders({ where: { user: this.order.user.userObj } })
        .then((userOrders: Array<any>) => {
          this.userOrders = userOrders;
          this.onChangeReferenceOrder();
        });
    }
  }

  onChangeReferenceOrder(): void {
    const [selectedOrder]: any = this.userOrders.filter((order: any) => (order.id === this.order.referenceOrder));
    if (!selectedOrder) return;
    this.order.contactNumber = selectedOrder.get('contactNumber');
    // autoUpdateProductList
    (selectedOrder.get('type') === ApiClientConstant.Order.Type.REGIMEN
      ? this.conn.getRegimen(selectedOrder.get('regimenId'))
        .then((regimen: any) => {
          const products = [];
          [].concat(regimen.get('morning'))
            .concat(regimen.get('night'))
            .forEach(({ product }: any) => {
              if (products.every((item: any) => (item.id !== product.id))) {
                products.push(product);
              }
            });
          return Promise.resolve(products);
        })
      : this.conn.getOrder(selectedOrder.id).then((order: any) => order.get('products')))
      .then((products: Array<any>) => (this.order.products = products));
  }

  stopPropagation(e: any): void {
    e.stopPropagation();
  }

  OnNoteUpdation(event: any): void {
    this.noteUpdated = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    delete this.router;
    delete this.appConfig;
    delete this.conn;
    delete this.route;
    delete this.subscriptions;
    delete this.order;
    delete this.orderObj;
  }

  // protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
