
<div class="col-xs-12 p-0">
  <div class="col-xs-12 p-0" *ngIf="userDetails?.length">
    <div class="col-xs-12 px-3 py-2 bg-primary clr-white bold text-cap" *ngIf="!fromNewOrderApprovalPage">userDetails<i class="fa fa-eye ml-3" *ngIf="!showUserDetails" (click)="showUserDetails = true"></i><i class="fa fa-eye-slash ml-3" *ngIf="showUserDetails" (click)="showUserDetails = false"></i></div>
    <ng-container *ngIf="showUserDetails">
      <div class="col-xs-12 p-0 flex fontS1" *ngFor="let row of userDetails;let i = index;">
        <div class="col-xs-5 ellipsis px-3 py-2 text-cap" title="{{questionTitleMap[row.key]}}">{{row.key}}<span class="pull-right">:</span></div>
        <div class="col-xs-7"><span class="pr-2">{{row.value}}</span></div>
      </div>
      <div class="col-xs-12 p-0 flex fontS1" *ngFor="let row of followupAnswers;let i = index;">
        <div class="col-xs-12 clr-red" *ngIf="importantDetails.includes(row.key)">
          <div class="col-xs-5 ellipsis px-3 py-2 text-cap" title="{{questionTitleMap[row.key]}}">{{row.key}}<span class="pull-right">:</span></div>
          <div class="col-xs-7"><span class="pr-2">{{row.value}}</span><span class="bold" *ngIf="row.createdAt">({{ row.createdAt| amDateFormat: 'MMM DD, YYYY'}})</span></div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="col-xs-12 p-0" *ngIf="!fromNewOrderApprovalPage && faceConsultation?.length">
    <div class="col-xs-12 px-3 py-2 bg-primary clr-white bold text-cap">face<i class="fa fa-eye ml-3" *ngIf="!showFaceConsultation" (click)="showFaceConsultation = true"></i><i class="fa fa-eye-slash ml-3" *ngIf="showFaceConsultation" (click)="showFaceConsultation = false"></i></div>
    <ng-container *ngIf="showFaceConsultation">
      <div class="col-xs-12 p-0 flex fontS1" *ngFor="let row of faceConsultation;let i = index;" [ngClass]="{&quot;clr-red&quot;: ((row.key === &quot;QueryForDoctor&quot; && row.value !== &quot;None&quot;) || (row.key === &quot;PastSkinTreatment&quot; && row.value !== &quot;None&quot;) || row.key === &quot;FemalePregOrNewMom&quot; && row.value != &quot;None of the above&quot;)}">
        <div class="col-xs-5 ellipsis px-3 py-2 text-cap" title="{{questionTitleMap[row.key]}}">{{row.key}}<span class="pull-right">:</span></div>
        <div class="col-xs-7"><span class="pr-2">{{row.value}}</span></div>
      </div>
    </ng-container>
  </div>
  <div class="col-xs-12 p-0" *ngIf="fromNewOrderApprovalPage && faceConsultation?.length && showFaceConsultation">
    <ng-container *ngIf="showFaceConsultation">
      <div class="col-xs-12 p-0 flex fontS1" *ngFor="let row of faceConsultation;let i = index;" [ngClass]="{&quot;clr-red&quot;: ((row.key === &quot;QueryForDoctor&quot; && row.value !== &quot;None&quot;) || (row.key === &quot;PastSkinTreatment&quot; && row.value !== &quot;None&quot;) || row.key === &quot;FemalePregOrNewMom&quot; && row.value != &quot;None of the above&quot;)}">
        <div class="col-xs-5 ellipsis px-3 py-2 text-cap" title="{{questionTitleMap[row.key]}}">{{row.key}}<span class="pull-right">:</span></div>
        <div class="col-xs-7"><span class="pr-2">{{row.value}}</span></div>
      </div>
    </ng-container>
  </div>
  <div class="col-xs-12 p-0" *ngIf="fromNewOrderApprovalPage && bodyConsultation?.length && showBodyConsultation">
    <ng-container *ngIf="showBodyConsultation">
      <div class="col-xs-12 p-0 flex fontS1" *ngFor="let row of bodyConsultation;let i = index;" [ngClass]="{&quot;clr-red&quot;: ((row.key === &quot;QueryForDoctor&quot; && row.value !== &quot;None&quot;) || (row.key === &quot;PastSkinTreatment&quot; && row.value !== &quot;None&quot;) || row.key === &quot;FemalePregOrNewMom&quot; && row.value != &quot;None of the above&quot;)}">
        <div class="col-xs-5 ellipsis px-3 py-2 text-cap" title="{{questionTitleMap[row.key]}}">{{row.key}}<span class="pull-right">:</span></div>
        <div class="col-xs-7"><span class="pr-2">{{row.value}}</span></div>
      </div>
    </ng-container>
  </div>
  <div class="col-xs-12 p-0" *ngIf="!fromNewOrderApprovalPage && bodyConsultation?.length">
    <div class="col-xs-12 px-3 py-2 bg-primary clr-white bold text-cap">body<i class="fa fa-eye ml-3" *ngIf="!showBodyConsultation" (click)="showBodyConsultation = true"></i><i class="fa fa-eye-slash ml-3" *ngIf="showBodyConsultation" (click)="showBodyConsultation = false"></i></div>
    <ng-container *ngIf="showBodyConsultation">
      <div class="col-xs-12 p-0 flex fontS1" *ngFor="let row of bodyConsultation;let i = index;" [ngClass]="{&quot;clr-red&quot;: ((row.key === &quot;QueryForDoctor&quot; && row.value !== &quot;None&quot;) || (row.key === &quot;PastSkinTreatment&quot; && row.value !== &quot;None&quot;) || row.key === &quot;FemalePregOrNewMom&quot; && row.value != &quot;None of the above&quot;)}">
        <div class="col-xs-5 ellipsis px-3 py-2 text-cap" title="{{questionTitleMap[row.key]}}">{{row.key}}<span class="pull-right">:</span></div>
        <div class="col-xs-7"><span class="pr-2">{{row.value}}</span></div>
      </div>
    </ng-container>
  </div>
  <div class="col-xs-12 p-0" *ngIf="fromNewOrderApprovalPage && hairConsultation?.length && showHairConsultation">
    <ng-container *ngIf="showHairConsultation">
      <div class="col-xs-12 p-0 flex fontS1" *ngFor="let row of hairConsultation;let i = index;" [ngClass]="{&quot;clr-red&quot;: ((row.key === &quot;QueryForDoctor&quot; && row.value !== &quot;None&quot;) || (row.key === &quot;PastSkinTreatment&quot; && row.value !== &quot;None&quot;) || row.key === &quot;FemalePregOrNewMom&quot; && row.value != &quot;None of the above&quot;)}">
        <div class="col-xs-5 ellipsis px-3 py-2 text-cap" title="{{questionTitleMap[row.key]}}">{{row.key}}<span class="pull-right">:</span></div>
        <div class="col-xs-7"><span class="pr-2">{{row.value}}</span></div>
      </div>
    </ng-container>
  </div>
  <div class="col-xs-12 p-0" *ngIf="!fromNewOrderApprovalPage && hairConsultation?.length">
    <div class="col-xs-12 px-3 py-2 bg-primary clr-white bold text-cap">hair<i class="fa fa-eye ml-3" *ngIf="!showHairConsultation" (click)="showHairConsultation = true"></i><i class="fa fa-eye-slash ml-3" *ngIf="showHairConsultation" (click)="showHairConsultation = false"></i></div>
    <ng-container *ngIf="showHairConsultation">
      <div class="col-xs-12 p-0 flex fontS1" *ngFor="let row of hairConsultation;let i = index;" [ngClass]="{&quot;clr-red&quot;: ((row.key === &quot;QueryForDoctor&quot; && row.value !== &quot;None&quot;) || (row.key === &quot;PastSkinTreatment&quot; && row.value !== &quot;None&quot;) || row.key === &quot;FemalePregOrNewMom&quot; && row.value != &quot;None of the above&quot;)}">
        <div class="col-xs-5 ellipsis px-3 py-2 text-cap" title="{{questionTitleMap[row.key]}}">{{row.key}}<span class="pull-right">:</span></div>
        <div class="col-xs-7"><span class="pr-2">{{row.value}}</span></div>
      </div>
    </ng-container>
  </div>
  <div class="col-xs-12 p-0" *ngIf="(followupAnswers?.length) || (isProductOrder && followupAnswers?.length)">
    <div class="col-xs-12 px-3 py-2 bg-primary clr-white bold text-cap" *ngIf="!fromNewOrderApprovalPage">followupAnswers<i class="fa fa-eye ml-3" *ngIf="!showFollowUpAnswers" (click)="showFollowUpAnswers = true"></i><i class="fa fa-eye-slash ml-3" *ngIf="showFollowUpAnswers" (click)="showFollowUpAnswers = false"></i></div>
    <ng-container *ngIf="showFollowUpAnswers">
      <div class="col-xs-12 p-0 flex fontS1" *ngFor="let row of followupAnswers;let i = index;" [ngClass]="{&quot;clr-red&quot;: ((row.key === &quot;QueryForDoctor&quot; && row.value !== &quot;None&quot;) || row.key === &quot;FemalePregOrNewMom&quot; && row.value != &quot;None of the above&quot;)}">
        <div class="col-xs-5 ellipsis px-3 py-2 text-cap" title="{{questionTitleMap[row.key]}}">{{row.key}}<span class="pull-right">:</span></div>
        <div class="col-xs-7"><span class="pr-2">{{row.value}}</span><span class="bold" *ngIf="row.createdAt">({{ row.createdAt| amDateFormat: 'MMM DD, YYYY'}})</span></div>
      </div>
    </ng-container>
  </div>
  <div class="col-xs-12" *ngIf="labels.length">
    <div class="col-xs-5 ellipsis px-3 py-2 text-cap">Labels <span class="fa fa-edit" (click)="addLabels()"></span><span class="pull-right">:</span></div>
    <div class="col-xs-7"><span class="label label-primary mr-1" *ngFor="let label of labels; let i = index;">{{label}}</span></div>
  </div>
  <div class="col-xs-12" *ngIf="!labels.length">
    <div class="col-xs-5 ellipsis px-3 py-2 text-cap">Add Labels<span class="fa fa-edit" (click)="addLabels()"></span></div>
  </div>
</div>
