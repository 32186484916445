
<div class="w100 image-magnifier flex">
  <div class="selected-image">
    <div class="heading flex"><i class="fa-solid fa-circle-chevron-left pointer" (click)="moveToOtherPhoto(-1)"></i><span class="flex-grow text-center">{{selectedInstantCheckUpImageCreatedAt}}</span><i class="fa-solid fa-circle-chevron-right pointer" (click)="moveToOtherPhoto(1)"></i></div>
    <div class="col-xs-12 posR" *ngIf="selectedInstantCheckUpImageUrl">
      <lib-ngx-image-zoom class="col-xs-12" #imageLense [thumbImage]="selectedInstantCheckUpImageUrl" [fullImage]="selectedInstantCheckUpImageUrl" [magnification]="1" [enableScrollZoom]="true" [enableLens]="true" [lensHeight]="600" [lensWidth]="460"></lib-ngx-image-zoom>
    </div>
  </div>
  <div class="w50 ml-5 flex-grow">
    <div class="heading flex justifySB text-cap">
      <div class="text-left pointer" (click)="openWaitingForImagePopup()">NEED PHOTOS?</div>
      <div class="flex pointer" (click)="hidePhoto()">
        <div class="text-RIGHT">DELETE</div><i class="fa fa-trash-o pointer ml-2 fontR"></i>
      </div>
    </div>
    <div class="body" #scrollContaine (scroll)="onScroll()" style="overflow-x: hidden">
      <div class="photo" *ngFor="let instantCheckUp of instantCheckUpImageList; let index = index" [ngClass]="{'blur-photo': index == selectedInstantCheckUpImageIndex}"><img class="pointer" [src]="instantCheckUp.compressedImagePath || instantCheckUp.imagePath" width="100%" height="180px" (click)="openImage(index)"/>
        <div class="col-xs-12 text-center fontR">{{instantCheckUp.createdAt | amDateFormat: 'DD MMM,YYYY'}}<i class="fas fa-star-of-life consultationIcon" *ngIf="instantCheckUp.consultationSessionImage"></i></div>
        <div class="col-xs-12 text-center fontR fw-700">({{instantCheckUp.differenceInDays}} days)</div>
        <div class="col-xs-12 text-center pointer fontS3 flex justifyC" (click)="editPhotoType(index, instantCheckUp.objectId)">
          <div class="text-cap">{{instantCheckUp.type}}</div><i class="fa fa-edit ml-2"></i>
        </div>
      </div>
    </div>
  </div>
</div>
