
<div class="hbox" *ngIf="mainConcern!=&quot;&quot;">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/trees/main-concerns">Main Concerns</a></li>
          <li>
            <language-view [languageStringObj]="mainConcern.get(&quot;displayNameLanguageString&quot;)"></language-view>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex-content p-4">
      <div class="col-xs-12 flex mt-0">
        <label class="col-xs-2">Display Name</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">
          <language-view [languageStringObj]="mainConcern.get(&quot;displayNameLanguageString&quot;)"></language-view>
        </div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2">Rank</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{mainConcern.get('rank')}}</div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2">Class</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{mainConcern.get('class')}}</div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2">Section</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{mainConcern.get('section')}}</div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2">Value</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{mainConcern.get('value')}}</div>
      </div>
      <div class="col-xs-12 flex">
        <label class="col-xs-2">Description</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">{{descriptionLanguageString}}</div>
      </div>
      <div class="col-xs-12 flex" *ngIf="mainConcern.get('keywords').length &gt; 0">
        <label class="col-xs-2">Keywords</label>
        <div class="col pull-left">:</div>
        <div class="col-xs-8">
          <ng-container *ngFor="let each of mainConcern?.get('keywords')">
            <language-view [languageStringObj]="each.keyLanguageString"></language-view><span class="pr-2">,</span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>