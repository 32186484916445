import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiClientConstant } from 'api-client';
import { AppConfig } from 'src/app/app.config';
import { Broadcaster } from 'src/components/broadcaster';
import { ConnectionService } from 'src/services/connection-service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  @Input('user') user: any;
  @Input('orders') orders: any;
  @Output('updateOrder') updateOrder: EventEmitter<any> = new EventEmitter();
  protected apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
  patientUser: any;
  labelList: Array<Table.Labels> = [];
  labels: Array<string> = [];
  hasSkinRegimen: boolean = false;
  hasHairRegimen: boolean = false;
  selectedConcern: string;
  selectedOrderId: string;
  selectedOrder: any;
  whatsappActions: Array<string> = ['ringing - No response', 'send product instructions', 'blur photo'];
  clevertapSupportedEvents: Array<string> = ['ringingNoResponse', 'sendProductInstructions', 'blurPhoto'];
  assignedUserLabelList: Array<any> = [];
  chatCacheObj: any;
  optedForDoctorCall: boolean = false;
  teenagerConsent: string = 'TEENAGER_CONSENT';
  teenagerNoConsent: string = 'TEENAGER_NO_CONSENT';
  constructor(private conn: ConnectionService,
    private broadcaster: Broadcaster,
    private appConfig: AppConfig,
  ) { }

  async ngOnChanges(): Promise<void> {
    if (this.user) {
      this.chatCacheObj = await this.conn.findAttendToChatCache(this.user);
      this.patientUser = JSON.parse(JSON.stringify(this.user));
      await this.findLabels();
    }
    if (!this.orders?.length) return;
    const cache = this.chatCacheObj.toJSON();
    this.updateUserLabels(cache);
    this.hasHairRegimen = this.orders.some((order: any): boolean => {
      if (order?.regimen?.class === ApiClientConstant.InstantCheckup.Type.HAIR) {
        this.selectedConcern = ApiClientConstant.InstantCheckup.Type.HAIR;
        this.selectedOrderId = order.objectId;
        this.selectedOrder = order;
        return true;
      }
      return false;
    });

    this.hasSkinRegimen = this.orders.some((order: any): boolean => {
      if (order?.regimen?.class === ApiClientConstant.InstantCheckup.Type.FACE) {
        this.selectedConcern = ApiClientConstant.InstantCheckup.Type.FACE;
        this.selectedOrderId = order.objectId;
        this.selectedOrder = order;
        return true;
      }
      return false;
    });
    this.optedForDoctorCall = this.selectedOrder?.regimen?.optedForDoctorCall;
  }

  addLabel(label: string): void {
    const field = 'userLabel';
    const chatCache = this.chatCacheObj.toJSON();
    if (!label || chatCache[field].includes(label)) {
      return;
    }
    chatCache[field].push(label);
    this.updateUserLabels(chatCache);
    this.chatCacheObj.set(field, chatCache[field]);
    this.saveChatCacheObj();
    this.removeLabelFromOptions(label);
  }

  removeLabelFromOptions(label: string): void {
    const index = this.labels.indexOf(label);
    this.labels.splice(index, 1);
  }

  async updateDoctorCallNeeded(doctorCallNeeded: boolean): Promise<void> {
    let confirmationText: string;
    if (doctorCallNeeded) {
      confirmationText = 'Are you sure to mark the doctor call needed?';
    } else {
      confirmationText = 'Are you sure to mark the doctor call not needed?';
    }
    if (confirm(confirmationText)) {
      try {
        await this.conn.optForRegimenDoctorCall(this.selectedOrder?.regimen?.regimenId, doctorCallNeeded);
        this.broadcaster.broadcast('NOTIFY', {
          message: 'Doctor call information saved',
          type: this.appConfig.Shared.Toast.Type.SUCCESS,
        });
        this.optedForDoctorCall = doctorCallNeeded;
      } catch (error) {
        this.broadcaster.broadcast('NOTIFY', { message: 'Failed saving doctor call', type: this.appConfig.Shared.Toast.Type.ERROR });
      }
    }
  }

  async saveChatCacheObj(): Promise<any> {
    await this.chatCacheObj.save();
  }

  updateUserLabels(cache: any): void {
    if (!cache?.userLabel) return;
    this.assignedUserLabelList = [];
    cache.userLabel.forEach((label: string) => {
      this.assignedUserLabelList.push({
        name: label,
        color: this.labelList[label],
      });
    });
  }

  updateOrderId(selectedConcern: string): void {
    const initOrder = this.orders.find((order: any): boolean => order.regimen.class === selectedConcern);
    this.selectedOrderId = initOrder.objectId;
    this.selectedOrder = initOrder;
  }

  async findLabels(): Promise<any> {
    const labelList = JSON.parse(JSON.stringify(await this.conn.findAllTags()));
    labelList.forEach((value: any) => {
      this.labelList[value.name] = value.colorCode;
    });
    this.labels = Object.keys(this.labelList);
    this.labels = this.filterOnlyUnselectedValues(this.labels, this.chatCacheObj?.get('userLabel') || []);
  }

  filterOnlyUnselectedValues(data: string[], selectedValues: string[]): string[] {
    return data.filter((each: any) => !selectedValues.includes(each));
  }

  removeItem(index: number, field: string): void {
    const cache = this.chatCacheObj.toJSON();
    const item = cache[field].splice(index, 1);
    this.labels.push(item);
    this.updateUserLabels(cache);
    this.chatCacheObj.set(field, cache[field]);
    this.saveChatCacheObj();
  }

  callUserThroughExotel(number: any): void {
    this.conn.callThroughExotel(number, 'newSalesPage')
      .then(() => this.broadcaster.broadcast('NOTIFY', {
        message: 'Connecting',
        type: this.appConfig.Shared.Toast.Type.SUCCESS,
      }))
      .catch((error: any) => this.broadcaster.broadcast('NOTIFY', {
        message: error.message,
        type: this.appConfig.Shared.Toast.Type.ERROR,
      }));
  }

  async sendCleverTapEvent(selectedCleverTapEvent: string): Promise<void> {
    if (!selectedCleverTapEvent) return;
    if (confirm('Are you sure to trigger event?')) {
      try {
        await this.conn.trackEventInCleverTap(selectedCleverTapEvent, this.patientUser.username);
        this.broadcaster.broadcast('NOTIFY', {
          message: 'Event Sent',
          type: this.appConfig.Shared.Toast.Type.SUCCESS,
        });
      } catch (error) {
        this.broadcaster.broadcast('NOTIFY', {
          message: error.message,
          type: this.appConfig.Shared.Toast.Type.ERROR,
        });
      }
    }
  }

  updateOrderInfo(type: string): void {
    if (type === this.selectedConcern) return;
    this.selectedConcern = type;
    this.updateOrderId(type);
    this.updateOrder.emit(this.selectedOrderId);
  }

  async teenagerCallConfirmation(tag: string): Promise<void> {
    let confirmationText: string;
    if (tag === this.teenagerConsent) {
      confirmationText = 'Are you sure to mark the teenager call consented?';
    } else {
      confirmationText = 'Are you sure to mark the teenager call not consented?';
    }
    if (confirm(confirmationText)) {
      try {
        let marketingTags = this.user?.get('marketingTags');
        marketingTags = marketingTags.filter((marketingTag: string): boolean => {
          const noConsent = marketingTag !== this.teenagerNoConsent;
          const consent = marketingTag !== this.teenagerConsent;
          return noConsent && consent;
        });
        marketingTags.push(tag);
        this.user.set('marketingTags', marketingTags);
        await this.user.save();
        this.broadcaster.broadcast('NOTIFY', {
          message: 'Teenager call status saved',
          type: this.appConfig.Shared.Toast.Type.SUCCESS,
        });
      } catch (error) {
        this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
      }
    }
  }
}
