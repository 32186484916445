/* eslint-disable new-cap */
import ExotelCRMWebSDK from 'exotel-ip-calling-crm-websdk';
import { Injectable, Injector } from '@angular/core';
import { ConnectionService } from '../connection-service';
import { AppConfig } from '../../app/app.config';
import { Broadcaster } from '../../components/broadcaster';

@Injectable({
  providedIn: 'root',
})
export class ExotelService {
  callActive: boolean = false;
  callAccepted: boolean = false;
  isDeviceRegistered: boolean = false;
  isMuted: boolean = false;
  isOnHold: boolean = false;
  webPhone: any = null;
  isIncomingCall: boolean = false;
  ringtoneCount: number = 30;
  ringtone: HTMLAudioElement = new Audio();
  ringToneIntervalID: any;
  constructor(private injector: Injector, private appConfig: AppConfig, private broadcaster: Broadcaster) {
    this.ringtone.src = 'assets/audio/ringtone.wav';
    this.ringtone.load();
  }

  resetState(): void {
    this.callActive = false;
    this.isDeviceRegistered = false;
    this.isMuted = false;
    this.callAccepted = false;
    this.isOnHold = false;
    this.isIncomingCall = false;
    this.webPhone = null;
    this.isIncomingCall = false;
  }

  async initializeWebPhone(accessToken: string, userId: string): Promise<void> {
    if (this.webPhone) return;

    const crmWebSDK = new ExotelCRMWebSDK(accessToken, userId, true);
    this.webPhone = await crmWebSDK.Initialize(
      this.handleCallEvents.bind(this),
      this.registrationEvent.bind(this),
    );
  }

  handleCallEvents(eventType: string, ...args: any[]): void {
    switch (eventType) {
      case 'incoming':
        this.handleIncoming();
        this.startRingTone();
        break;
      case 'connected':
        this.handleConnected();
        this.stopRingTone();
        break;
      case 'callEnded':
        this.handleCallEnded();
        this.stopRingTone();
        break;
      case 'holdtoggle':
        this.handleToggleOnHold();
        break;
      case 'mutetoggle':
        this.handleToggleMute();
        break;
      default:
        break;
    }
  }

  registrationEvent(event: string): void {
    if (event === 'registered') {
      this.isDeviceRegistered = true;
      return;
    }
    if (event === 'unregistered') {
      this.isDeviceRegistered = false;
    }
  }

  async dialCallback(fromPage: string, status: string, data: Record<string, any>): Promise<void> {
    if (status === 'success') {
      const conn = this.injector.get(ConnectionService);
      await conn.trackOutboundCallLogServerAPI({
        ...data.Data,
        FromPage: fromPage,
      });
    } else {
      this.hangup();
      this.resetState();
      this.broadcaster.broadcast('NOTIFY', {
        message: 'Something went wrong, please try again later',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
    }
  }

  dial(phoneNumber: string, fromPage: string): void {
    if (/^\+?[0-9]{10,14}$/.test(phoneNumber)) {
      this.webPhone?.MakeCall(phoneNumber, this.dialCallback.bind(this, fromPage));
    } else {
      this.broadcaster.broadcast('NOTIFY', {
        message: 'Phone number is not valid',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
    }
  }

  acceptCall(): void {
    this.callAccepted = true;
    this.isIncomingCall = false;
    this.webPhone?.AcceptCall();
  }

  hangup(): void {
    this.webPhone?.HangupCall();
    this.callActive = false;
    this.callAccepted = false;
    this.isIncomingCall = false;
  }

  onClickToggleMute(): void {
    this.webPhone?.ToggleMute();
  }

  handleIncoming(): void {
    this.callActive = true;
    this.callAccepted = false;
    this.isIncomingCall = true;
  }

  handleConnected(): void {
    this.callActive = true;
  }

  handleCallEnded(): void {
    this.callActive = false;
    this.isIncomingCall = false;
  }

  handleToggleMute(): void {
    this.isMuted = !this.isMuted;
  }

  handleToggleOnHold(): void {
    this.isOnHold = !this.isOnHold;
  }

  onClickToggleHold(): void {
    this.webPhone?.ToggleHold();
  }

  /**
   * Ringtone feature is already there in exotel-ip-calling-crm-websdk package
   * But with the current version it is not compatible with Angular
   * We can remove below code and it's usage if it's getting fixed in the upcoming version of the package
   */
  startRingTone(): void {
    try {
      let count = 0;
      this.ringToneIntervalID = setInterval(() => {
        this.ringtone.play()
          .then(() => {})
          .catch((e: unknown) => {
            this.broadcaster.broadcast('NOTIFY', {
              message: 'Something went wrong, while playing ringtone',
              type: this.appConfig.Shared.Toast.Type.ERROR,
            });
          });
        count += 1;
        if (count > this.ringtoneCount) {
          clearInterval(this.ringToneIntervalID);
        }
      }, 500);
    } catch (e) {
      this.broadcaster.broadcast('NOTIFY', {
        message: 'Something went wrong, while playing ringtone',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
    }
  }

  stopRingTone(): void {
    try {
      this.ringtone.pause();
      clearInterval(this.ringToneIntervalID);
    } catch (e) {
      this.broadcaster.broadcast('NOTIFY', {
        message: 'Something went wrong, while playing ringtone',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
    }
  }
}
