import { Component, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxImageZoomComponent } from 'ngx-image-zoom';
import { ApiClientConstant, Table } from 'api-client';
import { ConnectionService } from '../../services/connection-service';
import { WindowRefService } from '../../services/window-ref-service';
import { Broadcaster } from '../../components/broadcaster';
import { AppConfig } from '../app.config';

@Component({
  selector: 'instant-checkup',
  templateUrl: './instant-checkup.html',
  styleUrls: ['./instant-checkup.scss'],
})
export class InstantCheckupComponent {
  @Input('username') username: any;
  @Input() hideFullImage: boolean;
  @Input() useAsSubComponent: boolean;
  list: Array<any> = [];
  withoutFilterList: any[] = [];
  loading: boolean = true;
  currentInstantCheckup: any;
  currentCheckupIndex: number = -1;
  selectedComparisonIdsLength: number;
  selectedComparisonIds: any = {};
  filter: { TAG?: string, TYPE?: string } = { TAG: 'GOOD', TYPE: 'ALL' };
  photoTypes: string[] = [];
  tags: string[] = ['GOOD', 'BAD', 'DIM', 'BLUR', 'FOLLOWUP'];
  tagsInFilter: string[] = ['ALL', 'AI_GOOD', ...this.tags];
  instantCheckupFetchLimit: number = 100;
  instantCheckupToSkipNumber: number = 0;
  endOfList: boolean = false;
  showList: boolean = true;
  currentUser: any;
  consultationSessionImagesNames: Array<string> = [];
  patientUser: any;

  @ViewChild('imageLense', { static: false }) ngxImageZoomComponent: NgxImageZoomComponent;
  constructor(private router: Router,
    private conn: ConnectionService,
    private route: ActivatedRoute,
    private window: WindowRefService,
    private broadcast: Broadcaster,
    private changedDetector: ChangeDetectorRef,
    private appConfig: AppConfig) {
  }

  async ngOnInit(): Promise<any> {
    this.currentUser = await this.conn.getCurrentUser();
    this.broadcast.broadcast('ChatUserUpdate', { user: this.currentUser });
    if (this.currentUser && this.currentUser.get('username') === this.appConfig.Shared.ExternalUser.username) {
      this.showList = false;
    }
    this.photoTypes = Object.values(ApiClientConstant.InstantCheckup.Type);
    this.photoTypes.unshift('ALL');
    this.route.url.subscribe(async () => {
      const queryId = this.route.snapshot.params.id;
      this.username = this.route.snapshot.queryParams.username || this.username;
      this.patientUser = this.conn.findOneUser({ where: { username: this.username }, project: ['username'] });
      if (!this.list.length) {
        await this.fetchData();
      }
      this.list.forEach((each: any, index: any) => {
        if (each.objectId === queryId) {
          this.currentInstantCheckup = each;
          this.currentCheckupIndex = index;
        }
      });
      if (this.currentCheckupIndex === -1) {
        this.currentInstantCheckup = this.list[0];
        this.currentCheckupIndex = 0;
      }
      this.filterWithCondition();
      this.loading = false;
    });
    const consultationSessionImages = await this.conn.getAllConsultationChatImage(this.patientUser);
    this.consultationSessionImagesNames = consultationSessionImages.map((imageLink: string) => imageLink.split('/').pop());
    this.updateConsultationSessionImageFlag(this.withoutFilterList);
  }

  async fetchData(): Promise<void> {
    const imagesBatch = await this.conn.fetchUserInstantCheckup({
      skip: this.instantCheckupToSkipNumber,
      limit: this.instantCheckupFetchLimit,
      userId: this.username,
      project: ['type', 'imagePath', 'compressedImagePath', 'thumbnailImagePath', 'tags'],
    });
    if (imagesBatch.length < this.instantCheckupFetchLimit) {
      this.endOfList = true;
    }
    this.updateConsultationSessionImageFlag(imagesBatch);
    this.withoutFilterList.push(...imagesBatch);
    this.list.push(...imagesBatch);
    this.renderCheckup(0);
  }

  navigate(direction: number): any {
    if (direction === -1 && this.currentCheckupIndex > 0) {
      this.currentCheckupIndex -= 1;
    }
    if (direction === 1 && this.currentCheckupIndex < this.list.length - 1) {
      this.currentCheckupIndex += 1;
    }
    if (this.currentCheckupIndex >= 0 && this.currentCheckupIndex < this.list.length) {
      this.currentInstantCheckup = this.list[this.currentCheckupIndex];
    }
  }

  renderCheckup(index: number, event?: any): void {
    this.currentCheckupIndex = index;
    if (!this.list[index] && !index) {
      this.currentInstantCheckup = this.withoutFilterList[index];
    } else {
      this.currentInstantCheckup = this.list[index];
    }
    if (event) event.stopPropagation();
  }

  openInNewTab(index: number, event: any): void {
    event.stopPropagation();
    this.window.nativeWindow.open(`user/instantCheckup/${this.list[index].objectId}?username=${this.username}`);
  }

  addOrRemoveFromComparison(index: number, event: any): void {
    event.stopPropagation();
    const { objectId }: any = this.list[index];
    if (this.selectedComparisonIds[objectId]) {
      delete this.selectedComparisonIds[objectId];
    } else if (Object.keys(this.selectedComparisonIds).length === 2) {
      this.broadcast.broadcast('NOTIFY', { message: 'Remove any one checkup before adding new.' });
    } else {
      this.selectedComparisonIds[objectId] = objectId;
    }
    this.selectedComparisonIdsLength = Object.keys(this.selectedComparisonIds).length;
  }

  openComparison(): void {
    if (this.selectedComparisonIdsLength !== 2) return;
    const values = Object.values(this.selectedComparisonIds);
    this.window.nativeWindow.open(`/compare/${values[0]}/${values[1]}/${this.username}`);
  }

  resetComparison(): void {
    this.selectedComparisonIds = {};
  }

  async addTag(instantCheckup_: any, tag: any, event: any): Promise<void> {
    event.stopPropagation();
    const instantCheckup = instantCheckup_;
    try {
      const instantCheckupObject = new Table.InstantCheckup();
      instantCheckupObject.id = instantCheckup.objectId;
      const tags = instantCheckup.tags || [];
      const index = tags.indexOf(tag);
      if (index !== -1) {
        tags.splice(index, 1);
      } else {
        tags.push(tag);
      }
      instantCheckupObject.set('tags', tags);
      await instantCheckupObject.save();
      instantCheckup.tags = tags;
    } catch (error) {
      this.broadcast.broadcast('NOTIFY', { message: error.toString() });
    }
  }

  isTagAdded(instantCheckup: any, tag: any): boolean {
    return instantCheckup.tags ? instantCheckup.tags.includes(tag) : false;
  }

  filterTag(tag: string): void {
    this.filter.TAG = tag;
    this.filterWithCondition();
  }

  filterWithCondition(): void {
    let filteredList = this.withoutFilterList;
    if (this.filter.TYPE && this.filter.TYPE !== 'ALL') {
      filteredList = filteredList.filter((each: any) => each.type === this.filter.TYPE);
    }
    if (this.filter.TAG && this.filter.TAG !== 'ALL') {
      filteredList = filteredList.filter((each: any) => {
        if (each.tags) return each.tags.includes(this.filter.TAG);
        return false;
      });
    }
    this.list = filteredList;
    this.currentCheckupIndex = 0;
  }

  filterPhotoType(type: string): void {
    this.filter.TYPE = type;
    this.filter.TAG = 'GOOD';
    this.filterWithCondition();
  }

  stopPropagation(event: any): void {
    if (event) event.stopPropagation();
  }

  async deleteUserInstantCheckup(instantCheckup: any, event: any): Promise<void> {
    event.stopPropagation();
    try {
      const deleteObj = new Table.InstantCheckup();
      deleteObj.id = instantCheckup.objectId;
      await deleteObj.destroy();
      this.list = [];
      await this.fetchData();
    } catch (error) {
      this.broadcast.broadcast('NOTIFY', { message: error.message || error });
    }
  }

  async loadMoreImages(): Promise<void> {
    if (this.endOfList) {
      return;
    }
    this.instantCheckupToSkipNumber += this.instantCheckupFetchLimit;
    if (this.instantCheckupFetchLimit !== 50) {
      this.instantCheckupFetchLimit = 50;
    }
    await this.fetchData();
  }

  private updateConsultationSessionImageFlag(imagesBatch: Array<any>): void {
    imagesBatch.forEach((each_: any): void => {
      const each = each_;
      each.consultationSessionImage = this.consultationSessionImagesNames.includes(each.imagePath.split('?')[0].split('/').pop());
    });
  }

  changeImage(id: string): void {
    if (this.useAsSubComponent) {
      this.list.forEach((each: any, index: any) => {
        if (each.objectId === id) {
          this.currentInstantCheckup = each;
          this.currentCheckupIndex = index;
        }
      });
      return;
    }
    this.router.navigate([`/user/instantCheckup/${id}`], { queryParams: { username: this.username } });
  }
}
