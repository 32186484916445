
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="list-header">
        <div class="col-xs-5">
          <div>FollowUp Report</div>
          <div class="mt-1 btn-xs btn-danger fontS3" *ngIf="showProductChangeAlert">Regimen Change Needed</div>
          <div class="mt-1 btn-xs btn-danger fontS3" *ngIf="chatUser?.get('marketingTags')?.includes('STEROID')">STEROID</div>
          <div class="mt-1 btn-xs btn-danger fontS3 col-xs-12" *ngIf="userUnderExperiment">NPD CASE → AVOID REGIMEN EDIT WITHIN 8 WEEKS. IF EDIT IS URGENT - KINDLY GET APPROVAL FROM NPD DOCTOR IN CHARGE TO AVOID STUDY BEING INVALID</div>
          <div class="mt-1 btn-xs btn-danger fontS3 col-xs-12" *ngIf="discontinuedProductsInRegimen.length"><span>Discontinued Products</span><span *ngFor="let product of discontinuedProductsInRegimen">{{product.get('title')}},</span></div>
        </div>
        <div class="steps hidden-sm hidden-xs">
          <div class="step">
            <div class="circle" [ngClass]="{ &quot;active&quot;: reportCompletionStatus.imageComparison }">1</div>
            <div class="text">Image Comparison</div>
          </div>
          <div class="step">
            <div class="circle" [ngClass]="{ &quot;active&quot;: reportCompletionStatus.treatmentProgress }">2</div>
            <div class="text">Treatment Progress</div>
          </div>
          <div class="step">
            <div class="circle" [ngClass]="{ &quot;active&quot;: reportCompletionStatus.audioMessage }">3</div>
            <div class="text">Audio Message</div>
          </div>
          <div class="step">
            <div class="circle" [ngClass]="{ &quot;active&quot;: reportCompletionStatus.tip }">4</div>
            <div class="text">Tip</div>
          </div>
          <div class="step">
            <div class="circle" [ngClass]="{ &quot;active&quot;: reportCompletionStatus.treatmentOutcome }">5</div>
            <div class="text">Treatment Outcome</div>
          </div>
          <div class="step">
            <div class="circle" [ngClass]="{ &quot;active&quot;: reportCompletionStatus.investigation }">6</div>
            <div class="text">Investigation</div>
          </div>
          <div class="step">
            <div class="circle" [ngClass]="{ &quot;active&quot;: reportCompletionStatus.regimenEdit }">7</div>
            <div class="text">Regimen Edit</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-content">
      <div class="alert-warning p-3 bold text-center" *ngIf="editFollowupReport">You can edit the following fields -> Tip, Treatment Progress, Investigations. Only edited sections wil be updated. Audio & Comparison Image wil be reused if not edited.</div>
      <div class="col-xs-12 p-0">
        <comparison-slider *ngFor="let params of comparisonSliderConfig; let i = index" [params]="params" (onRemoveComparison)="onRemoveComparison(i)" (onZoomChange)="onImagePositionChange($event, i)" (onChangePhoto)="changePhoto($event, i)"></comparison-slider>
      </div>
      <div class="col-xs-12 p-3">
        <button class="btn-primary m-auto db" *ngIf="!showInstantCheckupList" (click)="addMoreComparisonReport()">ADD MORE COMPARISONS</button>
      </div>
      <div class="col-xs-12 col-md-6 p-0 bdr-left">
        <div class="list-header">Treatment outcome</div>
        <div class="col-xs-12 mt-3 ml-4">
          <div class="col-xs-12">
            <label class="col-xs-6">Treatment Outcome</label>
            <select class="col-xs-6" [(ngModel)]="treatmentOutcome.selectedTreatmentOutcome" name="selectedTreatmentOutcome" (change)="onChangeTreatmentOutcome()">
              <option *ngFor="let option of treatmentOutcomeOptions" [value]="option.value">{{option.display}}</option>
            </select>
          </div>
          <div class="col-xs-12 mt-3" *ngIf="subTreatmentOutcomeOptions.length">
            <label class="col-xs-6">Sub-Treatment Outcome</label>
            <select class="col-xs-6" [(ngModel)]="treatmentOutcome.subSelectedTreatmentOutcome" name="subSelectedTreatmentOutcome" (change)="onChangeSubtreatmentOutcome()">
              <option *ngFor="let option of subTreatmentOutcomeOptions" [value]="option.value">{{option.display}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-md-6 p-0 treatmentProgress">
        <div class="list-header">Treatment Progress</div>
        <div class="col-xs-12 p-3">
          <div class="labels col-xs-12 p-0">
            <div class="col-xs-3"></div>
            <div class="col-xs-8"><span class="treatment-progress-label">Worsened</span><span class="treatment-progress-label">No Change</span><span class="treatment-progress-label">Improvement Seen</span><span class="treatment-progress-label">Good</span><span class="treatment-progress-label">Excellent</span></div>
          </div>
          <div class="issue col-xs-12 p-0 flex" *ngFor="let item of concernsSelected; let i = index">
            <div class="name col-xs-3 bold">{{item.concern}}</div>
            <div class="col-xs-8">
              <input type="range" min="0" max="4" step="1" [(ngModel)]="item.score" (change)="reportCompletionStatus.treatmentProgress = true"/>
            </div>
            <div class="col-xs-1 text-center">
              <div class="fas fa-trash" (click)="removeConcernFromTreatmentProgress(item, i)"></div>
            </div>
          </div>
          <div class="col-xs-12 p-0">
            <label class="db">Select Concern</label>
            <mat-form-field>
              <mat-select class="w100" [(ngModel)]="selectedIssue" (ngModelChange)="addConcernToTreatmentProgress()">
                <mat-option *ngFor="let item of concernList" [hidden]="isConcernSelected(item)" [value]="item">{{item}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-xs-12 p-0" *ngIf="regimen">
        <div class="list-header">Regimen Edit</div>
        <div class="col-xs-12 px-3 regimenEdit">
          <regimen-edit class="col-xs-12 p0" [regimen]="regimen" (afterSaveRegimen)="afterSaveRegimen($event)" (afterChangeProduct)="afterChangeProduct($event)" [hideNotNecessaryThings]="true"></regimen-edit>
        </div>
      </div>
      <div class="col-xs-12 col-md-6 p-0">
        <div class="list-header">Audio Message
          <div class="pull-right fontS">{{this.chatUser?.get("languagePreference")}}</div>
        </div>
        <div class="col-xs-12 px-3">
          <label>Guidelines for voice Recording</label>
          <ol>
            <li>Intro - Hi (Pts name), I am dr...</li>
            <li>Address patient queries and questions and REASSURE - why no results, why so long? etc.</li>
            <li>Drs observations on photos</li>
            <li>Treatment plan - Regimen edits and why</li>
            <li>Prognosis - When to see result and how much result</li>
            <li>Continue and take next followup in 4 weeks</li>
          </ol>
        </div>
        <div class="col-xs-12 px-3">
          <audio-recorder (afterSave)="afterSaveRecording($event)" (onChange)="onAudioRecordingStatusChange($event)" [disableVoiceToText]="true"></audio-recorder>
        </div>
        <div class="col-xs-12 p-3">
          <textarea class="w100" [(ngModel)]="audioNote" placeholder="Audio Note for Internal Team (Not Visible To User)"></textarea>
        </div>
      </div>
      <div class="col-xs-12 col-md-6 p-0">
        <div class="list-header">Tip</div>
        <div class="col-xs-12 pl-4">
          <label class="db">Choose Concern</label>
          <mat-form-field>
            <mat-select class="w100" [(ngModel)]="selectedConcern" (ngModelChange)="fetchTips()">
              <mat-option *ngFor="let item of concerns; let i = index" [value]="item">{{item}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-xs-12 pl-4" *ngIf="selectedConcern">
          <label class="db">Choose Tip</label>
          <mat-form-field class="col-xs-8 p-0">
            <mat-select [(ngModel)]="selectedTip" (ngModelChange)="reportCompletionStatus.tip = true">
              <mat-option *ngFor="let item of tips; let i = index" [value]="i">{{item?.get('tip')}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-xs-12 col-md-6 p-0 bdr-left">
        <div class="list-header">Investigation</div>
        <div class="col-xs-12 mt-3">
          <div class="col-xs-6 pl-4">
            <label class="db">Choose Test</label>
            <mat-form-field>
              <mat-select class="w100" [(ngModel)]="selectedInvestigation.index" (ngModelChange)="changeInvestigation()">
                <mat-option value="-1">No Test</mat-option>
                <mat-option *ngFor="let item of investigationList; let i = index" [value]="i">{{item.get('name')}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-xs-6 flex">
            <input class="dib col-xs-8" [(ngModel)]="additionalTestInput" placeholder="Add Custom Test"/>
            <button class="ml-3" (click)="addAdditionalTestToList()">ADD</button>
          </div>
          <div class="clear-fix pl-4">
            <div class="pB10" *ngFor="let item of selectedInvestigation?.tests; let i = index"><span>{{item}}</span><span class="pL10"></span>
              <div class="fas fa-trash" (click)="removeTestFromSelection(i)"></div>
            </div>
          </div>
          <ng-container *ngIf="class === apiClientConstant.FollowUpReport.Type.FACE">
            <div class="col-xs-12 mt-2 flex">
              <label class="db ml-4">Isotret Consent Form</label>
              <button class="my-3 m-3" *ngIf="(!isIsotretConsent && (!editFollowupReport || (editFollowupReport && !editFollowupReport?.get('consentFormAsk'))))" (click)="wantsToTriggerConsentForm(true)">SEND</button>
            </div>
            <div class="col-xs-12 mt-2" *ngIf="(isIsotretConsent && (!editFollowupReport || (editFollowupReport && !editFollowupReport?.get('consentFormAsk'))))">
              <p class="ml-4 alert-danger">*Isotret Consent Form is Ready it will send to user along with FollowUp Report.</p>
            </div>
            <div class="col-xs-12 mt-2" *ngIf="editFollowupReport && editFollowupReport?.get('consentFormAsk')">
              <p class="ml-4 alert-danger">*Isotret Consent Form is Already Sent to User.</p>
            </div>
          </ng-container>
          <ng-container *ngIf="class === apiClientConstant.FollowUpReport.Type.HAIR && this.chatUser.get('Gender') === apiClientConstant.User.Gender.MALE">
            <div class="col-xs-12 mt-2 flex">
              <label class="db ml-4">Finasteride Consent Form</label>
              <button class="my-3 m-3" *ngIf="(!isFinasterideConsent && (!editFollowupReport || (editFollowupReport && !editFollowupReport?.get('consentFormAsk'))))" (click)="wantsToTriggerConsentForm(false)">SEND</button>
            </div>
            <div class="col-xs-12 mt-2" *ngIf="(isFinasterideConsent && (!editFollowupReport || (editFollowupReport && !editFollowupReport?.get('consentFormAsk'))))">
              <p class="ml-4 alert-danger">*Finasteride Consent Form is Ready it will send to user along with FollowUp Report.</p>
            </div>
            <div class="col-xs-12 mt-2" *ngIf="editFollowupReport && editFollowupReport?.get('consentFormAsk')">
              <p class="ml-4 alert-danger">*Finasteride Consent Form is Already Sent to User.</p>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-xs-12 col-md-6 p-0 bdr-left" *ngIf="enableDrObservationExperiment">
        <div class="list-header">Fill the Data</div>
        <ol class="col-xs-12 mt-3 ml-4">
          <li class="col-xs-12 flex">
            <label class="col-xs-3">Addressed patient queries</label>
            <input class="col-xs-8" [(ngModel)]="drObservation.addressedPatientQueries" placeholder="eg: darkspots after acneduo"/>
          </li>
          <li class="col-xs-12 flex">
            <label class="col-xs-3">Dr Observations</label>
            <input class="col-xs-8" [(ngModel)]="drObservation.drObservations" placeholder="eg: improved/worsened"/>
          </li>
          <li class="col-xs-12 flex">
            <label class="col-xs-3">Prognosis</label>
            <input class="col-xs-8" [(ngModel)]="drObservation.prognosis" placeholder="eg: recurrence"/>
          </li>
          <li class="col-xs-12 flex">
            <label class="col-xs-3">Time frame for expectations</label>
            <input class="col-xs-8" [(ngModel)]="drObservation.timeFrameForExpectations" placeholder="eg: 4w"/>
          </li>
          <li class="col-xs-12 flex">
            <label class="col-xs-3">Product addition/ Benefits</label>
            <input class="col-xs-8" [(ngModel)]="drObservation.changedProductBenefits" placeholder="eg: improves drynes"/>
          </li>
          <li class="col-xs-12 flex">
            <label class="col-xs-3">Treatment plan</label>
            <input class="col-xs-8" [(ngModel)]="drObservation.treatmentPlan" placeholder="eg: darkspot plan"/>
          </li>
          <li class="col-xs-12 flex">
            <label class="col-xs-3">Reassurance</label>
            <input class="col-xs-8" [(ngModel)]="drObservation.reassurance" placeholder="eg. Consistency will help/ Continue"/>
          </li>
          <li class="col-xs-12 flex">
            <label class="col-xs-3">Counselling</label>
            <input class="col-xs-8" [(ngModel)]="drObservation.counselling" placeholder="eg. addressed no-improvment concern"/>
          </li>
        </ol>
      </div>
      <div class="col-xs-12 flex">
        <button class="my-3 mr-3" *ngFor="let image of imageURL;let i = index" (click)="showFullSizeImage(i)">show/hide images of comparison {{i+1}}</button>
      </div>
      <div class="col-xs-12 pTB15" *ngIf="activateFullSizeImageIndex !== -1">
        <div class="w50 dib of-auto"><img [src]="imageURL[activateFullSizeImageIndex].firstImage"/></div>
        <div class="w50 dib of-auto"><img [src]="imageURL[activateFullSizeImageIndex].secondImage"/></div>
      </div>
    </div>
    <div class="flex-footer p20 text-center shdw">
      <button *ngIf="editReportId" (click)="saveFollowupReport()" [disabled]="disableSendButton">Update</button>
      <button *ngIf="!editReportId" (click)="saveFollowupReport()" [disabled]="disableSendButton">{{ disableSendButton ? "Sending ..." : "Send Report" }}</button>
    </div>
  </div>
</div>
<popup-wrapper *ngIf="showInstantCheckupList" (onHide)="showInstantCheckupList = !showInstantCheckupList">
  <instant-checkup-modal class="w100" [user]="chatUser" [doctor]="user" (onComparisonImageSelection)="addToComparison($event)"></instant-checkup-modal>
</popup-wrapper>
