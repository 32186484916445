
<div class="flex-box" *ngIf="leaveAndHolidayObj" fillComponentParentView="fillComponentParentView">
  <div class="flex-heading">
    <div class="col-xs-12 list-header"><span class="pR10">{{ leaveAndHolidayObj.id ? 'Edit' : 'Add' }} Leave / Holiday</span>
      <div class="pull-right" *ngIf="leaveAndHolidayObj.id">
        <button class="btn-xs" *ngIf="leaveEditor && leaveAndHolidayObj.id && (status === apiClientConstant.LeaveAndHoliday.STATUS.PENDING_APPROVAL || status === undefined)" (click)="approveLeave()">Approve Leave</button>
        <button class="btn-xs btn-danger mL5" *ngIf="leaveEditor || (leaveAndHolidayObj.get(&quot;user&quot;)?.id == currentUser.id)" (click)="rejectLeave()">Reject Leave</button>
      </div>
    </div>
    <div class="col-xs-6">
      <form class="col-xs-12" #leaves="ngForm">
        <div class="form-group p-2 pr-0">
          <div class="col-xs-12 p-0">
            <div class="col-xs-12 col-md-6">
              <div class="col-xs-12 mb-3 p-0">
                <label class="mb-2">Personal/Public</label>
                <div class="col-xs-12 p-0">
                  <mat-form-field>
                    <mat-select class="w100" [(ngModel)]="username" required="required" name="username" (ngModelChange)="onChangeDoctor()">
                      <mat-option [value]="username" selected="selected">{{username}}</mat-option>
                      <mat-option *ngIf="username !== &quot;Public Holiday&quot;" value="Public Holiday">Public Holiday</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-xs-12" *ngIf="userRoles.includes('adminDoctor') || userRoles.includes('leaveEditor')">
                  <input-internal-user-select class="col-xs-12 pl-0" (onSelectInternalUser)="onSelectDoctor($event)" [type]="appConfig.Shared.User.Type.DOCTOR"></input-internal-user-select>
                </div>
              </div>
              <div class="col-xs-12 mb-3 p-0" *ngIf="username === &quot;Public Holiday&quot;">
                <div class="col-xs-12 p-0">
                  <input class="mb-2" placeholder="Holiday Name" [(ngModel)]="holidayType"/>
                </div>
              </div>
              <div class="col-xs-12 mb-3 p-0" *ngIf="isPersonalLeave && usertype == apiClientConstant.User.Type.OPERATOR">
                <label class="mb-2">Select Mode</label>
                <div class="col-xs-12 p-0">
                  <input-select [parseObj]="leaveAndHolidayObj" name="mode" [options]="modeOptions" [required]="true"></input-select>
                </div>
              </div>
              <div class="col-xs-12 mb-3 p-0" *ngIf="isPersonalLeave">
                <label class="mb-2">Remaining leaves (No. of days)</label>
                <div class="col-xs-12 p-0">
                  <input class="mb-2" placeholder="Remaining leaves for user" [ngClass]="remainingLeaves&lt;0 ? 'bold-600 clr-red': ''" [value]="remainingLeaves" disabled="disabled"/>
                </div>
              </div>
              <div class="col-xs-12 mb-3 p-0">
                <label class="mb-2">Start Date</label>
                <date-selector class="col-xs-12 mb-2" [(value)]="startDate" (valueChange)="getOtherInternalUsersLeaves()"></date-selector>
              </div>
              <div class="col-xs-12 mb-3 p-0">
                <label class="mb-2">End Date</label>
                <date-selector class="col-xs-12 mb-2" [(value)]="endDate" (valueChange)="getOtherInternalUsersLeaves()"></date-selector>
              </div>
              <div class="col-xs-12 mb-3 p-0">
                <label class="col-xs-12 mb-2">Reason</label>
                <input-textarea class="col-xs-12" [parseObj]="leaveAndHolidayObj" name="reason" placeholder="Type your reason here" required="required"></input-textarea>
              </div>
              <form class="form-horizontal justifyC col-xs-12 px-0" *ngIf="isPersonalLeave && usertype == apiClientConstant.User.Type.DOCTOR" #distributionForm="ngForm">
                <div class="col-xs-12 mb-3 p-0">
                  <label class="mb-2">Exceptions List</label>
                  <div class="col-xs-12 p-0">
                    <mat-form-field>
                      <mat-select class="w100" [(ngModel)]="exceptions" name="exceptionsList" multiple="true">
                        <mat-option value="skipAllocationChange">Skip Allocation Change</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <button class="mt-3 db" *ngIf="leaveAndHolidayObj.id" (click)="saveLeave()" [disabled]="!(startDate &gt; today && endDate &gt; today && leaveAndHolidayObj.get('reason') && userRoles.includes('adminDoctor'))">Add Leave</button>
          <button class="mt-3 db" *ngIf="!leaveAndHolidayObj.id" (click)="saveLeave()" [disabled]="!(startDate && endDate && leaveAndHolidayObj.get('reason')) ">Add Leaves</button>
        </div>
      </form>
    </div>
    <div class="col-xs-6 p-0 bdr-lft h100">
      <div class="col-xs-12 list-header textC bdr-btm bdr-top"><span class="pR10">Leave Timetable of Other {{usertype}}s</span></div>
      <div class="item bold bg-header">
        <div class="col-xs-6">Name</div>
        <div class="col-xs-3">Start Date</div>
        <div class="col-xs-3">End Date</div>
      </div>
      <div class="col-xs-6 bold p-3 textC" *ngIf="!otherInternalUserLeaves.length">No data found.</div>
      <div class="item" *ngFor="let item of otherInternalUserLeaves">
        <div class="col-xs-6">{{item.user.username || item.user.DoctorDisplayName}}</div>
        <div class="col-xs-3">{{item.startDate}}</div>
        <div class="col-xs-3">{{item.endDate}}</div>
      </div>
    </div>
  </div>
</div>
