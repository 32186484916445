
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/products/catalogs">Catalogs</a></li>
          <li>{{productObj && productObj.id ? 'Edit' : 'New' }}</li>
        </ul>
      </div>
    </div>
    <div class="flex-content" *ngIf="productObj">
      <form class="col-xs-12 p-4" #productForm="ngForm">
        <div class="col-xs-12">
          <div class="col-xs-6">
            <label class="col-xs-4 mt-0">Title</label>
            <div class="col-xs-12">
              <language-string-selection [parseObj]="productObj" name="titleLanguageString" [required]="true" [tags]="[&quot;product_title&quot;]"></language-string-selection>
            </div>
          </div>
          <div class="col-xs-6">
            <label class="col-xs-4 mt-0">Internal Title</label>
            <div class="col-xs-12">
              <input-text [parseObj]="productObj" name="internalTitle"></input-text>
            </div>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Hidden Title</label>
          <div class="col-xs-12">
            <language-string-selection [parseObj]="productObj" name="hiddenTitleLanguageString" [required]="true" [tags]="[&quot;hidden_titles&quot;]"></language-string-selection>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Display Name</label>
          <div class="col-xs-12">
            <language-string-selection [parseObj]="productObj" name="displayNameLanguageString" [required]="false" [tags]="[&quot;display_name&quot;]"></language-string-selection>
          </div>
        </div>
        <div class="col-xs-12 mt-4">
          <language-string-tag-selection [parseObj]="productObj" name="tags"></language-string-tag-selection>
        </div>
        <div class="col-xs-12 mt-4">
          <label class="col-xs-4">Search Keywords</label>
          <div class="col-xs-12 p-0 pb-2">
            <div class="badge m-2 ml-0" *ngFor="let searchKeywords of searchKeywords; let i = index;"><span class="pr-2">{{searchKeywords}}</span><i class="fa fa-trash-o pointer" (click)="removeSearchKeyword(i)"></i></div>
          </div>
          <div class="col-xs-12 p-0 flex">
            <div class="col-xs-4">
              <input class="form-control" id="searchkeywordinput" [required]="required" [(ngModel)]="searchKeywordInput" name="searchKeywordInput" placeholder="Enter search keyword" [attr.type]="type"/>
            </div>
            <div class="col-xs-4 pl-3">
              <button type="button" (click)="addSearchKeywords()">Add</button>
            </div>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-3">Prescription Required</label>
          <input-checkbox [parseObj]="productObj" name="prescriptionRequired"></input-checkbox>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-3">Common Addon</label>
          <input-checkbox [parseObj]="productObj" name="isCommonAddon"></input-checkbox>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-3">Tablet</label>
          <input-checkbox [parseObj]="productObj" name="isTablet"></input-checkbox>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-3">Antibiotic</label>
          <input-checkbox [parseObj]="productObj" name="isAntibiotic"></input-checkbox>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-3">AntiFungal</label>
          <input-checkbox [parseObj]="productObj" name="isAntiFungal"></input-checkbox>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-3">Repair</label>
          <input-checkbox [parseObj]="productObj" name="isRepair"></input-checkbox>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-6">
            <label>Saftey Profile</label>
            <input-select [parseObj]="productObj" name="safteyProfile" [options]="safteyProfileOptions" (onValueChanged)="addPregAndBfTags($event)"></input-select>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-6">
            <label>Inventory Status</label>
            <input-select [parseObj]="productObj" name="inventoryStatus" [options]="inventoryStatus" [disabled]="isInventoryStatusDiscontinued" required="true"></input-select>
          </div>
          <div class="col-xs-6 pl-4" *ngIf="productObj.get(&quot;inventoryStatus&quot;) === apiClientConstant.Catalog.InventoryStatus.DISCONTINUED">
            <label>Alternative Product</label>
            <div class="col-xs-12 p-0 pb-2" *ngIf="productObj.get('alternateProduct')">
              <div class="badge m-2 ml-0"><span class="pr-2">{{productObj.get('alternateProduct').get('title')}}</span><i class="fa fa-trash-o pointer" (click)="removeAlternateProduct()"></i></div>
            </div>
            <input class="form-control" type="text" placeholder="Type Product Name..." matInput="matInput" name="add_product" autocomplete="off" [formControl]="autoCompleteProductController" [matAutocomplete]="productAuto"/>
            <mat-autocomplete class="search-bar-xs" #productAuto="matAutocomplete">
              <mat-option *ngFor="let option of productOptions | async" [value]="option.name" (onSelectionChange)="autoCompleteOnProductSelect(option)">{{option.name}}</mat-option>
            </mat-autocomplete>
          </div>
        </div>
        <div class="col-xs-6">
          <label class="col-xs-3">Reorder type</label>
          <div class="col-xs-12">
            <input-select [parseObj]="productObj" name="reorderType" [options]="paymentOptions" [required]="true"></input-select>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <label class="col-xs-12">Tracking Category</label>
          <div class="col-xs-6">
            <input-select [parseObj]="productObj" name="trackingCategoryType" [options]="trackingCategory"></input-select>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <label class="col-xs-12">Search Terms</label>
          <input-text [parseObj]="productObj" name="searchTerms"></input-text>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Product Unboxed Image</label>
          <div class="col-xs-12 m-r-xs flex">
            <input-image-upload [parseObj]="productObj" name="productUnboxedImage" [required]="false" [accept]="&quot;image/png&quot;" [constraints]="{ size: 150000, aspectRadio: [1, 2] }"></input-image-upload>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-6">
            <label class="col-xs-4">Rebranded Unboxed Product Image with Background</label>
            <div class="col-xs-12 m-r-xs flex">
              <input-image-upload [parseObj]="productObj" name="rebrandedImageWithBackground" [required]="false" [accept]="&quot;image/png, image/jpg, image/jpeg&quot;" [constraints]="{ size: 150000, aspectRadio: [1, 1.25, 2] }"></input-image-upload>
            </div>
          </div>
          <div class="col-xs-6">
            <label class="col-xs-4">Rebranded Unboxed Product Image Without Background</label>
            <div class="col-xs-12 m-r-xs flex">
              <input-image-upload [parseObj]="productObj" name="rebrandedImageWithoutBackground" [required]="false" [accept]="&quot;image/png&quot;" [constraints]="{ size: 150000 }"></input-image-upload>
            </div>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-6">
            <label>Extra images for slider</label><i class="fa fa-plus-square-o pointer m-l-xs m-r-xs" (click)="addExtraImages()"></i>
          </div>
          <ng-container *ngFor="let isVisible of extraImages; index as i">
            <div class="col-xs-12 m-r-xs flex" *ngIf="isVisible">
              <input-image-upload [parseObj]="productObj" [name]="&quot;extraImages_&quot;+i" [required]="false" [accept]="&quot;image/png, video/mp4&quot;" [constraints]="{ size: 1500000 }"></input-image-upload><i class="fa fa-minus-square-o pointer m-l-xs m-r-xs" (click)="removeExtraImages(i)"></i>
            </div>
          </ng-container>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Conditions</label>
        </div>
        <div class="col-xs-12">
          <question-condition [conditions]="productObj.get(&quot;conditions&quot;)"></question-condition>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Product Description</label>
          <div class="col-xs-12">
            <language-string-selection [parseObj]="productObj" name="descriptionLanguageString" [required]="true" [tags]="[&quot;productdescription&quot;]"></language-string-selection>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Product Features</label>
          <div class="col-xs-12">
            <language-string-selection [parseObj]="productObj" name="featureListLanguageString" [tags]="[&quot;productfeature&quot;]"></language-string-selection>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Product Ingredients</label>
          <div class="col-xs-12">
            <language-string-selection [parseObj]="productObj" name="ingredientListLanguageString" [tags]="[&quot;productingredient&quot;]"></language-string-selection>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">How to use</label>
          <div class="col-xs-12">
            <language-string-selection [parseObj]="productObj" name="instructionListLanguageString" [tags]="[&quot;productinstruction&quot;]"></language-string-selection>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4">
            <label>Vendor Name</label>
            <input-text [parseObj]="productObj" name="vendorName"></input-text>
          </div>
          <div class="col-xs-4 pl-4">
            <label>Market Equivalent Name</label>
            <input-text [parseObj]="productObj" name="marketEquivalentName"></input-text>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4">
            <label class="col-xs-12">Ingredient key Active</label>
            <textarea class="w100" [(ngModel)]="keyActive" name="keyActive"></textarea>
          </div>
          <div class="col-xs-4 pl-4">
            <label class="col-xs-12">Ingredient supporting Active</label>
            <textarea class="w100" [(ngModel)]="supportingActive" name="supportingActive"></textarea>
          </div>
          <div class="col-xs-4 pl-4">
            <label class="col-xs-12">Ingredient Base ingredients</label>
            <textarea class="w100" [(ngModel)]="baseIngredients" name="baseIngredients"></textarea>
          </div>
        </div>
        <div class="col-xs-6">
          <label class="col-xs-12">Net Weight/Volume</label>
          <div class="col-xs-4 pr-4">
            <input-text [parseObj]="productObj" required="required" name="quantity" placeholder=""></input-text>
          </div>
          <div class="col-xs-2">
            <input-select [parseObj]="productObj" name="quantityUnit" [options]="quantityUnits" required="required"></input-select>
          </div>
        </div>
        <div class="col-xs-6">
          <label class="col-xs-12">Category</label>
          <div class="col-xs-2">
            <input-select [parseObj]="productObj" name="category" [options]="categories" required="required"></input-select>
          </div>
        </div>
        <div class="col-xs-6">
          <label class="col-xs-12">Sub Category</label>
          <div class="col-xs-2">
            <input-select [parseObj]="productObj" name="subCategory" [options]="subCategories" required="required"></input-select>
          </div>
        </div>
        <div class="col-xs-6">
          <label class="col-xs-12">Gross Weight(in gms)</label>
          <div class="col-xs-4 pr-4">
            <input-text [parseObj]="productObj" name="grossWeight" placeholder="Weight in grams"></input-text>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-3 pr-4">
            <label class="col-xs-4">Marg ID</label>
            <div class="col-xs-12">
              <input-text [parseObj]="productObj" required="required" name="margId" placeholder=""></input-text>
            </div>
          </div>
          <div class="col-xs-6 pr-4">
            <label class="col-xs-12">Marg Name</label>
            <div class="col-xs-12">
              <input-text [parseObj]="productObj" name="margName" placeholder=""></input-text>
            </div>
          </div>
          <div class="col-xs-3 pr-4">
            <label class="col-xs-12">Marg Unit</label>
            <div class="col-xs-12">
              <input-text [parseObj]="productObj" name="margUnit" placeholder="" type="number"></input-text>
            </div>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-6 pr-4">
            <label class="col-xs-4">Video Link - Paid User</label>
            <div class="col-xs-12">
              <input-text [parseObj]="productObj" name="videoLinkPaid" placeholder="link_paid"></input-text>
            </div>
          </div>
          <div class="col-xs-6">
            <label class="col-xs-5">Video Link - Unpaid User</label>
            <div class="col-xs-12">
              <input-text [parseObj]="productObj" name="videoLinkUnpaid" placeholder="link_unpaid"></input-text>
            </div>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-3 pr-4">
            <label class="col-xs-12">MRP</label>
            <div class="col-xs-12">
              <input-text [parseObj]="productObj" name="mrp" placeholder="MRP" type="number" min="0" required="required" (onValueChange)="ui.maxSellingPrice = $event"></input-text>
            </div>
          </div>
          <div class="col-xs-3 pr-4">
            <label class="col-xs-12">Selling price (inc. GST)</label>
            <div class="col-xs-12">
              <input-text [parseObj]="productObj" name="price" placeholder="Selling Price" type="number" [max]="ui.maxSellingPrice" required="required" (onValueChange)="ui.maxEffectivePrice = $event; updateTaxDetails()"></input-text>
            </div>
          </div>
          <div class="col-xs-3 pr-4">
            <label class="col-xs-12">Effective Cost Price</label>
            <div class="col-xs-12">
              <input-text [parseObj]="productObj" name="effectiveCostPrice" placeholder="Effective Cost Price" type="number" [min]="ui.minEffectivePrice" required="required"></input-text>
            </div>
          </div>
          <div class="col-xs-3 pr-4">
            <label class="col-xs-12">Private label cost</label>
            <div class="col-xs-12">
              <input-text [parseObj]="productObj" name="externalEffectiveCostPrice" placeholder="External effective Cost Price" type="number"></input-text>
            </div>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-3 pr-4">
            <label class="col-xs-12">Offer Amount</label>
            <div class="col-xs-12">
              <input-text [parseObj]="productObj" name="offerAmount" placeholder="Offer Amount" type="number"></input-text>
            </div>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Purpose</label>
          <div class="col-xs-12">
            <language-string-selection [parseObj]="productObj" name="purposeLanguageString" [required]="true" [tags]="[&quot;purpose&quot;]"></language-string-selection>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Purpose Description</label>
          <div class="col-xs-12">
            <language-string-selection [parseObj]="productObj" name="purposeDescriptionLanguageString" [required]="true" [tags]="[&quot;purposedescription&quot;]"></language-string-selection>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Restrictions</label>
          <div class="col-xs-12 pl-0">
            <language-string-multiple-selection-with-tag [parseObj]="productObj" name="restrictions"></language-string-multiple-selection-with-tag>
          </div>
        </div>
        <div class="col-xs-12 mb-3">
          <label class="col-xs-12">Shop Tags</label>
          <div class="col-xs-7">
            <input-multi-select [parseObj]="productObj" name="shopTags" [options]="shopTags"></input-multi-select>
          </div>
        </div>
        <div class="col-xs-12 mb-3">
          <label class="col-xs-12">Exclude Boost Your Regimen Tags</label>
          <div class="col-xs-7">
            <input-multi-select [parseObj]="productObj" name="excludeBoostProductsTags" [options]="excludeBoostProductsTags"></input-multi-select>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Side Effects</label>
          <div class="col-xs-12 pl-0">
            <language-string-multiple-selection-with-tag [parseObj]="productObj" name="sideEffects"></language-string-multiple-selection-with-tag>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Indication Restrictions (Not visible to user)</label>
          <div class="col-xs-12 pl-0">
            <language-string-multiple-selection-with-tag [parseObj]="productObj" name="indicationRestrictions"></language-string-multiple-selection-with-tag>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Key Features</label>
          <div class="col-xs-12 pl-0">
            <language-string-multiple-selection-with-tag [parseObj]="productObj" name="key_features" [required]="true" [tags]="[&quot;key_features&quot;]"></language-string-multiple-selection-with-tag>
          </div>
        </div>
        <div class="col-xs-12 mt-4 flex">
          <div class="col-xs-4 flex">
            <label class="col-xs-2 m-0">GSTN</label>
            <div class="col-xs-9">
              <input-select [parseObj]="productObj" name="gstn" [options]="gstnOptions" [required]="true" [disabled]="productObj.get(&quot;gstn&quot;)"></input-select>
            </div>
          </div>
          <div class="col-xs-3 flex">
            <label class="col-xs-3 m-0">GST%</label>
            <div class="col-xs-9">
              <select name="GST" [(ngModel)]="gstSelectedSlab" (change)="updateTaxDetails()" required="required">
                <option [value]="undefined" disabled="disabled">Select Slab</option>
                <option *ngFor="let slab of GST_SLABS" value="{{slab}}">{{slab}}</option>
              </select>
            </div>
          </div>
          <div class="col-xs-2 flex">
            <label class="col-xs-3 m-0">CGST:</label>
            <div class="col-xs-9">{{ui.cgst | number:'.1-2'}}</div>
          </div>
          <div class="col-xs-2 flex">
            <label class="col-xs-3 m-0">SGST:</label>
            <div class="col-xs-9">{{ui.sgst | number:'.1-2'}}</div>
          </div>
        </div>
        <div class="col-xs-12 articleMargin">
          <label class="col-xs-12"></label>
          <div class="col-xs-12">
            <language-string-multiple-selection-with-tag title="Upload Article Image" [parseObj]="articleObj?.get(&quot;params&quot;)" name="articleImagesLanguageString" [required]="true" [tags]="[&quot;article_image&quot;]" [showUploadButton]="true"></language-string-multiple-selection-with-tag>
          </div>
        </div>
        <div class="col-xs-12 articleMargin" style="background: #eee; margin: 0; padding-bottom: 20px;">
          <label class="col-xs-12"></label>
          <div class="col-xs-12">
            <language-string-multiple-selection-with-tag title="Upload Article Image with Tag" [parseObj]="articleWithTagObj?.get(&quot;params&quot;)" name="articleImagesLanguageString" [required]="true" [tags]="[&quot;article_image&quot;]" [showUploadButton]="true"></language-string-multiple-selection-with-tag>
          </div>
          <label class="col-xs-12 mb-0" *ngIf="articleWithTagObj?.get('params')?.articleImagesLanguageString?.length">User tag for article</label>
          <label class="col-xs-12" *ngIf="articleWithTagObj?.get('params')?.articleImagesLanguageString?.length">
            <select name="userTagForArticle" [(ngModel)]="userTagForArticle" required="required">
              <option *ngFor="let tag of userTagList" [value]="tag">{{tag}}</option>
            </select>
          </label>
        </div>
        <div class="col-xs-12 flex">
          <div class="col-xs-6 flex">
            <label class="col-xs-12 mt-0">Internal title for article
              <div class="col-xs-12">
                <language-string-selection [parseObj]="articleObj" name="titleLanguageString" [tags]="[&quot;article&quot;,&quot;internal_title&quot;]" prefix="article"></language-string-selection>
              </div>
            </label>
          </div>
          <div class="col-xs-6 flex">
            <label class="col-xs-12 mt-0">Toolbar title for article
              <div class="col-xs-12">
                <language-string-selection [parseObj]="articleObj" name="toolbarTitleLanguageString" [tags]="[&quot;article&quot;,&quot;toolbar_title&quot;]"></language-string-selection>
              </div>
            </label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-4">
            <label>Ranking (Recommended product section ranking)</label>
            <input-text [parseObj]="productObj" name="ranking" type="number"></input-text>
          </div>
        </div>
        <div class="col-xs-12 flex mt-3">
          <div class="col-xs-4">
            <label class="col-xs-12 mt-0">Boost Your Regimen Tags</label>
            <div class="col-xs-12">
              <input-multi-select [parseObj]="productObj" name="boostYourRegimenTags" [options]="boostYourRegimenTags"></input-multi-select>
            </div>
          </div>
        </div>
        <div class="col-xs-12 mt-5">
          <button type="submit" [disabled]="!productForm.valid || disabled" (click)="saveProduct()">{{ productObj.id ? 'Update' : 'Create' }}</button>
        </div>
      </form>
    </div>
  </div>
</div>