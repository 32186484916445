import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiClientConstant, Table } from 'api-client';
import { ConnectionService } from '../../services/connection-service';
import { AppConfig } from '../../app/app.config';
import { Broadcaster } from '../broadcaster';

@Component({
  selector: 'change-allocated-doctor',
  templateUrl: './change-allocated-doctor.html',
})
export class ChangeAllocatedDoctor {
  selectedDoctor: any;
  selectedFollowups: any;
  doctorList: Array<any> = [];
  type: string;
  label: string;
  allocatedTeamType: Array<any>;
  constructor(
    private dialogRef: MatDialogRef<ChangeAllocatedDoctor>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private conn: ConnectionService,
    private broadcaster: Broadcaster,
    private appConfig: AppConfig) { }

  async ngOnInit(): Promise<void> {
    this.type = this.data.type;
    this.allocatedTeamType = [
      { display: 'MBBS', value: 'MBBS' },
      { display: 'DERMATOLOGIST', value: 'DERMATOLOGIST' },
    ];
    this.doctorList = await this.conn.getAllUsers({ where: { type: ApiClientConstant.User.Type.DOCTOR, inactive: { $ne: true } } });
  }

  async save(): Promise<void> {
    if (this.type === this.appConfig.Shared.AllocatedDoctorPopup.Type.ORDER) {
      await this.changeOrderAllocatedDoctor();
      return;
    }
    this.selectedFollowups = this.data.followUpList;
    try {
      await Promise.all(this.selectedFollowups.map((id: string) => {
        const followup = new Table.FollowUp();
        followup.id = id;
        if (this.selectedDoctor) {
          followup.set('allocatedDoctor', this.selectedDoctor);
        }
        if (this.label) {
          followup.set('allocatedTeam', this.label);
        }
        return followup.save();
      }));
      this.broadcaster.broadcast('NOTIFY', { message: 'Followup Allocated Successfully', type: this.appConfig.Shared.Toast.Type.SUCCESS });
      this.dialogRef.close(this.selectedDoctor || this.label);
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message || error, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  async changeOrderAllocatedDoctor(): Promise<void> {
    const orderObj = this.data.parseObject;
    orderObj.set('allocatedDoctor', this.selectedDoctor);
    try {
      await orderObj.save();
      this.broadcaster.broadcast('NOTIFY', { message: 'Order Allocated Successfully', type: this.appConfig.Shared.Toast.Type.SUCCESS });
      this.dialogRef.close('success');
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
      this.hideModal();
    }
  }

  hideModal(): void {
    this.dialogRef.close();
  }
}
