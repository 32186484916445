
<div class="sidebar-chat col-xs-12 col-sm-5 h-full p-3" *ngIf="patient">
  <div class="header">
    <div class="col-xs-12">
      <div class="col-xs-8">
        <user-priority [user]="patient"></user-priority>
        <span class="fontS pl-2" *ngIf="!showConsultationSession">
          {{patient.get('PatientName')}} - ({{patient.get('allocatedDoctor')?.get('username')}}) - {{patient.get('Gender')}}({{patient.get('Age')}}) &nbsp; - &nbsp; ({{patient.get('communicationLanguagePreference') || patient.get('languagePreference')}})
        </span>
        <span class="fontS pl-2" *ngIf="showConsultationSession">
          Bot Chat Summary - {{patient.get('username')}}
        </span>
      </div>
      <div class="col-xs-4">
        <div class="icons">
          <div (click)="removeMessageFromDND()" data-tooltip-text="DND Whitelist" data-tooltip-pos="bottom"><i class="fa fa-key"></i></div>
          <div (click)="sendSMS()" data-tooltip-pos="bottom" data-tooltip-text="Send SMS"><i class="fa fa-envelope"></i></div>
          <div *ngIf="!showConsultationSession" data-tooltip-pos="bottom" (click)="showConsultationSessions()" data-tooltip-text="User Details"><i class="fa fa-toggle-on"></i></div>
          <div *ngIf="showConsultationSession" data-tooltip-pos="bottom" (click)="showConsultationSessions()" data-tooltip-text="User Chat"><i class="fa fa-toggle-off"></i></div>
          <div (click)="reloadIframe()" data-tooltip-pos="bottom" data-tooltip-text="Reload Chat"><i class="fa fa-refresh"></i></div>
        </div>
      </div>
    </div>
  </div>
  <div class="content" *ngIf="chatViewLink && !supportTicketView">
    <iframe class="iframeNewChat" #newChatView [src]="chatViewLink"></iframe>
  </div>
  <div class="content" *ngIf="supportTicketView && !showFilter && !showConsultationSession">
    <support-chat-view #supportQueryHistoryComponent [user]="patient"></support-chat-view>
  </div>
  <div class="content details user-details" *ngIf="showConsultationSession">
    <div class="col-xs-12 mt-0 user-detail">
      <label class="fontR m-0">User Details</label>
      <button class="btn-xs" (click)="updatePatientInfo()">Update</button>
    </div>
    <div class="col-xs-12 mt-3">
      <label class="col-xs-4 mt-0">ObjectId</label>
      <div class="col-xs-8"><span>{{patient.id || '-'}}</span></div>
    </div>
    <div class="col-xs-12 mt-3" *ngIf="internalUserRoles.includes(&quot;adminOperator&quot;) && internalUserRoles.includes(&quot;regimenEditor&quot;)">
      <div class="col-xs-12 flex">
        <label class="col-xs-4 mt-2">Pull from Account</label>
        <div class="col-xs-8 flex">
          <input class="w100 input-height" #phoneNumber placeholder="Account 2 Primary Number" name="pullFromAccountNumber"/>
          <div class="col-xs-2 ml-3">
            <button class="btn-xxs" (click)="pullFromAccount(phoneNumber.value)">Pull</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 mt-3" *ngIf="internalUserRoles.includes(&quot;adminOperator&quot;)">
      <div class="col-xs-12 flex">
        <label class="col-xs-4 mt-2">Swap Numbers</label>
        <div class="col-xs-8 flex">
          <input class="w100 input-height" #swapingNumber placeholder="Account 2 Primary Number" name="swapNumber2"/>
          <div class="col-xs-2 ml-3">
            <button class="btn-xxs" (click)="swapMobileNumbers(swapingNumber.value)">Swap</button>
          </div>
        </div>
      </div>
      <div class="col-xs-12 fontS3 mt-1">(* Note : It wil just swap the primary number of both account, wont merge the data of both accounts)</div>
    </div>
    <div class="col-xs-12 flex mt-3" *ngIf="internalUserRoles.includes(&quot;cashAllocator&quot;)">
      <label class="col-xs-4 mt-2">Add Cash</label>
      <div class="col-xs-8 flex">
        <div class="col-xs-4 mr-2">
          <input class="w100 input-height" placeholder="Cash Amount" [(ngModel)]="cashAmountToAdd" type="number"/>
        </div>
        <div class="col-xs-3 mr-2">
          <select class="w100" name="Credit/Debit" (change)="updateCashEntryType($event.target.value)">
            <option value="1">Credit</option>
            <option value="-1">Debit</option>
          </select>
        </div>
        <div class="col-xs-2">
          <button class="btn-xxs" (click)="addCashEntry($event)">Add Cash</button>
        </div>
      </div>
    </div>
    <div class="col-xs-12 flex mt-3">
      <label class="col-xs-4 mt-2">Claim gift</label>
      <div class="col-xs-8 flex">
        <div class="col-xs-6 mr-2">
          <input class="w100 input-height" #claimCash placeholder="Coupon code" type="text"/>
        </div>
        <div class="col-xs-3">
          <button class="btn-xxs" (click)="claimGift(claimCash.value)">Claim</button>
        </div>
      </div>
    </div>
    <div class="col-xs-12 mt-3">
      <label class="col-xs-4 mt-0">Wallet Balance</label>
      <div class="col-xs-8"><span>{{walletBalance}}</span></div>
    </div>
    <div class="col-xs-12 mt-3">
      <label class="col-xs-4 mt-0">Referred By</label>
      <div class="col-xs-8"><span>{{referredBy}}</span></div>
    </div>
    <form #userDetailForm="ngForm">
      <div class="col-xs-12" *ngFor="let item of patientData; let i = index;">
        <div class="col-xs-12 mt-3 flex">
          <label class="col-xs-4 mt-2">{{item.key}}</label>
          <div class="col-xs-8 flex" *ngIf="editDisplayConfig[item.key.trim()] && editDisplayConfig[item.key.trim()].editType === EDITABLE_TYPE.INPUT">
            <div class="col-xs-6">
              <input class="w100 input-height" [(ngModel)]="item.value" name="name__{{i}}"/>
            </div>
            <div class="col-xs-2">
              <button class="ml-3 btn-xxs" *ngIf="item.key ===&quot;Alternate Number&quot;" (click)="connectionService.callThroughExotel(item.value, &quot;chatPage&quot;)">Call User</button>
            </div>
          </div>
          <div class="col-xs-8" *ngIf="editDisplayConfig[item.key.trim()] && editDisplayConfig[item.key.trim()].editType === EDITABLE_TYPE.BOOLEAN">
            <input-checkbox class="mb-2" [parseObj]="patient" name="{{item.originalKey}}" (onValueChange)="item.value = $event"></input-checkbox>
          </div>
          <div class="col-xs-8" *ngIf="editDisplayConfig[item.key.trim()] && editDisplayConfig[item.key.trim()].editType === EDITABLE_TYPE.DROP_DOWN">
            <select class="w100" [(ngModel)]="item.value" name="name_{{item.key}}_{{i}}" required="required">
              <option *ngFor="let option of editDisplayConfig[item.key.trim()].options" [value]="option.key">{{option.value}}</option>
            </select>
          </div>
          <div class="col-xs-8" *ngIf="editDisplayConfig[item.key.trim()] && editDisplayConfig[item.key.trim()].editType === EDITABLE_TYPE.LIST"><span class="label label-default m-l-xs" *ngFor="let each of item.value || [];let optionIndex = index;">{{each}}<i class="fa fa-trash-o" (click)="removeOption(item.value, optionIndex)"></i></span>
            <select class="input-sm p-1 ml-1" [(ngModel)]="item.selectedValue" name="name_{{item.key}}_{{i}}" (change)="onOptionAdd(item.value, item)">
              <option *ngFor="let option of editDisplayConfig[item.key.trim()].options" [value]="option.key" [disabled]="item.value.includes(option.key)">{{option.value}}</option>
            </select>
          </div>
          <div class="col-xs-8" *ngIf="!editDisplayConfig[item.key.trim()]"><span *ngIf="displayConfig[item.key] && displayConfig[item.key] === DISPLAY_TYPE.LIST"><span class="label label-default m-l-xs" *ngFor="let each of item.value || [];">{{each}}</span></span><span *ngIf="!displayConfig[item.key]">{{item.value}}</span>
            <button class="ml-3 btn-xxs" *ngIf="item.key.trim() === &quot;Mobile Number&quot;" (click)="connectionService.callThroughExotel(item.value, &quot;chatPage&quot;)">Call User</button>
          </div>
          <div class="col-xs-8" *ngIf="editDisplayConfig[item.key.trim()] && editDisplayConfig[item.key.trim()].editType === EDITABLE_TYPE.DATE">
            <date-selector class="w100" [(value)]="item.value"></date-selector>
          </div>
        </div>
      </div>
    </form>
    <div class="col-xs-12" *ngFor="let item of consultationSessionData; let i = index;">
      <div class="col-xs-12 mt-3 flex">
        <label class="col-xs-4 mt-2">{{item.key}}</label>
        <div class="col-xs-8" *ngIf="editDisplayConfig[item.key.trim()] && editDisplayConfig[item.key.trim()].editType === EDITABLE_TYPE.INPUT">
          <input class="input-height" [(ngModel)]="item.value" name="name_{{i}}"/>
        </div>
        <div class="col-xs-8" *ngIf="editDisplayConfig[item.key.trim()] && editDisplayConfig[item.key.trim()].editType === EDITABLE_TYPE.DROP_DOWN">
          <select [(ngModel)]="item.value" name="name_{{i}}" required="required">
            <option *ngFor="let option of editDisplayConfig[item.key.trim()].options" [value]="option.key">{{option.value}}</option>
          </select>
        </div>
        <div class="col-xs-8" *ngIf="!editDisplayConfig[item.key.trim()]">{{item.value}}</div>
      </div>
    </div>
  </div>
</div>
