
<mat-dialog-content class="mat-dialog-content" style="width:50vw">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Order Stage</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <form #stageForm="ngForm">
      <div class="col-xs-10">
        <label class="col-xs-12 mt-0">Stage Name</label>
        <input-text [parseObj]="newStage" name="name" autocomplete="off" placeholder="Enter the Stage Name" [required]="true" pattern="[A-Za-z _]*"></input-text>
      </div>
      <div class="col-xs-10 flex mt-3">
        <label>Visible To User</label>
        <input-checkbox class="ml-3" [parseObj]="newStage" name="visibleToUser"></input-checkbox>
      </div>
      <div class="col-xs-12">
        <label class="col-xs-12">Display Text</label>
        <language-string-selection class="col-xs-12" [parseObj]="newStage" [tags]="[&quot;orderstage&quot;]" name="displayTextLanguageString" [required]="true"></language-string-selection>
      </div>
      <div class="col-xs-10">
        <label class="col-xs-12">Image Url</label>
        <input-text [parseObj]="newStage" name="imageLink" autocomplete="off" placeholder="Enter the Image Url" [required]="true"></input-text>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="pull-right" type="submit" (click)="createNewStage()" [disabled]="!stageForm.valid">{{ newStage.id ? 'Update' : 'Create' }}</button>
  </div>
</mat-dialog-content>