
<div class="col-xs-12 p-0">
  <div class="col-xs-12 title" *ngIf="!hideTitle"><em class="fas fa-cart-plus pr-3"></em><span>Orders</span>
    <div class="pull-right alert-warning fontS3 py-1 px-4" *ngIf="isOlderThanTwoMonth"><span class="mr-1 fas fa-exclamation-circle"></span><span>Not Ordered in 2 Months</span></div><a class="fa fa-plus-square-o pl-3" routerLink="/orders/new" [queryParams]="{ username: user.get(&quot;username&quot;) }" target="_blank"></a>
  </div>
  <div class="col-xs-12 of-auto fontS" style="max-height:150px;" *ngIf="orders.length">
    <div class="orderRow" *ngFor="let order of orders;" [ngClass]="{ &quot;mac&quot; : isMacDevice, &quot;bg-warning&quot;: order.partialProductOrder }"><a class="text-info" href="/order/{{order.objectId}}" target="_blank"><span class="pr-2" *ngIf="order.regimen">({{ order.regimen.class}})</span><span class="pr-2" title="Rs {{order.amount}}">{{order.regimenId ? "REGIMEN" : "PRODUCT" }} {{order.regimenId}}</span><span class="pr-2">(</span><span class="pr-2">{{order.stage}}</span><span class="pr-2" *ngIf="order.deliveredOn">{{order.deliveredOn | amDateFormat: "MMM DD, YYYY"}}</span><span class="pr-2">)</span></a><i class="fas fa-comment" (click)="openOrderChat(order.objectId)"></i><a class="fa fa-pencil-square-o mx-3" href="/order/{{order.objectId}}/edit" target="_blank"></a>
      <div class="dib fontS2" *ngFor="let service of order.services;">
        <div class="dib mr-2 mt-2 product-label night" *ngIf="service.type !== 'COD'">{{service.title}}</div>
      </div>
      <div class="dib"><a class="dib mr-2 mt-2 product-label" *ngFor="let product of order.productInfo;" routerLink="/products/catalog/{{product.objectId}}" [ngClass]="product.allowAsAddon ? &quot;addon&quot; : &quot;night&quot;" title="{{product.allowAsAddon ? '[add on]': ''}} {{product.quantity}}{{product.quantityUnit}} Rs{{product.price}}">{{product.name}} &nbsp; {{product.type === appConfig.Shared.Catalog.Type.SAMPLE ? '(SAMPLE)' : '' }}</a></div>
    </div>
  </div>
</div>