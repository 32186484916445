
<div fillComponentParentView="fillComponentParentView">
  <div class="col-xs-12 list-header"><span>Order Stage</span><i class="fa fa-plus-square-o pull-right" (click)="showAddStageView()"></i></div>
  <div class="col-xs-12 p-0 pl-1 pr-4">
    <div class="col-xs-12 flex">
      <label class="fontL col-sm-4 col-xs-4 col-md-2 name">Stage Name</label>
      <label class="fontL col-xs-1">Edit</label>
      <label class="fontL col-sm-8 col-xs-8 col-md-8" id="possibleStages">Possible Stage Changes</label>
    </div>
    <div class="col-xs-12 flex mt-2 pl-3 list-item bdr-btm" *ngFor="let item of list;let i = index">
      <div class="col-sm-4 col-xs-4 col-md-2 name">{{item.get('name')}}</div>
      <div class="col-xs-1"><i class="fa fa-pencil-square-o Edit" (click)="editStage(item)"></i></div>
      <div class="col-sm-8 col-xs-8 col-md-8 flex flex-wrap badge-container">
        <div class="badge mr-2 pb-2 mb-2" *ngFor="let each of item.get(&quot;possibleStageChange&quot;);let j = index;">{{each.get('name')}}<i class="fa fa-trash-o pointer ml-2" *ngIf="j !== 0" (click)="removeStage(item,j)"></i></div>
        <div *ngIf="item.get(&quot;possibleStageChange&quot;).length === 0">No Stages Connected</div>
      </div>
      <div class="col-xs-1 btnContainer">
        <button class="fontS3 pull-right" (click)="dropdown.selectedRow = i; newStage.open = false; openAddStagePopup()">Add Stages</button>
      </div>
    </div>
    <div class="col-xs-12 wrapper-sm text-center" *ngIf="!list.length">-- No Stages Available --</div>
  </div>
</div>