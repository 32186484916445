import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { InputType } from '../../../../../typings/client/input';
import { AddressBookEditModal } from '../address-edit-selection';
import { AppConfig } from '../../../app.config';

@Component({ selector: 'address-book-selection', templateUrl: './address-book.html' })
export class AddressBookSelectionComponent {
  @Input('parseObject')
  set setParseObject(parseObject: any) {
    this.parseObject = parseObject;
    if (!parseObject) {
      return;
    }
    this.updateDefaultAddressIfNotSelected();
  }
  @Input('user')
  set setUser(user: any) {
    this.user = user;
    if (!user || !user.id) {
      return;
    }
    this.fetchUserAddress();
  }
  @Input('name') name: string;

  parseObject: any;
  user: any;
  addressOptions: Array<InputType.SelectOption> = [];
  disableAddressEdit: boolean = false;

  constructor(private conn: ConnectionService, private dialog: MatDialog, public appConfig: AppConfig) {
  }

  ngOnInit(): void {
    const orderData = JSON.parse(JSON.stringify(this.parseObject));
    this.disableAddressEdit = !!orderData?.trackingId;
  }

  private async fetchUserAddress(): Promise<void> {
    const addressBook = await this.conn.findAddressBook({ where: { user: this.user } });
    this.addressOptions = addressBook.map((each: any) => ({
      display: each.get('deliveryAddress'),
      value: each.id,
      parseValue: each,
    }));
    this.updateDefaultAddressIfNotSelected();
  }

  private updateDefaultAddressIfNotSelected(): void {
    if (!this.parseObject || !this.name) {
      return;
    }
    if (this.parseObject.has(this.name)) {
      return;
    }
    const defaultAddressBook = this.addressOptions.find((each: any) => each.parseValue.get('default'));
    if (!defaultAddressBook) {
      return;
    }
    const { parseObject }: { parseObject: any } = this;
    this.parseObject = undefined;
    parseObject.set(this.name, defaultAddressBook.parseValue);
    setTimeout(() => (this.parseObject = parseObject), 1000);
  }

  async openAddressEditPopup(action: string): Promise<void> {
    const currentAddress = action === this.appConfig.Shared.Actions.new
      ? new Table.AddressBook({ user: this.parseObject.get('user') })
      : this.parseObject.get('addressBook');
    const dialogRef = this.dialog.open(AddressBookEditModal, {
      data: { action, user: this.parseObject.get('user'), currentAddress },
    });
    dialogRef.afterClosed().subscribe((addressBook: any) => {
      if (addressBook) {
        this.parseObject.set('addressBook', addressBook);
      }
      this.fetchUserAddress();
    });
  }
}
