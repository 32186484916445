import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { MatDialogModule } from '@angular/material/dialog';
import { AddressBookSelectionComponent } from './address-book-selection.component';
import { InputSelectModule } from '../../../../components/input/input-select';
import { InputCheckboxModule } from '../../../../components/input/input-checkbox';
import { AddressBookEditModal } from '../address-edit-selection';
import { InputTextModule } from '../../../../components/input/input-text';
import { DirectivesModule } from '../../../../components/directives.module';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    InputSelectModule,
    InputTextModule,
    MatDialogModule,
    DirectivesModule,
    MomentModule,
    InputCheckboxModule,
  ],
  declarations: [AddressBookSelectionComponent, AddressBookEditModal],
  exports: [AddressBookSelectionComponent],
})
export class AddressBookModule {}
