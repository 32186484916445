
<div class="hbox" *ngIf="articleObj">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header"><a routerLink="/marketing/article">Article / {{id}}</a>
        <div class="pull-right">
          <button class="mr-3" [disabled]="[disabled]"><a href="{{webAppUrl}}/article/{{articleId}}" target="_blank">View</a></button>
          <button class="mr-3" routerLink="./edit">Edit</button>
          <button routerLink="/marketing/article/new">New</button>
        </div>
      </div>
    </div>
    <div class="flex-content">
      <div class="col-xs-12 wrapper-sm" *ngIf="articleObj.get(&quot;product&quot;)">
        <div class="col-sm-2 col-xs-11" style="font-weight:bold">Title of product</div>
        <div class="col pull-left">:</div>
        <div class="col-sm-6 col-xs-12">{{articleObj.get('product').get('title')}}</div>
      </div>
      <div class="col-xs-12 wrapper-sm">
        <div class="col-sm-2 col-xs-11" style="font-weight:bold">content Title</div>
        <div class="col pull-left">:</div>
        <div class="col-sm-6 col-xs-12">
          <language-view [languageStringObj]="articleObj.get(&quot;titleLanguageString&quot;)"></language-view>
        </div>
      </div>
      <div class="col-xs-12 wrapper-sm">
        <div class="col-sm-2 col-xs-11" style="font-weight:bold">HTML</div>
        <div class="col pull-left">:</div>
        <div class="col-sm-6 col-xs-12">{{html}}</div>
      </div>
      <div class="col-xs-12 wrapper-sm" *ngIf="articleObj.get(&quot;cssLink&quot;)">
        <div class="col-sm-2 col-xs-11" style="font-weight:bold">CSS Link</div>
        <div class="col pull-left">:</div>
        <div class="col-sm-6 col-xs-12">{{articleObj.get('cssLink')}}</div>
      </div>
      <div class="col-xs-12 wrapper-sm">
        <div class="col-sm-2 col-xs-11" style="font-weight:bold">Click To Action</div>
        <div class="col pull-left">:</div>
        <div class="col-sm-6 col-xs-12">{{articleObj.get('clickToAction')}}</div>
      </div>
      <div class="col-xs-12 wrapper-sm">
        <div class="col-sm-2 col-xs-11" style="font-weight:bold">Thumbnail</div>
        <div class="col pull-left">:</div>
        <div class="col-sm-6 col-xs-12">
          <language-view [languageStringObj]="articleObj.get(&quot;thumbnailLanguageString&quot;)"></language-view>
        </div>
      </div>
      <div class="col-xs-12 wrapper-sm">
        <div class="col-sm-2 col-xs-11" style="font-weight:bold">Question</div>
        <div class="col pull-left">:</div>
        <div class="col-sm-6 col-xs-12"><a *ngIf="articleObj.get(&quot;question&quot;)" routerLink="/trees/question/{{articleObj.get(&quot;question&quot;).id}}" target="_blank">{{articleObj.get('question').id}}</a>
          <button class="btn btn-xs btn-success" (click)="createOrUpdateQuestion()">{{ articleObj.get("question") ? "Update" : "Create" }} Question</button>
        </div>
      </div>
    </div>
  </div>
</div>