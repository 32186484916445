
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/products/regimens">Regimens</a></li>
          <li>{{regimenObj?.get('regimenId')}}</li>
        </ul>
        <div class="pull-right"><span *ngFor="let variant of variants;">
            <button class="btn-xs ml-1 mr-1" (click)="displayVariant(variant)" [ngClass]="{&quot;btn-primary&quot;: selectedVariant.variantId == variant.variantId, &quot;btn-primary-outline&quot;: selectedVariant.variantId !== variant.variantId }">{{variant.variantId}}</button></span><span class="ml-3" *ngIf="userRoles.includes(&quot;regimenEditor&quot;)">
            <button class="btn-xs btn-primary-outline" *ngIf="[apiClientConstant.Regimen.Type.MAIN, apiClientConstant.Regimen.Type.PERSONALIZED].includes(regimenObj.get(&quot;type&quot;))" routerLink="../../regimen/{{regimenObj.get(&quot;regimenId&quot;)}}/edit">Edit</button>
            <button class="btn-xs btn-primary-outline ml-2" *ngIf="regimenObj.get(&quot;type&quot;) === apiClientConstant.Regimen.Type.ALTERNATE" routerLink="../../alternate/{{regimenObj.get(&quot;regimenId&quot;)}}/edit">Edit</button>
            <button class="btn-xs btn-primary-outline ml-2" *ngIf="userRoles.includes(&quot;regimenEditor&quot;)" (click)="copyRegimen()">Copy</button>
            <button class="btn-xs btn-primary-outline ml-2" *ngIf="regimenObj.get(&quot;type&quot;) === apiClientConstant.Regimen.Type.MAIN" routerLink="../../regimens/new">New</button>
            <button class="btn-xs btn-primary-outline ml-2" *ngIf="regimenObj.get(&quot;type&quot;) === apiClientConstant.Regimen.Type.ALTERNATE" routerLink="../../alternates/new">New</button>
            <button class="btn-xs btn-primary-outline ml-2"><a href="{{conn.getWebAppUrl()}}/user?tab=regimen&amp;username={{user.get(&quot;username&quot;)}}&amp;regimenId={{regimenObj.get(&quot;regimenId&quot;)}}" target="_blank">View</a></button></span></div>
      </div>
    </div>
    <div class="flex-content">
      <div class="col-xs-12 p-4">
        <div class="col-xs-12 flex mt-0 mb-4" *ngIf="voiceIdentifier && isOperatorRolePresent">
          <label class="col-xs-3">Instruction Voice</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8 flex">
            <button class="btn btn-xs mr-1" *ngIf="enbaleResendVoiceInstruction" (click)="sendVoiceInstruction()">Send To User</button>
            <language-voice-recorder [identifier]="voiceIdentifier" [language]="voiceLanguage" (recordingURL)="enbaleResendVoiceInstruction = true"></language-voice-recorder>
          </div>
        </div>
        <div class="col-xs-12 flex mt-0">
          <label class="col-xs-2">Regimen Id</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{regimenObj.get('regimenId')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">Products</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8"><a class="inline product-label night" *ngFor="let product of selectedVariant.products;" routerLink="/products/catalog/{{product.id}}">{{product.get('title')}} &nbsp; [{{product.get('quantity')}}{{product.get('quantityUnit')}}] {{product.get('type') === apiClientConstant.Catalog.Type.SAMPLE ? '(SAMPLE)' : '' }}</a></div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">Skin Type</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{regimenObj.get('skinType')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">Sample Available</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{regimenObj.get('sampleAvailable') ? 'YES' : 'NO'}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">Approval Percentage</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{selectedVariant.approvalPercentage}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">Fixed Price</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{selectedVariant.fixedPrice}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">Number Of Products Limit</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{selectedVariant.numberOfProductsLimit}}</div>
        </div>
        <div class="col-xs-12 flex" *ngIf="regimenObj.get(&quot;class&quot;)">
          <label class="col-xs-2">Regimen Class</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{regimenObj.get('class')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">Concern</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">
            <language-view [languageStringObj]="this.regimenObj.get(&quot;concernsLanguageString&quot;)"></language-view>
          </div>
        </div>
        <div class="col-xs-12 flex" *ngIf="regimenObj.get(&quot;whatToExpect&quot;)">
          <label class="col-xs-2">What to expect</label>
          <div class="col pull-left mt-3">:</div>
          <div class="col-xs-8 mt-3">{{regimenObj.get('whatToExpect')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">Duration</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{regimenObj.get('durationInWeek')}} Weeks</div>
        </div>
        <div class="col-xs-12 mt-3">
          <label class="col-xs-2">Morning</label>
          <div class="col-xs-8 mt-3">
            <div class="col-xs-12">
              <ul class="nav">
                <li *ngFor="let item of selectedVariant.morning; let i = index;">
                  <div class="col-xs-12 product-morning-view">
                    <label class="col-xs-12 pl-0 mt-0 fontL1">{{item.product.get('title')}} ({{item.purpose}}) [{{item.product.get('quantity')}}{{item.product.get('quantityUnit')}}] {{item.product.get('type') === apiClientConstant.Catalog.Type.SAMPLE ? ('(' + item.product.get('type').toUpperCase() + ')') : ''}}</label>
                    <div class="col-xs-12 p-3 pl-0 b-t" *ngIf="item.purposeDescription">{{item.purposeDescription}}</div>
                    <div *ngIf="item.instructionSet && regimenObj.get(&quot;showSummaryView&quot;)">
                      <catalog-instruction-view [instruction]="item.instructionSet" [type]="apiClientConstant.Order.Type.REGIMEN"></catalog-instruction-view>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <label class="col-xs-2 mt-3">Night</label>
          <div class="col-xs-8 mt-3">
            <ul class="nav">
              <li *ngFor="let item of selectedVariant.night; let i = index;">
                <div class="col-xs-12 product-night-view">
                  <label class="col-xs-12 pl-0 fontL1 mt-0">{{item.product.get('title')}} ({{item.purpose}}) [{{item.product.get('quantity')}}{{item.product.get('quantityUnit')}}] {{item.product.get('type') === apiClientConstant.Catalog.Type.SAMPLE ? ('(' + item.product.get('type').toUpperCase() + ')') : ''}}</label>
                  <div class="col-xs-12 p-3 pl-0 b-t" *ngIf="item.purposeDescription">{{item.purposeDescription}}</div>
                  <div *ngIf="item.instructionSet && regimenObj.get(&quot;showSummaryView&quot;)">
                    <catalog-instruction-view [instruction]="item.instructionSet" [type]="apiClientConstant.Order.Type.REGIMEN"></catalog-instruction-view>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xs-12 flex" *ngIf="regimenObj.get(&quot;dietAndLifestyleQuestion&quot;)">
          <label class="col-xs-2">Diet And Lifestyle</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{regimenObj.get("dietAndLifestyleQuestion").get('title')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">Products Price</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{regimenPrice()}} Rs.</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">Discount</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{regimenObj.get("discount")}}%</div>
        </div>
        <div class="col-xs-12 flex" *ngIf="regimenObj.get(&quot;endDate&quot;)">
          <label class="col-xs-2">End Date</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{regimenObj.get("endDate")| amDateFormat: 'MMM DD, YYYY'}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">Note (Not Visible To User)</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{regimenObj.get("note") || '-'}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">Active</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{regimenObj.get('active') ? 'YES' : 'NO'}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">Regimen Link</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8"><a href="{{conn.getWebAppUrl()}}/user/?tab=regimen&amp;username={{user.get(&quot;username&quot;)}}&amp;regimenId={{regimenObj.get(&quot;regimenId&quot;)}}" target="_blank">Click To View</a></div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-2">Regimen Cover Image</label>
          <div class="col pull-left mt-3">:</div>
          <div class="mt-3"><img style="padding-left:15px" height="100px" widht="100px" src="{{selectedVariant.regimenCoverImage}}"/></div>
        </div>
      </div>
      <div class="col-xs-12 no-padder my-3" *ngIf="actionLog.length">
        <div class="col-xs-12 list-header mb-2 pl-4">Change in Regimen</div>
        <div class="col-xs-12" style="height:250px; overflow-y:scroll">
          <div class="col-xs-12 pl-4 my-2" *ngFor="let item of actionLog;">
            <div class="col-xs-4">{{item.get('Action')}}</div>
            <div class="col-xs-4">{{item.get("createdAt")| amDateFormat: 'MMM DD, YYYY hh:mm a'}}</div>
            <div class="col-xs-4">{{item.get('ByUser') ? item.get('ByUser').get('username') : ''}}</div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 no-padder my-3" *ngIf="regimenHistory.length">
        <div class="col-xs-12 list-header mb-2 pl-4">Regimen History</div>
        <div class="col-xs-12" #scrollContaine style="height:250px; overflow-y:scroll" (scroll)="onScroll()">
          <div class="col-xs-12 pl-4" *ngFor="let item of regimenHistory;">
            <div class="col-xs-2"><a class="text-info pointer" href="products/regimen/history/{{item.id}}" target="_blank">{{item.get("createdAt")| amDateFormat: 'MMM DD, YYYY hh:mm a'}}</a></div>
            <div class="col-xs-10 scroll">
              <morning-night-list [regimen]="item"></morning-night-list>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 no-padder my-3" *ngIf="regimenObj.get(&quot;type&quot;) === apiClientConstant.Regimen.Type.MAIN">
        <div class="col-xs-12 list-header mb-2 pl-4">Alternative Regimens<i class="fas fa-plus-square ml-3 pointer" routerLink="alternates/new" *ngIf="userRoles.includes(&quot;regimenEditor&quot;)"></i></div>
        <div class="col-xs-12 no-padder pl-4" *ngFor="let alternateRegimen of alternateRegimens; let i = index;">
          <div class="col-xs-12 my-2"><span class="text-info pointer" routerLink="alternate/{{alternateRegimen.get(&quot;regimenId&quot;)}}">{{alternateRegimen.get('regimenId')}} &nbsp;&nbsp;</span><a class="fa fa-pencil-square-o pointer m-l-xs" routerLink="alternate/{{alternateRegimen.get(&quot;regimenId&quot;)}}/edit" target="_blank"></a></div>
        </div>
      </div>
      <div class="col-xs-12 no-padder my-3" *ngIf="isMainRegimen">
        <div class="col-xs-12 list-header mb-2 pl-4">Trees Having this regimen</div>
        <div class="col-xs-12 my-2 pl-4" *ngFor="let tree of treesHavingThisRegimen;"><a class="text-info" routerLink="/tree/v4/{{tree.get(&quot;type&quot;).toLowerCase()}}/{{tree.get(&quot;name&quot;)}}">
            <div class="col-xs-12"></div>{{tree.get('name')}}</a></div>
      </div>
    </div>
  </div>
</div>
