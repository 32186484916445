
<div class="collapse">
  <div class="head" (click)="showList = !showList" [class]="extraClass" [ngStyle]="{'padding': !fromFollowUp ? '20px 0px' : '0px' }"><span class="flex"><i class="icons {{iconClass}}" *ngIf="iconClass"></i>
      <div class="title" [class]="extraClass">{{title}}&nbsp;</div>
      <div class="title" *ngIf="shortName" [class]="extraClass">({{shortName}})</div></span>
    <div class="dropDownIcon" [ngClass]="{&quot;rotate&quot;: showList}"><i class="fas fa-chevron-right fontL1"></i></div>
  </div>
  <div *ngIf="showList" [ngClass]="{&quot;body&quot;: !fromFollowUp, &quot;followUpSop&quot;: fromFollowUp}">
    <ng-content></ng-content>
  </div>
</div>