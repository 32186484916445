
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/tree/questions">Tree</a></li>
          <li>{{treeObj.get('name')}}</li>
        </ul>
        <div class="pull-right"><span>
            <button class="btn btn-xs" routerLink="routes">Tree Routes</button></span></div>
      </div>
    </div>
    <div class="flex-content">
      <div class="col-xs-12 p-4">
        <div class="col-xs-12 flex mt-0">
          <label class="col-xs-3">Tree</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-6">{{treeObj.get('name')}}</div>
        </div>
        <div class="col-xs-12 mt-4">
          <label class="col-xs-3 mt-0">Common Questions</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-6">
            <div class="col-xs-12" *ngFor="let question of treeObj.get(&quot;questions&quot;); let i = index;">{{(i + 1)}}. {{question.get('uniqueIdentifier')}}: {{question.get('title')}}</div>
          </div>
        </div>
        <div class="col-xs-12 mt-4">
          <button class="btn-info btn-xs" routerLink="edit">Edit Common Question List</button>
          <button class="btn btn-xs ml-3" routerLink="../../trees/questions/new">New Question</button>
        </div>
        <div class="col-xs-12 mt-4">
          <label class="col-xs-3 mt-0">Post Regimen Questions</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-6">
            <div class="col-xs-12 no-padder" *ngFor="let question of regimenObjTree.get(&quot;questions&quot;); let i = index;">{{(i + 1)}}. {{question.get('uniqueIdentifier')}}: {{question.get('title')}}</div>
          </div>
        </div>
        <div class="col-xs-12 mt-4">
          <button class="btn btn-xs" routerLink="postRegimen/{{regimenObjTree.get(&quot;name&quot;)}}/edit" *ngIf="regimenObjTree.id">Edit Post Regimen Question List</button>
          <button class="btn btn-xs ml-3" routerLink="postRegimens/new" *ngIf="!regimenObjTree.id">New Post Regimen Question List</button>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="col-xs-12 list-header mb-2 pl-4 fontR">Create Answer Independent tree<i class="fas fa-plus-square ml-3 pointer" (click)="createTree(false)"></i></div>
        <div class="col-xs-12 pl-4 my-2" *ngFor="let tree of answerIndependentTree">
          <div class="col-xs-3 text-info">{{tree.get('name')}}</div><a class="fa fa-pencil-square-o pointer m-l-xs" routerLink="answer_independent/{{tree.get(&quot;name&quot;)}}/edit" target="_blank"></a>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="col-xs-12 list-header mb-2 pl-4 fontR">Create Answer Dependent tree<i class="fas fa-plus-square ml-3 pointer" routerLink="answer_dependent/new"></i></div>
        <div class="col-xs-12 pl-4 my-2" *ngFor="let tree of answerDependentTree">
          <div class="col-xs-3 text-info pointer" routerLink="answer_dependent/{{tree.get(&quot;name&quot;)}}">{{tree.get('name')}}</div><a class="fa fa-pencil-square-o pointer m-l-xs" routerLink="answer_dependent/{{tree.get(&quot;name&quot;)}}/edit" target="_blank"></a>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="col-xs-12 list-header mb-2 pl-4 fontR">Indications<i class="fas fa-plus-square ml-3 pointer" routerLink="indications/new"></i></div>
        <div class="col-xs-12 pl-4 my-2" *ngFor="let indicationTree of indicationTrees">
          <div class="col-xs-3 text-info pointer" routerLink="indication/{{indicationTree.get(&quot;name&quot;)}}">{{indicationTree.get('name')}}</div><a class="fa fa-pencil-square-o pointer m-l-xs" routerLink="indication/{{indicationTree.get(&quot;name&quot;)}}/edit" target="_blank"></a>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="col-xs-12 list-header mb-2 pl-4 fontR">Treatment Delivery<i class="fas fa-plus-square ml-3 pointer" routerLink="treatment_delivery/new"></i></div>
        <div class="col-xs-12 pl-4 my-2" *ngFor="let treatmentDeliveryTree of treatmentDeliveryTrees">
          <div class="col-xs-3 text-info pointer" routerLink="treatment_delivery/{{treatmentDeliveryTree.get(&quot;name&quot;)}}">{{treatmentDeliveryTree.get('name')}}</div><a class="fa fa-pencil-square-o pointer m-l-xs" routerLink="treatment_delivery/{{treatmentDeliveryTree.get(&quot;name&quot;)}}/edit" target="_blank"></a>
        </div>
      </div>
      <div class="col-xs-12 mb-3">
        <div class="col-xs-12 list-header mb-2 pl-4 fontR">Actions<i class="fas fa-plus-square ml-3 pointer" routerLink="actions/new"></i></div>
        <div class="col-xs-12 pl-4 my-2" *ngFor="let actionTree of actionTrees">
          <div class="col-xs-3 text-info pointer" routerLink="action/{{actionTree.get(&quot;name&quot;)}}">{{actionTree.get('name')}}</div><a class="fa fa-pencil-square-o pointer m-l-xs" routerLink="action/{{actionTree.get(&quot;name&quot;)}}/edit" target="_blank"></a>
        </div>
      </div>
    </div>
  </div>
</div>