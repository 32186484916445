
<div class="col-xs-12 pl-4" *ngIf="order.get(&quot;notes&quot;)?.length">
  <div class="col-xs-12 p-0">
    <label class="col-xs-6">Message</label>
    <label class="col-xs-3">Created By</label>
    <label class="col-xs-3">Created At</label>
  </div>
  <div class="col-xs-12 pl-0 my-2" *ngFor="let note of order.get(&quot;notes&quot;) || []">
    <div class="col-xs-6">{{note.message}}</div>
    <div class="col-xs-3">{{note.byUser}}</div>
    <div class="col-xs-3">{{note.createdAt| amDateFormat: 'MMM DD YYYY,hh:mm a'}}</div>
  </div>
</div>
<div class="col-xs-12 pl-4">
  <label class="col-xs-12 p-0">Add Note</label>
  <div class="col-xs-4">
    <select class="form-control" [(ngModel)]="note" name="editNote">
      <option *ngFor="let note of noteOptions" value="{{note.value}}">{{note.display}}</option>
    </select>
  </div>
  <div class="col-xs-4">
    <textarea class="form-control" [(ngModel)]="note" name="note" placeholder="Add Notes"></textarea>
  </div>
  <div class="col-xs-1">
    <button class="btn-xs mt-2" [disabled]="!note" (click)="addOrderNote()">Add</button>
  </div>
</div>