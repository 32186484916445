
<div class="col-xs-12 title"><em class="fas fa-stopwatch pr-3"></em><span class="fw-700 fontL1">Follow-ups</span>
  <div class="label label-info ml-1" *ngFor="let label of followUp?.get('labels') || []; let i = index;">{{label}}</div>
  <div class="label label-primary ml-2 fontS3 p-1" *ngFor="let label of attendToChatCache?.get('label');">{{label}}</div>
</div>
<div class="col-xs-12">
  <div class="col-xs-12 p-0 mb-3" *ngIf="followUp"><span class="bold fontL">{{ followUp.get('effectiveFollowUpDate') ? (followUp.get('effectiveFollowUpDate') | amDateFormat: 'MMM DD') : (followUp.get('nextFollowUpDate') | amDateFormat: 'MMM DD')}} (Effective)</span><span class="pL10">{{ followUp && followUp.get('markedReadyOn') ? (followUp.get('markedReadyOn')| amDateFormat: 'MMM DD') : ''}} &nbsp; (Ready since)</span><a class="pl-2" *ngIf="followUp">( Done:  {{followUp.get('followUpCount')-1}} )</a><a class="pl-2" *ngIf="followUp">( {{followUp.get('allocatedDoctor')?.get('username')}} )</a>
    <user-on-antibiotic class="ml-3" [user]="patient" [showText]="[showText]"></user-on-antibiotic>
  </div>
  <div class="col-xs-12 p-0 mb-3 fontS2" *ngIf="followUp && followUp.get(&quot;products&quot;) && followUp.get(&quot;products&quot;).length">
    <div class="label mr-2 inline p-2" *ngFor="let product of followUp.get('products'); let i = index;" [ngClass]="{&quot;label-primary&quot;: product.purchased, &quot;label-danger&quot;: !product.purchased }">{{product.title}}<i class="fa fa-trash-o ml-2" (click)="removeFollowUpProduct(followUp, i)"></i></div>
  </div>
  <div class="col-xs-12 p-0">
    <button class="btn-xs btn-primary-outline mr-3 mb-2" (click)="toggleReadyFlag()" *ngIf="followUp && !followUp.get(&quot;ready&quot;)">Not Ready</button>
    <button class="btn-xs btn-primary-outline mr-3 mb-2" *ngIf="followUp && followUp.get(&quot;ready&quot;)" (click)="openFollowUpModal(appConfig.FollowUp.PopupType.MAKE_NEW_FOLLOWUP)">Done & Create New</button>
    <button class="btn-xs btn-primary-outline mr-3 mb-2" *ngIf="!followUp" (click)="openFollowUpModal(appConfig.FollowUp.PopupType.ADD_OR_EDIT_FOLLOWUP)">Add</button>
    <button class="btn-xs btn-primary-outline mr-3 mb-2" *ngIf="followUp" (click)="openFollowUpModal(appConfig.FollowUp.PopupType.ADD_OR_EDIT_FOLLOWUP)">Edit</button>
    <button class="btn-xs btn-primary-outline mr-3 mb-2" (click)="openFollowUpModal(appConfig.FollowUp.PopupType.HISTORY_FOLLOWUP)">FollowUp History</button>
    <button class="btn-xs btn-primary-outline mr-3 mb-2" *ngIf="followUp" (click)="openFollowUpModal(appConfig.FollowUp.PopupType.SKIP)">Skip</button>
    <button class="btn-xs btn-primary-outline mr-3 mb-2" *ngIf="followUp && (internalUserRoles?.includes(&quot;admin&quot;) || internalUserRoles?.includes(&quot;adminDoctor&quot;))" (dblclick)="deleteFollowup()">Delete Followup</button>
    <button class="btn-xs btn-primary-outline mr-3 mb-2"><a class="clr-primary" routerLink="/patient" [queryParams]="{ username: patient.get(&quot;username&quot;) }" target="_blank">Patient History</a></button>
  </div>
  <div class="col-xs-12 p-0">
    <button class="btn-xs btn-primary-outline" (click)="createImmediateDoctorFollowup()">48hr Doctor followup</button>
    <button class="btn-xs btn-primary-outline ml-3" *ngIf="followUp" (click)="openFollowUpModal(appConfig.FollowUp.PopupType.WAITING_FOR_IMAGE)">Request Instant Checkup / Image Upload</button>
  </div>
</div>
