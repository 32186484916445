import { Component, ElementRef, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { v4 as uuid } from 'uuid';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { DataUploadModal } from 'src/components/modal/dataUploadModal';
import { ApiClientConstant, ApiConnector, Table } from 'api-client';
import { TabNamingService } from '../../../services/tab-naming-service';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../app.config';
import { Broadcaster } from '../../../components/broadcaster';
import { AudioRecorderComponent } from '../../../components/audio-recorder/audio-recorder.component';
import { RegimenEditComponent } from '../../product/regimen/edit/regimen-edit.component';
import { VoiceRecordingComponent } from '../../../components/voice-recording/voice-recording.component';
import { RegimenProductsEditComponent } from '../../../components/regimen-products-edit/regimen-products-edit.component';
import { WindowRefService } from '../../../services/window-ref-service';
import { FollowUpDialog } from '../../chat/view/follow-up';
import { ConsentFormComponent } from '../../../components/consent-form/consent-form.component';

@Component({ selector: 'order-approval', templateUrl: './order-approval.html', styleUrls: ['./order-approval.scss'] })
export class OrderApprovalComponent implements OnDestroy {
  subscriptions: Array<Subscription> = [];
  @ViewChild('content', { static: false }) content: ElementRef;
  @ViewChild('contentOne', { static: false }) contentOne: ElementRef;
  @ViewChild('regimenSelection', { static: false }) regimenSelection: ElementRef;
  @ViewChild('newChatView', { static: false }) newChatView: ElementRef;
  @ViewChild(AudioRecorderComponent) audioRecorderComponent: AudioRecorderComponent;
  @ViewChild(RegimenProductsEditComponent, { static: false }) regimenProductsEditComponent: RegimenProductsEditComponent;
  @ViewChild(RegimenEditComponent, { static: false }) regimenEditComponent: RegimenEditComponent;
  @ViewChild('VoiceRecordingComponent') VoiceRecordingComponent: VoiceRecordingComponent;
  @ViewChild('audioPlayer') audioPlayer: ElementRef;
  @ViewChild('consentForm') consentForm: ConsentFormComponent;
  products: Array<any>;
  isAudio: boolean = true;
  showNewUI: boolean = true;
  activeRegimens: Array<any>;
  copyFromRegimenId: string;
  cancelReason: string;
  isBtnDisabled: boolean = true;
  selectedRegimenID: any;
  regimen: any;
  femaleHormonal: string = '';
  regimenList: any;
  user: any;
  username: string;
  audioUrl: string;
  order: any;
  selectedChatType: 'userChat' | 'chatSummary' = 'chatSummary';
  orderStage: string;
  regimenDirty: boolean;
  sopAnswers: Array<{ question: string; answer: string; currentSOPStates: Array<any>; }> = [];
  waitingForImageConfirmation: boolean;
  messageData: { message: string } = { message: '' };
  formController: UntypedFormControl = new UntypedFormControl();
  selectedWaitingForImageReason: string;
  selectedWaitingForImageReasonsTextArea: string;
  regimenUpdated: boolean;
  sopRegimenIds: Array<any>;
  pregnentMessages: Array<string> = ['I am pregnant'];
  breastfeedingMessages: Array<string> = ['I am a new mom and breastfeeding', 'Yes, I am breastfeeding'];
  extraData: any = {
    married: '-',
    pregnancy: '',
  };
  sensitiveSkin: string;
  mainRegimenIds: Array<any>;
  regimenId: string;
  isProductOrder: boolean;
  audioRecordedLink: string;
  audioNoteFromComponent: string;
  audioNote: string;
  audioMessageSent: boolean;
  audioMessageRecorded: boolean;
  shouldCallUserBeforeApproval: boolean = false;
  currentSOPStates: Array<{
    stateId: string;
    reply?: string;
    questionId?: string;
    message?: string;
    inputs?: Array<{ display: string; value: string; }>;
  }>;
  regimenUpdatedThroughSop: boolean;
  disableActionButtons: boolean;
  selectedConcern: string;
  concerns: Array<any> = [];
  selectedConcernVoiceRecordingPrefered: string;
  selectedConcernVoiceRecordingEn: string;
  enableCustomRecordings: boolean = true;
  viewType: 'treeInteractions' | 'humanInteractions' = 'treeInteractions';
  enableRegimenSop: boolean = true;
  voiceRecordingComp: boolean = true;
  callButtonClicked: boolean;
  oldOrders: Array<any> = [];
  showCustomRecording: boolean = true;
  currentUser: any;
  drObservation: any = {};
  enableDrObservationExperiment: boolean;
  orderMedicalRecord: any;
  callAlreadyDone: boolean = false;
  voiceNoteAlreadySent:boolean = false;
  showVoiceSection: boolean = true;
  showSopSection: boolean = true;
  showNotesSection: boolean = true;
  isFinasterideConsent: boolean = false;
  selectedHairFallStages: Array<string> = ['Stage5', 'Stage4', 'Stage3'];
  disableRegimenEdit: boolean = true;
  isIsotretConsent: boolean = false;
  userCommunicationLanguage: string;

  maleVoiceNodeExpEnabled: boolean;
  quickActions: Array<{section: string, icon: string, name: string}> = [
    { section: 'chatSection', icon: 'assets/images/quickActionChat.png', name: 'Chat' },
    { section: 'regimenSection', icon: 'assets/images/quickActionRegimen.png', name: 'Regimen Selection' },
    { section: 'voiceRecordingSection', icon: 'assets/images/quickActionRecording.png', name: 'Voice Note' }];
  constructor(private conn: ConnectionService,
    private route: ActivatedRoute,
    public appConfig: AppConfig,
    private broadcast: Broadcaster,
    private windowService: WindowRefService,
    private tabNameService: TabNamingService,
    private dialog: MatDialog,
    private windowRef: WindowRefService) {
  }

  async ngOnInit(): Promise<void> {
    if (!this.windowService.isCurrentDeviceIsLaptop) {
      this.broadcast.broadcast('NOTIFY', {
        message: 'This Page is not supported in Mobile or Tab',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
      return;
    }
    await this.subscribeToUrlQueryParams();
    if (this.order.get('type') !== ApiClientConstant.Order.Type.REGIMEN) {
      this.isProductOrder = true;
      this.quickActions[1] = {
        section: 'productSection',
        icon: 'src/assets/images/quickActionRegimen.png',
        name: 'Products',
      };
    }
    if (this.order.get('type') === ApiClientConstant.Order.Type.REGIMEN) {
      this.disableActionButtons = true;
    }
    if (this.order.get('regimen')?.get('optedForDoctorCall') === false
      || this.order.get('regimen')?.get('optedForDoctorCall') === true) {
      this.showCustomRecording = false;
      this.enableCustomRecordings = false;
    }
    if (this.order.get('regimen')?.get('optedForDoctorCall') === false
      || this.order.get('type') === ApiClientConstant.Order.Type.PRODUCT) {
      this.showCustomRecording = false;
      this.enableCustomRecordings = false;
    }
    this.currentUser = this.conn.getCurrentUser();
    this.broadcast.broadcast('ChatUserUpdate', { user: this.currentUser });
    this.maleVoiceNodeExpEnabled = !!(await this.conn.findExperiment({ where: { name: 'male_doctor_voice_experiment', enable: true } }));
    this.updateConcerns();
    if (this.order.get('regimen')?.get('optedForDoctorCall') === true && !this.callButtonClicked) {
      const userCalledByDoctor = await ApiConnector.findOne(Table.CallLog, {
        where: {
          order: this.order,
          direction: 'outgoing',
          duration: { $gte: 10 },
          status: 'completed',
          from: `0${this.order.get('allocatedDoctor').get('exotelNumber')}`,
        },
      });
      if (userCalledByDoctor) {
        this.callAlreadyDone = true;
      }
    }
    const isOrderSentVoiceNote = await ApiConnector.findOne(Table.OrderChat, {
      where: {
        order: this.order,
        Owner: 'DOCTOR',
        Mode: 'AUDIO',
      },
    });
    if (isOrderSentVoiceNote) {
      this.voiceNoteAlreadySent = true;
    }
    await this.checkDoctorCanCompleteOrder();
  }
  addAudioPlayer(): void {
    this.audioPlayer.nativeElement.innerHTML = '';
    const audio = this.windowRef.nativeWindow.document.createElement('audio');
    audio.setAttribute('controls', true);
    audio.setAttribute('class', 'w25 audio mB20');
    let source = '';
    if (this.selectedConcernVoiceRecordingPrefered) {
      source = this.selectedConcernVoiceRecordingPrefered;
    } else {
      source = this.selectedConcernVoiceRecordingEn;
    }
    audio.innerHTML = `<source src = ${source}>`;
    const div = this.windowRef.nativeWindow.document.createElement('div');
    div.setAttribute('class', 'flex');
    div.appendChild(audio);
    this.audioPlayer.nativeElement.appendChild(div);
  }
  subscribeToUrlQueryParams(): void {
    this.subscriptions.push(this.route.parent.params.subscribe(async () => {
      if (this.route.parent.snapshot.data.order) {
        this.order = this.route.parent.snapshot.data.order;
        this.orderStage = this.order.get('stage');
        await this.order.get('user').fetch();
        this.user = this.order.get('user');
        this.userCommunicationLanguage = this.user.get('communicationLanguagePreference') || this.user.get('languagePreference');
        await this.findUserMarriedStatus(this.user);
        await this.findUserPregnancyStatus(this.user);
        await this.findFemaleHormonalFromActiveConsultationSession(this.user);
        this.tabNameService.setTitle('', this.user.get('PatientName'), 'Order');
        this.username = this.user.get('username');
        await this.fetchRegimen();
        await this.fetchProducts();
        await this.checkIfUserHasOptedForDoctorCallBeforePlacingOrder();
        if (this.order.get('stage') !== ApiClientConstant.Order.Stage.DOCTOR_APPROVAL_PENDING) {
          this.isBtnDisabled = false;
          this.enableRegimenSop = false;
          setTimeout(() => {
            this.regimenEditComponent.disableButton = true;
          }, 0);
        }
        if (['variant_1999', 'variant_4999', 'variant_2999'].includes(this.order.get('regimen')?.get('selectedVariantId'))) {
          this.enableRegimenSop = false;
          this.isBtnDisabled = false;
          this.disableActionButtons = false;
          this.disableRegimenEdit = false;
        }
        if (this.enableRegimenSop && this.order.get('type') === ApiClientConstant.Order.Type.REGIMEN) {
          this.currentSOPStates = [{ stateId: 'v5_regimenSOP:' }];
          await this.triggerRegimenSopTree();
        }
        await this.checkIfSendingRecordingIsComp();
        await this.getPreviousOrderOfSameRegimenClass();
        await this.checkPersonalizedVoiceNoteEligibility();
      }
    }));
  }

  async captureOrder(): Promise<void> {
    if (this.order.get('doctorConfirmed')) {
      this.broadcast.broadcast('NOTIFY', { message: 'Order Already captured', type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    if (this.currentUser.get('type') === ApiClientConstant.User.Type.OPERATOR) {
      this.broadcast.broadcast('NOTIFY', { message: 'cannot capture order', type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    this.order.set('doctorConfirmed', true);
    this.order.set('allocatedDoctor', this.currentUser);
    try {
      await this.order.save();
    } catch (error) {
      this.broadcast.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  async checkIfSendingRecordingIsComp(): Promise<void> {
    if (this.shouldCallUserBeforeApproval) {
      this.enableCustomRecordings = false;
    }
  }

  async openFollowUpModal(SelectedPopUpType: string): Promise<void> {
    this.dialog.open(FollowUpDialog, {
      data: {
        SelectedPopUpType,
        userId: this.user.get('username'),
        chatUser: this.user,
      },
    });
  }

  cancelOrder(template: TemplateRef<any>): void {
    this.dialog.open(template).afterClosed().subscribe(async (isCancel: boolean) => {
      if (!isCancel) return;
      await this.saveOrder(ApiClientConstant.Order.Stage.CANCELED);
      this.broadcast.broadcast('NOTIFY', { message: 'Order Cancelled', type: this.appConfig.Shared.Toast.Type.WARNING });
    });
  }

  async cancelOrderNew(): Promise<void> {
    if (!this.cancelReason) {
      this.enableCustomRecordings = false;
      this.showCustomRecording = false;
      const dialogRef = this.dialog.open(DataUploadModal, {
        data: {
          title: 'Reason for cancelling order',
          minCharLength: 10,
        },
      });
      dialogRef.afterClosed().subscribe(async (response: string) => {
        if (response) {
          this.cancelReason = response;
          await this.cancelOrderForNewUI();
        }
      });
    } else {
      await this.cancelOrderForNewUI();
    }
  }
  async cancelOrderForNewUI(): Promise<void> {
    if (!this.cancelReason) {
      this.broadcast.broadcast('NOTIFY', { message: 'Cancel reason is mandatory',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    if (!this.audioMessageRecorded) {
      const elem = this.windowService.nativeWindow.document.getElementById('voiceRecordingSection');
      elem.scrollIntoView();
      this.broadcast.broadcast('NOTIFY', { message: 'Sending Audio while canceling is compulsory',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    if (!this.audioMessageSent && this.audioMessageRecorded) {
      await this.saveRecordingNew();
    }
    this.order.set('stage', this.appConfig.Shared.Order.Stage.CANCELED);
    const notes = this.order.get('notes') || [];
    const message = `Cancel Reaosn: ${this.cancelReason}`;
    notes.push({ message, byUser: this.conn.getCurrentUser().get('username'), createdAt: new Date().toISOString() });
    this.order.set('notes', notes);
    try {
      await this.order.save();
      await this.saveOrderChatAndSendNotification();
      this.broadcast.broadcast('NOTIFY', { message: 'Order Canceled Successfully', type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (error) {
      this.broadcast.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.SUCCESS });
    }
  }

  private async saveOrderNew(stage: string, context?: { images: Array<string> }): Promise<void> {
    if (!this.audioMessageSent && this.audioMessageRecorded) {
      await this.saveRecordingNew();
    }
    if (stage === this.appConfig.Shared.Order.Stage.WAITING_FOR_IMAGE) {
      await this.order.save({ stage }, { context });
      return;
    }
    if (this.isIsotretConsent) {
      await this.consentForm.triggerIsotretConsentForm();
    }
    if (this.isFinasterideConsent) {
      await this.consentForm.triggerFinasterideConsentForm();
    }
    await this.order.save({ stage });
    this.broadcast.broadcast('NOTIFY', { message: 'Order Approved Successfully', type: this.appConfig.Shared.Toast.Type.SUCCESS });
    this.orderStage = stage;
    delete this.waitingForImageConfirmation;
  }

  private async saveOrder(stage: string, context?: { images: Array<string> }): Promise<void> {
    if (!this.audioMessageSent && this.audioMessageRecorded) {
      await this.saveRecording();
    }
    if (this.isIsotretConsent) {
      await this.consentForm.triggerIsotretConsentForm();
    }
    if (this.isFinasterideConsent) {
      await this.consentForm.triggerFinasterideConsentForm();
    }
    try {
      if (stage === ApiClientConstant.Order.Stage.WAITING_FOR_IMAGE) {
        await this.order.save({ stage }, { context });
      } else {
        if (this.enableDrObservationExperiment) {
          this.orderMedicalRecord = await this.conn.findOrderMedicalRecordForOrder(this.order);
          await this.orderMedicalRecord.save({ drObservation: this.drObservation });
        }
        await this.order.save({ stage });
      }
      this.broadcast.broadcast('NOTIFY', { message: 'Order Approved Successfully', type: this.appConfig.Shared.Toast.Type.SUCCESS });
      this.orderStage = stage;
      delete this.waitingForImageConfirmation;
      if (this.order.get('type') === ApiClientConstant.Order.Type.REGIMEN) this.regimenEditComponent.disableButton = true;
    } catch (error) {
      if (this.order.get('type') === ApiClientConstant.Order.Type.REGIMEN) this.regimenEditComponent.disableButton = false;
      this.broadcast.broadcast('NOTIFY', { message: error.toString(), type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  markOrderWaitingForImage(): void {
    this.waitingForImageConfirmation = !this.waitingForImageConfirmation;
    this.contentOne.nativeElement.scrollTop = 0;
  }
  markOrderWaitingForImageNew(): void {
    this.waitingForImageConfirmation = !this.waitingForImageConfirmation;
    const elem = this.windowService.nativeWindow.document.getElementById('contentOne');
    elem.scrollIntoView();
  }

  async confirmWaitingForImage(): Promise<void> {
    if (this.order.get('type') === ApiClientConstant.Order.Type.REGIMEN) {
      await this.markRegimenOrderSkipDrApproval();
      return;
    }
    const notes = this.order.get('notes') || [];
    const context: { images: Array<string> } = { images: [] };
    let message = this.selectedWaitingForImageReason;
    if (this.selectedWaitingForImageReasonsTextArea) {
      message += `| ${this.selectedWaitingForImageReasonsTextArea}`;
    }
    notes.push({ message, byUser: this.conn.getCurrentUser().get('username'), createdAt: new Date().toISOString() });
    this.appConfig.Shared.Order.WaitingForImageReasons.forEach((reason: { key: string, images: Array<string> }) => {
      if (this.selectedWaitingForImageReason === reason.key) {
        reason.images.forEach((image: string) => {
          if (!context.images.includes(image)) {
            context.images.push(image);
          }
        });
      }
    });
    this.order.set('notes', notes);
    await this.saveOrder(ApiClientConstant.Order.Stage.WAITING_FOR_IMAGE, context);
  }
  async confirmWaitingForImageNew(): Promise<void> {
    if (this.order.get('type') === ApiClientConstant.Order.Type.REGIMEN) {
      await this.markRegimenOrderSkipDrApproval();
      return;
    }
    const notes = this.order.get('notes') || [];
    const context: { images: Array<string> } = { images: [] };
    let message = this.selectedWaitingForImageReason;
    if (this.selectedWaitingForImageReasonsTextArea) {
      message += `| ${this.selectedWaitingForImageReasonsTextArea}`;
    }
    notes.push({ message, byUser: this.conn.getCurrentUser().get('username'), createdAt: new Date().toISOString() });
    this.appConfig.Shared.Order.WaitingForImageReasons.forEach((reason: { key: string, images: Array<string> }) => {
      if (this.selectedWaitingForImageReason === reason.key) {
        reason.images.forEach((image: string) => {
          if (!context.images.includes(image)) {
            context.images.push(image);
          }
        });
      }
    });
    this.order.set('notes', notes);
    await this.saveOrderNew(ApiClientConstant.Order.Stage.WAITING_FOR_IMAGE, context);
  }
  async findUserMarriedStatus(user: any): Promise<any> {
    const femaleMarriedRow = await this.conn.findLatestResponseForFields(user, ['FemaleMarried', 'followUpMarried']);
    if (femaleMarriedRow) {
      this.extraData.married = femaleMarriedRow;
    }
  }
  async findFemaleHormonalFromActiveConsultationSession(user: any): Promise<void> {
    const where = { user, archive: false };
    const consultationSessions = await this.conn.findConsultationSession({
      where,
      project: ['FemaleHormonal', 'SensitiveSkin'],
      descending: 'createdAt',
    });
    if (!consultationSessions.length) {
      return;
    }
    const femaleHormonalUniqueAnswers = new Set();
    consultationSessions.forEach((session: any) => {
      femaleHormonalUniqueAnswers.add(session.get('FemaleHormonal'));
    });
    this.sensitiveSkin = consultationSessions[0].get('SensitiveSkin');
    this.femaleHormonal = Array.from(femaleHormonalUniqueAnswers).toString();
  }

  async findUserPregnancyStatus(user: any): Promise<any> {
    const pregnancyQuestionIds = [
      'FemalePregOrNewMom',
      'followUpPregaNewMom',
      'followUpBreastFeeding',
      'followUpPlanningPragnancy',
      'followUpPregnant',
    ];
    const response = await this.conn.findLatestResponseForFields(user, pregnancyQuestionIds);
    if (response) {
      this.extraData.pregnancy = response;
    }
  }

  afterSaveRegimen(regimen: any): void {
    this.regimen = regimen;
    this.regimenUpdated = true;
    this.order.set('regimen', regimen);
  }
  async saveRegimenAndApprove(): Promise<void> {
    const checkDoctorCanCompleteOrder = await this.checkDoctorCanCompleteOrder();
    if (!checkDoctorCanCompleteOrder) {
      return;
    }
    this.disableActionButtons = true;
    const allChecksPassed = await this.checkAllBeforeApprovalChecks();
    if (!allChecksPassed) {
      this.disableActionButtons = false;
      return;
    }
    if (this.order.get('type') === ApiClientConstant.Order.Type.REGIMEN
      && this.regimenEditComponent.regimenObj.dirtyKeys().length) {
      const error = await this.regimenEditComponent.saveRegimen();
      if (error) {
        this.broadcast.broadcast('NOTIFY', {
          message: error.message || error,
          type: this.appConfig.Shared.Toast.Type.ERROR,
        });
        this.disableActionButtons = false;
        return;
      }
    }
    if (this.order.get('regimen')?.dirtyKeys().length <= 1
      || this.order.get('type') !== ApiClientConstant.Order.Type.REGIMEN) {
      try {
        await this.saveOrder(ApiClientConstant.Order.Stage.DR_VERIFIED);
      } catch (error) {
        this.broadcast.broadcast('NOTIFY', { message: error.toString(), type: this.appConfig.Shared.Toast.Type.ERROR });
        this.disableActionButtons = false;
      }
    }
  }

  async saveRegimenAndApproveNew(): Promise<void> {
    const checkDoctorCanCompleteOrder = await this.checkDoctorCanCompleteOrder();
    if (!checkDoctorCanCompleteOrder) {
      return;
    }
    this.isBtnDisabled = true;
    if (!this.audioMessageSent && this.audioMessageRecorded && !this.enableCustomRecordings) {
      await this.saveRecordingNew();
    }
    if (!this.audioMessageSent && this.enableCustomRecordings && this.selectedConcern) {
      await this.saveOrderChatAndSendNotification();
    }

    const allChecksPassed = await this.checkAllBeforeApprovalChecksNew();
    if (!allChecksPassed) {
      this.isBtnDisabled = false;
      return;
    }
    if (this.order.get('type') === ApiClientConstant.Order.Type.REGIMEN && this.regimenProductsEditComponent.isRegimenSelected) {
      try {
        if (!this.regimenProductsEditComponent.isRegimenSave) {
          await this.regimenProductsEditComponent.saveRegimenOrderApproval();
        }
        this.broadcast.broadcast('NOTIFY', { message: 'Regimen saved', type: this.appConfig.Shared.Toast.Type.SUCCESS });
      } catch (error) {
        this.broadcast.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
        this.isBtnDisabled = false;
        return;
      }
    }
    try {
      await this.saveOrderNew(ApiClientConstant.Order.Stage.DR_VERIFIED);
      if (this.enableCustomRecordings && this.selectedConcern) {
        await this.saveOrderChatAndSendNotification();
      }
      if (this.audioUrl) {
        this.VoiceRecordingComponent.resetAudio();
      }
      delete this.audioUrl;
      this.broadcast.broadcast('NOTIFY', { message: 'Order saved', type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (error) {
      this.broadcast.broadcast('NOTIFY', { message: error.toString(), type: this.appConfig.Shared.Toast.Type.ERROR });
      this.isBtnDisabled = false;
    }
  }
  async checkAllBeforeApprovalChecksNew(): Promise<boolean> {
    if (this.order.get('type') !== ApiClientConstant.Order.Type.REGIMEN) return true;
    if (['variant_1999', 'variant_4999', 'variant_2999'].includes(this.order.get('regimen')?.get('selectedVariantId'))
      && this.order.get('regimen')?.get('products').length < 5) {
      this.broadcast.broadcast('NOTIFY', {
        message: '5 products to be sent for the current variant',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
      return false;
    }
    if (!this.regimenProductsEditComponent.isRegimenSelected) {
      this.broadcast.broadcast('NOTIFY', {
        message: 'Select regimen from the options given',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
      return false;
    }
    if (!this.callAlreadyDone && this.order.get('regimen')?.get('optedForDoctorCall') === true && !this.callButtonClicked) {
      const userCalledByDoctor = await ApiConnector.findOne(Table.CallLog, {
        where: {
          createdAt: { $gte: this.order.get('actualCreatedAt') },
          direction: 'outgoing',
          from: `0${this.order.get('allocatedDoctor').get('exotelNumber')}`,
          to: [`0${this.user.get('alternateNumber')}`, `0${this.user.get('MobileNumber')}`],
        },
      });
      if (!userCalledByDoctor) {
        this.broadcast.broadcast('NOTIFY', {
          message: 'Calling User is Compulsory',
          type: this.appConfig.Shared.Toast.Type.ERROR,
        });
        return false;
      }
    }
    if (!this.voiceNoteAlreadySent && (!this.audioMessageRecorded && !this.selectedConcern)
      && (this.voiceRecordingComp || this.order.get('regimen')?.get('optedForDoctorCall') === false)) {
      this.broadcast.broadcast('NOTIFY', { message: 'Sending Voice Message is Compulsory for this user',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return false;
    }
    return true;
  }

  async checkAllBeforeApprovalChecks(): Promise<boolean> {
    if (this.order.get('type') !== ApiClientConstant.Order.Type.REGIMEN) return true;
    if (['variant_1999', 'variant_4999', 'variant_2999'].includes(this.order.get('regimen')?.get('selectedVariantId'))
      && this.order.get('regimen')?.get('products').length < 5) {
      this.broadcast.broadcast('NOTIFY', {
        message: '5 products to be sent for the current variant',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
      return false;
    }
    if (!this.regimenEditComponent.editReason && this.regimenEditComponent.regimenObj.dirtyKeys().length > 1) {
      this.broadcast.broadcast('NOTIFY', {
        message: 'Select Reason of Regimen Edit',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
      return false;
    }
    if (!this.callAlreadyDone && this.order.get('regimen')?.get('optedForDoctorCall') === true && !this.callButtonClicked) {
      const userCalledByDoctor = await ApiConnector.findOne(Table.CallLog, {
        where: {
          createdAt: { $gte: this.order.get('actualCreatedAt') },
          direction: 'outgoing',
          from: `0${this.order.get('allocatedDoctor').get('exotelNumber')}`,
          to: [`0${this.user.get('alternateNumber')}`, `0${this.user.get('MobileNumber')}`],
        },
      });
      if (!userCalledByDoctor) {
        this.broadcast.broadcast('NOTIFY', {
          message: 'Calling User is Compulsory',
          type: this.appConfig.Shared.Toast.Type.ERROR,
        });
        return false;
      }
    }
    if (!this.voiceNoteAlreadySent
      && !this.audioMessageSent
      && (this.voiceRecordingComp || this.order.get('regimen')?.get('optedForDoctorCall') === false)) {
      this.broadcast.broadcast('NOTIFY', { message: 'Sending Voice Message is Compulsory for this user',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return false;
    }
    if (this.enableDrObservationExperiment) {
      if (!this.drObservation.addressConcern
        || !this.drObservation.specialConditionAddress
        || !this.drObservation.patientQueryAddressed
        || !this.drObservation.whatExcept) {
        this.broadcast.broadcast('NOTIFY', {
          message: 'specialConditionAddress or patientQueryAddressed are missing',
          type: this.appConfig.Shared.Toast.Type.ERROR,
        });
        return false;
      }
    }
    return true;
  }

  private async fetchRegimen(): Promise<void> {
    if (!this.order.get('regimen')) {
      return;
    }
    await this.order.get('regimen').fetch();
    this.regimen = await this.conn.getRegimen(this.order.get('regimenId'));
  }

  private async checkIfUserHasOptedForDoctorCallBeforePlacingOrder(): Promise<void> {
    const numberOfPendingCallsForUser = await this.conn.findNumberOfRequestedPendingCallForUser(this.user);
    if (numberOfPendingCallsForUser) {
      this.shouldCallUserBeforeApproval = true;
    }
  }

  private async fetchProducts(): Promise<void> {
    if (this.order.get('type') === ApiClientConstant.Order.Type.REGIMEN) {
      return;
    }
    this.products = this.order.get('productInfo');
    this.activeRegimens = (await this.conn.findRegimens({
      where: { forUser: this.user, active: true },
      project: ['morning', 'night', 'class', 'regimenId'],
    })).map((each: any) => each.toJSON());
    this.activeRegimens.forEach((activeRegimen: any) => {
      [...activeRegimen.morning, ...activeRegimen.night].forEach((product_: any) => {
        const product = product_;
        product.productName = product.productName?.split(' [')[0];
        product.onHold = product.onHold || product.instructionSet.title.includes('hold');
      });
    });
  }

  async sendTextMessage(): Promise<void> {
    const orderChat = await this.conn
      .saveOrderMessage({
        userId: this.user.get('username'),
        user: this.user,
        order: this.order,
        msg: this.messageData.message,
        mode: this.appConfig.Shared.Assistant.Mode.WITH_TEXT_REPLY,
        uniqueId: `${this.user.id}-${this.order.id}-${new Date().getTime()}`,
        context: {},
        questionId: 'aVoiceMessageFromDoctor',
      });
    await this.sendNotification(ApiClientConstant.Notification.Type.Text, orderChat.id);
    delete this.messageData.message;
    this.broadcast.broadcast('NOTIFY', { message: 'Message Sent', type: this.appConfig.Shared.Toast.Type.SUCCESS });
  }

  async saveOrderChatAndSendNotification(): Promise<void> {
    if (!this.audioRecordedLink) {
      this.broadcast.broadcast('NOTIFY', { message: 'Audio Not Available', type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    try {
      const orderChat = await this.conn.saveOrderMessage({
        userId: this.username,
        msg: 'No AudioNote Available',
        user: this.user,
        order: this.order,
        mode: this.appConfig.Shared.Assistant.Mode.AUDIO,
        metadata: `audioPath=${this.audioRecordedLink}`,
        uniqueId: `${this.user.id}${this.order.id}${new Date().getTime()}`,
        context: {},
      });
      await this.sendNotification(ApiClientConstant.Notification.Type.Audio, orderChat.id);
      this.broadcast.broadcast('NOTIFY', {
        message: 'Audio Message Sent Successfully',
        type: this.appConfig.Shared.Toast.Type.SUCCESS,
      });
      this.audioMessageSent = true;
      delete this.audioRecordedLink;
      delete this.audioNote;
    } catch (error) {
      this.broadcast.broadcast('NOTIFY', {
        message: error.message || error,
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
    }
  }

  async sendNotification(type: string, orderChatId: string): Promise<void> {
    const notification = new Table.Notification();
    if (type === ApiClientConstant.Notification.Type.Audio) {
      notification.set('audioURL', this.audioRecordedLink);
      notification.set('audioImageURL', this.order.get('allocatedDoctor')?.get('doctorDisplayImage'));
      this.audioMessageSent = true;
    } else {
      notification.set('message', this.messageData.message);
      notification.set('messageInEnglish', this.messageData.message);
    }
    await this.updateMessage(notification, type);
    notification.set('track', { event: `${type}MessageSendDuringApprovalOpen`, data: { orderId: this.order.id, orderChatId } });
    notification.set('type', ApiClientConstant.Notification.Type.Text);
    notification.set('questionId', 'aVoiceMessageFromDoctor');
    notification.set('category', ApiClientConstant.Notification.Category.Doctor);
    notification.set('openURL', '/user?tab=regimen');
    notification.set('user', this.user);
    notification.set('uniqueId', uuid());
    try {
      await notification.save();
      this.broadcast.broadcast('NOTIFY', { message: 'Message Sent Successfully', type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (error) {
      this.broadcast.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  async updateMessage(notification: any, type: string): Promise<void> {
    const uniqueId = type === 'Audio' ? 'aVoiceMessageFromDoctor' : 'aTextMessageFromDoctor';
    const message = await ApiConnector.findOne(Table.Question,
      {
        where: { uniqueIdentifier: uniqueId },
        include: ['titleLanguageString'],
        project: ['titleLanguageString'],
      });
    const titleLanguageString = message.get('titleLanguageString');
    notification.set('subject', titleLanguageString.get(`${this.userCommunicationLanguage as 'en'}`) || titleLanguageString.get('en'));
  }

  async triggerRegimenSopTree(): Promise<void> {
    try {
      let lastNode = this.currentSOPStates[this.currentSOPStates.length - 1];
      if (!lastNode.reply && !this.sopAnswers.length) {
        await this.conn.deleteOrderMedicalRecord(this.order);
      }
      if (lastNode.questionId === 'selectTreeRegimenOption') {
        this.regimenId = lastNode.reply;
        this.disableActionButtons = true;
        const isRegimenUpdated = await this.changeRegimenId();
        if (!isRegimenUpdated) {
          return;
        }
        this.regimenUpdatedThroughSop = true;
        const orderMedicalRecord = await this.conn.findOrderMedicalRecordForOrder(this.order);
        await orderMedicalRecord.save({ selectTreeRegimenOption: this.regimenId });
      }
      const result = await this.conn.activateState({
        username: this.user.get('username'),
        stateId: lastNode.stateId,
        language: 'en',
        storeResponse: lastNode.reply
          ? { nodeName: lastNode.stateId, answer: lastNode.reply }
          : undefined,
        context: { orderId: this.order.id, noStorage: true },
      });
      await this.updateRegimenWithTitle(result.statesInProcess);
      this.currentSOPStates = result.statesInProcess.map((node: any) => ({
        stateId: node.nodeName,
        questionId: node.questionId,
        message: node.message,
        inputs: node.inputs.map((each: any) => ({ display: each.text, value: each.value })),
      }));
      if (lastNode.reply) {
        this.sopAnswers.push({ question: lastNode.message, answer: lastNode.reply, currentSOPStates: this.currentSOPStates });
      }
      if (this.currentSOPStates[this.currentSOPStates.length - 1].message === 'Regimen selection') {
        setTimeout(async () => {
          const elem = this.windowService.nativeWindow.document.getElementById('regimenSelection');
          elem?.scrollIntoView();
        });
      }
      this.currentSOPStates = result.statesInProcess.map((node: any) => ({
        stateId: node.nodeName,
        questionId: node.questionId,
        message: node.message,
        inputs: node.inputs.map((each: any) => ({ display: each.text, value: each.value })),
      }));
      lastNode = this.currentSOPStates[this.currentSOPStates.length - 1];
      if (!lastNode) {
        return;
      }
      if (lastNode.questionId === 'disableSOP') {
        lastNode.reply = 'Yes';
        this.enableRegimenSop = false;
        this.isBtnDisabled = false;
        this.disableActionButtons = false;
        await this.triggerRegimenSopTree();
      }
    } catch (error: any) {
      if (error.code === 412 && error.message.startsWith('No new stateId to process for')) {
        return;
      }
      this.broadcast.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  async updateRegimenWithTitle(states: Array<any>): Promise<void> {
    if (states[states.length - 1].questionId !== 'selectTreeRegimenOption') {
      return;
    }
    this.broadcast.broadcast('NOTIFY', {
      message: 'Regimen should be selected correct as per right concern.',
      type: this.appConfig.Shared.Toast.Type.WARNING,
    });
    const regimenIds = states[states.length - 1].inputs.map((each: any) => each.text);
    const regimens = await this.conn.findRegimens({
      where: { regimenId: regimenIds },
      project: ['title', 'regimenId'],
    });
    const currentUserRegimen = this.order.get('regimen');
    regimens.push(currentUserRegimen);
    this.regimenList = regimens;
    states[states.length - 1].inputs.forEach((each_: any) => {
      const each = each_;
      each.value = each.text;
      each.text = regimens.find((regimen: any) => regimen.get('regimenId') === each.value).get('title');
    });
  }

  async changeRegimenId(): Promise<boolean> {
    this.enableRegimenSop = false;
    this.isBtnDisabled = false;
    await this.waitForRegimenEditComponent();
    this.regimenUpdatedThroughSop = true;
    this.regimenEditComponent.copyFromRegimenId = this.regimenId;
    await this.regimenEditComponent.copyRegimen(true);
    this.regimenEditComponent.editReason = 'REGIMEN_SOP_SELECTION';
    this.disableActionButtons = false;
    this.broadcast.broadcast('NOTIFY', {
      message: 'Regimen Updated',
      type: this.appConfig.Shared.Toast.Type.SUCCESS,
    });
    return true;
  }

  waitForRegimenEditComponent(): Promise<void> {
    return new Promise<void>((resolve: Function) => {
      const interval = setInterval(() => {
        if (!this.regimenEditComponent) {
          return;
        }
        clearInterval(interval);
        resolve();
      }, 100);
    });
  }

  async saveRecording(): Promise<void> {
    await this.audioRecorderComponent.saveRecording();
  }
  async saveRecordingNew(): Promise<void> {
    await this.VoiceRecordingComponent.saveRecording();
  }

  async afterSaveRecording(link: string): Promise<void> {
    if (link) {
      this.audioRecordedLink = link;
      if (!this.audioNote) {
        this.audioNote = 'No AudioNote Available';
      }
      try {
        const orderChat = await this.conn.saveOrderMessage({
          userId: this.username,
          msg: this.audioNote,
          user: this.user,
          order: this.order,
          mode: this.appConfig.Shared.Assistant.Mode.AUDIO,
          metadata: `audioPath=${this.audioRecordedLink}`,
          uniqueId: `${this.user.id}${this.order.id}${new Date().getTime()}`,
          context: {},
          isPersonalisedVoiceNote: true,
        });
        await this.sendNotification(ApiClientConstant.Notification.Type.Audio, orderChat.id);
        this.broadcast.broadcast('NOTIFY', {
          message: 'Audio Message Sent Successfully',
          type: this.appConfig.Shared.Toast.Type.SUCCESS,
        });
        this.audioMessageSent = true;
        delete this.audioRecordedLink;
        delete this.audioNote;
        if (this.showNewUI) {
          this.VoiceRecordingComponent.resetAudio();
        } else {
          this.audioRecorderComponent.resetAudio();
        }
      } catch (error) {
        this.broadcast.broadcast('NOTIFY', {
          message: error.message,
          type: this.appConfig.Shared.Toast.Type.ERROR,
        });
      }
    }
  }

  onAudioRecordingStatusChange(audioChunks: any[] = []): void {
    if (audioChunks.length) {
      this.audioMessageRecorded = true;
      this.audioNote = this.audioNoteFromComponent;
    } else {
      this.audioRecordedLink = '';
      this.audioNote = '';
    }
  }

  async callUserThroughExotel(number: any): Promise<void> {
    try {
      this.callButtonClicked = true;
      await this.conn.callThroughExotel(number, 'orderApprovalPage');
      this.broadcast.broadcast('NOTIFY', { message: 'Connecting', type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch {
      this.broadcast.broadcast('NOTIFY', { message: 'Unable to connect to exotel', type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  async editRegimenSop(index: any): Promise<void> {
    this.enableRegimenSop = true;
    this.isBtnDisabled = true;
    if (this.showNewUI) {
      this.regimenList = null;
    }
    if (index === 0) {
      await this.conn.deleteOrderMedicalRecord(this.order);
      this.currentSOPStates = [{ stateId: 'v4_regimenSOP:' }];
      this.sopAnswers = [];
      await this.triggerRegimenSopTree();
      return;
    }
    await this.editOrderMedicalRecord(this.order, index);
    this.currentSOPStates = this.sopAnswers[index - 1].currentSOPStates;
    this.sopAnswers = this.sopAnswers.slice(0, index);
    await this.triggerRegimenSopTree();
    this.broadcast.broadcast('NOTIFY', { message: 'Answer all the sop question to enable regimen edit',
      type: this.appConfig.Shared.Toast.Type.WARNING });
  }

  async editOrderMedicalRecord(order: any, index: number): Promise<void> {
    const orderMedicalRecord = await ApiConnector.findOne(Table.OrderMedicalRecord, { where: { order } });
    if (!orderMedicalRecord) {
      return;
    }
    const fieldsToDelete = this.sopAnswers.slice(index - 1) || [];
    fieldsToDelete.forEach((each: any) => {
      const fieldName = each.currentSOPStates[0].questionId;
      orderMedicalRecord.unset(fieldName);
    });
    await orderMedicalRecord.save();
  }

  async markRegimenOrderSkipDrApproval(): Promise<void> {
    try {
      await this.conn.markRegimenOrderSkipDrApproval(this.order.id, this.selectedWaitingForImageReason);
      this.broadcast.broadcast('NOTIFY', { message: 'Order has been processed successfully',
        type: this.appConfig.Shared.Toast.Type.SUCCESS });
      this.waitingForImageConfirmation = false;
      // await this.windowService.nativeWindow.location.reload();
    } catch (error) {
      this.broadcast.broadcast('NOTIFY', { message: error.message || error, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  onSelectButton(reply: string, msg?: string): void {
    this.currentSOPStates[0].reply = reply;
    if (msg === 'Regimen selection') {
      const elem = this.windowService.nativeWindow.document.getElementById('regimenSelection');
      this.selectedRegimenID = reply;
      elem.scrollIntoView();
      return;
    }
    this.triggerRegimenSopTree();
  }

  async updateVoiceRecording(chosenOption: any): Promise<void> {
    delete this.audioRecordedLink;
    delete this.selectedConcernVoiceRecordingEn;
    delete this.selectedConcernVoiceRecordingPrefered;
    this.selectedConcern = chosenOption;
    const selectedQuestion = JSON.parse(JSON.stringify(await ApiConnector.findOne(Table.AudioTemplate, {
      where: {
        name: this.selectedConcern,
      },
      include: ['urlLanguageString'],
      project: ['urlLanguageString', 'name'],
      option: { context: { translate: true, inLanguage: this.userCommunicationLanguage } },
    })));
    if (selectedQuestion.urlLanguageString[this.userCommunicationLanguage]) {
      this.selectedConcernVoiceRecordingPrefered = selectedQuestion.urlLanguageString[`${this.userCommunicationLanguage}SignedUrl`];
      this.audioRecordedLink = selectedQuestion.urlLanguageString[this.userCommunicationLanguage];
    } else {
      this.audioRecordedLink = selectedQuestion.urlLanguageString.en;
    }
    this.selectedConcernVoiceRecordingEn = selectedQuestion.urlLanguageString.enSignedUrl;
    this.addAudioPlayer();
  }
  updateConcerns(): void {
    if (this.order.get('type') !== ApiClientConstant.Order.Type.REGIMEN) return;
    if (this.currentUser.get('Gender') === ApiClientConstant.User.Gender.MALE && this.maleVoiceNodeExpEnabled) {
      this.updateConcernsForMaleDoctor();
    } else {
      this.updateConcernsForFemaleDoctor();
    }
  }

  updateConcernsForFemaleDoctor(): void {
    switch (this.order.get('regimen')?.get('class')) {
      case ApiClientConstant.Regimen.Class.FACE: {
        this.concerns = [{ display: 'General Face (Pigm/Acn)', value: 'generalFaceOrderApprovalRecording' },
          { display: 'Preg/BF for Pigmentation', value: 'PregPigmentationFaceOrderApprovalRecording' },
          { display: 'Changed concern → Acne first', value: 'concernChangedToAcneFaceOrderApprovalRecording' },
          { display: 'Freckles', value: 'frecklesFaceOrderApprovalRecording' },
          { display: 'Fairness', value: 'fairnessFaceOrderApprovalRecording' },
          { display: 'Anti Aging', value: 'antiAgingFaceOrderApprovalRecording' },
          { display: 'acne scar', value: 'acneScarFaceOrderApprovalRecording' },
          { display: 'Steroid Abuse', value: 'steroidAbuseFaceOrderApprovalRecording' }];
        break;
      }
      case ApiClientConstant.Regimen.Class.HAIR: {
        this.concerns = [{ display: 'HairFall', value: 'generalHairOrderApprovalRecording' },
          { display: 'Minoxidil', value: 'minoxidilHairOrderApprovalRecording' },
          { display: 'Gray Hair', value: 'grayHairOrderApprovalRecording' }];
        break;
      }
      default: {
        this.concerns = [];
      }
    }
  }
  updateConcernsForMaleDoctor(): void {
    switch (this.order.get('regimen')?.get('class')) {
      case ApiClientConstant.Regimen.Class.FACE: {
        this.concerns = [{ display: 'General Face (Pigm/Acn)', value: 'generalFaceOrderApprovalRecordingForMaleDoctor' },
          { display: 'Preg/BF for Pigmentation', value: 'PregPigmentationFaceOrderApprovalRecordingForMaleDoctor' },
          { display: 'Changed concern → Acne first', value: 'concernChangedToAcneFaceOrderApprovalRecordingForMaleDoctor' },
          { display: 'Freckles', value: 'frecklesFaceOrderApprovalRecordingForMaleDoctor' },
          { display: 'Fairness', value: 'fairnessFaceOrderApprovalRecordingForMaleDoctor' },
          { display: 'Anti Aging', value: 'antiAgingFaceOrderApprovalRecordingForMaleDoctor' },
          { display: 'acne scar', value: 'acneScarFaceOrderApprovalRecordingForMaleDoctor' },
          { display: 'Steroid Abuse', value: 'steroidAbuseFaceOrderApprovalRecordingForMaleDoctor' }];
        break;
      }
      case ApiClientConstant.Regimen.Class.HAIR: {
        this.concerns = [{ display: 'HairFall', value: 'generalHairOrderApprovalRecordingForMaleDoctor' },
          { display: 'Minoxidil', value: 'minoxidilHairOrderApprovalRecordingForMaleDoctor' },
          { display: 'Gray Hair', value: 'grayHairOrderApprovalRecordingForMaleDoctor' }];
        break;
      }
      default: {
        this.concerns = [];
      }
    }
  }

  toggleView(viewType: 'treeInteractions' | 'humanInteractions'): void {
    this.viewType = viewType;
  }

  async getPreviousOrderOfSameRegimenClass(): Promise<void> {
    const orders = await this.conn.findOrders({
      where: {
        user: this.user,
        type: ApiClientConstant.Order.Type.REGIMEN,
        actualCreatedAt: { $gte: moment().subtract(2, 'week').toDate() },
        objectId: { $ne: this.order.id },
        doctorConfirmed: true,
        regimen: this.regimen,
      },
      include: ['products', 'regimen', 'allocatedDoctor'],
      descending: 'createdAt',
      project: [
        'regimenId',
        'stage',
        'products.title' as 'products',
        'products.quantity' as 'products',
        'products.quantityUnit' as 'products',
        'products.allowAsAddon' as 'products',
        'products.type' as 'products',
        'products.price' as 'products',
        'services.type' as 'services',
        'services.title' as 'services',
        'regimen',
        'amount',
        'productInfo',
        'type',
        'allocatedDoctor.exotelNumber' as 'allocatedDoctor',
      ],
    });
    this.oldOrders = orders.map((each: any) => each.toJSON());
    if (this.oldOrders.length) {
      alert('sending VN is compulsory as regimen is already approved');
      this.showCustomRecording = false;
      this.enableCustomRecordings = false;
    }
  }

  wantsToTriggerConsentForm(isotretConsent: boolean):void {
    if (isotretConsent) {
      this.isIsotretConsent = true;
    } else {
      this.isFinasterideConsent = true;
    }
  }

  wantsToCancelSendingConsentForm(): void {
    this.isFinasterideConsent = false;
    this.isIsotretConsent = false;
  }

  async checkPersonalizedVoiceNoteEligibility(): Promise<void> {
    if ((this.order.get('regimen')?.get('class') !== ApiClientConstant.Regimen.Class.HAIR) || (!this.order.get('regimenId'))) {
      return;
    }
    const userData = await this.conn.getPersonalizedVoiceNoteEligibility(
      this.user.get('username'), this.order.get('regimenId'));
    if (userData) {
      if (this.selectedHairFallStages.includes(userData.get('differentStagesOfHairLoss'))
    || this.selectedHairFallStages.includes(userData.get('stagesOfHairFallFemale'))
      ) {
        this.showCustomRecording = false;
        this.enableCustomRecordings = false;
      }
    }
  }

  changeSelectedChatType(selectChat: boolean): void {
    if (selectChat) {
      this.selectedChatType = 'userChat';
      return;
    }
    this.selectedChatType = 'chatSummary';
  }

  async checkDoctorCanCompleteOrder(): Promise<boolean> {
    const currentOrderStatus = await this.conn.findOneOrder({ where: { objectId: this.order.id }, project: ['allocatedDoctor', 'stage'] });
    if (currentOrderStatus.get('stage') !== this.appConfig.Shared.Order.Stage.DOCTOR_APPROVAL_PENDING) {
      this.broadcast.broadcast('NOTIFY', {
        message: 'order is not in doctor approval Stage',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
      return false;
    }
    if (currentOrderStatus.get('allocatedDoctor')?.id !== this.currentUser.id) {
      alert('order allocated to a different doctor');
      return false;
    }
    return true;
  }

  onChooseSection(event: string): void {
    const elem = this.windowRef.nativeWindow.document.getElementById(event);
    elem?.scrollIntoView({ behavior: 'smooth' });
  }

  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
