
<svg [attr.width]="width" [attr.height]="height" style="position:absolute;left:0;top:0;z-index:-1;" *ngIf="to && from">
  <defs>
    <marker id="arrow" markerWidth="10" markerHeight="10" refX="0" refY="3" orient="auto" markerUnits="strokeWidth">
      <path d="M0,0 L0,6 L9,3 z" fill="#f00"></path>
    </marker>
  </defs>
  <line *ngIf="from.getBoundingClientRect().top &lt;= to.getBoundingClientRect().top && from.getBoundingClientRect().left &lt;= to.getBoundingClientRect().left" [attr.x1]="from.getBoundingClientRect().left + from.getBoundingClientRect().width" [attr.y1]="from.getBoundingClientRect().top + from.getBoundingClientRect().height - errorY + scrollY" [attr.x2]="((to.getBoundingClientRect().left + to.getBoundingClientRect().right) / 2) - 6" [attr.y2]="to.getBoundingClientRect().top - errorY - 3 + scrollY" [attr.stroke]="color" stroke-width="1" marker-end="url(#arrow)"></line>
  <line *ngIf="from.getBoundingClientRect().top &lt;= to.getBoundingClientRect().top && from.getBoundingClientRect().left &gt; to.getBoundingClientRect().left" [attr.x1]="from.getBoundingClientRect().left + from.getBoundingClientRect().width" [attr.y1]="from.getBoundingClientRect().top + from.getBoundingClientRect().height - errorY + scrollY" [attr.x2]="((to.getBoundingClientRect().left + to.getBoundingClientRect().right) / 2) + 6" [attr.y2]="to.getBoundingClientRect().top - errorY - 3 + scrollY" [attr.stroke]="color" stroke-width="1" marker-end="url(#arrow)"></line>
  <line *ngIf="from.getBoundingClientRect().top &gt; to.getBoundingClientRect().top && from.getBoundingClientRect().left &lt;= to.getBoundingClientRect().left" [attr.x1]="from.getBoundingClientRect().left + from.getBoundingClientRect().width" [attr.y1]="from.getBoundingClientRect().top - errorY + scrollY" [attr.x2]="((to.getBoundingClientRect().left + to.getBoundingClientRect().right) / 2) - 6" [attr.y2]="to.getBoundingClientRect().top + to.getBoundingClientRect().height - errorY + 3 + scrollY" [attr.stroke]="color" stroke-width="1" marker-end="url(#arrow)"></line>
  <line *ngIf="from.getBoundingClientRect().top &gt; to.getBoundingClientRect().top && from.getBoundingClientRect().left &gt; to.getBoundingClientRect().left" [attr.x1]="from.getBoundingClientRect().left + from.getBoundingClientRect().width" [attr.y1]="from.getBoundingClientRect().top - errorY + scrollY" [attr.x2]="((to.getBoundingClientRect().left + to.getBoundingClientRect().right) / 2) + 6" [attr.y2]="to.getBoundingClientRect().top + to.getBoundingClientRect().height - errorY + 3 + scrollY" [attr.stroke]="color" stroke-width="1" marker-end="url(#arrow)"></line>
</svg>
