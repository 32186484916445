import { Injectable } from '@angular/core';
import { ApiClientConstant } from 'api-client';
import { ValueOf } from 'api-client/src/common';
import { HttpClient } from '@angular/common/http';
import { ConnectionService } from '../connection-service';
import { AppConfig } from '../../app/app.config';
import { Broadcaster } from '../../components/broadcaster';

@Injectable()
export class HelperService {
  constructor(private conn: ConnectionService, private appConfig: AppConfig, public broadcaster: Broadcaster, private http: HttpClient) {
  }
  convertParseToDictionary(parseObj: any, dO: any, defaultValue?: object): void {
    const dictionaryObj = dO;
    Object.keys(dictionaryObj).forEach((key: string) => (dictionaryObj[key] = parseObj.get(key)));
    if (!defaultValue) return;
    Object.keys(defaultValue).forEach((key: string) => {
      if (dictionaryObj[key]) return;
      dictionaryObj[key] = defaultValue[key];
    });
  }

  convertDictionaryToParse(dictionaryObj: any, parseObj: any): void {
    Object.keys(dictionaryObj).filter((key: string) => (dictionaryObj[key] !== parseObj.get(key)))
      .forEach((key: string) => parseObj.set(key, dictionaryObj[key]));
  }

  createCopyOfArrayObjectItem(arrayItem: Array<any>): Array<any> {
    return arrayItem.map((item: any) => item);
  }

  createCopyOfObjectItem(item: any): any {
    const result = {};
    const fields = Object.keys(item);
    fields.forEach((field: any) => {
      if (item[field] instanceof Array) {
        result[field] = this.createCopyOfArrayObjectItem(item[field]);
        return;
      }
      result[field] = item[field];
    });
    return result;
  }

  isAntibioticRegimen(regimens: Array<any>): boolean {
    return regimens
      .some((regimen: any) => regimen.get('products')
        .some((product: any) => product.get('isTablet') || product.get('isAntibiotic')));
  }

  isAntibioticProduct(product: any): boolean {
    return product.isAntibiotic || product.isTablet;
  }

  getInstantCheckupClassByType(type: string): ValueOf<typeof ApiClientConstant.Regimen.Class> {
    let instantCheckupClass: ValueOf<typeof ApiClientConstant.Regimen.Class>;
    switch (type) {
      case ApiClientConstant.InstantCheckup.Type.FULL_FACE:
      case ApiClientConstant.InstantCheckup.Type.FRONT_FACE:
      case ApiClientConstant.InstantCheckup.Type.LEFT_SIDE_FACE:
      case ApiClientConstant.InstantCheckup.Type.RIGHT_SIDE_FACE:
      case ApiClientConstant.InstantCheckup.Type.SIDE_FACE: {
        instantCheckupClass = ApiClientConstant.Regimen.Class.FACE;
        break;
      }
      case ApiClientConstant.InstantCheckup.Type.HAIR:
      case ApiClientConstant.InstantCheckup.Type.HAIR_FRONT:
      case ApiClientConstant.InstantCheckup.Type.HAIR_TOP: {
        instantCheckupClass = ApiClientConstant.Regimen.Class.HAIR;
        break;
      }
      default: {
        instantCheckupClass = ApiClientConstant.Regimen.Class.BODY;
      }
    }
    return instantCheckupClass;
  }

  async getFileByImageUrl(url: string, fileName: string, fileExtention: string): Promise<any> {
    const file = await this.convertImageUrlToFile(url, fileName, fileExtention);
    return file;
  }

  async convertImageUrlToFile(url: string, fileName: string, fileExtention: string): Promise<any> {
    try {
      const response = await this.http.get(url, { responseType: 'blob' }).toPromise();
      const blob = response as Blob;
      return new File([blob], `${fileName}.${fileExtention}`);
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error,
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return '';
    }
  }

  convertDataURLtoBlob(dataURI: any): Blob {
    const byteString = atob(dataURI.split(',')[1]);

    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const arrayBuffer = new ArrayBuffer(byteString.length);
    const unit8Arr = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length;) {
      unit8Arr[i] = byteString.charCodeAt(i);
      i += 1;
    }

    return new Blob([arrayBuffer], { type: mimeString });
  }

  findFileType(fileName: string): string {
    let type: string;
    switch (fileName.split('.')[1]) {
      case 'pdf': {
        type = this.appConfig.Shared.Assistant.Attachment.Type.PDF;
        break;
      }
      default: type = this.appConfig.Shared.Assistant.Attachment.Type.IMAGE;
    }
    return type;
  }
}
