import { Component, Injector, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApiClientConstant, Table } from 'api-client';
import { ValueOf } from 'api-client/src/common';
import { AppConfig } from '../../../app.config';
import { ConnectionService } from '../../../../services/connection-service';
import { Broadcaster } from '../../../../components/broadcaster';
import { AudioRecorderComponent } from '../../../../components/audio-recorder/audio-recorder.component';

@Component({ selector: 'pending-call-complete', templateUrl: './pending-call-complete.html' })
export class PendingCallCompleteComponent {
  @ViewChild(AudioRecorderComponent) audioRecorderComponent: AudioRecorderComponent;

  private objectId: string;
  public type: string;
  private referenceId: string;
  userNeedForConfirmation: string;
  followUpCallNeeded: boolean = false;
  followUpCallTime: Date;
  reason: ValueOf<typeof ApiClientConstant.PendingCall.Status>;
  subReason: string = '';
  note: string = '';
  options: Array<{ display: string, value: string }> = [];
  subOptions: { [key: string]: Array<{ display: string, value: string }> } = {};
  languageOptions: Array<string> = [];
  preferedLanguage: string;
  teamOptions: Array<string> = [];
  team: string;
  referenceMessage: string;
  audioRecordedLink: string;
  audioNote: string;
  data: any = {};
  teenager: boolean;
  userId: string;

  constructor(
    public dialogRef: MatDialogRef<PendingCallCompleteComponent>,
    public injector: Injector,
    public appConfig: AppConfig,
    public broadcasterService: Broadcaster,
    public connectionService: ConnectionService) {
    const data = this.injector.get(MAT_DIALOG_DATA);
    this.data = data;
    this.objectId = data.objectId;
    this.referenceId = data.referenceId;
    this.preferedLanguage = data.languagePreference;
    this.type = data.type;
    this.teenager = data.teenager;
    [this.team] = data?.teams || [''];
    this.userId = data.userId;
    this.addOptions();
    this.languageOptions = Object.values(ApiClientConstant.LanguageString);
    this.teamOptions = AppConfig.Shared.Team.Name;
  }

  private addOptions(): void {
    switch (this.type) {
      case ApiClientConstant.PendingCall.Type.AreYouOk: {
        this.options.push(
          { display: 'Resolved On Chat', value: 'ResolvedOnChat' },
          { display: 'Call Done', value: 'CallCompleted' },
          { display: 'NotReachable', value: 'NotReachable' },
          { display: 'Ringing or No Response', value: 'RingingOrNoResponse' },
          { display: 'Waiting', value: 'Waiting' },
          { display: 'Not An Escalation', value: 'NotAnEscalation' },
          { display: 'Duplicate', value: 'Duplicate' },
          { display: 'Other', value: 'Other' });
        break;
      }
      case ApiClientConstant.PendingCall.Type.OnlinePaymentPending:
      case ApiClientConstant.PendingCall.Type.MinoxidilCall:
      case ApiClientConstant.PendingCall.Type.DoctorOrder:
      case ApiClientConstant.PendingCall.Type.AbandonedCart:
      case ApiClientConstant.PendingCall.Type.ReacquisitionCall:
      case ApiClientConstant.PendingCall.Type.InitialOrder: {
        this.options.push(
          { display: 'Canceled', value: 'Canceled' },
          { display: 'Not Reachable', value: 'NotReachable' },
          { display: 'Ringing or No Response', value: 'RingingOrNoResponse' },
          { display: 'OrderPlaced', value: 'OrderPlaced' },
          { display: 'Duplicate', value: 'Duplicate' },
          { display: 'OrderNotPlaced', value: 'OrderNotPlaced' },
          { display: 'Online payment pending', value: 'Online payment pending' },
          { display: 'Other', value: 'Other' });
        break;
      }
      case ApiClientConstant.PendingCall.Type.MissedCall:
      case ApiClientConstant.PendingCall.Type.ScheduledCall: {
        this.options.push(
          { display: 'Not Reachable', value: 'NotReachable' },
          { display: 'Ringing or No Response', value: 'RingingOrNoResponse' },
          { display: 'Call Done', value: 'CallCompleted' },
          { display: 'Other', value: 'Other' });
        break;
      }
      case ApiClientConstant.PendingCall.Type.OrderConfirmation: {
        this.options.push(
          { display: 'Not Reachable', value: 'NotReachable' },
          { display: 'Ringing or No Response', value: 'RingingOrNoResponse' },
          { display: 'Picked and Disconnected', value: 'PickedAndDisconnected' },
          { display: 'Call From Doctor', value: 'DoctorCallNeeded' },
          { display: 'Voice Note', value: 'VoiceNote' },
          { display: 'Cancel Order', value: 'CancelOrder' },
          { display: 'User will Inform later', value: 'UserWillInformLater' },
          { display: 'Call Later', value: 'CallLater' },
          { display: 'Consent Denied', value: 'ConsentDenied' },
          { display: 'Consent Not Confirmed', value: 'ConsentNotConfirmed' },
          { display: 'Consent Confirmed', value: 'ConsentConfirmed' },
          { display: 'No voice or call needed.', value: 'NoVoiceOrCallNeeded' },
          { display: 'Change Language.', value: 'ChangeLanguage' },
          { display: 'Retry Call Again', value: 'FollowUpCall' },
          { display: 'Other', value: 'Other' });
        break;
      }
      case ApiClientConstant.PendingCall.Type.WaitingForImage: {
        this.options.push(
          { display: 'Not Reachable', value: 'NotReachable' },
          { display: 'Ringing or No Response', value: 'RingingOrNoResponse' },
          { display: 'Picked and Disconnected', value: 'PickedAndDisconnected' },
          { display: 'Cancel Order', value: 'CancelOrder' },
          { display: 'User will upload', value: 'UserWillUpload' },
          { display: 'Not Serviceable', value: 'NotServiceable' },
          { display: 'Other', value: 'Other' });
        break;
      }
      case ApiClientConstant.PendingCall.Type.RegimenFollowUp: {
        this.options.push(
          { display: 'Call Done', value: 'CallCompleted' },
          { display: 'Not Reachable', value: 'NotReachable' },
          { display: 'Ringing or No Response', value: 'RingingOrNoResponse' },
          { display: 'Picked and Disconnected', value: 'PickedAndDisconnected' },
          { display: 'Call Later', value: 'CallLater' },
          { display: 'User will upload', value: 'UserWillUpload' },
          { display: 'Other', value: 'Other' });
        break;
      }
      case ApiClientConstant.PendingCall.Type.FollowUpPurchase:
      case ApiClientConstant.PendingCall.Type.FollowUpImage:
      case ApiClientConstant.PendingCall.Type.FollowUpSkipped: {
        this.options.push(
          { display: 'Not Reachable', value: 'NotReachable' },
          { display: 'Ringing or No Response', value: 'RingingOrNoResponse' },
          { display: 'Picked and Disconnected', value: 'PickedAndDisconnected' },
          { display: 'Call Later', value: 'CallLater' },
          { display: 'Not happy with treatment', value: 'NotHappyWithTreatment' },
          { display: 'Not happy with support', value: 'NotHappyWithSupport' },
          { display: 'User will take followUp', value: 'UserWillTakeFollowUp' },
          { display: 'Other', value: 'Other' });
        break;
      }
      case this.appConfig.Shared.PendingCall.Type.UnpaidUserDoctorCall: {
        this.options.push(
          { display: 'Call Done', value: 'CallCompleted' },
          { display: 'Call Unsuccessful', value: 'NotReachable' },
          { display: 'Call Later', value: 'CallLater' });
        break;
      }
      case ApiClientConstant.PendingCall.Type.AddressConfirmation: {
        this.options.push(
          { display: 'Address Confirmed', value: 'AddressConfirmed' },
          { display: 'Not Reachable', value: 'NotReachable' },
          { display: 'Ringing or No Response', value: 'RingingOrNoResponse' },
          { display: 'Picked and Disconnected', value: 'PickedAndDisconnected' },
          { display: 'Duplicate', value: 'Duplicate' },
          { display: 'Call Done', value: 'CallCompleted' },
          { display: 'Call Later', value: 'CallLater' },
          { display: 'Other', value: 'Other' });
        break;
      }
      default: {
        this.options.push(
          { display: 'Not Reachable', value: 'NotReachable' },
          { display: 'Ringing or No Response', value: 'RingingOrNoResponse' },
          { display: 'Picked and Disconnected', value: 'PickedAndDisconnected' },
          { display: 'Duplicate', value: 'Duplicate' },
          { display: 'Call Done', value: 'CallCompleted' },
          { display: 'Call Later', value: 'CallLater' },
          { display: 'Other', value: 'Other' });
      }
    }
    const canceledOrOrderNotPlacedSubStatus = [
      { display: 'Recently ordered same products', value: 'recentlyOrderedSameProducts' },
      { display: 'Will check and order', value: 'WillCheckAndOrder' },
      { display: 'Financial issues', value: 'FinancialIssues' },
      { display: 'Buy Later', value: 'BuyLater' },
      { display: 'Check with Family', value: 'CheckWithFamily' },
      { display: 'Window Shopping', value: 'WindowShopping' },
      { display: 'High Price', value: 'HighPrice' },
      { display: 'Results issue', value: 'ResultsIssue' },
      { display: 'Out of Station', value: 'OutOfStation' },
      { display: 'No Improvements', value: 'NoImprovements' },
    ];
    this.subOptions.Canceled = canceledOrOrderNotPlacedSubStatus;
    this.subOptions.OrderNotPlaced = canceledOrOrderNotPlacedSubStatus;
    this.subOptions.MinoxidilCall = canceledOrOrderNotPlacedSubStatus;
    this.subOptions.CancelOrder = this.subOptions.Canceled;
    if (this.teenager) {
      this.subOptions.ConsentConfirmed = [
        { display: 'Confirm By Parent', value: 'ConfirmByParent' },
        { display: 'Confirm By Teenager', value: 'ConfirmByTeenager' },
      ];
    }
  }

  async saveResponse(): Promise<void> {
    try {
      const pendingCall = new Table.PendingCall();
      pendingCall.id = this.objectId;
      const context: any = {};
      await this.updateVoiceOrCallNeeded(context);
      if (this.followUpCallNeeded && this.followUpCallTime) {
        pendingCall.set('followUpCallTime', new Date(this.followUpCallTime));
      }
      if (pendingCall.get('languagePreference') !== this.preferedLanguage) {
        context.languagePreference = this.preferedLanguage;
      }
      if ((pendingCall.get('teams') && pendingCall.get('teams')[0]) !== this.team) {
        context.teams = [this.team];
      }
      if (pendingCall.get('referenceMessage') !== this.referenceMessage && this.referenceMessage) {
        context.referenceMessage = this.referenceMessage;
      }
      if (this.subOptions[this.reason]) {
        pendingCall.set('subStatus', this.subReason);
      }
      await pendingCall.save({ status: this.reason, note: this.note }, { context });
      if (this.type === this.appConfig.Shared.PendingCall.Type.UnpaidUserDoctorCall && this.reason === 'NotReachable') {
        await this.audioRecorderComponent.saveRecording();
      }
      this.dialogRef.close(true);
    } catch (error) {
      this.broadcasterService.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  private async updateVoiceOrCallNeeded(context: any): Promise<void> {
    if (this.type !== ApiClientConstant.PendingCall.Type.OrderConfirmation || !this.userNeedForConfirmation) {
      return;
    }
    if (!['ConsentConfirmed', 'Other'].includes(this.reason)) {
      return;
    }
    context.updateDoctorCallStatus = true;
    // eslint-disable-next-line default-case
    switch (this.userNeedForConfirmation) {
      case this.appConfig.Shared.CallConfirmation.DOCTOR_CALL: {
        context.optedForDoctorCall = true;
        break;
      }
      case this.appConfig.Shared.CallConfirmation.VOICE_INSTRUCTION: {
        context.optedForDoctorCall = false;
        break;
      }
      case this.appConfig.Shared.CallConfirmation.NONE: {
        context.optedForDoctorCall = undefined;
        break;
      }
    }
  }

  async sendFeedbackForm(): Promise<void> {
    const currentUser = this.connectionService.getCurrentUser();
    try {
      await this.connectionService.createNPSFeedback({
        userId: this.userId,
        createdByUserId: currentUser.id,
      });
      this.broadcasterService.broadcast('NOTIFY', { message: 'Sent Successfully', type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (error) {
      this.broadcasterService.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }
}
