
<div class="flex-box">
  <div class="flex-heading">
    <div class="list-header col-xs-12"><span class="pR20">Offline Regimens  ({{count || 0}})</span></div>
  </div>
  <div class="flex-content scroll-x" style="height:70vh">
    <div class="list-view">
      <div class="list-content">
        <ag-grid-angular class="ag-theme-balham highlight-first" style="height: 100%;" [columnDefs]="columnDefs" [components]="components" [rowBuffer]="0" [rowModelType]="ui.grid.rowModelType" [paginationPageSize]="ui.grid.pageSize" [cacheBlockSize]="ui.grid.pageSize" [cacheOverflowSize]="0" [maxConcurrentDatasourceRequests]="1" [infiniteInitialRowCount]="1" [maxBlocksInCache]="10" (gridReady)="onGridReady($event)"></ag-grid-angular>
      </div>
    </div>
  </div>
  <kiosk-calls style="height: 30vh"></kiosk-calls>
</div>