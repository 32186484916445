import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiClientConstant, Table } from 'api-client';
import { AppConfig } from '../../app/app.config';
import { ConnectionService } from '../../services/connection-service';
import { DataUploadModal } from '../modal/dataUploadModal';
import { Broadcaster } from '../broadcaster';

@Component({
  selector: 'doctor-notes',
  templateUrl: './doctor-notes.html',
  styleUrls: ['./doctor-notes.scss'],
})
export class DoctorNotesComponent {
  @Input('username')
  set onUpdateUser(username: any) {
    if (!username) {
      return;
    }
    this.username = username;
    this.loadDoctorNotes();
  }
  username: string;
  notes: Array<any> = [];
  @Output('newMedicalNoteAdded') newMedicalNoteAdded: EventEmitter<boolean> = new EventEmitter();
  constructor(public appConfig: AppConfig,
    private broadcaster: Broadcaster,
    private connectionService: ConnectionService,
    private dialog: MatDialog) {}

  async loadDoctorNotes(): Promise<void> {
    this.notes = await this.connectionService.findNotes({
      where: { userId: this.username },
      include: ['AddedBy', 'DoctorDisplayName'],
      project: [
        'message',
        'Name',
        'AddedBy.type' as 'AddedBy',
        'AddedBy.username' as 'AddedBy',
        'DoctorDisplayName',
      ],
      descending: 'createdAt',
    });
  }

  async addNotes(): Promise<void> {
    const dialogRef = this.dialog.open(DataUploadModal, { data: { title: 'Note Message' } });
    dialogRef.afterClosed().subscribe(async (data: string) => {
      if (!data) return;
      const note = new Table.Note();
      note.set('message', data);
      note.set('AddedBy', this.connectionService.getCurrentUser());
      note.set('Name', this.connectionService.getCurrentUser().get('DoctorDisplayName')
      || this.connectionService.getCurrentUser().get('username'));
      note.set('userId', this.username);
      note.set('type', ApiClientConstant.Note.Type.MEDICAL);
      try {
        await note.save();
        this.notes.unshift(note);
        this.newMedicalNoteAdded.emit(true);
      } catch (error) {
        this.broadcaster.broadcast('NOTIFY', {
          message: error?.message,
          type: this.appConfig.Shared.Toast.Type.ERROR,
        });
      }
    });
  }

  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
