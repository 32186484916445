import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppConfig } from '../../app/app.config';
import { ConnectionService } from '../../services/connection-service';

@Component({ selector: 'instant-checkup-image-view', templateUrl: './instant-checkup-image-view.html' })
export class InstantCheckupImageView {
  instantCheckUpImageList: Array<any> = [];
  selectedInstantCheckUpImageIndex: number;

  constructor(public dialogRef: MatDialogRef<InstantCheckupImageView>,
    private connectionService: ConnectionService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appConfig: AppConfig) {
  }

  async ngOnInit(): Promise<void> {
    this.instantCheckUpImageList = await this.connectionService.fetchUserInstantCheckup(this.data.query);
  }

  editPhotoType(): void {
  }

  async selectImage(index: number): Promise<any> {
    this.selectedInstantCheckUpImageIndex = index;
  }

  closePopup(): void {
    this.dialogRef.close({ instantCheckUp: this.instantCheckUpImageList[this.selectedInstantCheckUpImageIndex] });
  }
}
