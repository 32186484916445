
<div class="dashboard-box" [ngClass]="{grayColor: dailyTarget === 0 }">
  <div class="div flex" style="flex-flow: column; align-items: start; margin-bottom: 15px;">
    <div class="title">{{title}}</div>
    <div class="count" *ngIf="dailyTarget &gt; 0">{{soFarAchieved}}/{{dailyTarget * diffInDays}}</div>
    <div class="count" *ngIf="dailyTarget === 0">{{soFarAchieved}}</div>
  </div>
  <div class="div flex pt-2" style="flex-flow: column; align-items: start; border-top: 1px solid rgba(0,0,0, 0.1);">
    <div class="data" *ngFor="let item of dataItems" [ngClass]="{ &quot;clr-orange&quot;: item.value &gt; 0 }">
      <div class="div" *ngIf="item.value"><span>{{item.title}} &nbsp;</span><span>{{item.value}}</span></div>
    </div>
  </div>
</div>