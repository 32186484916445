import { Component, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { v4 as uuid } from 'uuid';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FaviconService } from 'src/services/favicon-service';
import { TabNamingService } from 'src/services/tab-naming-service';
import { SupportTicketHistoryComponent } from 'src/app/patient/support-ticket-history/support-ticket-history.component';
import { AudioRecorderComponent } from 'src/components/audio-recorder/audio-recorder.component';
import { ApiClientConstant, Table, RequestQueryPayload } from 'api-client';
import { ConnectionService } from '../../../services/connection-service';
import { TimeService } from '../../../services/time-service';
import { LocalStorage } from '../../../services/local-storage-service';
import { AppConfig } from '../../app.config';
import { WindowRefService } from '../../../services/window-ref-service';
import { Broadcaster } from '../../../components/broadcaster';
import { HelperService } from '../../../services/helper-service';
import { EditInstantCheckup } from '../../chat/view/instant-checkup/edit-instant-checkup';
import { FollowUpDialog } from '../../chat/view/follow-up';

@Component({
  selector: 'patient-edit',
  templateUrl: './patient-edit.html',
  styleUrls: ['./patient-edit.scss'],
})

export class PatientEditComponent {
  @ViewChild(AudioRecorderComponent) audioRecorderComponent: AudioRecorderComponent;

  user: any;
  userData: any = [];
  currentUser: any;
  userRoles: Array<any> = [];
  moment: any;
  webAppUrl: SafeResourceUrl;
  showNotes: false;
  followUp: any;
  genderType: Array<any> = [];
  languageOptions: Array<any> = [];
  instantCheckups: Array<any> = [];

  // iframe variables
  consultations: Array<any> = [];
  selectedConsultation: string = '';

  // sendMessage Variables
  audioRecordedLink: string;
  audioNoteFromComponent: string;
  audioNote: string;
  audioMessageSent: boolean;
  message: string = '';
  openUrl: string;

  constructor(private conn: ConnectionService,
    private router: Router,
    private route: ActivatedRoute,
    private appConfig: AppConfig,
    private localStorageService: LocalStorage,
    private timeService: TimeService,
    private dialog: MatDialog,
    private window: WindowRefService,
    private helper: HelperService,
    private dom: DomSanitizer,
    private faviconService: FaviconService,
    private tabNameService: TabNamingService,
    private broadcaster: Broadcaster) {
    this.moment = moment;
  }

  async ngOnInit(): Promise<any> {
    this.currentUser = this.conn.getCurrentUser();
    this.userRoles = this.localStorageService.getJsonValue('userRoles');
    this.user = await this.conn.getUserByUserName(this.route.snapshot.queryParams.username);
    this.broadcaster.broadcast('ChatUserUpdate', { user: this.user });
    await this.loadFollowUp();
    await this.findConsultationChatURL();
    this.genderType = [
      { value: ApiClientConstant.User.Gender.MALE, display: 'Male' },
      { value: ApiClientConstant.User.Gender.FEMALE, display: 'Female' },
    ];
    Object.values(ApiClientConstant.LanguageString).forEach((each: string) => {
      this.languageOptions.push({ display: each, value: each });
    });
  }

  openRegimenEdit(regimen: any): void {
    const url = this.conn.getBaseUrl();
    this.window.nativeWindow.open(`${url}/products/regimen/${regimen.get('regimenId')}/edit`);
  }

  openInstantCheckup(item: any): void {
    const url = this.conn.getBaseUrl();
    this.window.nativeWindow.open(`${url}/user/instantCheckup/${item.instantCheckup.id}?username=${this.user.get('username')}`);
  }

  openInstantCheckupEditPopup(id: string, imageUrl: string): void {
    const dialogRef = this.dialog.open(EditInstantCheckup, { data: { id, imageUrl } });
    dialogRef.afterClosed().subscribe((data: any): any => {
      this.userData.forEach((each: any, index: any) => {
        each[0].instantCheckups?.forEach((instantCheckup: any) => {
          if (data.id === instantCheckup.instantCheckup?.id) {
            instantCheckup.instantCheckup.set('type', data.get('type'));
          }
        });
      });
    });
  }

  async findConsultationChatURL(): Promise<void> {
    this.consultations = await this.conn.findConsultationSession({ where: { user: this.user },
      descending: 'createdAt',
      project: ['PrivateMainConcernClass'] });
    if (!this.consultations?.length) {
      return;
    }
    this.selectedConsultation = this.consultations[0].id;
    this.webAppUrl = this.dom.bypassSecurityTrustResourceUrl(
      `${this.conn.getWebAppUrl()}/chatV2/${this.selectedConsultation}?username=${this.user.get('username')}`
       + '&type=consultationSession&loginType=token');
  }

  updateIframe(): void {
    this.webAppUrl = this.dom.bypassSecurityTrustResourceUrl(
      `${this.conn.getWebAppUrl()}/chatV2/${this.selectedConsultation}?username=${this.user.get('username')}`
      + '&type=consultationSession&loginType=token');
  }

  callUserThroughExotel(number: any): void {
    this.conn.callThroughExotel(number, 'PatientEditPage')
      .then(() => this.broadcaster.broadcast('NOTIFY', { message: 'Connecting',
        type: this.appConfig.Shared.Toast.Type.SUCCESS }))
      .catch((error: any) => this.broadcaster.broadcast('NOTIFY', { message: error.message,
        type: this.appConfig.Shared.Toast.Type.ERROR }));
  }

  openSupportTicketHistory(): void {
    if (!this.user) {
      return;
    }
    this.dialog.open(SupportTicketHistoryComponent, {
      panelClass: 'w-half',
      data: { user: this.user },
    });
  }

  openFollowUpModal(SelectedPopUpType: string): void {
    this.dialog.open(FollowUpDialog, {
      data: {
        SelectedPopUpType,
        followUp: this.followUp,
        userId: this.user.get('username'),
        chatUser: this.user,
      },
    });
  }

  async loadFollowUp(): Promise<void> {
    const requestPayload: RequestQueryPayload<Table.FollowUp> = {
      where: {
        user: this.user,
        State: [
          ApiClientConstant.FollowUp.State.PENDING,
          ApiClientConstant.FollowUp.State.WAITING_FOR_IMAGE,
        ],
      },
      project: [
        'State',
        'newFollowUpMessage',
        'followUpReason',
        'nextFollowUpDate',
        'firstFollowup',
        'regimens.activeFromDate' as 'regimens',
        'regimens.concernsLanguageString' as 'regimens',
        'effectiveFollowUpDate',
        'nextFollowUpDate',
        'markedReadyOn',
        'followUpCount',
        'ready',
        'products',
        'instructionCallNeeded',
        'allocatedDoctor.username' as 'allocatedDoctor',
      ],
      include: ['allocatedDoctor', 'regimens'],
      descending: 'createdAt',
      limit: 1,
    };
    [this.followUp] = await this.conn.findFollowUps(requestPayload);
  }

  async updateUser(): Promise<void> {
    if (!this.user.get('MobileNumber') || !this.user.get('Age') || !this.user.get('PatientName') || !this.user.get('languagePreference')
      || !this.user.get('Gender')) {
      this.broadcaster.broadcast('NOTIFY', { message: 'User Params Missing', type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    try {
      await this.user.save();
      this.broadcaster.broadcast('NOTIFY', { message: 'User Updated Successfully', type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  async afterSaveRecording(link: string): Promise<void> {
    if (!link) return;
    this.audioRecordedLink = link;
    if (!this.audioNote) {
      this.audioNote = 'No AudioNote Available';
    }
    await this.sendNotification(ApiClientConstant.Notification.Type.Audio);
  }

  onAudioRecordingStatusChange(audioChunks: any[] = []): void {
    if (audioChunks.length) {
      this.audioNote = this.audioNoteFromComponent;
    } else {
      this.audioRecordedLink = '';
      this.audioNote = '';
    }
  }

  async saveRecording(): Promise<void> {
    await this.audioRecorderComponent.saveRecording();
  }

  async sendNotification(type: string): Promise<void> {
    const notification = new Table.Notification();
    if (type === this.appConfig.Shared.Notification.Type.Audio) {
      notification.set('audioURL', this.audioRecordedLink);
      await this.user.get('allocatedDoctor')?.fetch();
      notification.set('audioImageURL', this.user.get('allocatedDoctor')?.get('doctorDisplayImage'));
    } else {
      notification.set('message', this.message);
      notification.set('messageInEnglish', this.message);
    }
    notification.set('track', { event: `${type}MessageSendBySalesTeam`, data: { userId: this.user.id } });
    notification.set('type', ApiClientConstant.Notification.Type.Text);
    notification.set('category', ApiClientConstant.Notification.Category.Referral);
    notification.set('openURL', `${this.conn.getWebAppUrl()}/user/orders`);
    if (this.openUrl) {
      const openUrl = `${this.openUrl}&redirectTo=browser`;
      notification.set('openURL', openUrl);
    }
    notification.set('user', this.user);
    notification.set('questionId', 'salesMessage');
    notification.set('uniqueId', uuid());
    try {
      await notification.save();
      this.broadcaster.broadcast('NOTIFY', {
        message: 'Message Send Successfully',
        type: this.appConfig.Shared.Toast.Type.SUCCESS,
      });
      this.audioMessageSent = true;
      delete this.audioRecordedLink;
      delete this.audioNote;
      delete this.message;
      delete this.openUrl;
      this.audioRecorderComponent.resetAudio();
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', {
        message: error.message,
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
    }
  }

  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
