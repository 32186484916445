import { ChangeDetectorRef, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiClientConstant, ApiConnector, Table } from 'api-client';
import { ValueOf } from 'api-client/src/common';
import { startOfDay, endOfDay, addDays, addHours, differenceInDays, subDays, isBefore } from 'date-fns';
import { WindowRefService } from 'src/services/window-ref-service';
import { ConnectionService } from '../../services/connection-service';
import { LocalStorage } from '../../services/local-storage-service';
import { AppConfig } from '../app.config';
import { DashboadOrderComponent } from './order-information/order.component';
import { Broadcaster } from '../../components/broadcaster';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.html',
  styleUrls: ['./dashboard.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboadComponent {
  @ViewChild(DashboadOrderComponent) dashboadOrderComponent: DashboadOrderComponent;
  currentUser: any;
  allowReSelection: { team: boolean } = { team: false };
  user: any;
  teams: Array<Table.InternalTeam> = [];
  mbbsDoctors: Array<any> = [];
  dermatDoctors: Array<any> = [];
  userRoles: Array<any> = [];
  username: string;
  selectedTeam: Table.InternalTeam;
  todayDate: { start: Date; end: Date; } = {
    start: startOfDay(new Date()),
    end: endOfDay(new Date()),
  };
  tomorrowDate: { start: Date; end: Date; } = {
    start: startOfDay(addDays(new Date(), 1)),
    end: endOfDay(addDays(new Date(), 1)),
  };
  userType: ValueOf<typeof ApiClientConstant.User.UserType>;
  startDate: Date;
  endDate: Date;
  onlineDoctors: Array<any> = [];
  addedOrderApprovals: number = 0;
  uniqueInterval: any;
  totalOrderCreated: number = 0;
  totalDeliveredOrder: number = 0;
  minOrderToCreate: number = 0;
  diffInDays: number = 0;
  teamLeadIds: Array<string> = [];
  newUI: boolean = true;
  range: FormGroup;
  teamMemberType: Array<string> = ['DERMAT', 'MBBS'];
  operators: Array<Table.User>;
  userDetails: { startDate: Date, endDate: Date, user: Table.User };

  constructor(private conn: ConnectionService,
    private router: Router,
    private route: ActivatedRoute,
    private appConfig: AppConfig,
    private changeDetectorRef: ChangeDetectorRef,
    private broadcaster: Broadcaster,
    private localStorageService: LocalStorage,
    private fb: FormBuilder,
    private windowService: WindowRefService) {
    this.range = this.fb.group({
      start: [startOfDay(new Date())],
      end: [startOfDay(addDays(new Date(), 1))],
    });
  }

  async ngOnInit(): Promise<any> {
    this.currentUser = await this.conn.getCurrentUser();
    this.userType = this.currentUser.get('userType');
    this.user = await this.conn.getCurrentUser();
    this.username = this.user.get('username');
    this.userRoles = this.localStorageService.getJsonValue('userRoles') || [];
    const currentHour = new Date().getHours();
    this.startDate = currentHour < 6
      ? addHours(startOfDay(subDays(new Date(), 1)), 6)
      : addHours(startOfDay(new Date()), 6);
    this.endDate = currentHour < 6
      ? addHours(startOfDay(new Date()), 6)
      : addHours(startOfDay(addDays(new Date(), 1)), 6);

    this.diffInDays = differenceInDays(this.endDate, this.startDate);
    this.allowReSelection.team = this.userRoles.includes('admin') || this.userRoles.includes('adminDoctor');
    this.userDetails = { startDate: this.startDate, endDate: this.endDate, user: this.user };
    await this.getAllTeams();
    this.getAllOnlineDoctors();
    this.uniqueInterval = setInterval(() => {
      this.getAllOnlineDoctors();
    }, 10000);
  }

  teamSelectionChanged(event: any): void {
    this.selectedTeam = this.teams[event.target.value];
    if (this.selectedTeam.get('type') === ApiClientConstant.InternalTeam.Type.DERMAT_TEAM) {
      this.dermatDoctors = this.selectedTeam.get('members');
      this.mbbsDoctors = this.selectedTeam.get('extraMembers');
    } else {
      this.operators = this.selectedTeam.get('members');
    }
    delete this.username;
  }

  async onChangeDate(): Promise<void> {
    this.startDate = addHours(startOfDay(new Date(this.range.get('start')?.value)), 6);
    this.endDate = addHours(startOfDay(new Date(this.range.get('end')?.value)), 6);
    await this.reset();
  }

  async doctorSelectionChanged(isSelectedDermat:boolean): Promise<void> {
    this.user = isSelectedDermat ? this.dermatDoctors.find((each: any) => each.get('username') === this.username)
      : this.mbbsDoctors.find((each: any) => each.get('username') === this.username);
    await this.reset();
  }

  async operatorSelectionChanged(event: any): Promise<void> {
    this.user = this.operators[event.target.value];
    await this.reset();
  }

  async reset(): Promise<void> {
    this.startDate = addHours(startOfDay(new Date(this.range.get('start')?.value)), 6);
    this.endDate = addHours(startOfDay(new Date(this.range.get('end')?.value)), 6);
    this.userDetails = { endDate: this.endDate, startDate: this.startDate, user: this.user };
    this.diffInDays = differenceInDays(this.endDate, this.startDate);
  }

  async getAllOnlineDoctors(): Promise<void> {
    try {
      const onlineDoctors = await this.conn.getAllOnlineDoctors([
        { query_string: { analyze_wildcard: true, query: 'internalUser.type:doctor' } },
      ]);
      this.onlineDoctors = onlineDoctors.map((each: { username: string }) => each.username);
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  ngOnDestroy(): void {
    delete this.conn;
    clearInterval(this.uniqueInterval);
  }

  private async getAllTeams(): Promise<void> {
    const teams = await this.conn.findInternalTeam({
      where: { active: true },
      include: ['members', 'extraMembers'],
      project: [
        'extraMembers.username' as 'extraMembers',
        'extraMembers.userType' as 'extraMembers',
        'members.username' as 'members',
        'members.userType' as 'members',
        'name',
        'extraMembers.DoctorDisplayName' as 'extraMembers',
        'members.DoctorDisplayName' as 'members',
        'lead',
        'type',
        'members.exotelNumber' as 'members',
        'extraMembers.exotelNumber' as 'extraMembers',
      ],
    });
    this.teams = teams;
    teams.forEach((team: any) => this.teamLeadIds.push(team.get('lead').id));
    this.updateTeamSelectionAccess();
  }

  updateTeamSelectionAccess(): void {
    if (this.teamLeadIds.includes(this.currentUser.id)) {
      this.allowReSelection.team = true;
    }
  }

  toggleNav(): void {
    const sidebar = this.windowService.nativeWindow.document.getElementById('mySidebar');
    const main = this.windowService.nativeWindow.document.getElementById('main');
    const hamburger = this.windowService.nativeWindow.document.getElementById('hamburger');
    if (sidebar && main) {
      sidebar.classList.toggle('open');
      main.classList.toggle('shifted');
    }
    if (hamburger) {
      hamburger.classList.toggle('close');
    }
  }

  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
