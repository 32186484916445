import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { DirectivesModule } from '../../../../components/directives.module';
import { QuestionEditComponent } from './question-edit.component';
import { LanguageStringSelectionModule } from '../../../../components/language-string/language-string-selection';
import { InputTextModule } from '../../../../components/input/input-text';
import { InputSelectModule } from '../../../../components/input/input-select';
import { InputTextareaModule } from '../../../../components/input/input-textarea';
import { InputCheckboxModule } from '../../../../components/input/input-checkbox';
import { LanguageStringAutoCompleteModule } from '../../../../components/language-string/language-string-auto-complete';
import { LanguageViewModule } from '../../../../components/language-string/language-view';
import { QuestionConditionModule } from './question-condition';

const ROUTES: Routes = [
  { path: '', component: QuestionEditComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    LanguageStringSelectionModule,
    InputTextModule,
    InputSelectModule,
    InputTextareaModule,
    InputCheckboxModule,
    LanguageViewModule,
    LanguageStringAutoCompleteModule,
    QuestionConditionModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [QuestionEditComponent],
  exports: [QuestionEditComponent],
})
export class QuestionEditModule {
}
