
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/trees/main-concerns">Main Concerns</a></li>
          <li>{{mainConcernId ? 'Edit' : 'New'}}</li>
        </ul>
      </div>
    </div>
    <div class="flex-content">
      <form class="col-xs-12 p-4" #mainConcernForm="ngForm">
        <div class="col-xs-12">
          <label class="col-xs-4 mt-0">Display Name</label>
          <div class="col-xs-12">
            <language-string-selection class="col-xs-7" [parseObj]="mainConcern" [tags]="[&quot;main&quot;]" name="displayNameLanguageString" [required]="true"></language-string-selection>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Description</label>
          <div class="col-xs-12">
            <language-string-selection class="col-xs-7" [parseObj]="mainConcern" [tags]="[&quot;main-concern-description&quot;]" name="descriptionLanguageString"></language-string-selection>
          </div>
        </div>
        <div class="col-xs-12">
          <language-string-multiple-selection class="col-xs-7" [parseObj]="mainConcern" name="tempKeywords" title="MainConcern Tags" [required]="true" [tags]="[&quot;main&quot;]"></language-string-multiple-selection>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Class
            <div class="col-xs-12">
              <mat-form-field class="col-xs-5">
                <mat-select class="w100" name="class" [(ngModel)]="selectedClass" (ngModelChange)="changeMainConcernClass()">
                  <mat-option *ngFor="let option of classes" [value]="option">{{option}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </label>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4 mt-0">Rank</label>
          <div class="col-xs-12">
            <input-text class="col-xs-5" [parseObj]="mainConcern" name="rank" type="number" placeholder="Rank" [required]="true"></input-text>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-12">Gender</label>
          <div class="col-xs-6">
            <input-select [parseObj]="mainConcern" name="gender" [options]="genderOption"></input-select>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Value</label>
          <div class="col-xs-12">
            <input-text class="col-xs-5" [parseObj]="mainConcern" name="value" placeholder="Value" [required]="true"></input-text>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Section</label>
          <div class="col-xs-12">
            <input-text class="col-xs-5" [parseObj]="mainConcern" name="section" placeholder="Section" [required]="true"></input-text>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-4">Tree</label>
          <div class="col-xs-12">
            <input-text class="col-xs-5" [parseObj]="mainConcern" name="tree" placeholder="Tree Name"></input-text>
          </div>
        </div>
        <div class="col-xs-12 flex">
          <label class="mr-2">Visible to User :</label>
          <input-checkbox [parseObj]="mainConcern" name="visibleToUser"></input-checkbox>
        </div>
        <div class="col-xs-12 mt-3">
          <button class="db" [disabled]="!mainConcernForm.valid" (click)="saveMainConcern()">{{mainConcernId ? 'Update' : 'Create'}}</button>
        </div>
      </form>
    </div>
  </div>
</div>