
<mat-dialog-content class="mat-dialog-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Call Completed Response</h4><i class="fas fa-times pull-right fontL3" (click)="cancel()"></i>
  </div>
  <div class="modal-body">
    <form class="col-xs-12" #callCompletedForm="ngForm">
      <div class="col-xs-12">
        <select class="form-control" [(ngModel)]="reason" (ngModelChange)="enableFollowUp()" required="true" name="reason">
          <option disabled="true" selected="true" value="">>>>>>>>Select Response<<<<<<<<</option>
          <option *ngFor="let option of options;" value="{{option.value}}">{{option.display}}</option>
        </select>
      </div>
      <div class="col-xs-12 mt-2" *ngIf="subOptions[reason]">
        <select class="form-control" [(ngModel)]="subReason" required="true" name="subReason">
          <option disabled="true" selected="true" value="">>>>>>>>Select Response<<<<<<<<</option>
          <option *ngFor="let option of subOptions[reason];" value="{{option.value}}">{{option.display}}</option>
        </select>
      </div>
      <div class="col-xs-12 mt-2">
        <textarea class="form-control" [(ngModel)]="note" placeholder="Notes(Optional)" name="notes"></textarea>
      </div>
      <div class="col-xs-12 col-md-12" *ngIf="type === appConfig.Shared.PendingCall.Type.OrderConfirmation && [&quot;ConsentConfirmed&quot;, &quot;Other&quot;].includes(reason)">
        <label class="col-xs-12">What user needs ?</label>
        <mat-radio-group [(ngModel)]="userNeedForConfirmation" required="required">
          <mat-radio-button class="col-xs-12" [value]="appConfig.Shared.CallConfirmation.DOCTOR_CALL">User wanted a doctor call</mat-radio-button>
          <mat-radio-button class="col-xs-12" [value]="appConfig.Shared.CallConfirmation.VOICE_INSTRUCTION">User wanted Voice Instruction</mat-radio-button>
          <mat-radio-button class="col-xs-12" [value]="appConfig.Shared.CallConfirmation.NONE">None</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="col-xs-12 mt-3">
        <div class="col-xs-4 flex">
          <label class="mr-3 mb-0 mt-0">FollowUp Call</label>
          <label class="i-switch i-switch-sm bg-success m-0">
            <input type="checkbox" [(ngModel)]="followUpCallNeeded" name="followUpCallNeeded"/><i></i>
          </label>
        </div>
        <div class="col-xs-7 flex" *ngIf="followUpCallNeeded">
          <label class="mr-3 mt-0 mb-0">Time</label>
          <input class="form-control input-sm" type="datetime-local" [(ngModel)]="followUpCallTime" required="true"/>
        </div>
      </div>
      <div class="col-xs-12" *ngIf="followUpCallNeeded">
        <div class="col-xs-12 flex justifySB">
          <div class="col-xs-5">
            <label>Language</label>
            <select class="form-control" [(ngModel)]="preferedLanguage" required="true" name="preferedLanguage">
              <option *ngFor="let option of languageOptions;" value="{{option}}">{{option}}</option>
            </select>
          </div>
          <div class="col-xs-5">
            <label>Team</label>
            <select class="form-control" [(ngModel)]="team" required="true" name="team">
              <option *ngFor="let option of teamOptions;" value="{{option}}">{{option}}</option>
            </select>
          </div>
        </div>
        <div class="col-x-12 mt-2">
          <label>Reference Message</label>
          <textarea class="form-control" [(ngModel)]="referenceMessage" placeholder="Reference Message (Optional)" name="referenceMessage"></textarea>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="pull-right m-1 btn-xs" type="button" (click)="saveResponse()" [disabled]="!reason || !callCompletedForm.valid">Update</button>
    <button class="pull-right m-1 btn-xs" *ngIf="team === 'checkIn' && reason === 'CallCompleted'" type="button" (click)="sendFeedbackForm()">Send Feedback Form</button>
  </div>
</mat-dialog-content>
