
<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Assigned Chat History</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body" *ngFor="let item of assignedChats;">
    <div class="col-xs-12 p-0">
      <div class="col-xs-12 text-center mb-2">
        <div class="label label-success" [ngClass]="{ &quot;label-danger&quot;: item.status === &quot;Finished&quot;}">{{item.status}}</div>
        <div class="label label-info pointer ml-2" *ngIf="item.status === &quot;Assigned&quot; && item.team === &quot;doctor&quot;" (click)="changeAssignedDoctor(item)">Change Doctor</div>
      </div>
      <div class="col-xs-6 b-r">
        <div class="col-xs-12 p-0 bold mb-2">Assignment Details ({{item.assignedDoctor}}, {{item.assignedOperator}})</div>
        <div class="col-xs-12 p-0">Assigned by {{item.assignedByName}} at {{item.createdAt}}</div>
        <div class="col-xs-12 p-0">{{item.message || '-'}}</div>
      </div>
      <div class="col-xs-6 pl-3">
        <div class="col-xs-12 p-0 bold mb-2">Completion Details</div>
        <div class="col-xs-12 p-0" *ngIf="item.completedAt">Completed by {{item.completedByName}} at {{item.completedAt}}</div>
        <div class="col-xs-12 p-0">{{item.responseMessage || '-'}}</div>
      </div>
    </div>
  </div>
</mat-dialog-content>
