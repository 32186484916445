import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Table } from 'api-client';
import { ConnectionService } from '../../services/connection-service';
import { followupChatFieldsToSkip } from './fields-to-skip-in-followup-chat';
import { AppConfig } from '../../app/app.config';
import { FollowUpDialog } from '../../app/chat/view/follow-up';

@Component({
  selector: 'followup-chat-view-summary',
  templateUrl: './followup-chat-view.html',
})
export class FollowupChatViewComponent {
  @Input('followupId')
  set setFollowUp(followupId: any) {
    if (!followupId) {
      return;
    }
    this.followUpId = followupId;
    this.loadData();
  }

  @Input('user')
  set setUser(user: any) {
    if (!user) {
      return;
    }
    this.user = user;
  }
  user: any;
  followUpId: string;
  followUp: any;
  followupChat: Array<any> = [];
  followUpReports: Array<{ key: string, value: string, editable?: boolean, editLink?: string }> = [];
  // highlightedKeys: Array<string> = ['anyOtherConcernInFollowup', 'married', 'thyroidOrSeriousIllness', 'sensitiveSkin',
  //   'followUpBodyImprovement', 'followUpHairImprovement', 'followUpFaceImprovement', 'ProgressCheckFaceAppointment'];
  highlightedKeys: Array<string> = [];
  thyroidOrSeriousIllness: { value?: string, updatedAt?: Date } = {};
  thyroidOrSeriousIllnessOptions: Array<string> = ['Low iron', 'thyroid', 'Deficiency of any vitamin',
    'Significant weight loss in the last 6 months', 'Feeling excessively tired or stressed', 'None of the above', 'I don\'t know.'];
  displayFollowUpQuestions: any = {
    MAINCONCERN: 'MainConcern',
    THYROIDORSERIOUSILLNESS: 'Thyroid Illness',
    FEMALEHORMONAL: 'Female Hormonal',
    MARRIED: 'Married',
    FOLLOWUPFACEIMPROVEMENT: 'Face Improvement Seen',
    FOLLOWUPFACEIMPROVEMENTNOCHANGE: 'Any Other Concern In Face',
    FOLLOWUPHAIRIMPROVEMENT: 'Hair Improvement Seen',
    FOLLOWUPHAIRIMPROVEMENTNOCHANGE: 'Any Other Concern In Hair',
    PRODUCTSUSEDV2FOLLOWUP: 'Products Using',
    PROGRESSCHECKFACEAPPOINTMENT: 'Problems Facing',
    ANYOTHERCONCERNINFOLLOWUP: 'Any Other Concern',
    FOLLOWUPBODYIMPROVEMENT: 'Body Improvement Seen',
    FOLLOWUPBODYIMPROVEMENTNOCHANGE: 'Any Other Concern In Body',
    WAITINGFORIMAGETYPES: 'Photos needed',
  };
  activeRegimens: Array<string> = [];
  constructor(private conn: ConnectionService,
    private router: Router,
    public appConfig: AppConfig,
    public dialog: MatDialog) {
  }

  private async loadSummary(followupId: string): Promise<void> {
    this.followUp = JSON.parse(JSON.stringify(await this.conn.findOneFollowUp({
      where: { objectId: followupId },
      include: ['regimens'],
    })));
    this.activeRegimens = this.followUp.regimens.map((regimen: any) => regimen.class);
    followupChatFieldsToSkip.forEach((each: string) => delete this.followUp[each]);
    Object.keys(this.followUp).forEach((each: string) => {
      if (this.highlightedKeys.includes(each)) {
        this.followupChat.push({ key: each, value: this.followUp[each], highlight: true });
      } else {
        this.followupChat.push({ key: each, value: this.followUp[each] });
      }
    });
  }

  async loadData(): Promise<void> {
    await this.reset();
    await this.loadSummary(this.followUpId);
    await this.findFemaleHormonalFromActiveConsultationSession(this.user);
    await this.loadCurrentFollowUpReports();
  }

  reset(): void {
    this.followUp = undefined;
    this.followupChat = [];
    this.followUpReports = [];
  }
  async findFemaleHormonalFromActiveConsultationSession(user: any): Promise<void> {
    const where = { user };
    const consultationSessions = await this.conn.findConsultationSession({
      where,
      project: ['FemaleHormonal', 'ThyroidOrSeriousIllness', 'SensitiveSkin'],
      descending: 'createdAt',
    });
    if (!consultationSessions.length) {
      return;
    }
    const femaleHormonalUniqueAnswers = new Set();
    consultationSessions.forEach((session: any) => {
      femaleHormonalUniqueAnswers.add(session.get('FemaleHormonal'));
      if (!this.thyroidOrSeriousIllness.value) {
        const thyroidOrSeriousIllnessValue = this.thyroidOrSeriousIllnessOptions[
          this.convertToNumberIfValid(session.get('ThyroidOrSeriousIllness')) - 1];
        this.thyroidOrSeriousIllness.value = thyroidOrSeriousIllnessValue;
        this.thyroidOrSeriousIllness.updatedAt = session.createdAt;
      }
    });
    this.followupChat.push({ key: 'thyroidOrSeriousIllness',
      value: this.thyroidOrSeriousIllness.value,
      date: this.thyroidOrSeriousIllness.updatedAt });
    this.followupChat.push({ key: 'sensitiveSkin', value: consultationSessions[0].get('SensitiveSkin') });
    this.followupChat.push({ key: 'femaleHormonal', value: Array.from(femaleHormonalUniqueAnswers).toString() });
    const femaleMarriedRow = await this.conn.findLatestResponseForFields(user, ['FemaleMarried', 'followUpMarried']);
    if (femaleMarriedRow) {
      this.followupChat.push({ key: 'married', value: femaleMarriedRow });
    }
    if (user.get('PrivateMainConcern')) {
      this.followupChat.unshift({ key: 'mainConcern', value: user.get('PrivateMainConcern')?.split('_').join(' ').toLowerCase() });
    }
  }

  convertToNumberIfValid(input: string): any {
    if (/^[0-9]+(\.[0-9]*)?$/.test(input)) {
      return parseFloat(input);
    }
    return NaN;
  }

  async loadCurrentFollowUpReports(): Promise<void> {
    const followUp = new Table.FollowUp();
    followUp.id = this.followUpId;
    const reports = await this.conn.findReports({
      where: { user: this.user, isSecondaryComparison: false, followUp },
      project: ['type', 'read', 'beforeInstantCheckup', 'afterInstantCheckup'],
    });
    reports.forEach((report: any) => {
      this.followUpReports.push({ key: report.get('type'),
        value: report.id,
        editLink: `/compare/${report.get('beforeInstantCheckup').id}/${report.get('afterInstantCheckup').id}`
          + `/${this.user.get('username')}?edit=${report.id}`,
        editable: report.get('read') });
    });
  }

  openFollowUpHistory(SelectedPopUpType: string): void {
    this.dialog.open(FollowUpDialog, {
      data: {
        SelectedPopUpType,
        followUp: this.followUp,
        userId: this.user.get('username'),
        chatUser: this.user,
      },
    });
  }
}
