
<div class="hbox">
  <div class="w100 flex gap-12 mt-3">
    <div class="w60 comparison-section section">
      <header class="comparison-section-header">
        <p class="pill">face auto</p>
        <div class="flex-1"></div>
        <p class="pill-dark" style="background-color: #f0f0f0">+</p>
      </header>
      <div class="comparison-section-body">
        <div class="auto-face">
          <ng-container *ngIf="!editedCheckup && class === appConfig.Shared.FollowUpReport.Type.FACE">
            <app-auto-face [instantCheckupIds]="primaryInstantCheckUpIds" [username]="user?.get(&quot;username&quot;)" (imageConfig)="setImageConfig($event, 0)" (selectBeforeImage)="selectBeforePhotoForInvestigation(0)" (selectAfterImage)="selectAfterPhotoForInvestigation(0)" (showManualEditSection)="editedCheckup = true" [title]="primaryComparisonTitle"></app-auto-face>
          </ng-container>
          <ng-container *ngIf="editedCheckup || class !== appConfig.Shared.FollowUpReport.Type.FACE">
            <comparison-viewer #primaryComparison [user]="user" [instantCheckupIds]="primaryInstantCheckUpIds" (selectBeforeImage)="selectBeforePhotoForInvestigation(0)" (selectAfterImage)="selectAfterPhotoForInvestigation(0)" [imageConfig]="reportObject.imageConfigs[0]" (setImageConfig)="setImageConfig($event, 0)" [title]="primaryComparisonTitle"></comparison-viewer>
          </ng-container>
          <div class="actions"><i><img src="../../assets/images/delete.png" style="width: 22px; height: 22px;" (click)="deleteComparison(0)"/></i><i><img src="../../assets/images/border_color.png" style="width: 22px; height: 22px;" (click)="openImageComparisonPopup(0)"/></i></div>
        </div>
        <div class="divider"></div>
        <div class="auto-face">
          <comparison-viewer #secondaryComparison [user]="user" [instantCheckupIds]="secondaryInstantCheckUpIds" (selectBeforeImage)="selectBeforePhotoForInvestigation(1)" (selectAfterImage)="selectAfterPhotoForInvestigation(1)" [title]="secondaryComparisonTitle" (setImageConfig)="setImageConfig($event, 1)" [imageConfig]="reportObject.imageConfigs[1]"></comparison-viewer>
          <div class="actions"><i><img src="../../assets/images/delete.png" style="width: 22px; height: 22px;" (click)="deleteComparison(1)"/></i><i><img src="../../assets/images/border_color.png" style="width: 22px; height: 22px;" (click)="openImageComparisonPopup(1)"/></i></div>
        </div>
      </div>
    </div>
    <div class="w40 flex gap-12" style="align-self: stretch; flex-direction: column; justify-content: start; max-height: 700px; overflow-y: auto;">
      <div class="steroid-question section w100" *ngIf="!user.get('marketingTags')?.includes('STEROID') && showSteroidQuestion">
        <div class="flex">
          <div>Is User Steroid</div>
          <div class="flex justifySB">
            <div class="btn bdr ml-3" (click)="updateSteroidTag(true)">Yes</div>
            <div class="btn bdr ml-3" (click)="updateSteroidTag(false)">No</div>
          </div>
        </div>
      </div>
      <div class="treatment-progress section w100" *ngIf="!followUpSopEnabled">
        <div class="title">Treatment Progress</div>
        <div class="content">
          <div class="bar">
            <div class="flex-score">
              <div class="div flex">
                <div class="concern-name"></div>
              </div>
              <div class="div flex" style="gap: 5px">
                <div class="progress-title">INCREASED</div>
                <div class="progress-title">SAME</div>
                <div class="progress-title">BETTER</div>
                <div class="progress-title">GOOD</div>
              </div>
            </div>
          </div>
          <div class="bar flex" *ngFor="let progress of treatmentProgress; let i=index">
            <div class="flex-score bdr-btm">
              <div class="div flex">
                <div class="concern-name">{{progress.issue}}</div>
              </div>
              <div class="div flex" style="gap: 5px">
                <div class="increased progress-score justifyC alignC" (click)="updateScore(i, 0)"><i class="fa-solid fa-check" *ngIf="progress.score == 0"></i></div>
                <div class="same progress-score justifyC alignC" (click)="updateScore(i, 1)"><i class="fa-solid fa-check" *ngIf="progress.score == 1"></i></div>
                <div class="better progress-score justifyC alignC" (click)="updateScore(i, 3)"><i class="fa-solid fa-check" *ngIf="progress.score == 3"></i></div>
                <div class="good progress-score justifyC alignC" (click)="updateScore(i, 4)"><i class="fa-solid fa-check" *ngIf="progress.score == 4"></i></div>
              </div>
            </div>
            <div class="flex-delete">
              <div class="delete"><i><img src="../../assets/images/delete.png" style="width: 18px; height: 18px; color: #1C1B1F;" (click)="deleteBar(i)"/></i></div>
            </div>
          </div>
          <div class="add-concern-button" (click)="addNewConcern()">Add new +</div>
        </div>
      </div>
      <div class="investigation section w100" *ngIf="!followUpSopEnabled">
        <div class="investigation-body">
          <div class="investigation-options">
            <ng-container *ngIf="this.investigationOptions?.length">
              <input-select-text style="font-weight: 400" [options]="this.investigationOptions" selectWidth="300px" defaultSelect="Choose Test" name="investigationOptions" (onValueChanged)="onSelectInvestigation($event)"></input-select-text>
            </ng-container>
            <div class="add-test-button" (click)="addCustomTest()">Add custom test +</div>
          </div>
          <div class="choosen-investigation-tests">
            <div class="flex" *ngFor="let test of reportObject.investigations?.tests; let i = index"><span class="pR10">{{test}}</span>
              <div class="fas fa-trash-o" (click)="removeTestFromInvestigation(i)"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="treatment-outcome section w100" *ngIf="!followUpSopEnabled">
        <div class="flex justify-content-between">
          <div>Treatment Outcome</div>
          <ng-container *ngIf="this.treatmentOutcomeOptions?.length">
            <input-select-text [options]="this.treatmentOutcomeOptions" selectWidth="300px" defaultSelect="Choose Outcome" name="investigationOptions" (onValueChanged)="onSelectTreatmentOutcome($event)"></input-select-text>
          </ng-container>
        </div>
      </div>
      <div class="followUp-sop section w100" *ngIf="followUpSopEnabled">
        <div class="pl-3 pr-5">
          <div>Treatment Outcome</div>
          <div class="my-4" *ngIf="this.followUpSopOptions?.length">
            <input-select-text [options]="this.followUpSopOptions" selectWidth="300px" defaultSelect="Select sop" name="followUpSop" (onValueChanged)="onSelectFollowUpSop($event)"></input-select-text>
          </div>
        </div>
        <div class="default-selected-sop" *ngIf="this.followUpSop.selectedFollowUpSop">
          <div class="flex" *ngFor="let concern of this.followUpSop.selectedFollowUpSop?.treatmentProgress">
            <div class="boldText">{{concern.issue}}</div>
            <div class="score ml-5" [ngStyle]="{backgroundColor: getBackgroundColor(concern.score)}">{{getTextForScore(concern.score)}}<i class="fa-solid fa-check clr-black"></i></div>
          </div>
          <div class="flex alignFS mt-3" *ngIf="this.followUpSop.selectedFollowUpSop.tests?.name">
            <div class="boldText">Investigation</div>
            <div class="normalText ml-5">
              <div>{{this.followUpSop.selectedFollowUpSop.tests.name}}</div>
              <ul>
                <li class="text-lwr" *ngFor="let test of this.followUpSop.selectedFollowUpSop.tests?.tests">{{test}}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="normalText mt-3 pl-3" *ngIf="this.followUpSop.selectedFollowUpSop"><span class="fw-700">Regimen: &nbsp;</span><span>({{regimen.get('title')}})&nbsp;</span><span class="clr-green">Updated</span><i class="fa-solid fa-check clr-green"></i></div>
      </div>
    </div>
  </div>
</div>
