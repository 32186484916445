
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/users/doctors">Doctors</a></li>
          <li>Edit</li>
        </ul>
      </div>
    </div>
    <div class="flex-content" *ngIf="userObj">
      <form class="col-xs-12 p-4" #doctorEditForm="ngForm">
        <div class="col-xs-12 col-md-6">
          <div class="col-xs-12">
            <label class="mt-0 col-xs-12">Display Name</label>
            <div class="col-xs-10">
              <input-text [parseObj]="userObj" name="DoctorDisplayName" placeholder="" [required]="true"></input-text>
            </div>
          </div>
          <div class="col-xs-12">
            <label class="mt-0 col-xs-12">For Doctor</label>
            <div class="col-xs-10">
              <input-text [parseObj]="userObj" name="forDoctor" placeholder="" (onValueChange)="handleValueChange($event)"></input-text>
            </div>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-12">Qualification</label>
            <div class="col-xs-10">
              <input-text [parseObj]="userObj" name="doctorQualification" placeholder="" [required]="true"></input-text>
            </div>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-12">Qualification (Internal Use)</label>
            <div class="col-xs-10">
              <input-text [parseObj]="userObj" name="doctorQualificationInternal" placeholder="" [required]="true"></input-text>
            </div>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-12">No.Of Patients Treated</label>
            <div class="col-xs-10">
              <input-text [parseObj]="userObj" name="patientsTreated" placeholder="" [required]="true"></input-text>
            </div>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-12">Medical Council</label>
            <div class="col-xs-10">
              <input-select [parseObj]="userObj" name="medicalCouncil" [options]="medicalCouncilOptions" placeholder="" [required]="true"></input-select>
            </div>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-12">Medical Council Reg Number</label>
            <div class="col-xs-10">
              <input-text [parseObj]="userObj" name="mciNumber" placeholder="" [required]="true"></input-text>
            </div>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-12" *ngIf="showPersonalizedInfo">Personal Information<i class="fa fa-plus-square-o ml-2" (click)="show = !show"></i>
              <div class="col-xs-12 ml-6" *ngIf="show">
                <label class="col-xs-12">Qualification</label>
                <div class="col-xs-10">
                  <input class="col-xs-12" type="text" [(ngModel)]="personalInformation.qualification" name="qualification"/>
                </div>
                <label class="col-xs-12">Medical Council State</label>
                <div class="col-xs-10">
                  <input class="col-xs-12" type="text" [(ngModel)]="personalInformation.medicalCouncilState" name="medicalCouncilState"/>
                </div>
                <label class="col-xs-12">Medical Council Registration Number</label>
                <div class="col-xs-10">
                  <input class="col-xs-12" type="text" [(ngModel)]="personalInformation.medicalCouncilRegNumber" name="medicalCouncilRegNumber"/>
                </div>
              </div>
            </label>
          </div>
          <div class="col-xs-12">
            <label>Languages Known</label>
            <div class="col-xs-12 flex flex-wrap"><span class="flex mr-3" *ngFor="let each of languages; let i = index">
                <input [checked]="each.selected" type="checkbox" name="knownLanguages" [value]="each.value" id="{{i}}lang" (change)="each.selected = !each.selected"/>
                <label class="mt-3 ml-3" for="{{i}}lang">{{each.display}}</label></span></div>
          </div>
          <div class="col-xs-10">
            <div class="col-lg-4 col-xs-12">
              <div class="col-xs-12">
                <label>Image</label><a class="fa fa-eye" href="{{ userObj.get(&quot;DoctorImageThumbnail&quot;) }}" target="_blank"></a>
                <input-single-image-upload [parseObj]="userObj" [username]="userObj.get(&quot;username&quot;)" name="DoctorImageThumbnail" [accept]="&quot;image/png&quot;"></input-single-image-upload>
              </div>
            </div>
            <div class="col-lg-4 col-xs-12">
              <div class="col-xs-12">
                <label>Display picture</label><a class="fa fa-eye" href="{{ userObj.get(&quot;doctorDisplayImage&quot;) }}" target="_blank"></a>
                <input-single-image-upload [parseObj]="userObj" [username]="userObj.get(&quot;username&quot;)" name="doctorDisplayImage" [accept]="&quot;image/jpg&quot;"></input-single-image-upload>
              </div>
            </div>
            <div class="col-lg-4 col-xs-12">
              <div class="col-xs-12 flex flex-dir-col alignC">
                <div class="flex">
                  <label class="mb-3">Signature</label>
                </div>
                <div class="flex flex-dir-col">
                  <div class="flex" style="min-height: 90px;"><img class="mb-2" *ngIf="signImage" style="width: 80px; height: 80px;" src="{{signImage}}"/></div>
                  <button class="btn-xs btn-primary" (click)="openCropPopup()">Upload & Crop</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12">
            <label>Videos links</label>
            <div class="col-xs-12">
              <mat-form-field>
                <mat-select class="w100" name="selectedLanguage" [(ngModel)]="selectedLanguage" (ngModelChange)="changeDoctorTemplate()">
                  <mat-option *ngFor="let option of options" [value]="option">{{option}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-xs-12 bold">
              <div class="flex">
                <div class="col-xs-5 pr-2 fontS2">Meet Your Doctor</div>
                <input class="mb-2 w100" type="text" name="meetYourDoctor" [(ngModel)]="videos.meetYourDoctor"/><a class="fa fa-eye ml-3" href="{{videos.meetYourDoctor}}" target="_blank"></a>
              </div>
              <div class="flex">
                <div class="col-xs-5 pr-2 fontS2">Video Thumbnail</div>
                <input class="mb-2 w100" type="text" name="thumbnailImage" [(ngModel)]="videos.thumbnailImage"/><a class="fa fa-eye ml-3" href="{{videos.thumbnailImage}}" target="_blank"></a>
              </div>
              <div class="flex">
                <div class="col-xs-5 pr-2 fontS2">Using Your Regimen - Hair</div>
                <input class="mb-2 w100" type="text" name="usingYourRegimenHair" [(ngModel)]="videos.usingYourRegimenHair"/><a class="fa fa-eye ml-3" href="{{videos.usingYourRegimenHair}}" target="_blank"></a>
              </div>
              <div class="flex">
                <div class="col-xs-5 pr-2 fontS2">Using Your Regimen - Skin</div>
                <input class="mb-2 w100" type="text" name="usingYourRegimenSkin" [(ngModel)]="videos.usingYourRegimenSkin"/><a class="fa fa-eye ml-3" href="{{videos.usingYourRegimenSkin}}" target="_blank"></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="col-xs-12">
            <label class="mt-0 col-xs-12">Professional Area</label>
            <div class="col-xs-10">
              <language-string-selection [parseObj]="userObj" name="doctorProfessionalAreaLanguageString" [required]="true" [tags]="[&quot;doctor_professional_area&quot;]"></language-string-selection>
            </div>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-12">Qualification Detail</label>
            <div class="col-xs-10">
              <language-string-selection [parseObj]="userObj" name="doctorQualificationDetailLanguageString" [required]="true" [tags]="[&quot;doctor_qualification&quot;]"></language-string-selection>
            </div>
          </div>
          <div class="col-xs-10">
            <language-string-multiple-selection [parseObj]="userObj" name="tagsLanguageString" title="Doctor Tags" [required]="true" [tags]="[&quot;doctor_tags&quot;]"></language-string-multiple-selection>
          </div>
        </div>
        <div class="col-xs-12 mt-3">
          <button class="db" [disabled]="!doctorEditForm.valid" (click)="updateDoctor()">Update</button>
        </div>
      </form>
      <div class="col-xs-12">
        <div class="col-xs-12 list-header">
          <div class="dib fontL1">Reviews</div><i class="fa fa-plus-square-o ml-2" (click)="openAddReviewDialog()"></i>
        </div>
        <div class="col-xs-12 p-4">
          <div class="col-xs-8" *ngIf="reviews?.length">
            <label class="col-xs-3 mt-0">Name</label>
            <label class="col-xs-4 mt-0">Treated For</label>
            <label class="col-xs-5 mt-0">Review</label>
          </div>
          <div class="col-xs-12 text-center bold" *ngIf="!reviews?.length">No reviews to show.</div>
          <div class="col-xs-8 mt-2" *ngFor="let review of reviews">
            <div class="col-xs-3">{{ review.get("name") }}</div>
            <div class="col-xs-4">
              <language-view [languageStringObj]="review.get(&quot;treatedForLanguageString&quot;)"></language-view>
            </div>
            <div class="col-xs-5">
              <language-view [languageStringObj]="review.get(&quot;reviewLanguageString&quot;)"></language-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>