
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/trees/questions">Questions</a></li>
          <li>{{questionObj?.id ? 'Edit' : 'New' }}</li>
        </ul>
      </div>
    </div>
    <div class="flex-content">
      <form class="col-xs-12 p-4" #questionForm="ngForm" (submit)="saveQuestion()">
        <div class="col-xs-12">
          <label class="col-xs-12 mt-0">Question</label>
          <div class="col-xs-12">
            <language-string-selection class="col-xs-8" [parseObj]="questionObj" name="titleLanguageString" [tags]="titleTags" [required]="true"></language-string-selection>
          </div>
          <div class="col-xs-3 mt-3">
            <input-text [parseObj]="questionObj" name="uniqueIdentifier" [required]="true" placeholder="Short Name (Unique)" pattern="[A-Za-z0-9]*" [disabled]="questionObj.id"></input-text>
          </div>
        </div>
        <div class="col-xs-12">
          <label>Template Params</label><i class="fas fa-plus-square ml-3 pointer" (click)="addTemplateParam()"></i>
          <div class="col-xs-12 p-0" *ngIf="questionObj.get(&quot;templateParams&quot;)?.length">
            <label class="col-xs-4 mt-0">Key</label>
            <label class="col-xs-1 mt-0 px-4">DB</label>
            <label class="col-xs-5 mt-0">Value</label>
          </div>
          <div class="col-xs-12 p-0 flex mb-3" *ngFor="let row of questionObj.get(&quot;templateParams&quot;); let i = index;">
            <div class="col-xs-4 flex">
              <input class="w100" [(ngModel)]="row.key" name="key_{{i}}"/>
            </div>
            <div class="col-xs-1 flex px-4">
              <label class="i-switch i-switch-sm bg-success m-0">
                <input class="w100" type="checkbox" [(ngModel)]="row.database" name="database_{{i}}"/><i></i>
              </label>
            </div>
            <div class="col-xs-5" *ngIf="row.database">
              <div class="col-xs-5 mr-3">
                <select [(ngModel)]="row.databaseValue" name="databaseValue_{{i}}" required="required">
                  <option *ngFor="let table of treeTables" value="{{table.value}}">{{table.display}}</option>
                </select>
              </div>
              <div class="col-xs-5">
                <input [(ngModel)]="row.databaseKey" name="databaseKey_{{i}}"/>
              </div>
            </div>
            <div class="col-xs-5" *ngIf="!row.database">
              <div class="col-xs-12 of-x-auto"><span class="badge bg-secondary p-2 rounded mr-2 mb-2" *ngFor="let value of row.valuesLanguageString || [];let valueIndex = index;">
                  <language-view [languageStringObj]="value"></language-view><i class="fa fa-trash-o pointer ml-2" (click)="removeToRowLanguagesStringValues(row, valueIndex)"></i></span></div>
              <language-string-auto-complete [tags]="[&quot;template params&quot;]" [prefix]="i" (onSelection)="addToRowLanguageString($event, row)"></language-string-auto-complete>
            </div><i class="fas fa-trash pointer ml-3" (click)="removeTemplateParam(i)"></i>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-12">Store In Table</label>
          <div class="col-xs-4">
            <input-select [parseObj]="questionObj" name="storeInTable" [required]="true" [options]="storeInTable"></input-select>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-12">Type</label>
          <div class="col-xs-4">
            <input-select [parseObj]="questionObj" name="type" [required]="true" [options]="types" (onValueChanged)="type = $event"></input-select>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-12">Table</label>
          <div class="col-xs-4">
            <input-select [parseObj]="questionObj" name="table" [required]="true" [options]="treeTables" (onValueChanged)="table = $event"></input-select>
          </div>
          <div class="col-xs-4 pl-3" *ngIf="table === apiClientConstant.TreeTables.TEMPORARY_ANSWER">
            <input-text [parseObj]="questionObj.get(&quot;params&quot;)" name="expireInDay" [required]="true" placeholder="Expire In (Days)" type="number"></input-text>
          </div>
        </div>
        <div class="col-xs-12">
          <label class="col-xs-12">Tasks</label>
          <div class="col-xs-4">
            <input-select class="mb-3" [parseObj]="questionObj" name="task" [required]="true" [options]="treeTasks" (onValueChanged)="taskTree = ($event === &quot;ASSISTANT_TASK&quot;)"></input-select>
          </div>
          <div class="col-xs-4 pl-3">
            <input-text *ngIf="taskTree" [parseObj]="questionObj" required="required" name="taskTree" placeholder="Enter the task"></input-text>
          </div>
        </div>
        <div class="col-xs-12" *ngIf="!isReminder">
          <div class="col-xs-6">
            <label class="col-xs-4">Scheduled Node</label>
            <input-checkbox [parseObj]="questionObj" name="isScheduled" (onValueChange)="isScheduled = $event"></input-checkbox>
          </div>
          <div class="col-xs-12 mt-3" *ngIf="isScheduled">
            <label class="col-xs-2">Scheduled By (hours)</label>
            <input type="number" [(ngModel)]="delayByInHours" name="scheduledBy" (ngModelChange)="questionObj.get(&quot;params&quot;).delayBy = delayByInHours * 60 * 60 * 1000"/>
          </div>
        </div>
        <div class="col-xs-12" *ngIf="!isScheduled">
          <div class="col-xs-6">
            <label class="col-xs-4">Reminder Node</label>
            <input-checkbox [parseObj]="questionObj" name="isReminder" (onValueChange)="isReminder = $event"></input-checkbox>
          </div>
          <div class="col-xs-12" *ngIf="isReminder">
            <label class="col-xs-2">Remind By (hours)</label>
            <input type="number" [(ngModel)]="remindByInHours" name="remindBy" (ngModelChange)="questionObj.get(&quot;params&quot;).remindBy = remindByInHours * 60 * 60 * 1000"/>
          </div>
        </div>
        <div class="col-xs-12" *ngIf="isScheduled || isReminder">
          <div class="col-xs-12">
            <label>Reminder/Scheduled Time Frame</label><i class="fas fa-plus-square ml-3 pointer" (click)="addTimeFrame()"></i>
          </div>
          <div class="col-xs-6 mb-2 flex clear-fix" *ngFor="let timeFrame of questionObj.get('params').timeFrame || [];let timeFrameIndex = index;">
            <div class="col-xs-5">
              <input class="input-xs form-control" type="number" min="1" max="24" [(ngModel)]="timeFrame.startInHour" name="{{timeFrameIndex}}_startInHour" (ngModelChange)="timeFrame.start = timeFrame.startInHour * 60" placeholder="Start Time (24 Hour)" required="required"/>
            </div>
            <div class="col-xs-1 text-center">-</div>
            <div class="col-xs-5">
              <input class="input-xs form-control" type="number" min="1" max="24" [(ngModel)]="timeFrame.endInHour" name="{{timeFrameIndex}}_endInHour" (ngModelChange)="timeFrame.end = timeFrame.endInHour * 60" placeholder="End Time (24 Hour)" required="required"/>
            </div>
            <div class="col-xs-1"><i class="fa fa-trash-o pointer ml-2" (click)="questionObj.get(&quot;params&quot;).timeFrame.splice(timeFrameIndex, 1)"></i></div>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-5">
            <label class="col-xs-8">Priority Message</label>
            <input-checkbox [parseObj]="questionObj" name="isPriorityMessage"></input-checkbox>
          </div>
          <div class="col-xs-5">
            <label class="col-xs-8">Auto Response</label>
            <input-checkbox [parseObj]="questionObj" name="autoResponse"></input-checkbox>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-5">
            <label class="col-xs-8">No Chat Message</label>
            <input-checkbox [parseObj]="questionObj" name="noChatMessage"></input-checkbox>
          </div>
          <div class="col-xs-5">
            <label class="col-xs-8">Silent</label>
            <input-checkbox [parseObj]="questionObj" name="isSilent"></input-checkbox>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-5">
            <label class="col-xs-8">Notification Only</label>
            <input-checkbox [parseObj]="questionObj" name="isNotificationOnly"></input-checkbox>
          </div>
          <div class="col-xs-5">
            <label class="col-xs-8">WhatsApp Message</label>
            <input-checkbox [parseObj]="questionObj" name="whatsAppMessage" (onValueChange)="updateTitleTags($event)"></input-checkbox>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-5">
            <label class="col-xs-8">UnBlock Chat</label>
            <input-checkbox [parseObj]="questionObj" name="unBlockChat"></input-checkbox>
          </div>
          <div class="col-xs-5">
            <label class="col-xs-8">Block Chat</label>
            <input-checkbox [parseObj]="questionObj" name="blockChat"></input-checkbox>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-5">
            <label class="col-xs-8">Audio Message</label>
            <input-checkbox [parseObj]="questionObj" name="isAudioMessageAllowed"></input-checkbox>
          </div>
        </div>
        <div class="col-xs-12" *ngIf="[apiClientConstant.Question.Mode.ARTICLE, apiClientConstant.Question.Mode.RICH_TEXT].includes(mode)">
          <div class="col-xs-5">
            <label class="col-xs-8">Diet Article</label>
            <input-checkbox [parseObj]="questionObj" name="isDietArticle"></input-checkbox>
          </div>
        </div>
        <div class="col-xs-12" *ngIf="type === apiClientConstant.Question.Type.EVALUATE">
          <div class="col-xs-12">
            <label class="col-xs-4">Evaluate Function</label>
            <div class="col-xs-12">
              <input-text [parseObj]="questionObj" required="required" name="evaluateFunction" placeholder=""></input-text>
            </div>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-4">Key Pair<i class="fa fa-plus-square-o pointer m-l-xs" (click)="addEvaluateKeyValue()"></i></label>
          </div>
          <div class="col-xs-12 flex my-2" *ngFor="let keyValue of questionObj.get(&quot;params&quot;).keyValue; let keyValueI = index;">
            <div class="col-xs-4 mr-3">
              <input class="form-control" [(ngModel)]="keyValue.key" name="key_evaluate_{{keyValueI}}"/>
            </div>
            <div class="col-xs-4">
              <input class="form-control" [(ngModel)]="keyValue.value" name="value_evaluate_{{keyValueI}}"/>
            </div>
            <div class="col-xs-2 pl-3"><i class="fa pointer fa-trash-o" (click)="removeEvaluateKeyValue(keyValueI)"></i></div>
          </div>
          <div class="col-xs-12">
            <label class="col-xs-4">Conditions</label>
          </div>
          <div class="col-xs-12">
            <question-condition [conditions]="questionObj.get(&quot;conditions&quot;)"></question-condition>
          </div>
        </div>
        <div class="col-xs-12 mt-4" *ngIf="type === apiClientConstant.Question.Type.SEND_TO_USER">
          <div class="col-xs-12">
            <button class="btn-xs mr-3" type="button" [ngClass]="{&quot;btn-primary-outline&quot;: mode !== apiClientConstant.Question.Mode.GENERIC_USER_INPUT,  &quot;btn-primary&quot;: mode === apiClientConstant.Question.Mode.GENERIC_USER_INPUT }" (click)="updateQuestionMode(apiClientConstant.Question.Mode.GENERIC_USER_INPUT)">Single Selector</button>
            <button class="btn-xs mr-3" type="button" [ngClass]="{&quot;btn-primary-outline&quot;: mode !== apiClientConstant.Question.Mode.STICKY_ACTION, &quot;btn-primary&quot;: mode === apiClientConstant.Question.Mode.STICKY_ACTION}" (click)="updateQuestionMode(apiClientConstant.Question.Mode.STICKY_ACTION)">Sticky Selector</button>
            <button class="btn-xs mr-3" type="button" [ngClass]="{&quot;btn-primary-outline&quot;: mode !== apiClientConstant.Question.Mode.PAYMENT, &quot;btn-primary&quot;: mode === apiClientConstant.Question.Mode.PAYMENT}" (click)="updateQuestionMode(apiClientConstant.Question.Mode.PAYMENT)">Regimen Payment</button>
            <button class="btn-xs mr-3" type="button" [ngClass]="{&quot;btn-primary-outline&quot;: mode !== apiClientConstant.Question.Mode.REQUEST_IMAGE, &quot;btn-primary&quot;: mode === apiClientConstant.Question.Mode.REQUEST_IMAGE}" (click)="updateQuestionMode(apiClientConstant.Question.Mode.REQUEST_IMAGE)">REQUEST Image</button>
            <button class="btn-xs mr-3" type="button" [ngClass]="{&quot;btn-primary-outline&quot;: mode !== apiClientConstant.Question.Mode.RICH_TEXT, &quot;btn-primary&quot;: mode === apiClientConstant.Question.Mode.RICH_TEXT}" (click)="updateQuestionMode(apiClientConstant.Question.Mode.RICH_TEXT)">Rich Text</button>
            <button class="btn-xs mr-3" type="button" [ngClass]="{&quot;btn-primary-outline&quot;: mode !== apiClientConstant.Question.Mode.ARTICLE, &quot;btn-primary&quot;: mode === apiClientConstant.Question.Mode.ARTICLE}" (click)="updateQuestionMode(apiClientConstant.Question.Mode.ARTICLE)">Article</button>
          </div>
          <div class="col-xs-6 mt-3" *ngIf="[apiClientConstant.Question.Mode.ARTICLE, apiClientConstant.Question.Mode.RICH_TEXT].includes(mode)">
            <div class="col-xs-12">
              <language-string-selection [parseObj]="questionObj.get(&quot;params&quot;)" name="contentLanguageString" [tags]="[&quot;article content&quot;]" [required]="true"></language-string-selection>
            </div>
            <div class="col-xs-12 mt-3">
              <input-text [parseObj]="questionObj.get(&quot;params&quot;)" required="required" name="thumbnail" placeholder="Thumbnail URL"></input-text>
            </div>
            <div class="col-xs-12 mt-3">
              <input-text [parseObj]="questionObj.get(&quot;params&quot;)" required="required" name="url" placeholder="Content URL"></input-text>
            </div>
          </div>
          <div class="col-xs-12 mt-3" *ngIf="[apiClientConstant.Question.Mode.GENERIC_USER_INPUT, apiClientConstant.Question.Mode.STICKY_ACTION].includes(mode)">
            <button class="btn-xs mr-3" type="button" [ngClass]="{&quot;btn-primary-outline&quot;: questionType !== appConfig.ChatConstants.QUESTION_TYPES.SINGLE_SELECTOR, &quot;btn-primary&quot;: questionType === appConfig.ChatConstants.QUESTION_TYPES.SINGLE_SELECTOR}" (click)="setQuestionType(appConfig.ChatConstants.QUESTION_TYPES.SINGLE_SELECTOR)">Buttons</button>
            <button class="btn-xs mr-3" type="button" [ngClass]="{&quot;btn-primary-outline&quot;: questionType !== appConfig.ChatConstants.QUESTION_TYPES.MESSAGE, &quot;btn-primary&quot;: questionType === appConfig.ChatConstants.QUESTION_TYPES.MESSAGE}" (click)="setQuestionType(appConfig.ChatConstants.QUESTION_TYPES.MESSAGE)">Message</button>
          </div>
        </div>
        <div class="col-xs-12" *ngIf="showOptions">
          <label class="col-xs-4">Options<i class="fas fa-plus-square pointer ml-2" (click)="addOption()"></i></label>
          <div class="col-xs-12 my-3 flex options" *ngFor="let input of questionObj.get(&quot;inputs&quot;);let i = index;">
            <div class="col-xs-11">
              <div class="col-xs-12">
                <div class="col-xs-6 pr-sm-3">
                  <select class="form-control" [(ngModel)]="input.action" name="action_{{i}}" required="required">
                    <ng-container *ngFor="let action of actions">
                      <option [ngValue]="action.key" *ngIf="action.hideFor !== mode">{{action.value}}</option>
                    </ng-container>
                  </select>
                </div>
                <div class="col-xs-6">
                  <select class="form-control" [(ngModel)]="input.type" name="input_type_{{i}}" required="required">
                    <ng-container *ngFor="let inputType of inputTypes">
                      <option [ngValue]="inputType.key" *ngIf="inputType.hideFor !== input.action">{{inputType.value}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="col-xs-12 mt-3">
                <div class="col-xs-4 flex">
                  <label class="m-0 col-xs-6">AutoClick</label>
                  <input-checkbox [parseObj]="input" name="autoClick" [class]="&quot;m-0 bg-success&quot;"></input-checkbox>
                </div>
                <div class="col-xs-4 flex" *ngIf="input.type === apiClientConstant.Question.InputType.TEXT">
                  <label class="col-xs-6">Is Number Input</label>
                  <input-checkbox [parseObj]="input" name="isNumber"></input-checkbox>
                </div>
              </div>
              <div class="col-xs-12 mt-3" *ngIf="input.type === apiClientConstant.Question.InputType.IMAGE">
                <div class="col-xs-12 flex">
                  <div class="col-xs-2">
                    <button class="btn-xs btn-secondary" type="button" (click)="openFileUplaodDialog(i)">Upload Image</button>
                  </div>
                  <div class="col-xs-3">
                    <language-string-selection [parseObj]="input" name="textLanguageString" [tags]="[&quot;tree response&quot;]" [prefix]="i" [required]="true" placeholder="Text (Visible to user)"></language-string-selection>
                  </div>
                  <div class="col-xs-3 ml-3">
                    <input class="form-control" [(ngModel)]="input.value" name="value_{{i}}" placeholder="Value" required="required" disabled="{{input.editDisabled != undefined ? input.editDisabled : false }}"/>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 mt-3" *ngIf="input.type !== apiClientConstant.Question.InputType.IMAGE">
                <div class="col-xs-4">
                  <language-string-selection [parseObj]="input" name="textLanguageString" [tags]="[&quot;tree response&quot;]" [prefix]="i" [required]="true" placeholder="Text (Visible to user)"></language-string-selection>
                </div>
                <div class="col-xs-4">
                  <input class="form-control" [(ngModel)]="input.value" name="value_{{i}}" placeholder="Tree branch value" required="required" disabled="{{input.editDisabled != undefined ? input.editDisabled : false}}"/>
                </div>
              </div>
              <div class="col-xs-12 mt-3">
                <div class="col-xs-12" *ngIf="input.action === apiClientConstant.Question.InputAction.ACTION">
                  <select class="form-control input-xs no-padder" [(ngModel)]="input.params.actionName" name="actionName_{{i}}" required="required">
                    <option value="InstantCheckup">Instant Checkup</option>
                    <option value="Regimen">Regimen</option>
                    <option value="Checkout">Checkout</option>
                    <option value="OpenURL">OpenURL</option>
                    <option value="CloudFunction">CloudFunction</option>
                    <option value="OrderSelection">Order Selection</option>
                    <option value="ProductSelection">Product Selection</option>
                    <option value="OpenInCustomTab">Open In New Tab</option>
                  </select>
                </div>
                <div class="col-xs-12">
                  <label class="col-xs-4"><i class="fas fa-plus-square mr-3 pointer" (click)="addInputTempParam(input)"></i><span>Key</span></label>
                  <label class="col-xs-4 pl-3">Value</label>
                </div>
                <div class="col-xs-12 mt-3 flex" *ngFor="let row of input.keyValue; let i2 = index;">
                  <div class="col-xs-4">
                    <input class="w100" [(ngModel)]="row.key" name="input_{{i}}_key_{{i2}}" placeholder="key"/>
                  </div>
                  <div class="col-xs-4 pl-3">
                    <input class="w100" [(ngModel)]="row.value" name="input_{{i}}_value_{{i2}}" placeholder="value"/>
                  </div>
                  <div class="col-xs-4 pl-3"><a class="col-xs-12" [href]="row.value" target="_blank"><img [src]="row.value" width="100" height="100"/></a></div><i class="fa fa-trash-o pointer pl-2" (click)="removeInputTempParam(input, i2)"></i>
                </div>
              </div>
            </div>
            <div class="col-xs-1 text-center"><i class="fas fa-trash pointer" (click)="removeOption(i)" *ngIf="questionObj.get(&quot;inputs&quot;).length &gt; 1"></i></div>
          </div>
        </div>
        <div class="col-xs-12 mt-3" *ngIf="mode === apiClientConstant.Question.Mode.PAYMENT">
          <input-select [parseObj]="questionObj" name="regimenId" [required]="true" [options]="regimens"></input-select>
        </div>
        <div class="col-xs-12 mt-3">
          <button class="btn" type="submit" [disabled]="!questionForm.valid">{{ questionObj.id ? 'Update' : 'Create' }}</button>
        </div>
      </form>
    </div>
  </div>
</div>